import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    signInConfig: {}
};

const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        updateSignInConfig: (state, action) => {
            let res = action.payload;
            state.signInConfig = res;
        },
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateSignInConfig } = activitySlice.actions;

export const selectActivityData = ({ activity }) => activity;

export default activitySlice.reducer;
