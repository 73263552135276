import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import userInterfaceConfigs from '../main/user-interface/UserInterfaceConfigs';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import dashboardsConfigs from '../main/dashboards/dashboardsConfigs';
import appsConfigs from '../main/apps/appsConfigs';
import pagesConfigs from '../main/pages/pagesConfigs';
import authRoleExamplesConfigs from '../main/auth/authRoleExamplesConfigs';
import DocumentationConfig from '../main/documentation/DocumentationConfig';
import HomeConfig from '../main/home/HomeConfig';
import GameConfig from '../main/game/GameConfig';
import ComingSoonConfig from '../main/comingSoon/ComingSoonConfig';
import PlayConfig from '../main/play/PlayConfig';
import GameOtherConfig from '../main/gameOther/GameOtherConfig';
import AffiliateConfig from '../main/affiliate/AffiliateConfig';
import FundConfig from '../main/fund/FundConfig';
import VipConfig from '../main/vip/VipConfig';
import PhoneChatConfig from '../main/phoneChat/PhoneChatConfig';
import SportMatchConfig from '../main/sport-match/SportMatchConfig';
import SportProjectConfig from '../main/sport-project/SportProjectConfig';
import SportConfig from '../main/sport/SportConfig';
import CookiesConfig from '../main/cookies/CookiesConfig';
import SpinConfig from '../main/spin/SpinConfig';
import ShareConfig from '../main/share/ShareConfig';
import ReliefConfig from '../main/relief/ReliefConfig';
import DepositConfig from '../main/deposit/DepositConfig';
import CourseConfig from '../main/course/CourseConfig';
import ProviderConfig from '../main/Provider/ProviderConfig';
import OfficialAPPConfig from '../main/officialAPP/OfficialAPPConfig';
import MaintenanceConfig from '../main/maintenance/MaintenanceConfig';


const routeConfigs = [
  ...appsConfigs,
  ...dashboardsConfigs,
  ...pagesConfigs,
  ...authRoleExamplesConfigs,
  ...userInterfaceConfigs,
  DocumentationConfig,
  SignOutConfig,
  SignInConfig,
  SignUpConfig,
  HomeConfig,
  ComingSoonConfig,
  PlayConfig,
  GameOtherConfig,
  AffiliateConfig,
  SportMatchConfig,
  SportProjectConfig,
  FundConfig,
  VipConfig,
  PhoneChatConfig,
  SportConfig,
  CookiesConfig,
  SpinConfig,
  GameConfig,
  ShareConfig,
  DepositConfig,
  CourseConfig,
  ProviderConfig,
  OfficialAPPConfig,
  MaintenanceConfig,
  ReliefConfig
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    element: <Navigate to="home" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '*',
    element: <Navigate to="pages/error/404" />,
  },
];

export default routes;
