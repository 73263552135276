import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    topGroup: [],   // 一级分类
};

const topGroupSlice = createSlice({
    name: 'topGroup',
    initialState,
    reducers: {
        updateTagAndGroup: (state, action) => {
            let res = action.payload;
            state.topGroup = res.topGroup;
        },
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateTagAndGroup } = topGroupSlice.actions;

export const selectTopGroup = ({ topGroup }) => topGroup;

export default topGroupSlice.reducer;
