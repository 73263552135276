import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    currentCountryData: {}, // 当前国家数据
};

const userLoginCountrySlice = createSlice({
    name: 'userLoginCountry',
    initialState,
    reducers: {

        updateCurrentCountryData: (state, action) => {
            let res = action.payload;
            state.currentCountryData = res;
        },
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateCurrentCountryData } = userLoginCountrySlice.actions;

export const selectUserLoginCountry = ({ userLoginCountry }) => userLoginCountry;

export default userLoginCountrySlice.reducer;
