  const locale = {
  GAME: '게임',
  Casino: "카지노",
  ProvablyFair: "공정성 입증",
  Option: '옵션',
  Crypto: '암호화폐',
  Forex: '외환',
  Stocks: '주식',
  NFTFuniCard: 'NFT 충전 카드',
  StakeNFT: '베 팅 NFT 벌다 USDT',
  NFTStake: 'NFT베 팅',
  Marketplace: '마켓',
  NFTMint: 'NFT 조폐소',
  BettoEarn: '베팅하여 적립',
  EarnUSGT: '벌다 USDT',
  DeFi: '탈중심화 금융',
  ToBeVolunteer: '자원 봉사하기',
  SYSTEM: '시스템',
  Affiliate: '에이전트',
  News: '뉴스',
  FAQs: '자주 묻는 질문',
  DOWNLOAD: '다운로드',
  DOWNLOADApp: '다운로드 App',
  Savetodesktop: '데스크톱에 저장',
  BetMining: '베팅 마이닝',
  ToBeBanker: '은행가가 되려면',
  ProfitPool: '이익 풀',
  USGTLiquidityMining: 'USDT 유동성 채굴',
  BGTLiquidityMining: 'BGT 유동성 채굴',
  Slots: '슬롯머신',
  Favorite: '즐겨찾기',
  Recent: '최근',
  Popular: "인기",
  LiveCasino: "라이브 카지노",
  TableGames: "테이블 게임",
  BlackJack: "블랙 잭",
  Baccarat: "바카라",
  Roulette: "룰렛",
  MoreGames: "더 많은 게임",
  Provablyfair: "공정성 입증",
  Special: "특별 한",
  Recommend: "추천",
  FeatureBuyin: "기능 구매",
  EnableTrialPlay: "놀이 체험",
  GameShow: "게임 쇼",
  RGSVSB: "RGS - VSB",
  Scratch: "스크래치 카드",
  Bingo: "빙고",
  Fishing: "낚시",
  Turntable: "턴테이블",
  Dice: "주사위",
  Poker: "포커",
  AllGames: "모든 게임",
  EuropeanView: "유럽 뷰",
  AfricanView: "아프리카 뷰",
  LatinView: "라틴어 뷰",
  AsianView: "아시아",
  LsportView: "리그",
  StandardView: "표준",
  EsportView: "전자 스포츠",
  LiveBetting: '라이브 베팅',
  Provider: '공급자',
  Sport: "스포츠",
};

export default locale;
