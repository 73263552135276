const locale = {
    GAME: '遊戲',
    Casino: "賭場",
    ProvablyFair: "區塊鏈遊戲",
    Option: '期權',
    Crypto: '加密貨幣',
    Forex: '外匯',
    Stocks: '股票',
    NFTFuniCard: 'NFT充值卡',
    StakeNFT: '質押NFT賺USDT',
    NFTStake: 'NFT質押',
    Marketplace: '交易所',
    NFTMint: 'NFT鑄造',
    BettoEarn: '賭注',
    EarnUSGT: '賺USDT',
    DeFi: '去中心化金融',
    ToBeVolunteer: '志願者',
    SYSTEM: '系統',
    Affiliate: '代理',
    News: '新聞',
    FAQs: '常見問題',
    DOWNLOAD: '下載',
    DOWNLOADApp: '下載App',
    Savetodesktop: '保存到桌面',
    BetMining: '投注礦池',
    ToBeBanker: '成為股東',
    ProfitPool: '利潤池',
    USGTLiquidityMining: 'USDT流动性挖矿',
    BGTLiquidityMining: 'BGT流动性挖矿',
    Slots: '拉霸',
    Favorite: '最喜歡',
    Recent: '最近的',
    Popular: "受歡迎的",
    LiveCasino: "真人娛樂場",
    TableGames: "桌面遊戲",
    BlackJack: "二十一點",
    Baccarat: "百家樂",
    Roulette: "輪盤賭",
    MoreGames: "更多遊戲",
    Provablyfair: "去中心化遊戲",
    Special: "特別",
    Recommend: "推薦",
    FeatureBuyin: "特征買入",
    EnableTrialPlay: "開啟試玩",
    GameShow: "遊戲秀",
    RGSVSB: "RGS - VSB",
    Scratch: "刮刮卡",
    Bingo: "數字遊戲",
    Fishing: "捕魚",
    Turntable: "轉盤",
    Dice: "骰子",
    Poker: "撲克",
    AllGames: "所有遊戲",
    EuropeanView: "歐洲",
    AfricanView: "非洲",
    LatinView: "拉丁",
    AsianView: "亞洲",
    LsportView: "聯賽",
    StandardView: "標準",
    EsportView: "電競",
    LiveBetting: '即場投注',
    Provider: '供應商',
    Sport:'體育',

};

export default locale;
