import "../../../styles/index.css";
import "../../../styles/xianStyle.css";
import utils from "../../util/tools/utils";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useState, useEffect, forwardRef, default as React } from "react";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import history from "@history";
import { useDispatch, useSelector } from "react-redux";
import {
  getChargeGiftConfig,
  getChargeGiftStatus,
  getChargeGiftReward,
} from "../../store/activity/activityThunk";
import { selectUserData } from "../../store/user";
import AwardPop from "../../components/AwardPop";
import AnimateModal from "../../components/FuniModal";
import { openWallet } from "../../store/user/userThunk";

function Deposit(props) {
  const { t } = useTranslation("mainPage");
  const open = props.open;
  const onClose = props.onClose;

  const dispatch = useDispatch();

  const [chargeGiftConfig, setChargeGiftConfig] = useState({});
  const [chargeGiftStatus, setChargeGiftStatus] = useState({});

  const [popAward, setPopAward] = useState(false);
  const [rewardBalance, setRewardBalance] = useState({
    balance: 0,
    symbol: "eUSDT",
  });

  // 获取充值礼包配置
  const handleGetChargeGiftConfig = () => {
    dispatch(getChargeGiftConfig()).then((res) => {
      let result = res.payload;
      if (result) {
        setChargeGiftConfig(result);
      }
    });
  };

  // 获取充值礼包领取状态
  const handleGetChargeGiftStatus = () => {
    dispatch(getChargeGiftStatus()).then((res) => {
      let result = res.payload;
      if (result) {
        setChargeGiftStatus(result);
      }
    });
  };

  // 领取奖励
  const handleGetChargeGiftReward = (type) => {
    dispatch(
      getChargeGiftReward({
        giftType: type,
      })
    ).then((res) => {
      let result = res.payload;
      if (result) {
        setPopAward(true);
        setRewardBalance({
          balance: result?.RewardCashCoupon / 100,
          symbol: "eUSDT",
        });
        handleGetChargeGiftStatus();
      }
    });
  };

  // 打开充值页面
  const openDepositPage = () => {
    dispatch(
      openWallet({
        type: "deposite",
      })
    );
  };

  useEffect(() => {
    if (open) {
      handleGetChargeGiftConfig();
      handleGetChargeGiftStatus();
    }
  }, [open]);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <>
      {/* 遊戲首衝 */}

      <AnimateModal
        className="depositDi"
        closeClass="closeBtnRelief"
        open={open}
        onClose={onClose}
      >
        {/*还未充值过,引导用户充值*/}
        {(chargeGiftStatus?.HasGotFirst === false ||
          chargeGiftStatus?.RechargeCountAll === 0) && (
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="imgHidden funbetTxt depositDiImg"
              style={{ height: "100%", width: "100%", maxWidth: "360px" }}
            >
              <div
                className="positionAb faGuang turnAni2"
                style={{ top: "0px", bottom: "0px", left: "-16px", right: "0px" }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb titleDeposit titleTxt"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }
                }
              >
                {t("home_DepositFift")}
              </motion.div>
              <div
                className="positionAb bigXcDeposit activityMoveLeftDeposit"
                style={{ top: "48px", right: "0px", bottom: "0px" }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle titleTxt2"
                style={{ top: "60px", left: "24px" }}
              >
                {t("home_First")}
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "102px", left: "24px" }}
              >
                {t("home_DEPOSIT")}
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "128px", left: "24px" }}
              >
                {t("home_ONLY")}
                <span className="bigXcDepositTitle3 ">$1</span>
              </motion.div>
              <div
                className="positionAb bigXcDepositBan flex align-item pb-6 activityMoveRightDeposit"
                style={{ top: "278px" }}
              >
                <div className="pl-8">
                  <div className="depositBanTxt fontBold">{t("home_EXTRA")}</div>
                  <div className="depositBanTxt fontBold">{t("home_Reward")}</div>
                </div>
                <div className="pl-4">
                  <img
                    src={`${React.$imgUrl}/images/index/vip1.png`}
                    style={{ width: "40px", height: "40px" }}
                  ></img>
                  <div
                    className="fontBold textShadow"
                    style={{
                      width: "43px",
                      textAlign: "center",
                      marginTop: "-16px",
                    }}
                  >
                    VIP1
                  </div>
                </div>
                <div
                  className="bigXcDepositTitle3"
                  style={{ color: "#333333", marginLeft: "-2px" }}
                >
                  +
                </div>
                <div className="pl-4">
                  <img
                    src={`${React.$imgUrl}/images/nav/depMoney.png`}
                    style={{ width: "40px", height: "40px" }}
                  ></img>
                </div>
                <div
                  className="bigXcDepositTitle3 titleTxt2"
                  style={{ color: "#333333" }}
                >
                  {chargeGiftConfig?.FirstChargeCashCoupon / 100}%
                </div>
              </div>
              <motion.div
                variants={item}
                className="positionAb "
                style={{
                  top: "360px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }}
              >
                <div className="containerSpinBtn align-item flex justifyContent">
                  <div className="btn">
                    <a
                      onClick={() => {
                        openDepositPage();
                      }}
                      className="titleTxt"
                      style={{ fontSize: "20px", color: "#ffffff" }}
                    >
                      {" "}
                      {t("home_DEPOSIT")}{" "}
                    </a>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}

        {/*第一次充值,首充奖励直接发放,展示已领取*/}
        {chargeGiftStatus?.HasGotFirst === true &&
          chargeGiftStatus?.IsFirstChargeDay === true &&
          chargeGiftStatus?.HasTodayReward === false && (
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="imgHidden funbetTxt depositDiImg"
              style={{ height: "100%", width: "100%", maxWidth: "360px" }}
            >
              <div
                className="positionAb faGuang turnAni2"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "-16px",
                  right: "0px",
                }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb titleDeposit titleTxt"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }
                }
              >
                {t("home_DepositFift")}
              </motion.div>
              <div
                className="positionAb bigXcDeposit activityMoveLeftDeposit"
                style={{ top: "48px", right: "0px", bottom: "0px" }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle titleTxt2"
                style={{ top: "60px", left: "24px" }}
              >
                {t("home_First")}
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "102px", left: "24px" }}
              >
                {t("home_DEPOSIT")}
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "128px", left: "24px" }}
              >
                {t("home_ONLY")}
                <span className="bigXcDepositTitle3">$1</span>
              </motion.div>

              <div
                className="positionAb bigXcDepositBan flex align-item pb-6 activityMoveRightDeposit"
                style={{ top: "278px" }}
              >
                <div className="pl-8">
                  <div className="depositBanTxt fontBold">
                    {t("home_EXTRA")}
                  </div>
                  <div className="depositBanTxt fontBold">
                    {t("home_Reward")}
                  </div>
                </div>
                <div className="pl-4">
                  <img
                    src={`${React.$imgUrl}/images/index/vip1.png`}
                    style={{ width: "40px", height: "40px" }}
                  ></img>
                  <div
                    className="fontBold textShadow"
                    style={{
                      width: "43px",
                      textAlign: "center",
                      marginTop: "-16px",
                    }}
                  >
                    VIP1
                  </div>
                </div>
                <div
                  className="bigXcDepositTitle3 titleTxt2"
                  style={{ color: "#333333", marginLeft: "-2px" }}
                >
                  +
                </div>
                <div className="pl-4">
                  <img
                    src={`${React.$imgUrl}/images/nav/depMoney.png`}
                    style={{ width: "40px", height: "40px" }}
                  ></img>
                </div>
                <div
                  className="bigXcDepositTitle3"
                  style={{ color: "#333333" }}
                >
                  {chargeGiftConfig?.FirstChargeCashCoupon / 100}%
                </div>
              </div>

              <motion.div
                variants={item}
                className="positionAb "
                style={{
                  top: "360px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }}
              >
                <div className="containerSpinBtn align-item flex justifyContent">
                  <div
                    className=" mt-16 titleTxt flex"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    <img
                      src="assets/images/activity/received.png"
                      alt=""
                      className="mr-6"
                    />
                    {t("home_Received")}
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}

        {/* 每日衝 */}
        {/*每日首充(还未充值)*/}
        {chargeGiftStatus?.HasGotFirst === true &&
          chargeGiftStatus?.HasTodayReward === true &&
          chargeGiftStatus?.IsFirstChargeDay === false &&
          chargeGiftStatus?.RechargeCount === 0 &&
          chargeGiftStatus?.RechargeCount !=
          chargeGiftStatus?.RechargeCountAll && (
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="imgHidden funbetTxt scMoneyDi1"
              style={{ height: "100%", width: "100%", maxWidth: "360px" }}
            >
              <div
                className="positionAb faGuang turnAni2"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "-16px",
                  right: "0px",
                }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb titleCzDeposit titleCzDepositBg1 titleTxt "
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }
                }
              >
                {t("home_DepositFift")}
              </motion.div>
              <div
                className="positionAb bigXcDeposit2 activityMoveLeftDeposit"
                style={{ top: "48px", right: "0px", bottom: "0px" }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle titleTxt2"
                style={{ top: "60px", left: "24px" }}
              >
                {t("home_1ST")}
                <span
                  style={{
                    fontSize: "36px",
                    color: "#ffffff",
                    fontFamily: "Furore",
                  }}
                >
                  /
                </span>
                <span
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    marginTop: "-4px",
                  }}
                >
                  {t("home_TODAY")}
                </span>
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "102px", left: "24px" }}
              >
                {t("home_DEPOSIT")}
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "128px", left: "24px" }}
              >
                {t("home_ONLY")}
                <span className="bigXcDepositTitle3">$1</span>
              </motion.div>

              <div
                className="positionAb bigXcDepositBan flex align-item pb-6 activityMoveRightDeposit"
                style={{ top: "278px" }}
              >
                <div className="pl-8">
                  <div className="depositBanTxt fontBold">
                    {t("home_EXTRA")}
                  </div>
                  <div className="depositBanTxt fontBold">
                    {t("home_Reward")}
                  </div>
                </div>
                <div className="pl-12">
                  <img
                    src={`${React.$imgUrl}/images/nav/depMoney.png`}
                    style={{ width: "40px", height: "40px" }}
                  ></img>
                </div>
                <div
                  className="bigXcDepositTitle3 pl-8 titleTxt2"
                  style={{ color: "#333333" }}
                >
                  <span>+</span>
                  {(chargeGiftConfig?.FirstDailyReward / 100).toFixed(2)}%
                </div>
              </div>

              <motion.div
                variants={item}
                className="positionAb "
                style={{
                  top: "360px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }}
              >
                <div className="containerSpinBtn align-item flex justifyContent">
                  <div className="btn titleTxt">
                    <a
                      onClick={() => {
                        openDepositPage();
                      }}
                      style={{ fontSize: "20px", color: "#ffffff" }}
                    >
                      {t("home_DEPOSIT")}
                    </a>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}

        {/*领取每日首充*/}
        {chargeGiftStatus?.HasGotFirst === true &&
          chargeGiftStatus?.HasTodayReward === true &&
          chargeGiftStatus?.IsFirstChargeDay === false &&
          chargeGiftStatus?.RechargeCount >= 1 &&
          chargeGiftStatus?.HasGotDailyFirst === false &&
          chargeGiftStatus?.RechargeCount !=
          chargeGiftStatus?.RechargeCountAll && (
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="imgHidden funbetTxt scMoneyDi1"
              style={{ height: "100%", width: "100%", maxWidth: "360px" }}
            >
              <div
                className="positionAb faGuang turnAni2"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "-16px",
                  right: "0px",
                }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb titleCzDeposit scMoneyTitleDi1 titleTxt"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }
                }
              >
                {t("home_DepositFift")}
              </motion.div>
              <div
                className="positionAb bigXcDeposit2 activityMoveLeftDeposit"
                style={{ top: "48px", right: "0px", bottom: "0px" }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle titleTxt2"
                style={{ top: "60px", left: "24px" }}
              >
                {t("home_1ST")}
                <span
                  style={{
                    fontSize: "36px",
                    color: "#ffffff",
                    fontFamily: "Furore",
                  }}
                >
                  /
                </span>
                <span
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    marginTop: "-4px",
                  }}
                >
                  {t("home_TODAY")}
                </span>
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "102px", left: "24px" }}
              >
                {t("home_DEPOSIT")}
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "128px", left: "24px" }}
              >
                {t("home_ONLY")}
                <span className="bigXcDepositTitle3">$1</span>
              </motion.div>

              <div
                className="positionAb bigXcDepositBan flex align-item pb-6 activityMoveRightDeposit"
                style={{ top: "278px" }}
              >
                <div className="pl-8">
                  <div className="depositBanTxt fontBold">
                    {t("home_EXTRA")}
                  </div>
                  <div className="depositBanTxt fontBold">
                    {t("home_Reward")}
                  </div>
                </div>
                <div className="pl-12">
                  <img
                    src={`${React.$imgUrl}/images/nav/depMoney.png`}
                    style={{ width: "40px", height: "40px" }}
                  ></img>
                </div>
                <div
                  className="bigXcDepositTitle3 pl-8 titleTxt2"
                  style={{ color: "#333333" }}
                >
                  <span>+</span>
                  {(chargeGiftConfig?.FirstDailyReward / 100).toFixed(2)}%
                </div>
              </div>

              <motion.div
                variants={item}
                className="positionAb "
                style={{
                  top: "360px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }}
              >
                <div className="containerSpinBtn align-item flex justifyContent">
                  <div className="btn titleTxt">
                    <a
                      onClick={() => {
                        handleGetChargeGiftReward(3);
                      }}
                      style={{ fontSize: "20px", color: "#ffffff" }}
                    >
                      {t("home_receive")}
                    </a>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}

        {/*每日次充(还未充值)*/}
        {chargeGiftStatus?.HasGotFirst === true &&
          chargeGiftStatus?.HasTodayReward === true &&
          chargeGiftStatus?.IsFirstChargeDay === false &&
          chargeGiftStatus?.RechargeCount === 1 &&
          chargeGiftStatus?.HasGotDailyFirst === true &&
          chargeGiftStatus?.RechargeCount !=
          chargeGiftStatus?.RechargeCountAll && (
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="imgHidden funbetTxt scMoneyDi2"
              style={{ height: "100%", width: "100%", maxWidth: "360px" }}
            >
              <div
                className="positionAb faGuang turnAni2"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "-16px",
                  right: "0px",
                }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb titleCzDeposit scMoneyTitleDi2 titleTxt"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }
                }
              >
                {t("home_DepositFift")}
              </motion.div>
              <div
                className="positionAb bigXcDeposit2 activityMoveLeftDeposit"
                style={{ top: "48px", right: "0px", bottom: "0px" }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle titleTxt2"
                style={{ top: "60px", left: "24px" }}
              >
                {t("home_2ND")}
                <span
                  style={{
                    fontSize: "36px",
                    color: "#ffffff",
                    fontFamily: "Furore",
                  }}
                >
                  /
                </span>
                <span
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    marginTop: "-4px",
                  }}
                >
                  {t("home_TODAY")}
                </span>
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "102px", left: "24px" }}
              >
                {t("home_DEPOSIT")}
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "128px", left: "24px" }}
              >
                {t("home_ONLY")}
                <span className="bigXcDepositTitle3">$1</span>
              </motion.div>

              <div
                className="positionAb bigXcDepositBan flex align-item pb-6 activityMoveRightDeposit"
                style={{ top: "278px" }}
              >
                <div className="pl-8">
                  <div className="depositBanTxt fontBold">
                    {t("home_EXTRA")}
                  </div>
                  <div className="depositBanTxt fontBold">
                    {t("home_Reward")}
                  </div>
                </div>
                <div className="pl-12">
                  <img
                    src={`${React.$imgUrl}/images/nav/depMoney.png`}
                    style={{ width: "40px", height: "40px" }}
                  ></img>
                </div>
                <div
                  className="bigXcDepositTitle3 pl-8 titleTxt2"
                  style={{ color: "#333333" }}
                >
                  <span>+</span>
                  {(chargeGiftConfig?.SecondDailyReward / 100).toFixed(2)}%
                </div>
              </div>

              <motion.div
                variants={item}
                className="positionAb "
                style={{
                  top: "360px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }}
              >
                <div className="containerSpinBtn align-item flex justifyContent">
                  <div className="btn titleTxt">
                    <a
                      onClick={() => {
                        openDepositPage();
                      }}
                      style={{ fontSize: "20px", color: "#ffffff" }}
                    >
                      {t("home_DEPOSIT")}
                    </a>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}

        {/*领取每日次充*/}
        {chargeGiftStatus?.HasGotFirst === true &&
          chargeGiftStatus?.HasTodayReward === true &&
          chargeGiftStatus?.IsFirstChargeDay === false &&
          chargeGiftStatus?.RechargeCount >= 2 &&
          chargeGiftStatus?.HasGotDailyFirst === true &&
          chargeGiftStatus?.HasGotDailySecond === false &&
          chargeGiftStatus?.RechargeCount !=
          chargeGiftStatus?.RechargeCountAll && (
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="imgHidden funbetTxt scMoneyDi2"
              style={{ height: "100%", width: "100%", maxWidth: "360px" }}
            >
              <div
                className="positionAb faGuang turnAni2"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "-16px",
                  right: "0px",
                }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb titleCzDeposit scMoneyTitleDi2 titleTxt"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }
                }
              >
                {t("home_DepositFift")}
              </motion.div>
              <div
                className="positionAb bigXcDeposit2 activityMoveLeftDeposit"
                style={{ top: "48px", right: "0px", bottom: "0px" }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle titleTxt2"
                style={{ top: "60px", left: "24px" }}
              >
                {t("home_2ND")}
                <span
                  style={{
                    fontSize: "36px",
                    color: "#ffffff",
                    fontFamily: "Furore",
                  }}
                >
                  /
                </span>
                <span
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    marginTop: "-4px",
                  }}
                >
                  {t("home_TODAY")}
                </span>
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "102px", left: "24px" }}
              >
                {t("home_DEPOSIT")}
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "128px", left: "24px" }}
              >
                {t("home_ONLY")}
                <span className="bigXcDepositTitle3">$1</span>
              </motion.div>

              <div
                className="positionAb bigXcDepositBan flex align-item pb-6 activityMoveRightDeposit"
                style={{ top: "278px" }}
              >
                <div className="pl-8">
                  <div className="depositBanTxt fontBold">
                    {t("home_EXTRA")}
                  </div>
                  <div className="depositBanTxt fontBold">
                    {t("home_Reward")}
                  </div>
                </div>
                <div className="pl-12">
                  <img
                    src={`${React.$imgUrl}/images/nav/depMoney.png`}
                    style={{ width: "40px", height: "40px" }}
                  ></img>
                </div>
                <div
                  className="bigXcDepositTitle3 pl-8 titleTxt2"
                  style={{ color: "#333333" }}
                >
                  <span>+</span>
                  {(chargeGiftConfig?.SecondDailyReward / 100).toFixed(2)}%
                </div>
              </div>

              <motion.div
                variants={item}
                className="positionAb "
                style={{
                  top: "360px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }}
              >
                <div className="containerSpinBtn align-item flex justifyContent">
                  <div className="btn titleTxt">
                    <a
                      onClick={() => {
                        handleGetChargeGiftReward(4);
                      }}
                      style={{ fontSize: "20px", color: "#ffffff" }}
                    >
                      {t("home_receive")}
                    </a>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}

        {/*每日三充(还未充值)*/}
        {chargeGiftStatus?.HasGotFirst === true &&
          chargeGiftStatus?.HasTodayReward === true &&
          chargeGiftStatus?.IsFirstChargeDay === false &&
          chargeGiftStatus?.RechargeCount === 2 &&
          chargeGiftStatus?.HasGotDailySecond === true &&
          chargeGiftStatus?.RechargeCount !=
          chargeGiftStatus?.RechargeCountAll && (
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="imgHidden funbetTxt scMoneyDi3"
              style={{ height: "100%", width: "100%", maxWidth: "360px" }}
            >
              <div
                className="positionAb faGuang turnAni2"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "-16px",
                  right: "0px",
                }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb titleCzDeposit scMoneyTitleDi3 titleTxt"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }
                }
              >
                {t("home_DepositFift")}
              </motion.div>
              <div
                className="positionAb bigXcDeposit2 activityMoveLeftDeposit"
                style={{ top: "48px", right: "0px", bottom: "0px" }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle titleTxt2"
                style={{ top: "60px", left: "24px" }}
              >
                {t("home_3TH")}
                <span
                  style={{
                    fontSize: "36px",
                    color: "#ffffff",
                    fontFamily: "Furore",
                  }}
                >
                  /
                </span>
                <span
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    marginTop: "-4px",
                  }}
                >
                  {t("home_TODAY")}
                </span>
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "102px", left: "24px" }}
              >
                {t("home_DEPOSIT")}
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "128px", left: "24px" }}
              >
                {t("home_ONLY")}
                <span className="bigXcDepositTitle3">$1</span>
              </motion.div>

              <div
                className="positionAb bigXcDepositBan flex align-item pb-6 activityMoveRightDeposit"
                style={{ top: "278px" }}
              >
                <div className="pl-8">
                  <div className="depositBanTxt fontBold">
                    {t("home_EXTRA")}
                  </div>
                  <div className="depositBanTxt fontBold">
                    {t("home_Reward")}
                  </div>
                </div>
                <div className="pl-12">
                  <img
                    src={`${React.$imgUrl}/images/nav/depMoney.png`}
                    style={{ width: "40px", height: "40px" }}
                  ></img>
                </div>
                <div
                  className="bigXcDepositTitle3 pl-8 titleTxt2"
                  style={{ color: "#333333" }}
                >
                  <span>+</span>
                  {(chargeGiftConfig?.ThirdDailyReward / 100).toFixed(2)}%
                </div>
              </div>

              <motion.div
                className="positionAb "
                style={{
                  top: "360px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }}
              >
                <div className="containerSpinBtn align-item flex justifyContent">
                  <div className="btn titleTxt">
                    <a
                      onClick={() => {
                        openDepositPage();
                      }}
                      style={{ fontSize: "20px", color: "#ffffff" }}
                    >
                      {t("home_DEPOSIT")}
                    </a>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}

        {/*领取每日三充*/}
        {chargeGiftStatus?.HasGotFirst === true &&
          chargeGiftStatus?.HasTodayReward === true &&
          chargeGiftStatus?.IsFirstChargeDay === false &&
          chargeGiftStatus?.RechargeCount >= 3 &&
          chargeGiftStatus?.HasGotDailyFirst === true &&
          chargeGiftStatus?.HasGotDailySecond === true &&
          chargeGiftStatus?.HasGotDailyThird === false &&
          chargeGiftStatus?.RechargeCount !=
          chargeGiftStatus?.RechargeCountAll && (
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="imgHidden funbetTxt scMoneyDi3"
              style={{ height: "100%", width: "100%", maxWidth: "360px" }}
            >
              <div
                className="positionAb faGuang turnAni2"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "-16px",
                  right: "0px",
                }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb titleCzDeposit scMoneyTitleDi3 titleTxt"
                style={
                  {
                    top: "0px",
                    bottom: "0px",
                    left: "0px",
                    right: "0px",
                    margin: "0 auto",
                  }
                }
              >
                {t("home_DepositFift")}
              </motion.div>
              <div
                className="positionAb bigXcDeposit2 activityMoveLeftDeposit"
                style={{ top: "48px", right: "0px", bottom: "0px" }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle titleTxt2"
                style={{ top: "60px", left: "24px" }}
              >
                {t("home_3TH")}
                <span
                  style={{
                    fontSize: "36px",
                    color: "#ffffff",
                    fontFamily: "Furore",
                  }}
                >
                  /
                </span>
                <span
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    marginTop: "-4px",
                  }}
                >
                  {t("home_TODAY")}
                </span>
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "102px", left: "24px" }}
              >
                {t("home_DEPOSIT")}
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "128px", left: "24px" }}
              >
                {t("home_ONLY")}
                <span className="bigXcDepositTitle3">$1</span>
              </motion.div>

              <div
                className="positionAb bigXcDepositBan flex align-item pb-6 activityMoveRightDeposit"
                style={{ top: "278px" }}
              >
                <div className="pl-8">
                  <div className="depositBanTxt fontBold">
                    {t("home_EXTRA")}
                  </div>
                  <div className="depositBanTxt fontBold">
                    {t("home_Reward")}
                  </div>
                </div>
                <div className="pl-12">
                  <img
                    src={`${React.$imgUrl}/images/nav/depMoney.png`}
                    style={{ width: "40px", height: "40px" }}
                  ></img>
                </div>
                <div
                  className="bigXcDepositTitle3 pl-8 titleTxt2"
                  style={{ color: "#333333" }}
                >
                  <span>+</span>
                  {(chargeGiftConfig?.ThirdDailyReward / 100).toFixed(2)}%
                </div>
              </div>

              <motion.div
                variants={item}
                className="positionAb "
                style={{
                  top: "360px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }}
              >
                <div className="containerSpinBtn align-item flex justifyContent">
                  <div className="btn titleTxt">
                    <a
                      onClick={() => {
                        handleGetChargeGiftReward(5);
                      }}
                      style={{ fontSize: "20px", color: "#ffffff" }}
                    >
                      {t("home_receive")}
                    </a>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}

        {/*三次充值奖励已全部领取*/}
        {chargeGiftStatus?.HasGotFirst === true &&
          chargeGiftStatus?.HasTodayReward === true &&
          chargeGiftStatus?.IsFirstChargeDay === false &&
          chargeGiftStatus?.RechargeCount >= 3 &&
          chargeGiftStatus?.HasGotDailyFirst === true &&
          chargeGiftStatus?.HasGotDailySecond === true &&
          chargeGiftStatus?.HasGotDailyThird === true &&
          chargeGiftStatus?.RechargeCount !=
          chargeGiftStatus?.RechargeCountAll && (
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="imgHidden funbetTxt scMoneyDi3"
              style={{ height: "100%", width: "100%", maxWidth: "360px" }}
            >
              <div
                className="positionAb faGuang turnAni2"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "-16px",
                  right: "0px",
                }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb titleCzDeposit scMoneyTitleDi3 titleTxt"
                style={{
                  top: "0px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                  fontSize: "16px",
                }
                }
              >
                {t("home_DepositFift")}
              </motion.div>
              <div
                className="positionAb bigXcDeposit2 activityMoveLeftDeposit"
                style={{ top: "48px", right: "0px", bottom: "0px" }}
              ></div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle titleTxt2"
                style={{ top: "60px", left: "24px" }}
              >
                {t("home_3TH")}
                <span
                  style={{
                    fontSize: "36px",
                    color: "#ffffff",
                    fontFamily: "Furore",
                  }}
                >
                  /
                </span>
                <span
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    marginTop: "-4px",
                  }}
                >
                  {t("home_TODAY")}
                </span>
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "102px", left: "24px" }}
              >
                {t("home_DEPOSIT")}
              </motion.div>
              <motion.div
                variants={item}
                className="positionAb bigXcDepositTitle2 titleTxt2"
                style={{ top: "128px", left: "24px" }}
              >
                {t("home_ONLY")}
                <span className="bigXcDepositTitle3">$1</span>
              </motion.div>

              <div
                className="positionAb bigXcDepositBan flex align-item pb-6 activityMoveRightDeposit"
                style={{ top: "278px" }}
              >
                <div className="pl-8">
                  <div className="depositBanTxt fontBold">
                    {t("home_EXTRA")}
                  </div>
                  <div className="depositBanTxt fontBold">
                    {t("home_Reward")}
                  </div>
                </div>
                <div className="pl-12">
                  <img
                    src={`${React.$imgUrl}/images/nav/depMoney.png`}
                    style={{ width: "40px", height: "40px" }}
                  ></img>
                </div>
                <div
                  className="bigXcDepositTitle3 pl-8 titleTxt2"
                  style={{ color: "#333333" }}
                >
                  <span>+</span>
                  {(chargeGiftConfig?.ThirdDailyReward / 100).toFixed(2)}%
                </div>
              </div>

              <motion.div
                variants={item}
                className="positionAb "
                style={{
                  top: "360px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "0 auto",
                }}
              >
                <div className="containerSpinBtn align-item flex justifyContent">
                  <div
                    className=" mt-16 titleTxt flex"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    <img
                      src="assets/images/activity/received.png"
                      alt=""
                      className="mr-6"
                    />
                    {t("home_Received")}
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}
      </AnimateModal>

      <AwardPop
        open={popAward}
        onClose={() => {
          setPopAward(false);
        }}
        symbol={rewardBalance.symbol}
        symbolImg={`assets/images/symbol/${rewardBalance.symbol}.png`}
        balance={rewardBalance.balance}
      />
    </>
  );
}
export default Deposit;
