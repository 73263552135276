const locale = {
    GAME: '游戏',
    Casino: "赌场",
    ProvablyFair: "区块链游戏",
    Option: '期权',
    Crypto: '加密货币',
    Forex: '外汇',
    Stocks: '股票',
    NFTFuniCard: 'NFT充值卡',
    StakeNFT: '质押NFT赚USDT',
    NFTStake: 'NFT质押',
    Marketplace: '交易所',
    NFTMint: 'NFT铸造',
    BettoEarn: '赌注',
    EarnUSGT: '赚USDT',
    DeFi: '去中心化金融',
    ToBeVolunteer: '志愿者',
    SYSTEM: '系统',
    Affiliate: '代理',
    News: '新闻',
    FAQs: '常见问题',
    DOWNLOAD: '下载',
    DOWNLOADApp: '下载App',
    Savetodesktop: '保存到桌面',
    BetMining: '投注矿池',
    ToBeBanker: '成为股东',
    ProfitPool: '利润池',
    USGTLiquidityMining: 'USDT流动性挖矿',
    BGTLiquidityMining: 'BGT流动性挖矿',
    Slots: '拉霸',
    Favorite: '最喜欢',
    Recent: '最近的',
    Popular: "受欢迎的",
    LiveCasino: "真人娱乐场",
    TableGames: "桌面游戏",
    BlackJack: "二十一点",
    Baccarat: "百家乐",
    Roulette: "轮盘赌",
    MoreGames: "更多游戏",
    Provablyfair: "去中心化游戏",
    Special: "特别",
    Recommend: "推荐",
    FeatureBuyin: "特征买入",
    EnableTrialPlay: "开启试玩",
    GameShow: "游戏秀",
    RGSVSB: "RGS - VSB",
    Scratch: "刮刮卡",
    Bingo: "数字游戏",
    Fishing: "捕鱼",
    Turntable: "转盘",
    Dice: "骰子",
    Poker: "扑克",
    Sport: "体育",
    AllGames: "所有游戏",
    EuropeanView: "欧洲",
    AfricanView: "非洲",
    LatinView: "拉丁",
    AsianView: "亚州",
    LsportView: "联赛",
    StandardView: "标准",
    EsportView: "电竞",
    LiveBetting:'现场投注',
    Provider:'供应商',
};

export default locale;
