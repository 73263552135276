import { createAsyncThunk } from '@reduxjs/toolkit';
import React from "react";
import history from '@history';
import { showMessage } from 'app/store/fuse/messageSlice';
import { updateUserToken, updateIsUserInfoLoading } from "./index";
import { updateUser } from "./userInfo";
import { updateUserSettingSetting, updateUserSettingUser, updateIsUserSettingLoading } from "./userSetting";
import { updateUserSettingScore } from "./userGameScore";
import { updateUserSettingProperties } from "./userProperties";
import utils from "../../util/tools/utils";
import BN from "bn.js";
// import { updateUserSetting } from "./index";
import { showMaintainPage } from "app/store/config/maintainConfig";
import { updateUserFiats, updateUserSymbols } from "./userAssets";
import { updateAllFiats } from "../config/fiats";
import { updateAllSymbols } from "../config/symbols";

// 打开登录页
export const onLogin = createAsyncThunk(
    'user/onLogin',
    (settings, { dispatch, getState }) => {
        let callback = settings.callback ?? (() => { });
        dispatch(updateIsUserInfoLoading(true));
        FuniBox.Tools.default.openWallet(settings.type ?? 'login', {}, function () {
            const token = FuniBox.Tools.default.getToken() || localStorage.getItem('Authorization');
            if (token && token !== 'undefined') {
                FuniBox.Tools.default.getUserInfo().then(async (res) => {
                    if (res) {
                        // dispatch(userProfile({
                        //     token: token,
                        //     userData: res
                        // }));
                        const userInfo = res;

                        // 获取用户信息
                        const getFuniBoxUserAmount = async (userInfo, token, callback) => {
                            var allFiats = {};
                            var allSymbols = {};
                            var userFiats = {};
                            var userSymbols = {};

                            // 获取所有法币
                            var allFiatsRes = await FuniBox.Tools.default.getAllFiats();
                            if (allFiatsRes) {
                                allFiats = allFiatsRes.currency;
                                // dispatch(updateAllFiats(allFiatsRes.currency));
                            }

                            // 获取所有虚拟币
                            var allSymbolsRes = await FuniBox.Tools.default.getAllSymbols();
                            if (allSymbolsRes) {
                                allSymbols = allSymbolsRes;
                            }

                            // 获取用户法币余额
                            var userFiatsRes = await FuniBox.Tools.default.getUserFiat();
                            if (userFiatsRes) {
                                userFiats = userFiatsRes;
                            }

                            // 获取用户虚拟币余额
                            var userSymbolsRes = await FuniBox.Tools.default.getCenterGetTokenBalanceList();
                            if (userSymbolsRes) {
                                userSymbols = userSymbolsRes;
                            }

                            if (
                                token.length > 0 &&
                                JSON.stringify(userInfo) !== '{}' &&
                                JSON.stringify(allFiats) !== '{}' &&
                                JSON.stringify(allSymbols) !== '{}' &&
                                JSON.stringify(userFiats) !== '{}'
                            ) {
                                // dispatch(initialUpdateUser({
                                //     token,
                                //     userInfo,
                                //     // allFiats,
                                //     // allSymbols
                                // }));

                                dispatch(updateUserToken(token));
                                dispatch(updateUser(userInfo))
                                dispatch(updateAllSymbols(allSymbols));
                                dispatch(updateAllFiats(allFiats));
                                dispatch(updateUserFiats(userFiats));
                                dispatch(updateUserSymbols(userSymbols));
                                dispatch(getUserSetting({
                                    callback: callback
                                }));
                                dispatch(updateIsUserInfoLoading(false));
                            }
                        };

                        await getFuniBoxUserAmount(userInfo, token, callback);

                        // 每次完成登陆后，清除之前的弹出绑定手机
                        window.localStorage.setItem('mobileBindPhone', 0);
                        // var timer = setInterval(() => {
                        //     // if (token.length > 0 && JSON.stringify(userInfo) !== '{}' && JSON.stringify(allFiats) !== '{}' && JSON.stringify(allSymbols) !== '{}' && JSON.stringify(userFiats) !== '{}' && JSON.stringify(userSymbols) !== '{}') {
                        //     if (token.length > 0 && JSON.stringify(userInfo) !== '{}' && JSON.stringify(allFiats) !== '{}' && JSON.stringify(allSymbols) !== '{}' && JSON.stringify(userFiats) !== '{}') {
                        //         clearInterval(timer);
                        //         dispatch(initialUpdateUser({
                        //             token,
                        //             userInfo,
                        //             allFiats,
                        //             allSymbols,
                        //             userFiats,
                        //             userSymbols,
                        //         }));
                        //         // 获取用户信息
                        //         dispatch(getUserSetting({
                        //             callback: callback
                        //         }));
                        //     }
                        // }, 100);
                    } else {
                        dispatch(updateIsUserInfoLoading(false));
                    }
                });
            } else {
                dispatch(updateIsUserInfoLoading(false));
                callback();
            }

        })
    }
);

export const openWallet = createAsyncThunk(
    'user/openWallet',
    (settings, { dispatch, getState }) => {
        const type = settings.type ?? 'wallet';
        const data = settings.data ?? {};
        const callbackFun = settings?.callback ?? (() => { });

        const callback = function () {
            // dispatch(updateIsUserSettingLoading(true));
            dispatch(getUserSetting({
                callback: callbackFun
            }));
        };

        const asyncData = () => {
            React.$api("gamecenter.saveToWallet", {
                USDT: true,
                eUSDT: true,
                Other: true,
                Kick: true,
            });
        };

        asyncData();
        // switch (type) {
        //     case 'wallet':
        //         asyncData();
        //         break;
        //     case 'account':
        //         asyncData();
        //         break;
        //     case 'buy':
        //         asyncData();
        //         break;
        // }
        FuniBox.Tools.default.openWallet(type, data, callback);
    }
);

// 将余额转至钱包
export const doSaveToWallet = createAsyncThunk(
    'user/doSaveToWallet',
    async (settings, { dispatch, getState }) => {
        const result = await React.$api("gamecenter.saveToWallet", {
            USDT: true,
            eUSDT: true,
            Other: true,
            Kick: true,
        });

        if (result.errno === 0) {
            // 获取用户余额
            const getFuniBoxUserAmount = async () => {
                await dispatch(getUserSetting({
                    callback: () => { }
                }))
                // 获取用户法币余额
                const userFiat = await FuniBox.Tools.default.getUserFiat()
                await dispatch(updateUserFiats(userFiat));

                // 获取用户虚拟币余额
                const userSymbols = await FuniBox.Tools.default.getCenterGetTokenBalanceList()
                await dispatch(updateUserSymbols(userSymbols));
            };

            await getFuniBoxUserAmount();
            return true
        } else {
            dispatch(showMessage({ message: result.errmsg, code: 2 }));
        }
        return false
    }
);

// 设置用户推广ID
export const setSpreaderInfo = createAsyncThunk(
    'user/setSpreaderInfo',
    async (settings, { dispatch, getState }) => {
        if (settings.spreaderID) {
            await React.$api("gamecenter.setSpreaderInfo", {
                spreaderID: settings.spreaderID
            });
        }
    }
);

// 获取用户聊天室Token
export const getChatToken = createAsyncThunk(
    'user/getChatToken',
    async (settings, { dispatch, getState }) => {
        const result = await React.$api("gamecenter.getChatToken");
        if (result.errno === 0) {
            return result.data
        } else {
            // dispatch(showMessage({ message: result.errmsg, code: 2 }));
            return false
        }
    }
);

// 获取Sport Token
export const getSportToken = createAsyncThunk(
    'user/getSportToken',
    async (settings, { dispatch, getState }) => {
        const result = await React.$api("gamecenter.queryUserToken", settings);
        if (result.errno === 0) {
            return {
                code: 0,
                data: result.data
            }
        } else {
            if (result.data && result.data.code == 500) {
                dispatch(showMaintainPage({
                    isShow: true,
                    startTime: result.data.startDate,
                    endTime: result.data.endDate,
                }));
            } else {
                dispatch(showMessage({ message: result.errmsg, code: 2 }));
            }
            return {
                code: -1,
                data: result.errmsg
            }
        }
    }
);

// 获取用户信息
export const userProfile = createAsyncThunk(
    'user/userProfile',
    (settings, { dispatch, getState }) => {
        const token = settings.token || '';
        const userData = settings.userData || {};
        dispatch(updateUserToken(token));
        dispatch(updateUser(userData));
        dispatch(updateIsUserInfoLoading(false));
        // const userProfile = await React.$api("user.profile");
        // if (userProfile.errno === 0) {
        //     dispatch(updateUser(userProfile));
        // } else {
        //     dispatch(showMessage({ message: userProfile.errmsg }));
        // }
    }
);

// 获取用户信息和配置
export const getUserSetting = createAsyncThunk(
    'user/getUserSetting',
    (settings, { dispatch, getState }) => {
        const callback = settings.callback ?? (() => { });

        React.$api("gamecenter.getUserSetting").then((result) => {
            if (result.errno === 0) {
                const getFuniBoxUserAmount = async () => {
                    // await dispatch(doSaveToWallet())

                    // const userFiats = await FuniBox.Tools.default.getUserFiat()
                    // console.log(userFiats, 'userFiats......')
                    // dispatch(updateUserFiats(userFiats));
                    //
                    // const userSymbols = await FuniBox.Tools.default.getCenterGetTokenBalanceList()
                    // console.log(userSymbols, 'userSymbols......')
                    // dispatch(updateUserSymbols(userSymbols));
                };


                Promise.all([
                    // getFuniBoxUserAmount(),
                    dispatch(updateUserSettingProperties(result.data?.properties || {})),
                    dispatch(updateUserSettingScore(result.data?.score || {})),
                    dispatch(updateUserSettingSetting(result.data?.setting || {})),
                    dispatch(updateUserSettingUser(result.data?.user || {})),
                ]).then(() => {
                    callback();
                })
            } else {
                callback();
                dispatch(updateUserToken(''))
                dispatch(showMessage({ message: result.errmsg, code: 2 }));
            }
        });
    }
);

// 设置用户信息
export const setUserSetting = createAsyncThunk(
    'user/setUserSetting',
    (settings, { dispatch, getState }) => {
        const lang = settings.lang || '';
        const currencyType = settings.currencyType || '';
        const symbol = settings.symbol || '';
        const fiatCode = settings.fiatCode || '';

        React.$api("gamecenter.setUserSetting", {
            lang,
            currencyType,
            symbol,
            fiatCode,
        }).then((result) => {
            if (result.errno === 0) {
                dispatch(showMessage({ message: 'success', code: 1 }));
            } else {
                dispatch(showMessage({ message: result.errmsg, code: 2 }));
            }
        });
    }
);

// 设置用户头像/昵称
export const setGameProfile = createAsyncThunk(
    'user/setUserSetting',
    async (settings, { dispatch, getState }) => {
        const faceID = settings.faceID;
        const nickName = settings.nickName;

        const result = await React.$api("gamecenter.setGameProfile", {
            faceID,
            nickName,
        });

        if (result.errno === 0) {
            dispatch(showMessage({ message: 'success', code: 1 }));
            return true
        } else {
            dispatch(showMessage({ message: result.errmsg, code: 2 }));
        }
    }
);

// 收藏 / 取消收藏
export const setGameFavor = createAsyncThunk(
    'user/setGameFavor',
    async (settings, { dispatch, getState }) => {
        const casinoID = settings.casinoID;
        const bRemove = settings.bRemove;

        const result = await React.$api("gamecenter.setGameFavor", {
            casinoID,
            bRemove,
        });

        if (result.errno === 0) {
            dispatch(showMessage({ message: 'success', code: 1 }));
            return result.data
        } else {
            dispatch(showMessage({ message: result.errmsg, code: 2 }));
        }
    }
);

// 管理员设置分类
export const setCasinoGameTagList = createAsyncThunk(
    'user/setCasinoGameTagList',
    async (settings, { dispatch, getState }) => {
        const channel = settings.channel || '';
        const gameID = settings.gameID || '';
        const mainTagID = settings.mainTagID;
        const addTagID = settings.addTagID;
        const removeTagID = settings.removeTagID;

        const result = await React.$api("admin.setCasinoGameTagList", {
            channel,
            gameID,
            mainTagID,
            addTagID,
            removeTagID,
        });
        if (result.errno === 0) {
            dispatch(showMessage({ message: 'success', code: 1 }));
        } else {
            dispatch(showMessage({ message: result.errmsg, code: 2 }));
        }
    }
);

// 发送手机验证码
export const sendSms = createAsyncThunk(
    'user/sendSms',
    async (settings, { dispatch, getState }) => {
        let data = {
            nationCode: settings.nationCode,
            phone: settings.phone,
            codeType: settings.codeType
        };
        const res = await React.$api("user.sendSms", data);
        if (res?.errno === 0) {
            dispatch(showMessage({ message: 'success', code: 1 }));
            return true
        } else {
            const msg = res?.errmsg;
            dispatch(showMessage({ message: msg, code: 2 }));
            return false
        }
    }
);

// 发送邮件验证码
export const sendEmail = createAsyncThunk(
    'user/sendEmail',
    async (settings, { dispatch, getState }) => {
        let data = {
            email: settings.email,
            codeType: settings.codeType
        };
        const res = await React.$api("user.sendEmail", data);
        if (res?.errno === 0) {
            dispatch(showMessage({ message: 'success', code: 1 }));
            return true
        } else {
            const msg = res?.errmsg;
            dispatch(showMessage({ message: msg, code: 2 }));
            return false
        }
    }
);

// 计算用户的总资产
export const countUserAllAssets = createAsyncThunk(
    'user/countUserAllAssets',
    async (settings, { dispatch, getState }) => {
        let userAssets = 0;

        return userAssets
    }
);

export const initUserData = createAsyncThunk(
    'user/initUserData',
    async (settings, { dispatch, getState }) => {
        let data = {
            email: settings.email,
            codeType: settings.codeType
        };
        const res = await React.$api("user.sendEmail", data);
        if (res?.errno === 0) {
            dispatch(showMessage({ message: 'success', code: 1 }));
            return true
        } else {
            const msg = res?.errmsg;
            dispatch(showMessage({ message: msg, code: 2 }));
            return false
        }
    }
);
// String feedbackTitle, String feedbackContent, String imageUrl
//添加建议
export const getInsert = createAsyncThunk(
    'gameFeedback/insertGameFeedback',
    async (settings, { dispatch, getState }) => {
        let data = {
            feedbackTitle: settings.title,
            feedbackContent: settings.content,
            imageUrl: settings.imgurl
        };
        const res = await React.$api("user.getInsert", data)

        if (res?.errno === 0) {
            dispatch(showMessage({ message: 'success', code: 1 }));
            return res.data;
        } else {
            const msg = res?.errmsg;
            dispatch(showMessage({ message: msg, code: 2 }));
            return false
        }
    }
);
//查询建议
export const getFeedBack = createAsyncThunk(
    'gameFeedback/getGameFeedback',
    async (settings, { dispatch, getState }) => {
        let data = {
            IsProcessed: settings.IsProcessed
        };
        const res = await React.$api("user.getFeedBack", data)
        if (res?.errno === 0) {
            return res.data;
        } else {
            // const msg = res?.errmsg;
            // dispatch(showMessage({ message: msg, code: 2 }));
            return false
        }
    }
)

//查询交易记录
export const getTransRecord = createAsyncThunk(
    'gameFeedback/getTransRecord',
    async (settings, { dispatch, getState }) => {
        let data = {};
        const res = await React.$api("user.getTransRecord", data)
        if (res?.errno === 0) {
            return res.data;
        } else {
            // const msg = res?.errmsg;
            // dispatch(showMessage({ message: msg, code: 2 }));
            return false
        }
    }
)

//上传图片
export const getUpload = createAsyncThunk(
    'oauth/storage/upload',
    async (settings, { dispatch, getState }) => {
        let data = {
            file: settings.url,
        };
        const res = await React.$api("user.getUpload", data)
        return res.data;
    }
)

//入金通知
export const depositNotify = createAsyncThunk(
    '/funiBox/depositNotify',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("user.depositNotify")
        return res.data;
    }
)

// 判断是否领取过手机下载APP并登陆奖励
export const checkAwardOfDownLoadApp = createAsyncThunk(
    '/activity/checkAwardOfDownLoadApp',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.checkAwardOfDownLoadApp")
        if (res.errno === 0) {
            if (!res.data) {
                dispatch(getAwardOfDownLoadApp())
            }
            return true
        }
    }
)

// 手机下载APP并登陆领取奖励
export const getAwardOfDownLoadApp = createAsyncThunk(
    '/activity/getAwardOfDownLoadApp',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.getAwardOfDownLoadApp")
        if (res.errno === 0) {
            return res.data;
        }
    }
)