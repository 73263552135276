import * as React from 'react';
import { useContext } from "react";
import { useRoutes } from 'react-router-dom';
import AppContext from 'app/AppContext';
import FuseSuspense from '@fuse/core/FuseSuspense';
import PhoneHeader from './components/PhoneHeader';
import PhoneMenu from './components/PhoneMenu';
import themeLayouts from "app/theme-layouts/themeLayouts";
import FuseLayout from "@fuse/core/FuseLayout/FuseLayout";

function PhoneLayout(props) {
    const appContext = useContext(AppContext);
    const { routes } = appContext;

    return (
        <div
            style={{
                width: '100%',
                color: '#ffffff',
                transform: 'none',
                // overflowY: 'scroll',
                overflow: "hidden",
                backgroundColor: "#111827"
            }}
        >
            {/* <div id="loginZheZhao" style={{ position: "fixed", zIndex: "999", top: "0", bottom: "0px", left: "0px", right: "0px", backgroundColor: "#0E1421", display: 'none' }}></div> */}
            {/*<div className="hiddenIt">*/}
            {/*    <PhoneHeader ></PhoneHeader>*/}
            {/*</div>*/}
            <div className="betDiColor phoneContMT" style={{
                minHeight: '100%'
            }}>
                <FuseLayout layouts={themeLayouts} />
                {/*<FuseSuspense >{useRoutes(routes)}</FuseSuspense>*/}
            </div>
            <div className="hiddenIt">
                <PhoneMenu ></PhoneMenu>
            </div>
        </div>
    );

}
export default PhoneLayout;
