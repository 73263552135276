import Share from './Share';


const ShareConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'share',
            element: <Share />,
        },
    ],
};

export default ShareConfig;