import Cookies from './Cookies';

const CookiesConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'cookies',
            element: <Cookies />,
        },
    ],
};

export default CookiesConfig;