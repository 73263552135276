import GameOther from './GameOther';


const GameOtherConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'gameOther/:typeId/:typeName/:isTagGroup',
            element: <GameOther />,
        },
    ],
};

export default GameOtherConfig;
