import "../../../styles/index.css";
import "../../../styles/xianStyle.css";
import utils from "../../util/tools/utils";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useState, useEffect, forwardRef, default as React } from "react";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import history from "@history";
import { useDispatch, useSelector } from "react-redux";
import {
  getTurnConfig,
  doTurn,
  getTurnRecord,
  getVIPConfig,
} from "../../store/activity/activityThunk";
import { selectUserData } from "../../store/user";
import { selectUserSetting } from "app/store/user/userSetting";
import AwardPop from "../../components/AwardPop";
import { getUserSetting } from "../../store/user/userThunk";
import { selectUserProperties } from "app/store/user/userProperties";

function Spin(props) {
  const { t } = useTranslation("mainPage");
  const dispatch = useDispatch();
  const [turnConfig, setTurnConfig] = useState([]);
  const [turnRecord, setTurnRecord] = useState({});
  const [offOn, setOffOn] = useState(false);
  const [userTurnTimes, setUserTurnTime] = useState(0);
  const [spinRewardBalance, setSpinRewardBalance] = useState({
    balance: 0,
    symbol: "USDT",
  });
  const [vipConfig, setVipConfig] = useState({});
  const [chargeTotal, setChargeTotal] = useState(0);
  const [betTotal, setBetTotal] = useState(0);
  const [vipLevel, setVipLevel] = useState(0);
  const [vipExperience, setVipExperience] = useState(0.0);

  const userData = useSelector(selectUserData);
  const userSetting = useSelector(selectUserSetting);
  const userProperties = useSelector(selectUserProperties);

  const [popAward, setPopAward] = useState(false);
  const [vipActive, setVipActive] = useState(0);
  const vipImgList = [
    {
      turnBackGround: "https://scource-static.funibet.com/funibet/images/nav/diPan2.png",
      turnPointerImg: "https://scource-static.funibet.com/funibet/images/nav/diPan0.png",
      turnTipImg: "https://scource-static.funibet.com/funibet/images/nav/diPan5.png",
      src1: "https://scource-static.funibet.com/funibet/images/nav/san1.png",
      src2: "https://scource-static.funibet.com/funibet/images/nav/san2.png",
      turnBackGroundColor: "#653120",
    },

    {
      turnBackGround: "https://scource-static.funibet.com/funibet/images/nav/diPan2_1.png",
      turnPointerImg: "https://scource-static.funibet.com/funibet/images/nav/diPan0.png",
      turnTipImg: "https://scource-static.funibet.com/funibet/images/nav/diPan5_1.png",
      src1: "https://scource-static.funibet.com/funibet/images/nav/san1.png",
      src2: "https://scource-static.funibet.com/funibet/images/nav/san2.png",
      turnBackGroundColor: "#A03426",
    },

    {
      turnBackGround: "https://scource-static.funibet.com/funibet/images/nav/diPan2_2.png",
      turnPointerImg: "https://scource-static.funibet.com/funibet/images/nav/diPan0_1.png",
      turnTipImg: "https://scource-static.funibet.com/funibet/images/nav/diPan5_2.png",
      src1: "https://scource-static.funibet.com/funibet/images/nav/san3.png",
      src2: "https://scource-static.funibet.com/funibet/images/nav/san4.png",
      turnBackGroundColor: "#9856F5",
    },

  ];

  // VIP等级
  const handleGetVIPConfig = () => {
    dispatch(getVIPConfig()).then((res) => {
      let result = res.payload;
      if (result) {
        let tmpConfig = {};
        result.map((item) => {
          tmpConfig[item.VIPLevel] = item;
        });
        setVipConfig(tmpConfig);
      }
    });
  };

  // 转盘
  const handleGetTurnConfig = () => {
    dispatch(getTurnConfig()).then((res) => {
      let result = res.payload;
      setTurnConfig(result);
    });
  };

  // 转盘记录
  const handleGetTurnRecord = () => {
    dispatch(getTurnRecord()).then((res) => {
      let result = res.payload;
      setTurnRecord(result);
    });
  };

  // 转动转盘
  const handleDoTurn = async () => {
    let res = await dispatch(doTurn());
    let result = res.payload;
    if (result.RemainTimes <= 0) {
      notSpinClick();
    }
    setUserTurnTime(result.RemainTimes);
    setSpinRewardBalance({
      balance: result.AwardCashCouponNum / 100,
      symbol: "USDT",
    });
    dispatch(getUserSetting({
      callback: () => {}
    }));
    return result;
  };

  // 可选中状态
  const canSpinClick = () => {
    var divDom = document.getElementById("spin-div-rata");
    var aDom = document.getElementById("spin-a-rata");

    if (divDom && aDom) {
      divDom.classList.remove("btn-disable");
      aDom.classList.remove("spin-a-rata-disable");
    }
  };

  // 不可选中状态
  const notSpinClick = () => {
    var divDom = document.getElementById("spin-div-rata");
    var aDom = document.getElementById("spin-a-rata");

    if (divDom && aDom) {
      divDom.classList.add("btn-disable");
      aDom.classList.add("spin-a-rata-disable");
    }
  };

  const spinRata = async () => {
    // 可以直接打开奖励这两行
    // setPopAward(true);
    // return
    if (userTurnTimes <= 0) {
      return false;
    }

    // 正在转动
    if (offOn) {
      return false;
    }

    // 真正会去调用接口
    let doTurnResult = await handleDoTurn();
    if (!doTurnResult) {
      return false;
    }

    // 测试数据
    // let doTurnResult = {
    //     ItemID: 5
    // };

    var oTurntable = document.getElementById("spin");
    var cat = 22.5; //总共12个扇形区域，每个区域约30度
    var num = 0; //转圈结束后停留的度数
    if (!offOn) {
      oTurntable.style.transition = "all 4s";
      oTurntable.style.transform = "rotate(0deg)";
      setOffOn(true);
      ratating();
    }

    function ratating() {
      var timer = null;
      var rdm = 0; //随机度数
      clearInterval(timer);
      timer = setInterval(function () {
        let rewardKey = turnConfig
          .map((item) => item.itemID)
          .indexOf(doTurnResult.ItemID);
        console.log(Math.floor(rdm / 360), "rewardKey......");
        if (Math.floor(rdm / 360) < 3) {
          rdm = Math.floor(0.5 * 3600) - cat * rewardKey;
        } else {
          oTurntable.style.transform = "rotate(" + rdm + "deg)";
          clearInterval(timer);
          setTimeout(function () {
            setOffOn(false);
            num = rdm % 360;
            setTimeout(() => {
              setPopAward(true);
            }, 600);
          }, 4000);
        }
      }, 30);
    }
  };

  const IntNum = (moneyNum) => {
    let returnNum = 0.0;
    if (moneyNum < 10) {
      returnNum = moneyNum.toFixed(6);
    } else if (moneyNum >= 10) returnNum = moneyNum.toFixed(5);
    else if (moneyNum >= 100) returnNum = moneyNum.toFixed(4);
    else if (moneyNum >= 1000) returnNum = moneyNum.toFixed(3);
    else if (moneyNum >= 10000) returnNum = moneyNum.toFixed(2);
    else if (moneyNum >= 100000) returnNum = moneyNum.toFixed(1);
    else if (moneyNum >= 1000000) returnNum = moneyNum.toFixed(0);
    return returnNum;
  };

  const backVipCommission = () => {
    let level = 0;
    let plus = 0;
    if (vipActive === 0) {
      level = vipLevel;
    } else if (vipActive === 1) {
      level = vipLevel + 1;
    } else if (vipActive === 2) {
      level = vipLevel + 2;
    }

    if (level > 0) {
      plus = vipConfig[level]?.TurntablePlus || 0;
    }
    return {
      level: level,
      plus: plus,
    };
  };

  const handleChangeExperience = () => {
    if (chargeTotal > 0) {
      let experience = 0;
      if (betTotal == 0) {
        setVipExperience(experience);
        return;
      }
      if (parseInt(chargeTotal) > parseInt(betTotal)) {
        experience = betTotal;
      } else if (parseInt(chargeTotal) <= parseInt(betTotal)) {
        experience = chargeTotal;
      }
      setVipExperience(experience);
    }
  };

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };
  const [isMoveShow, setIsMoveShow] = useState(false);

  useEffect(() => {
    handleGetTurnConfig();
    handleGetVIPConfig();
    handleGetTurnRecord();

    setTimeout(() => {
      setIsMoveShow(true);
      var oTurntable = document.getElementById("spin");
      oTurntable.classList.remove("spinZhuanPanFangDa");
    }, 700);
  }, []);

  useEffect(() => {
    if (userProperties?.properties?.TurntableRemain) {
      canSpinClick();
      setUserTurnTime(userProperties.properties.TurntableRemain);
    } else {
      notSpinClick();
    }

    // 充值金额
    if (userProperties?.properties?.ChargeTotal) {
      setChargeTotal(userProperties.properties.ChargeTotal);
    }

    // 下注金额
    if (userProperties?.properties?.BetTotal) {
      setBetTotal(userProperties.properties.BetTotal);
    }

    // vip等级
    if (userProperties?.properties?.MemberOrder) {
      setVipLevel(userProperties.properties.MemberOrder);
    }
  }, [userProperties, isMoveShow, vipActive]);

  useEffect(() => {
    handleChangeExperience();
  }, [chargeTotal, betTotal]);

  useEffect(() => { }, []);

  return (
    <>
      <Box
        className="imgHidden marginJuZhong funbetTxt"
        style={{ height: "100%", width: "100%", maxWidth: "360px" }}
      >
        <div
          className=" positionAb borderRadius-8 promotion-list1 activityMoveLeft"
          style={{
            top: "28px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            margin: "0 auto",
            backgroundColor: "#191A1B",
            width: "340px",
            height: "60px",
          }}
        >
          <div
            onClick={() => {
              setVipActive(0);
            }}
            className={clsx(
              "borderRadius-5 imgHidden btnPointer txtBrightness",
              vipActive === 0 && "spinbtn1Color"
            )}
            style={{ width: "60px", height: "60px" }}
          >
            <div
              className={clsx(
                "positionAb vipZiRota viptxtColorHui fontBold",
                vipActive === 0 && "sigin-txt-yellow"
              )}
              style={{
                top: "4px",
                right: "0px",
                bottom: "0px",
                left: "9px",
                textAlign: "center",
              }}
            >
              VIP{vipLevel}
            </div>
            <div
              className="positionAb"
              style={{ top: "20px", right: "0px", bottom: "0px", left: "22px" }}
            >
              <img
                className=""
                src={
                  vipActive === 0
                    ? `${React.$imgUrl}/images/nav/smallZhuanPan1.png`
                    : `${React.$imgUrl}/images/nav/smallZhuanPan2.png`
                }
              />
            </div>
          </div>

          <div
            onClick={() => {
              setVipActive(1);
            }}
            className={clsx(
              "borderRadius-5 imgHidden btnPointer txtBrightness",
              vipActive === 1 && "spinbtn2Color"
            )}
            style={{ width: "60px", height: "60px" }}
          >
            <div
              className={clsx(
                "positionAb vipZiRota viptxtColorHui fontBold",
                vipActive === 1 && "sigin-txt-yellow"
              )}
              style={{
                top: "4px",
                right: "0px",
                bottom: "0px",
                left: "9px",
                textAlign: "center",
              }}
            >
              VIP{vipLevel + 1}
            </div>
            <div
              className="positionAb"
              style={{ top: "20px", right: "0px", bottom: "0px", left: "22px" }}
            >
              <img
                className=""
                src={
                  vipActive === 1
                    ? `${React.$imgUrl}/images/nav/smallZhuanPan1.png`
                    : `${React.$imgUrl}/images/nav/smallZhuanPan2.png`
                }
              />
            </div>
          </div>

          <div
            onClick={() => {
              setVipActive(2);
            }}
            className={clsx(
              "borderRadius-5 imgHidden btnPointer txtBrightness",
              vipActive === 2 && "spinbtn3Color"
            )}
            style={{ width: "60px", height: "60px" }}
          >
            <div
              className={clsx(
                "positionAb vipZiRota viptxtColorHui fontBold",
                vipActive === 2 && "sigin-txt-yellow"
              )}
              style={{
                top: "4px",
                right: "0px",
                bottom: "0px",
                left: "9px",
                textAlign: "center",
              }}
            >
              VIP{vipLevel + 2}
            </div>
            <div
              className="positionAb"
              style={{ top: "20px", right: "0px", bottom: "0px", left: "22px" }}
            >
              <img
                className=""
                src={
                  vipActive === 2
                    ? `${React.$imgUrl}/images/nav/smallZhuanPan1.png`
                    : `${React.$imgUrl}/images/nav/smallZhuanPan2.png`
                }
              />
            </div>
          </div>

          <div
            className="spinbtn1Color borderRadius-5"
            style={{
              width: "154px",
              height: "60px",
              paddingTop: "8px",
              backgroundColor: vipImgList[vipActive].turnBackGroundColor,
            }}
          >
            <div
              className="text-align spinbtn1textColor"
              style={{
                width: "142px",
                height: "31px",
                backgroundImage: "url('https://scource-static.funibet.com/funibet/images/nav/diPan7.png')",
                margin: "0 auto",
              }}
            >
              VIP{backVipCommission().level}
            </div>
            <div
              className="text-align txtColor"
            >
              {t("home_Bonusincreased")}
              {backVipCommission().level}%
            </div>
          </div>
        </div>
        <div
          className="spinDi2 positionAb spinZhuanPanFangDa"
          style={{
            backgroundImage: `url(${vipImgList[vipActive].turnBackGround})`,
            top: "100px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            margin: "0px auto",
          }}
        ></div>
        <div
          id="spin"
          className="spinDi1 positionAb spinZhuanPanFangDa"
          style={{
            top: "122px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            margin: "0 auto",
          }}
        >
          <div className="" style={{ marginLeft: "200px", marginTop: "100px" }}>
            <div
              className=" flex width-100 align-item rotate270"
              style={{
                justifyContent: "end",
                margin: "-60px 0px 0px -96px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[0]?.awardNum
                  ? IntNum(turnConfig[0]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[0]?.currency
                    ? `assets/images/symbol/${turnConfig[0]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item rotate292-5"
              style={{
                justifyContent: "end",
                margin: "-22px 0px 0px -58px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[1]?.awardNum
                  ? IntNum(turnConfig[1]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[1]?.currency
                    ? `assets/images/symbol/${turnConfig[1]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item rotate315"
              style={{
                justifyContent: "end",
                margin: "-6px 0px 0px -24px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[2]?.awardNum
                  ? IntNum(turnConfig[2]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[2]?.currency
                    ? `assets/images/symbol/${turnConfig[2]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item rotate337-5"
              style={{
                justifyContent: "end",
                margin: "6px 0px 0px -2px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[3]?.awardNum
                  ? IntNum(turnConfig[3]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[3]?.currency
                    ? `assets/images/symbol/${turnConfig[3]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item"
              style={{
                justifyContent: "end",
                margin: "12px 0px 0px 6px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[4]?.awardNum
                  ? IntNum(turnConfig[4]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[4]?.currency
                    ? `assets/images/symbol/${turnConfig[4]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item rotate22-5"
              style={{
                justifyContent: "end",
                margin: "10px 0px 0px -2px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[5]?.awardNum
                  ? IntNum(turnConfig[5]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[5]?.currency
                    ? `assets/images/symbol/${turnConfig[5]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item rotate45"
              style={{
                justifyContent: "end",
                margin: "5px 0px 0px -24px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[6]?.awardNum
                  ? IntNum(turnConfig[6]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[6]?.currency
                    ? `assets/images/symbol/${turnConfig[6]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item rotate67-5"
              style={{
                justifyContent: "end",
                margin: "-6px 0px 0px -58px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[7]?.awardNum
                  ? IntNum(turnConfig[7]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[7]?.currency
                    ? `assets/images/symbol/${turnConfig[7]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item rotate90"
              style={{
                justifyContent: "end",
                margin: "-22px 0px 0px -96px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[8]?.awardNum
                  ? IntNum(turnConfig[8]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[8]?.currency
                    ? `assets/images/symbol/${turnConfig[8]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item rotate112-5"
              style={{
                justifyContent: "end",
                margin: "-36px 0px 0px -134px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[9]?.awardNum
                  ? IntNum(turnConfig[9]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[9]?.currency
                    ? `assets/images/symbol/${turnConfig[9]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item rotate135"
              style={{
                justifyContent: "end",
                margin: "-48px 0px 0px -168px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[10]?.awardNum
                  ? IntNum(turnConfig[10]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[10]?.currency
                    ? `assets/images/symbol/${turnConfig[10]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item rotate157-5"
              style={{
                justifyContent: "end",
                margin: "-60px 0px 0px -190px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[11]?.awardNum
                  ? IntNum(turnConfig[11]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[11]?.currency
                    ? `assets/images/symbol/${turnConfig[11]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item rotate180"
              style={{
                justifyContent: "end",
                margin: "-68px 0px 0px -198px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[12]?.awardNum
                  ? IntNum(turnConfig[12]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[12]?.currency
                    ? `assets/images/symbol/${turnConfig[12]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item rotate202-5"
              style={{
                justifyContent: "end",
                margin: "-68px 0px 0px -190px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[13]?.awardNum
                  ? IntNum(turnConfig[13]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[13]?.currency
                    ? `assets/images/symbol/${turnConfig[13]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item rotate225"
              style={{
                justifyContent: "end",
                margin: "-60px 0px 0px -168px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[14]?.awardNum
                  ? IntNum(turnConfig[14]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[14]?.currency
                    ? `assets/images/symbol/${turnConfig[14]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>

            <div
              className=" flex width-100 align-item rotate247-5"
              style={{
                justifyContent: "end",
                margin: "-50px 0px 0px -136px",
                color: "#ffffff",
              }}
            >
              <div className="text-13 fontBold">
                {turnConfig[15]?.awardNum
                  ? IntNum(turnConfig[15]?.awardNum / 100)
                  : 0.0}
              </div>
              <img
                className="height-28 ml-4 borderRadius spinIconShadow"
                src={
                  turnConfig[15]?.currency
                    ? `assets/images/symbol/${turnConfig[15]?.currency}.png`
                    : `assets/images/symbol/USGT.PNG`
                }
              ></img>
            </div>
          </div>
        </div>

        <div
          className="positionAb spinZhuanPanFangDa"
          style={{ top: "100px", bottom: "0px", left: "160px", right: "0px" }}
        >
          <img className="" src={vipImgList[vipActive].turnPointerImg}></img>
        </div>

        <img
          className="spinDi3 positionAb btnPointer spinZhuanPanFangDa"
          style={{ top: "229px", bottom: "0px", left: "130px", right: "0px" }}
          src={`${React.$imgUrl}/images/nav/zhiZhen.png`}
        ></img>
        <img
          className={clsx(
            "spinZhuanBtnImgX positionAb Zindex txtBrightness btnPointer ",
            vipActive !== 0 && "displayNone"
          )}
          style={{ top: "232px", bottom: "0px", left: "128px", right: "0px" }}
          onClick={() => {
            spinRata();
          }}
          src={`${React.$imgUrl}/images/nav/spinZi1.png`}
        ></img>
        <img
          className="spinDi7 positionAb activityMoveRight"
          style={{
            top: "414px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            width: "338px",
            height: "80px",
          }}
          src={vipImgList[vipActive].turnTipImg}
        ></img>

        <div
          className="spinDi4 positionAb spinZhuanPanFangDa"
          style={{
            top: "100px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            margin: "0 auto",
          }}
        >
          <img className="sanImg" src={vipImgList[vipActive].src1}></img>
        </div>
        <div
          className="spinDi4 positionAb spinZhuanPanFangDa"
          style={{
            top: "100px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            margin: "0 auto",
          }}
        >
          <img className="sanImg1" src={vipImgList[vipActive].src2}></img>
        </div>

        {isMoveShow && (
          <motion.div variants={container} initial="hidden" animate="show">
            <motion.div
              variants={item}
              className="my-4 "
              style={{ paddingTop: "490px" }}
            >
              {/* <div className='text-48  text-align mb-6 sigin-txt-yellow'>
                        <span>{t("home_turnTimes")}</span><span>{userTurnTimes}</span>
                    </div>
                    <div className="flex  justifyContent">
                        <Button className=" borderRadius px-28  py-24  text-24 marginJuZhong" variant="contained" color="secondary" onClick={() => { spinRata() }}>
                            {t("home_FreeSpin")}
                        </Button>
                    </div> */}
              {vipActive === 0 ? (
                <div className="containerSpinBtn  align-item flex justifyContent">
                  <div className="btn" id="spin-div-rata">
                    <a
                      id="spin-a-rata"
                      className="titleTxt"
                      onClick={() => {
                        spinRata();
                      }}
                      style={{ fontSize: "20px", color: "#ffffff" }}
                    >
                      {t("home_Times")}
                      {userTurnTimes}
                    </a>
                  </div>
                </div>
              ) : (
                <div
                  className="borderRadius-5 spin-vip promotion-list1 testRed"
                  style={{
                    background: "#151617",
                    height: "75px",
                    overflow: "hidden",
                    marginTop: "4px",
                    marginBottom: "5px",
                  }}
                >
                  <div className="">
                    <img
                      className="spin-vip-img"
                      src={`assets/images/index/vip${vipLevel}.png`}
                    />
                    <div className="spin-vip-text spin-vip-img text-align" style={{ color: "#ffffff" }}>
                      VIP{vipLevel}
                    </div>
                  </div>
                  <div style={{ width: "240px" }}>
                    <div className="spin-vip-2 font-weight500 VipBgDiColor fontBold borderRadius  text-align position-relative">
                      <div
                        className="spin-vip-2 font-weight500 VipBgDiColor fontBold borderRadius  text-align positionAb"
                        style={{
                          background: "#FFC600",
                          width: vipConfig[backVipCommission().level]?.Condition
                            ? `${(
                              (vipExperience /
                                vipConfig[backVipCommission().level]
                                  ?.Condition) *
                              100
                            ).toFixed(2)}%`
                            : 0,
                          marginTop: 0,
                        }}
                      ></div>
                      <div style={{ position: "absolute", left: "0px", right: "0px" }}>
                        {vipExperience / 100}/{" "}
                        {vipConfig[backVipCommission().level]?.Condition
                          ? vipConfig[backVipCommission().level]?.Condition / 100
                          : 0}
                      </div>
                    </div>
                    <div className="spin-vip-3  text-align colorGameListTitle">
                      {t("home_Yourcurrent")}
                      <span className="txtColor">${vipExperience / 100}</span>
                    </div>
                  </div>

                  <div className="">
                    <img
                      className="spin-vip-img"
                      src={`assets/images/index/vip${backVipCommission().level
                        }.png`}
                    />
                    <div className="spin-vip-text spin-vip-img text-align " style={{ color: "#ffffff" }}>
                      VIP{backVipCommission().level}
                    </div>
                  </div>
                </div>
              )}
            </motion.div>
            <motion.div
              variants={item}
              className="spinDi10 spin-bottom flex justify-between items-center p-4"
            >
              <div className="spin-bonus flex justify-center flex-wrap items-center py-8">
                <div
                >
                  {t("home_SPINBONUSTOTAL")}
                </div>
                <div
                  style={{
                    fontSize: "20px",
                    color: "#E9D317",
                    fontWeight: "800",
                  }}
                >
                  $
                  {turnRecord?.TotalAward
                    ? (turnRecord.TotalAward / 100).toFixed(2)
                    : 0.0}
                </div>
              </div>
              <div className="spin-bonus px-6 py-8 flex">
                <div className="spin-user-avatar" style={{ minWidth: "28px" }}>
                  <img
                    style={{
                      width: "28px",
                      height: "28px",
                      borderRadius: "50%",
                    }}
                    src={
                      turnRecord?.Records
                        ? `assets/images/head/${turnRecord.Records[0].FaceID}.png`
                        : "assets/images/head/0.png"
                    }
                    alt=""
                  />
                </div>
                <div className="spin-record ml-6">
                  <div style={{ color: "#8997B9" }}>
                    {turnRecord?.Records ? turnRecord.Records[0].NickName : ""}
                  </div>
                  <div>
                    {t("home_WIN")}
                    <span style={{ color: "#45CB1D" }}>
                      {turnRecord?.Records
                        ? (turnRecord.Records[0].RewardAmount / 100).toFixed(2)
                        : 0}
                    </span>
                    &nbsp;
                    <span style={{ color: "#ffffff" }}>eUSDT</span> <br />
                    {t("home_In")}
                    <span
                      style={{
                        fontSize: "16px",
                        color: "#FF5AC4",
                        fontWeight: "600",
                      }}
                    >
                      {t("home_SPIN")}
                    </span>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
        {/* <div className="text-13 text-align mt-16" style={{ color: "#ffffff" }}>
                    {t("home_Turntheturntable")}
                </div> */}
      </Box>
      <AwardPop
        open={popAward}
        onClose={() => {
          setPopAward(false);
          var oTurntable = document.getElementById("spin");
          oTurntable.style.transition = "all 0s";
          oTurntable.style.transform = "rotate(0deg)";
        }}
        symbol={spinRewardBalance.symbol}
        symbolImg={`assets/images/symbol/${spinRewardBalance.symbol}.png`}
        balance={spinRewardBalance.balance}
      />
    </>
  );
}
export default Spin;
