  const locale = {
  GAME: 'Gry',
  Casino: "Kasyno",
  ProvablyFair: "Provably fair",
  Option: 'Opcja',
  Crypto: 'Krypto',
  Forex: 'Forex',
  Stocks: 'Akcje',
  NFTFuniCard: 'NFT FuniCard',
  StakeNFT: 'Zakład NFT zarobek USDT',
  NFTStake: 'NFT Zakład',
  Marketplace: 'Rynek',
  NFTMint: 'NFT Mennica',
  BettoEarn: 'Zakład, aby zarobić',
  EarnUSGT: 'Zarabiać USDT',
  DeFi: 'DeFi',
  ToBeVolunteer: 'Być wolontariuszem',
  SYSTEM: 'SYSTEM',
  Affiliate: 'Powiązanie partnerskie',
  News: 'Aktualności',
  FAQs: 'FAQ',
  DOWNLOAD: 'POBIERZ',
  DOWNLOADApp: 'Pobierz App',
  Savetodesktop: 'Zapisz na pulpicie',
  BetMining: 'Kopanie zakładu',
  ToBeBanker: 'Bazin dochodu',
  ProfitPool: 'Pool de profits',
  USGTLiquidityMining: 'USDT Liquidity Mining',
  BGTLiquidityMining: 'BGT Liquidity Mining',
  Slots: 'Slots',
  Favorite: 'Ulubione',
  Recent: 'Ostatnie',
  Popular: "Popularne",
  LiveCasino: "Kasyno na żywo",
  TableGames: "Gry stołowe",
  BlackJack: "BlackJack",
  Baccarat: "Bakarat",
  Roulette: "Ruletka",
  MoreGames: "Więcej gier",
  Provablyfair: "Provably fair",
  Special: "Specjalne",
  Recommend: "Polecany",
  FeatureBuyin: "Feature Buy-in",
  EnableTrialPlay: "Włącz próbę gry",
  GameShow: "Game Show",
  RGSVSB: "RGS - VSB",
  Scratch: "Zdrapka",
  Bingo: "Bingo",
  Fishing: "Łowienie ryb",
  Turntable: "Stoisko",
  Dice: "Kostki",
  Poker: "Poker",
  AllGames: "Wszystkie gry",
  EuropeanView: "Widok europejski",
  AfricanView: "Widok afrykański",
  LatinView: "Widok łaciński",
  AsianView: "Widok azjatycki",
  LsportView: "Widok ligowy",
  StandardView: "Widok standardowy",
  EsportView: "Widok esportowy",
  LiveBetting:'Zakłady na żywo',
  Provider:'Dostawca',
  Sport: "Sport",
  


};

export default locale;
