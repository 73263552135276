  const locale = {
  GAME: 'खेल',
  Casino: "कैसीनो",
  ProvablyFair: "सत्यापन योग्य",
  Option: 'विकल्प',
  Crypto: 'क्रिप्टो',
  Forex: 'विदेशी मुद्रा',
  Stocks: 'स्टॉक',
  NFTFuniCard: 'NFT रिचार्ज कार्ड',
  StakeNFT: 'स्टेक NFT अर्जित करें USDT',
  NFTStake: 'NFT स्टेक',
  Marketplace: 'मार्केटप्लेस',
  NFTMint: 'NFT मिंट',
  BettoEarn: 'जीतने के लिए बेट',
  EarnUSGT: 'कमाएं USDT',
  DeFi: 'डीसेंट्रलाइज्ड फाइनेंसi',
  ToBeVolunteer: 'स्वयंसेवक बनना',
  SYSTEM: 'सिस्टम',
  Affiliate: 'सहबद्ध',
  News: 'समाचार',
  FAQs: 'सामान्य प्रश्न',
  DOWNLOAD: 'डाउनलोड',
  DOWNLOADApp: 'डाउनलोड App',
  Savetodesktop: 'डेस्कटॉप पर सहेजें',
  BetMining: 'बेट माइनिंग',
  ToBeBanker: 'बैंकर बनना',
  ProfitPool: 'लाभ पूल',
  USGTLiquidityMining: 'USDT  लिक्विडिटी माइनिंग',
  BGTLiquidityMining: 'BGT  लिक्विडिटी माइनिंग',
  Slots: 'स्लॉट',
  Favorite: 'पसंदीदा',
  Recent: 'हाल का',
  Popular: "लोकप्रिय",
  LiveCasino: "लाइव कैसीनो",
  TableGames: "टेबल गेम्स",
  BlackJack: "ब्लैकजैक",
  Baccarat: "बकारी",
  Roulette: "रूलेट",
  MoreGames: "अधिक खेल",
  Provablyfair: "सत्यापन योग्य",
  Special: "विशेष",
  Recommend: "सिफारिश",
  FeatureBuyin: "फ़ीचर खरीद",
  EnableTrialPlay: "ट्रायल प्ले सक्षम करें",
  GameShow: "गेम शो",
  RGSVSB: "RGS - VSB",
  Scratch: "खरोंच",
  Bingo: "बिंगो",
  Fishing: "मछली पकड़ना",
  Turntable: "टर्नटेबल",
  Dice: "डाइस",
  Poker: "पोकर",
  AllGames: "सभी खेल",
  EuropeanView: "यूरोपीय दृश्य",
  AfricanView: "अफ़्रीकी दृश्य",
  LatinView: "लैटिन दृश्य" ,
  AsianView: "एशियाई दृश्य",
  LsportView: "लीग स्पोर्ट",
  StandardView: "मानक दृश्य",
  EsportView: "इस्पोर्ट",
  LiveBetting: 'लाइव बेटिंग',
  Provider: 'प्रदाता',
  Sport: "खेल",



};

export default locale;
