import { combineReducers } from '@reduxjs/toolkit';
import fuse from './fuse';
import i18n from './i18nSlice';
// import user from './userSlice';
import user from './user';
import userInfo from './user/userInfo';
import userSetting from './user/userSetting';
import userAssets from './user/userAssets';
import userGameScore from './user/userGameScore';
import userProperties from './user/userProperties';
import userAvatars from "app/store/user/userAvatars";
import userLoginCountry from "app/store/user/userLoginCountry";
import fiats from "app/store/config/fiats";
import symbols from "app/store/config/symbols";
import faqs from "app/store/config/faqs";
import news from "app/store/config/news";
import maintainConfig from "app/store/config/maintainConfig";
import casino from './casino';
import topGroup from "app/store/casino/topGroup";
import tags from "app/store/casino/tags";
import nav from "app/store/casino/nav";
import searchCasinoChannel from "app/store/casino/searchCasinoChannel";
import activity from './activity';
import config from './config';
import chat from './chat';
import channels from "app/store/config/channels";

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    fuse,
    i18n,
    user,
    userInfo,
    userSetting,
    userAssets,
    userGameScore,
    userProperties,
    userAvatars,
    userLoginCountry,
    fiats,
    symbols,
    faqs,
    news,
    maintainConfig,
    casino,
    topGroup,
    tags,
    nav,
    searchCasinoChannel,
    activity,
    config,
    chat,
    channels,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === 'user/userLoggedOut') {
    // state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
