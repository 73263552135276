import { createSlice } from '@reduxjs/toolkit';
import history from '@history';
import { setSpreaderInfo } from "app/store/user/userThunk";

// state
const initialState = {
    news: []
};

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        updateNews: (state, action) => {
            let res = action.payload;
            state.news = res;
        },
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateNews } = newsSlice.actions;

export const selectNews = ({ news }) => news;

export default newsSlice.reducer;
