import FuseNavigation from '@fuse/core/FuseNavigation';
import clsx from 'clsx';
import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation } from 'app/store/fuse/navigationSlice';
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { getCasinoType } from "../../store/casino/casinoThunk";
import { useTranslation } from "react-i18next";
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import history from '@history';
import { selectCasinoData } from "../../store/casino";
import {selectNav, updateNavigatorList} from "../../store/casino/nav";

import { motion } from 'framer-motion';

function NavigationPhone(props) {
    const navigation = useSelector(selectNavigation);
    const casinoData = useSelector(selectCasinoData);
    // const [navigationData, setNavigationData] = useState([]);
    const navigationData = useSelector(selectNav).navigatorList;
    const { t } = useTranslation('mainPage');

    const [inputVal, setInputVal] = useState({
        searchText: ''
    });

    const handleChangeInputVal = (prop) => (event) => {
        doSearchData(event.target.value);
        setInputVal({ ...inputVal, [prop]: event.target.value });
    };

    const [selectedTab, setSelectedTab] = useState(0);
    function handleTabChange(event, value) {
        setSelectedTab(value);
    }

    const dispatch = useDispatch();

    const initData = () => {
        dispatch(getCasinoType({
            tagGroup: ''
        })).then((res) => {
            let result = res.payload;
            if (result.list) {
                let tmpNav = [];
                result.list.map((item) => {
                    let tmpUrl = '';
                    if (item.tagGroup === 1) {
                        tmpUrl = `/gameOther/${item.tagID}/${item.tagName}`
                    } else {
                        tmpUrl = `/game/${item.tagName}`
                    }
                    tmpNav.push({
                        id: item.tagName,
                        title: item.tagName,
                        type: 'item',
                        icon: `heroicons-outline:${item.tagName}`,
                        url: tmpUrl,
                        end: true,
                    })
                });
                navigation[0].children[0].children = tmpNav;
                navigation.splice(2, 2);
    
                // setNavigationData(navigation);
                setTimeout(() => {
                    dispatch(updateNavigatorList(navigation))
                }, 400)
                
            }
        });
    };
    useEffect(() => {
        initData()
    }, []);


    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };


    return useMemo(() => {
        return (
            <div className="pt-20  betDiColor phoneMove2" style={{ width: "100%" }} >
                <FormControl className="wallet-search phoneGameSearch" sx={{ width: '92%', borderColor: '#94A3B8', margin: "0 auto" }} variant="outlined">
                    <OutlinedInput
                        sx={{ width: '100%', borderColor: '#94A3B8' }}
                        id="outlined-adornment-address outlined-adornment-address-wallet outlined-adornment-address-wallet-input"
                        value={inputVal.searchText}
                        onChange={handleChangeInputVal('searchText')}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            'aria-label': 'searchText',
                        }}
                        autoFocus
                        placeholder={t("game_search")}
                        className="borderRadius"
                        endAdornment={
                            <FuseSvgIcon
                                size={20}
                                onClick={() => {
                                    history.push(`/gameOther/1/Slots`)
                                }}
                            >
                                material-outline:search
                            </FuseSvgIcon>
                        }
                    />
                </FormControl>
                        <FuseNavigation
                            className={clsx('navigation mb-20', props.className)}
                            navigation={navigationData}
                            layout={props.layout}
                            dense={props.dense}
                            active={props.active}
                        />
            </div>
        );
    }, [dispatch, navigationData, props.active, props.className, props.dense, props.layout]);
}

NavigationPhone.defaultProps = {
    layout: 'vertical',
};

export default memo(NavigationPhone);


