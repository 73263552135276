  const locale = {
    GAME: 'விளையாட்டு',
    Casino: "காசினோ",
    ProvablyFair: "Provably fair",
    Option: 'விருப்பம்',
    Crypto: 'குறியீடு பணம்',
    Forex: 'Forex',
    Stocks: 'பங்குகள்',
    NFTFuniCard: 'NFT ரீசார்ஜ் கார்டு',
    StakeNFT: 'Stake NFT earn USDT',
    NFTStake: 'NFT பங்கு வைத்து',
    Marketplace: 'சந்தை',
    NFTMint: 'NFT மின்னல்',
    BettoEarn: 'பந்தயம் செய்து சம்பாதி',
    EarnUSGT: 'USDT சம்பாதி',
    DeFi: 'முதலீடு தொகுப்பு',
    ToBeVolunteer: 'தொண்டர்',
    SYSTEM: 'கணினி',
    Affiliate: 'நடிப்பு',
    News: 'செய்திகள்',
    FAQs: 'பொது கேள்விகள்',
    DOWNLOAD: 'பதிவிறக்கம்',
    DOWNLOADApp: 'பதிவிறக்கம் app',
    Savetodesktop: 'டெஸ்க்டாப் சேமிக்க',
    BetMining: 'பந்து குழாய்',
    ToBeBanker: 'வங்கியாளராக',
    ProfitPool: 'லாப குழாய்',
    USGTLiquidityMining: 'USDT Liquidity Mining',
    BGTLiquidityMining: 'BGT Liquidity Mining',
    Slots: 'ஸ்லாட்ஸ்',
    Favorite: 'பிடித்தவை',
    Recent: 'சமீபத்தியது',
    Popular: "பிரபலமான",
    LiveCasino: "Live Casino",
    TableGames: "டேபிள் கூடுகள்",
    BlackJack: "பிளாக் ஜாக்",
    Baccarat: "பகாரத்",
    Roulette: "ரூலெட்",
    MoreGames: " மேலும்",
    Provablyfair: "Provably Fair",
    Special: "சிறப்பு",
    Recommend: "பரிந்துரைக்கப்படும்",
    FeatureBuyin: "Feature Buy-in",
    EnableTrialPlay: "Enable Trial Play",
    GameShow: "விளையாட்டு ஷோ",
    RGSVSB: "RGS - VSB",
    Scratch: "Scratch",
    Bingo: "Bingo",
    Fishing: "மீன் பிடி",
    Turntable: "பட்டம் சுழற்று",
    Dice: "குழாய்",
    Poker: "போக்கர் கார்டு",
    AllGames: "அனைத்தும்",
    EuropeanView: "ஐரோப்பிய காட்சி",
    AfricanView: "ஆப்பிரிக்க காட்சி",
    LatinView: "லத்தீன்",
    AsianView: "ஆசியா",
    LsportView: "அணி விளையாட்டு",
    StandardView: "நிலையான காட்சி",
    EsportView: "Esport",
    LiveBetting:'நேரடி பந்தயம்',
    Provider:'வழங்குபவர்',
    Sport: "விளையாட்டு",
    
    
    };
    
    export default locale;
    
