import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState, useEffect, default as React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectUser } from 'app/store/userSlice';
import { getUserSetting, userProfile, setGameProfile, onLogin } from "../../store/user/userThunk";
import { selectUserData } from "../../store/user";
import IconButton from '@mui/material/IconButton';
import { motion } from 'framer-motion';
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import {selectUserSetting} from "app/store/user/userSetting";
import {selectUserAvatars} from "app/store/user/userAvatars";
import {selectUserInfo} from "app/store/user/userInfo";
import history from "@history";

function UserMenu(props) {
    const { t } = useTranslation('mainPage');
    const dispatch = useDispatch();
    const user = useSelector(selectUserData);
    const userInfo = useSelector(selectUserInfo);
    const userAvatars = useSelector(selectUserAvatars);
    const userSetting = useSelector(selectUserSetting);

    const [userMenu, setUserMenu] = useState(null);
    const [userFaceID, setUserFaceID] = useState(userSetting?.user?.faceID || 0);
    const [userNickname, setUserNickname] = useState('');
    const [isEditNickname, setIsEditNickname] = useState(false);

    const handleChangeUserNickname = (prop) => (event) => {
        setUserNickname(event.target.value)
    };

    const userMenuClick = (event) => {
        setUserMenu(event.currentTarget);
    };

    const userMenuClose = () => {
        setUserMenu(null);
    };

    const onLogin = () => {
        dispatch(onLogin)
    };

    const handleSetProfileNickname = () => {
        if (isEditNickname) {
            setIsEditNickname(false);
            dispatch(setGameProfile({
                nickName: userNickname
            })).then((res) => {
                if (res.payload) {
                    dispatch(getUserSetting({
                        callback: () => {}
                    }))
                } else {
                    setUserNickname(userSetting?.user?.nickName);
                }
            })
        } else {
            setIsEditNickname(true);
        }
    };

    const handleSetProfileFaceID = (faceID) => {
        dispatch(setGameProfile({
            faceID
        })).then((res) => {
            if (res.payload) {
                setUserFaceID(faceID);
                dispatch(getUserSetting({
                    callback: () => {}
                }))
            }
        })
    };

    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };

    useEffect(() => {
        console.log(userSetting, 'userSetting.......')
        if (userSetting?.user?.nickName) {
            setUserNickname(userSetting?.user?.nickName);
        }
        if (userSetting?.user?.faceID || userSetting?.user?.faceID === 0) {
            setUserFaceID(userSetting?.user?.faceID);
        }
    }, [userSetting]);

    return (
        <>
            <Button
                className="txtBrightness phoneHeadBtn"
                onClick={userMenuClick}
                color="primary"
            >
                <div className="hidden md:flex flex-col  items-end">
                    <Typography component="span" className="font-semibold flex">
                        {userInfo.userInfo?.nickname}
                    </Typography>
                    {/*<Typography className="text-11 font-medium capitalize" color="text.secondary">*/}
                    {/*  {user.role.toString()}*/}
                    {/*  {(!user.role || (Array.isArray(user.role) && user.role.length === 0)) && 'Guest'}*/}
                    {/*</Typography>*/}
                </div>

                {/*{user.userInfo.avatar ? (*/}
                {/*    <Avatar className=" loginHead" alt="user photo" src={user.userInfo.avatar} />*/}
                {/*) : (*/}
                {/*    <Avatar className=" loginHead">{user.userInfo?.nickname}</Avatar>*/}
                {/*)}*/}
                <Avatar className=" loginHead" alt="user photo" src={userAvatars.allAvatars[userFaceID].url} />
            </Button>


            <Popover
                open={Boolean(userMenu)}
                anchorEl={userMenu}
                onClose={userMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{
                    paper: 'headDiWh',
                }}
            >
                <div className='flex px-16 mb-12 funbetTxt'>
                    <img className='headIconWh' src={userAvatars.allAvatars[userFaceID].url} />
                    <div className='userNameID'>
                        <div style={window.localStorage.getItem("lang") == "pl" ? { height: "24px", fontSize: "12px" } : { height: "24px", lineHeight: "24px" }}>{t("home_UserId")}{userSetting?.user?.userID}</div>
                        {isEditNickname ? (
                            <div style={{ height: "40px", lineHeight: "40px", minWidth: "200px", maxWidth: "190px", overflow: "hidden" }} className="flex justify-center items-center">
                                <FormControl className="wallet-search" sx={{ width: '100%', borderColor: '#94A3B8', maxWidth: "1196px" }} variant="outlined">
                                    <OutlinedInput
                                        key="changeUserNickname"
                                        sx={{ width: '100%', borderColor: '#94A3B8', borderSize: '1px', padding: '0 5px' }}
                                        id="outlined-adornment-address outlined-adornment-address-wallet outlined-adornment-address-wallet-input"
                                        value={userNickname}
                                        onChange={handleChangeUserNickname()}
                                        aria-describedby="outlined-weight-helper-text"
                                        placeholder={t("home_UserName")}
                                    />
                                </FormControl>
                                <div className=''>
                                    <IconButton onClick={() => {
                                        handleSetProfileNickname()
                                    }} color="inherit" size="large">
                                        <FuseSvgIcon>heroicons-outline:pencil-alt</FuseSvgIcon>
                                    </IconButton>
                                </div>
                            </div>
                        ) : (
                            <div style={{ lineHeight: "24px", minWidth: "200px", maxWidth: "190px", overflow: "hidden" }} className="flex  items-center ">
                                {t("home_UserName")}{userNickname.length > 13 ? userNickname.slice(0, 12) + ".." : userNickname}
                                <div className=''>
                                    <IconButton onClick={() => {
                                        handleSetProfileNickname()
                                    }} color="inherit" size="large">
                                        <FuseSvgIcon>heroicons-outline:pencil-alt</FuseSvgIcon>
                                    </IconButton>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div style={{ margin: "0 auto", width: "90%", borderBottom: "1px solid #14C2A3", marginBottom: "12px" }}></div>

                <motion.div className='promotion-list1'>
                    <motion.div
                        className='promotion-list1'
                        variants={container}
                        initial="hidden"
                        animate="show"
                        style={{ margin: "0 auto", paddingRight: "8px", width: "90%", height: "332px", overflowY: "scroll" }}>
                        {Object.keys(userAvatars.allAvatars).map((key) => {
                            return (
                                <motion.div
                                    variants={item}
                                    key={key}
                                    onClick={() => {
                                        handleSetProfileFaceID(key)
                                    }}
                                >
                                    <img className={clsx('iconYuan txtBrightness', key == userFaceID && 'bdBian')} src={userAvatars.allAvatars[key].url} />
                                </motion.div>
                            )
                        })}
                    </motion.div>
                </motion.div>
                {!user.token || user.token.length === 0 ? (
                    <>
                        <MenuItem role="button" onClick={() => {
                            onLogin()
                        }}>
                            <ListItemIcon className="min-w-40 ">
                                <FuseSvgIcon>heroicons-outline:lock-closed</FuseSvgIcon>
                            </ListItemIcon>
                            <ListItemText primary={t("home_SignIn")} />
                        </MenuItem>
                        <MenuItem role="button" onClick={() => {
                            onLogin()
                        }}>
                            <ListItemIcon className="min-w-40 ">
                                <FuseSvgIcon>heroicons-outline:user-add </FuseSvgIcon>
                            </ListItemIcon>
                            <ListItemText primary={t("home_SignUp")} />
                        </MenuItem>
                    </>
                ) : (
                    <>
                        {/*<MenuItem component={Link} to="/apps/profile" onClick={userMenuClose} role="button">*/}
                        {/*  <ListItemIcon className="min-w-40">*/}
                        {/*    <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>*/}
                        {/*  </ListItemIcon>*/}
                        {/*  <ListItemText primary="My Profile" />*/}
                        {/*</MenuItem>*/}
                        {/*<MenuItem component={Link} to="/apps/mailbox" onClick={userMenuClose} role="button">*/}
                        {/*  <ListItemIcon className="min-w-40">*/}
                        {/*    <FuseSvgIcon>heroicons-outline:mail-open</FuseSvgIcon>*/}
                        {/*  </ListItemIcon>*/}
                        {/*  <ListItemText primary="Inbox" />*/}
                        {/*</MenuItem>*/}
                        <div className='mt-10 '>
                            <MenuItem
                                // component={NavLink}
                                // to="/sign-out"
                                onClick={() => {
                                    userMenuClose();
                                    FuniBox.Tools.default.destroyToken();
                                    dispatch(userProfile({
                                        token: '',
                                        userData: {}
                                    }));
                                    history.push('/')
                                }}
                                className="borderRadius-5"
                                style={{ width: "90%", margin: "0 auto" }}
                            >
                                <ListItemIcon className="min-w-40 ">
                                    <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
                                </ListItemIcon>
                                <ListItemText primary={t("home_SignOut")} />
                            </MenuItem>
                        </div>
                    </>
                )}
            </Popover>
        </>
    );
}

export default UserMenu;
