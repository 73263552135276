import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    navigatorList: [],  // nav列表
    allNavTagList: {}, // 所有列表
};

const navSlice = createSlice({
    name: 'nav',
    initialState,
    reducers: {

        updateNavigatorList: (state, action) => {
            let res = action.payload;
            state.navigatorList = res;
        },

        updateAllNavTagList: (state, action) => {
            let res = action.payload;
            state.allNavTagList = res;
        },
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateNavigatorList, updateAllNavTagList } = navSlice.actions;

export const selectNav = ({ nav }) => nav;

export default navSlice.reducer;
