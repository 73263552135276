import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    setting: {},
    user: {},
    isUserSettingLoading: false
};

const userSettingSlice = createSlice({
    name: 'userSetting',
    initialState,
    reducers: {
        updateIsUserSettingLoading: (state, action) => {
            let res = action.payload;
            state.isUserSettingLoading = res;
        },

        updateUserSettingSetting: (state, action) => {
            let res = action.payload;
            if (JSON.stringify(res) !== '{}' && JSON.stringify(res) !== JSON.stringify(state.setting)) {
                state.setting = res;
            }
        },

        updateUserSettingUser: (state, action) => {
            let res = action.payload;
            if (JSON.stringify(res) !== '{}' && JSON.stringify(res) !== JSON.stringify(state.user)) {
                state.user = res;
            }
        },
        
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateIsUserSettingLoading, updateUserSettingSetting, updateUserSettingUser } = userSettingSlice.actions;

export const selectUserSetting = ({ userSetting }) => userSetting;

export default userSettingSlice.reducer;
