  const locale = {
  GAME: 'Игры',
  Casino: "Казино",
  ProvablyFair: "",
  Option: 'Вариант',
  Crypto: 'Крипто',
  Forex: 'Форекс',
  Stocks: 'Акции',
  NFTFuniCard: 'NFT FuniCard',
  StakeNFT: 'Доля NFT заработать USDT',
  NFTStake: 'NFT Доля',
  Marketplace: 'Маркетплейс',
  NFTMint: 'NFT Мята',
  BettoEarn: 'Ставка на заработок',
  EarnUSGT: 'Заработать USDT',
  DeFi: 'DeFi',
  ToBeVolunteer: 'Быть волонтером',
  SYSTEM: 'Система',
  Affiliate: 'Партнерская программа',
  News: 'Новости',
  FAQs: 'FAQs',
  DOWNLOAD: 'СКАЧАТЬ',
  DOWNLOADApp: 'Скачать App',
  Savetodesktop: 'Save to desktop',
  BetMining: 'Добыча ставок',
  ToBeBanker: 'Стать банкиром',
  ProfitPool: 'Пул прибыли',
  USGTLiquidityMining: 'USDT Liquidity Mining',
  BGTLiquidityMining: 'BGT Liquidity Mining',
  Slots: 'Слоты',
  Favorite: 'Избранное',
  Recent: 'Недавний',
  Popular: "Популярный",
  LiveCasino: "Живое казино",
  TableGames: "Настольные игры",
  BlackJack: "Блэкджек",
  Baccarat: "Баккара",
  Roulette: "Рулетка",
  MoreGames: "Больше игр",
  Provablyfair: "Provably fair",
  Special: "Особый",
  Recommend: "Рекомендация",
  FeatureBuyin: "Функция купить",
  EnableTrialPlay: "Пробная игра",
  GameShow: "Game Show",
  RGSVSB: "RGS - VSB",
  Scratch: "Скретч",
  Bingo: "Бинго",
  Fishing: "Рыбалка",
  Turntable: "Рулетка",
  Dice: "Кости",
  Poker: "Покер",
  AllGames: "Все игры",
  EuropeanView: "Европейский",
  AfricanView: "Африканский",
  LatinView: "Латинский",
  AsianView: "Азиатский",
  LsportView: "Лига",
  StandardView: "Стандартный",
  EsportView: "Киберспортивный",
  LiveBetting:'LiveСтавки',
  Provider:'Провайдер',
  Sport: "Спорт",



};

export default locale;
