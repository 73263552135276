import Fund from './Fund';


const FundConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'fund',
            element: <Fund />,
        },
    ],
};

export default FundConfig;