  const locale = {
    GAME: 'Permainan',
    Casino: "Kasino",
    ProvablyFair: "Dapat Disahkan Keadilan",
    Option: 'Pilihan',
    Crypto: 'Kripto',
    Forex: 'Pasaran Forex',
    Stocks: 'Saham',
    NFTFuniCard: 'Kad Isi Semula NFT',
    StakeNFT: 'Stake NFT dan dapatkan USDT',
    NFTStake: 'Stake NFT',
    Marketplace: 'Pasaran',
    NFTMint: 'Mencetak NFT',
    BettoEarn: 'Bertaruh untuk Mencari',
    EarnUSGT: 'Memperoleh USDT',
    DeFi: 'Kewangan Terdesentralisasi',
    ToBeVolunteer: 'Menjadi Sukarelawan',
    SYSTEM: 'Sistem',
    Affiliate: 'Afiliasi',
    News: 'Berita',
    FAQs: 'FAQs',
    DOWNLOAD: 'Unduh',
    DOWNLOADApp: 'Unduh Aplikasi',
    Savetodesktop: 'Simpan ke Desktop',
    BetMining: 'kolam pertaruhan',
    ToBeBanker: 'To Be Banker',
    ProfitPool: 'Kolam Keuntungan',
    USGTLiquidityMining: 'USDT Liquidity Mining',
    BGTLiquidityMining: 'BGT Liquidity Mining',
    Slots: 'Mesin Slot',
    Favorite: 'Favorit',
    Recent: 'Terbaru',
    Popular: "Populer",
    LiveCasino: "Kasino Langsung",
    TableGames: "Permainan Meja",
    BlackJack: "Blackjack",
    Baccarat: "Baccarat",
    Roulette: "Roda putar",
    MoreGames: "Permainan Lain",
    Provablyfair: "Adil Terbukti",
    Special: "Spesial",
    Recommend: "Direkomendasikan",
    FeatureBuyin: "Beli Fitur",
    EnableTrialPlay: "Enable Trial Play",
    GameShow: "Acara Permainan",
    RGSVSB: "RGS - VSB",
    Scratch: "Goresan",
    Bingo: "Bingo",
    Fishing: "Memancing",
    Turntable: "Putar",
    Dice: "Dadu",
    Poker: "Poker",
    AllGames: "Semua Permainan",
    EuropeanView: "Eropah",
    AfricanView: "Afrika",
    LatinView: "Amerika Latin",
    AsianView: "Asia",
    LsportView: "Liga",
    StandardView: "Standard",
    EsportView: "Sukan Elektronik",
    LiveBetting:'LiveBetting',
    Provider:'Pembekal',
    Sport: "Sukan"
    
    
    };
    
    export default locale;
    
