import "../../../styles/game.css";
import * as React from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Common_foot from '../common/Common_foot';
import Common_gameReport from '../common/Common_gameReport';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import { height } from "@mui/system";

import Checkbox from '@mui/material/Checkbox';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    margin: 0,
    border: 'none',
    borderRadius: '8px!important',
    backgroundColor: '#1E293B!important',
    marginBottom: 24,
    '&:before': {
        display: 'none',
    },
    '&:first-of-type': {},
    '&:last-of-type': {
        marginBottom: 0,
    },
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function SportMatch(props) {

    const [selectedTab2, setSelectedTab2] = useState(true);
    const [selectedTab3, setSelectedTab3] = useState(true);


    const [selectedTab, setSelectedTab] = useState(0);
    function handleTabChange(event, value) {
        setSelectedTab(value);
    }

    const [selectedTab1, setSelectedTab1] = useState(0);
    function handleTabChange1(event, value) {
        setSelectedTab1(value);
    }

    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };

    var data1 = ["World Cup(3)", "Int.Friendly Games(3)"]
    const [tmpWidth1, setTmpWidth1] = useState('100%');
    useEffect(() => {
        switch (data1.length) {
            case 0:
                setTmpWidth('100%')
                break;
            case 1:
                setTmpWidth('100%')
                break;
            case 2:
                setTmpWidth('49%')
                break;
            case 3:
                setTmpWidth('32.4%')
                break;
            case 4:
                setTmpWidth('24.15%')
                break;
            case 5:
                setTmpWidth('19.1%')
                break;
            default:
                setTmpWidth('19%')
                break;
        }
    }, []);


    var data2 = ["Premier League(20)", "championship(20)", "League One(21)", "League Two(6)", " EFL Cup(20)", " EFL Cup(20)", " FA Cup(21)"]
    const [tmpWidth, setTmpWidth] = useState('100%');
    useEffect(() => {
        switch (data2.length) {
            case 0:
                setTmpWidth('100%')
                break;
            case 1:
                setTmpWidth('100%')
                break;
            case 2:
                setTmpWidth('49%')
                break;
            case 3:
                setTmpWidth('32.4%')
                break;
            case 4:
                setTmpWidth('24.15%')
                break;
            case 5:
                setTmpWidth('19.1%')
                break;
            default:
                setTmpWidth('19%')
                break;
        }
    }, []);

    return (
        <div className="flex w-full container">
            <div className="flex flex-col sm:flex-row flex-auto sm:items-center betDiColor min-w-0 p-10 md:p-32 pb-0 md:pb-0">
                <div className="flex flex-col flex-auto align-item">
                    <div className="flex grow shrink-0 flex-col items-center container">
                        <div className="activitybanner  text-24 mb-16">World Cup</div>
                        <motion.div
                            variants={container}
                            initial="hidden"
                            animate="show"
                            className="activitybanner"
                        >
                            <motion.div variants={item} className="min-w-full">
                                <div className="zuQiu">
                                    <div className="promotion-list activitybanner marginJuZhong">
                                        <div>
                                            <Tabs
                                                value={selectedTab}
                                                onChange={handleTabChange}
                                                indicatorColor="primary"
                                                textColor="inherit"
                                                variant="scrollable"
                                                scrollButtons={false}
                                                className="min-h-40  backGround-foot borderRadius"
                                                classes={{ indicator: 'flex justify-center bg-transparent w-full h-full' }}
                                                TabIndicatorProps={{
                                                    children: (
                                                        <Box
                                                            sx={{ bgcolor: 'text.disabled' }}
                                                            className="w-full h-full rounded-full gamesListBtnBackground-Color opacity-100"
                                                        />
                                                    ),
                                                }}
                                            >
                                                <Tab
                                                    className="text-14 font-semibold min-h-40  Zindex  flex"
                                                    disableRipple
                                                    label="Live & Upcoming"
                                                />
                                                <Tab
                                                    className="text-14 font-semibold min-h-40  Zindex"
                                                    disableRipple
                                                    label="Outrights"
                                                />
                                                <Tab
                                                    className="text-14 font-semibold min-h-40  Zindex  flex"
                                                    disableRipple
                                                    label="All Soccer"
                                                />
                                            </Tabs>
                                        </div>

                                        <div className="displayPhoneSwitch1">
                                            <Tabs
                                                value={selectedTab1}
                                                onChange={handleTabChange1}
                                                indicatorColor="primary"
                                                textColor="inherit"
                                                variant="scrollable"
                                                scrollButtons={false}
                                                className="min-h-32 borderRadius"
                                                classes={{ indicator: 'flex justify-center bg-transparent w-full h-full' }}
                                                TabIndicatorProps={{
                                                    children: (
                                                        <Box
                                                            sx={{ bgcolor: 'text.disabled' }}
                                                            className="w-full h-full rounded-full btnGreenColor opacity-100 "
                                                        />
                                                    ),
                                                }}
                                            >
                                                <Tab
                                                    className="text-18 font-semibold min-h-32  Zindex  "
                                                    disableRipple
                                                    label="1X2"
                                                />
                                                <Tab
                                                    className="text-18 font-semibold min-h-32  Zindex "
                                                    disableRipple
                                                    label="HDP"
                                                />
                                                <Tab
                                                    className="text-18 font-semibold min-h-32  Zindex  "
                                                    disableRipple
                                                    label="O/U"
                                                />
                                                <Tab
                                                    className="text-18 font-semibold min-h-32  Zindex  "
                                                    disableRipple
                                                    label="HT 1X2"
                                                />
                                                <Tab
                                                    className="text-18 font-semibold min-h-32  Zindex  "
                                                    disableRipple
                                                    label="HT HDP"
                                                />
                                                <Tab
                                                    className="text-18 font-semibold min-h-32 Zindex  "
                                                    disableRipple
                                                    label="HT O/U"
                                                />
                                            </Tabs>
                                        </div>

                                        <div className="displayPhoneSwitch mt-20">
                                            <div className="width-120  h-32  backGround-foot   borderRadius btnPointer txtBrightness">
                                                <div className="flex align-item justifyContent ">
                                                    <div className="text-16 font-weight500" style={{ lineHeight: "32px", marginLeft: "12px" }}> HT 1X2</div>
                                                    <img className="height-24" src="assets/images/index/jianTou.png"></img>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="mt-16">

                                    {selectedTab === 0 && <div className="flex flex-wrap activitybanner">
                                        <StyledAccordion
                                            component={motion.div}
                                            variants={item}
                                            classes={{
                                                root: 'FaqPage-panel shadow',
                                            }}
                                            className="zuQiu"
                                            expanded={selectedTab2}
                                            onChange={() => {
                                                setSelectedTab2(!selectedTab2)
                                            }}
                                            style={{}}
                                        >
                                            <AccordionSummary
                                                expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                className="backGroundColorNode sportTitleborderRadius-8"
                                                style={{ maxHeight: "32px" }}
                                            >
                                                <div className="flex items-center flex-grow " style={{ width: '100%' }}>
                                                    <div className="flex items-center">
                                                        <div className="font-medium">
                                                            <Typography className="text-16 font-medium fontColor font-weight500" >World Cup</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ padding: "0" }} className="backGroundColorNode borderRadius-8">
                                                <div>
                                                    <div className="fontColor text-12 font-weight500 px-16 py-8  sportTitlebackGroundColor">December 10, 2022</div>
                                                    <div className="promotion-list1">

                                                        <div className="sportPhoneWidth">
                                                            <div className="promotion-list1 mx-16 mt-8 fontColor " >
                                                                <div className="text-12 font-weight500 height-24 lineHeght-24">10 11:00 PM</div>
                                                                <div className="displayPhoneSwitch3">
                                                                    <img className="height-24 mx-4 btnPointer txtBrightness" src="assets/images/icon/10003.png"></img>
                                                                    <img className="height-24 mx-4 btnPointer txtBrightness" src="assets/images/icon/10010.png"></img>
                                                                    <div className="height-24 mx-4 text-14 font-weight500 lineHeght-24">+120</div>
                                                                </div>
                                                            </div>
                                                            <div className="text-12 mx-16 mt-12 font-weight500">Morocco</div>
                                                            <div className="text-12 mx-16 mt-6 font-weight500">Portugal</div>
                                                        </div>

                                                        <div className="phoneMx-16 phone100Bai">
                                                            <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Asian Handicap</div>
                                                            <div className="flex mt-4 mb-12">
                                                                <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                    alignItems: 'center',
                                                                    flexWrap: 'wrap'
                                                                }}>
                                                                    <img className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></img>
                                                                </div>
                                                                <div className="height-52 phoneMW-224 borderRadius-5 backGround-foot mx-10">
                                                                    <div className="mx-8 mt-8 text-12 font-weight500">MAR(0)</div>
                                                                    <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.22</div>
                                                                </div>
                                                                <div className="height-52 phoneMW-224 borderRadius-5 backGround-foot mr-10">
                                                                    <div className="mx-8 mt-8 text-12 font-weight500">POR(0)</div>
                                                                    <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.22</div>
                                                                </div>
                                                                <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+120</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="fontColor text-12 font-weight500 px-16 py-8  sportTitlebackGroundColor">December 10, 2022</div>
                                                    <div className="promotion-list1">

                                                        <div className="sportPhoneWidth">
                                                            <div className="promotion-list1 mx-16 mt-8 fontColor " >
                                                                <div className="text-12 font-weight500 height-24 lineHeght-24">Sun,Dec 11 3:00 AM</div>
                                                                <div className="displayPhoneSwitch3">
                                                                    <img className="height-24 mx-4 btnPointer txtBrightness" src="assets/images/icon/10003.png"></img>
                                                                    <img className="height-24 mx-4 btnPointer txtBrightness" src="assets/images/icon/10010.png"></img>
                                                                    <div className="height-24 mx-4 text-14 font-weight500 lineHeght-24">+100</div>
                                                                </div>
                                                            </div>
                                                            <div className="text-12 mx-16 mt-12 font-weight500">Englend</div>
                                                            <div className="text-12 mx-16 mt-6 font-weight500">France</div>
                                                        </div>

                                                        <div className="phoneMx-16 phone100Bai">
                                                            <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Asian Handicap</div>
                                                            <div className="flex mt-4 mb-12">
                                                                <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                    alignItems: 'center',
                                                                    flexWrap: 'wrap'
                                                                }}>
                                                                    <img className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></img>
                                                                </div>
                                                                <div className="height-52 phoneMW-224 borderRadius-5 backGround-foot mx-10">
                                                                    <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                    <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                </div>
                                                                <div className="height-52 phoneMW-224 borderRadius-5 backGround-foot mr-10">
                                                                    <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                    <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                </div>
                                                                <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+100</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="fontColor text-12 font-weight500 px-16 py-8  sportTitlebackGroundColor">December 10, 2022</div>
                                                    <div className="promotion-list1">
                                                        <div className="sportPhoneWidth">
                                                            <div className="promotion-list1 mx-16 mt-8 fontColor " >
                                                                <div className="text-12 font-weight500 height-24 lineHeght-24">10 11:00 PM</div>
                                                                <div className="displayPhoneSwitch3">
                                                                    <img className="height-24 mx-4 btnPointer txtBrightness" src="assets/images/icon/10003.png"></img>
                                                                    <img className="height-24 mx-4 btnPointer txtBrightness" src="assets/images/icon/10010.png"></img>
                                                                    <div className="height-24 mx-4 text-14 font-weight500 lineHeght-24">+160</div>
                                                                </div>
                                                            </div>
                                                            <div className="text-12 mx-16 mt-12 font-weight500">Morocco</div>
                                                            <div className="text-12 mx-16 mt-6 font-weight500">Portugal</div>
                                                        </div>
                                                        <div className="phoneMx-16 phone100Bai">
                                                            <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Asian Handicap</div>
                                                            <div className="flex mt-4 mb-12">
                                                                <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                    alignItems: 'center',
                                                                    flexWrap: 'wrap'
                                                                }}>
                                                                    <img className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></img>
                                                                </div>
                                                                <div className="height-52 phoneMW-224 borderRadius-5 backGround-foot mx-10">
                                                                    <div className="mx-8 mt-8 text-12 font-weight500">ARG(0)</div>
                                                                    <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.42</div>
                                                                </div>
                                                                <div className="height-52 phoneMW-224 borderRadius-5 backGround-foot mr-10">
                                                                    <div className="mx-8 mt-8 text-12 font-weight500">CRO(0)</div>
                                                                    <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.74</div>
                                                                </div>
                                                                <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+160</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </StyledAccordion>
                                    </div>}


                                    {selectedTab === 1 && <div className="flex flex-wrap activitybanner">
                                        <StyledAccordion
                                            component={motion.div}
                                            variants={item}
                                            classes={{
                                                root: 'FaqPage-panel shadow',
                                            }}
                                            className="zuQiu"
                                            expanded={selectedTab2}
                                            onChange={() => {
                                                setSelectedTab2(!selectedTab2)
                                            }}
                                        >
                                            <AccordionSummary
                                                expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                className="backGroundColorNode sportTitleborderRadius-8"
                                                style={{ maxHeight: "32px" }}
                                            >
                                                <div className="flex items-center flex-grow " style={{ width: '100%' }}>
                                                    <div className="flex items-center">
                                                        <div className="font-medium">
                                                            <Typography className="text-16 font-medium fontColor font-weight500">World Cup</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ padding: "0" }} className="backGroundColorNode borderRadius-8 ">
                                                <div>
                                                    <div className="fontColor text-12 font-weight500 px-16 py-8  sportTitlebackGroundColor">December 10, 2022</div>
                                                    <div className="promotion-list1">
                                                        <div className="mb-12">
                                                            <div className="mx-14 mt-12 text-16 font-weight500">World Cup</div>
                                                            <div className="fontColor text-12 mx-16 mt-12 font-weight500">Soccer → International → World Cup</div>
                                                        </div>
                                                        <div className="mx-16">
                                                            <div className="fontColor mt-12 text-12 font-weight500 " style={{ textAlign: "right" }}>+36</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </StyledAccordion>
                                    </div>}


                                    {selectedTab === 2 && <div className="flex flex-wrap activitybanner">

                                        <StyledAccordion
                                            component={motion.div}
                                            variants={item}
                                            classes={{
                                                root: 'FaqPage-panel shadow',
                                            }}
                                            className="zuQiu"
                                            expanded={selectedTab3}
                                            onChange={() => {
                                                setSelectedTab3(!selectedTab3)
                                            }}
                                        >
                                            <AccordionSummary
                                                expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                className="backGroundColorNode sportTitleborderRadius-8"
                                                style={{ maxHeight: "32px" }}
                                            >
                                                <div className="flex items-center flex-grow  justify-content-between" style={{ width: '100%' }}>
                                                    <div className="flex items-center">
                                                        <div className="font-medium">
                                                            <Typography className="text-16 font-medium fontColor font-weight500">Intermational</Typography>
                                                        </div>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ padding: "0" }} className="backGroundColorNode sportTitleborderRadius2-8 ">
                                                <div style={{ borderTop: "1px solid #1E2633" }} className="mb-7"></div>
                                                <div className="pb-12 px-5 flex flexWrap displayPhoneSwitch1">
                                                    {data1.map((item) => {
                                                        return (
                                                            <div>
                                                                <div style={{ width: tmpWidth, backgroundColor: "#1E293B", }} className="mx-5 my-5 borderRadius-5 txtBrightness" > <Checkbox {...label} />{item}</div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="pb-12 px-5 displayPhoneSwitch">
                                                    {data1.map((item) => {
                                                        return (
                                                            <div>
                                                                <div style={{ width: "97%", backgroundColor: "#1E293B", }} className="mx-5 my-5 borderRadius-5 txtBrightness" > <Checkbox {...label} />{item}</div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </AccordionDetails>
                                        </StyledAccordion>

                                        <StyledAccordion
                                            component={motion.div}
                                            variants={item}
                                            classes={{
                                                root: 'FaqPage-panel shadow',
                                            }}
                                            className="zuQiu"
                                            expanded={selectedTab2}
                                            onChange={() => {
                                                setSelectedTab2(!selectedTab2)
                                            }}
                                        >
                                            <AccordionSummary
                                                expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                className="backGroundColorNode sportTitleborderRadius-8"
                                                style={{ maxHeight: "32px" }}
                                            >
                                                <div className="flex items-center flex-grow  justify-content-between" style={{ width: '100%' }}>
                                                    <div className="flex items-center">
                                                        <div className="font-medium">
                                                            <Typography className="text-16 font-medium fontColor font-weight500">Englend</Typography>
                                                        </div>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ padding: "0" }} className="backGroundColorNode sportTitleborderRadius2-8 ">
                                                <div style={{ borderTop: "1px solid #1E2633" }} className="mb-7"></div>
                                                <div className="pb-12 px-5 flex flexWrap displayPhoneSwitch1">
                                                    {data2.map((item) => {
                                                        return (
                                                            <div style={{ width: tmpWidth, backgroundColor: "#1E293B", }} className="mx-5 my-5 borderRadius-5 txtBrightness" > <Checkbox {...label} />{item}</div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="pb-12 px-5  displayPhoneSwitch">
                                                    {data2.map((item) => {
                                                        return (
                                                            <div style={{ width: "97%", backgroundColor: "#1E293B", }} className="mx-5 my-5 borderRadius-5 txtBrightness" > <Checkbox {...label} />{item}</div>
                                                        )
                                                    })}
                                                </div>

                                            </AccordionDetails>
                                        </StyledAccordion>

                                    </div>}

                                </div>
                                <div className="zuQiu mt-20 mb-20">

                                </div>
                            </motion.div>
                        </motion.div>
                    </div>
                    <Common_gameReport />
                    <Common_foot />
                    <div className="mt-32  promotion-list colorGameListTitle mb-20 displayPhoneSwitch1">
                        <div>FuniBet 2022- ALL Rights Reserved</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SportMatch;
