function EmptyExampleComponent() {
  return (
    <>
      <div className="flex flex-col flex-auto min-w-0">
        <div className="flex-auto p-24 sm:p-40">
          <div className="h-7xl min-h-7xl max-h-7xl border-2 border-dashed rounded-2xl" />
        </div>
      </div>
    </>
  );
}

export default EmptyExampleComponent;
