import i18next from 'i18next';
import br from './navigation-i18n/br';
import cn from './navigation-i18n/cn';
import de from './navigation-i18n/de';
import en from './navigation-i18n/en';
import es from './navigation-i18n/es';
import fi from './navigation-i18n/fi';
import fr from './navigation-i18n/fr';
import hk from './navigation-i18n/hk';
import id from './navigation-i18n/id';
import India from './navigation-i18n/in';
import jp from './navigation-i18n/jp';
import kr from './navigation-i18n/kr';
import ph from './navigation-i18n/ph';
import pl from './navigation-i18n/pl';
import ru from './navigation-i18n/ru';
import tr from './navigation-i18n/tr';
import vn from './navigation-i18n/vn';
import ae from './navigation-i18n/ae';
import bd from './navigation-i18n/bd';
import mld from './navigation-i18n/mld';
import my from './navigation-i18n/my';
import th from './navigation-i18n/th';
import tmr from './navigation-i18n/tmr';
import mm from './navigation-i18n/mm';
import pak from './navigation-i18n/pak';

i18next.addResourceBundle('br', 'navigation', br);
i18next.addResourceBundle('cn', 'navigation', cn);
i18next.addResourceBundle('de', 'navigation', de);
i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('es', 'navigation', es);
i18next.addResourceBundle('fi', 'navigation', fi);
i18next.addResourceBundle('fr', 'navigation', fr);
i18next.addResourceBundle('hk', 'navigation', hk);
i18next.addResourceBundle('id', 'navigation', id);
i18next.addResourceBundle('in', 'navigation', India);
i18next.addResourceBundle('jp', 'navigation', jp);
i18next.addResourceBundle('kr', 'navigation', kr);
i18next.addResourceBundle('ph', 'navigation', ph);
i18next.addResourceBundle('pl', 'navigation', pl);
i18next.addResourceBundle('ru', 'navigation', ru);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('vn', 'navigation', vn);
i18next.addResourceBundle('ae', 'navigation', ae);
i18next.addResourceBundle('bd', 'navigation', bd);
i18next.addResourceBundle('mld', 'navigation', mld);
i18next.addResourceBundle('my', 'navigation', my);
i18next.addResourceBundle('th', 'navigation', th);
i18next.addResourceBundle('tmr', 'navigation', tmr);
i18next.addResourceBundle('mm', 'navigation', mm);
i18next.addResourceBundle('pak', 'navigation', pak);


const navigationConfig = [
    {
        id: 'GAME',
        title: 'GAME',
        subtitle: '',
        type: 'group',
        icon: 'heroicons-outline:home',
        translate: 'GAME',
        children: [
            {
                id: 'Casino',
                title: 'Casino',
                type: 'collapse',
                icon: 'heroicons-outline:Casino',
                translate: 'Casino',
                children: [

                ],
            },
            {
                id: 'Sport',
                title: 'Sport',
                type: 'collapse',
                icon: 'heroicons-outline:Sports',
                translate: 'Sport',
                children: [
                    {
                        id: 'asianview',
                        title: 'Asian View',
                        type: 'item',
                        icon: 'heroicons-outline:pinPang',
                        url: 'sport/asianView',
                        translate: 'AsianView',
                        end: true,
                    },
                    {
                        id: 'europeanView',
                        title: 'European View',
                        type: 'item',
                        icon: 'heroicons-outline:Ice Hockey',
                        url: 'sport/europeanView',
                        translate: 'EuropeanView',
                        end: true,
                    },
                    {
                        id: 'latinView',
                        title: 'Latin View',
                        type: 'item',
                        icon: 'heroicons-outline:Soccer',
                        url: 'sport/LatinoView',
                        translate: 'LatinView',
                        end: true,
                    },
                    // {
                    //     id: 'lsportview',
                    //     title: 'Lsport View',
                    //     type: 'item',
                    //     icon: 'heroicons-outline:American Football',
                    //     url: 'sport/lsportView',
                    //     translate: 'LsportView',
                    //     end: true,
                    // },
                    {
                        id: 'ESport',
                        title: 'ESport',
                        type: 'item',
                        icon: 'heroicons-outline:Special',
                        url: 'sport/eSport',
                        translate: 'EsportView',
                        end: true,
                    },
                    // {
                    //     id: 'Mobile',
                    //     title: 'Mobile',
                    //     type: 'item',
                    //     icon: 'heroicons-outline:device-mobile',
                    //     url: 'sport/mobile',
                    //     translate: 'Mobile',
                    //     end: true,
                    // },
                    // {
                    //     id: 'SimpleEast',
                    //     title: 'SimpleEast',
                    //     type: 'item',
                    //     icon: 'heroicons-outline:Tennis',
                    //     url: 'sport/simpleEast',
                    //     translate: 'SimpleEast',
                    //     end: true,
                    // },

                    // {
                    //     id: 'LiveBetting',
                    //     title: 'Live Betting',
                    //     type: 'item',
                    //     icon: 'heroicons-outline:Soccer',
                    //     url: 'sport/LivePage',
                    //     translate: 'LiveBetting',
                    //     end: true,
                    // },
                ],
            },
            {
                id: 'ProvablyFair',
                title: 'ProvablyFair',
                type: 'item',
                icon: 'heroicons-outline:FuniHash',
                url: 'gameOther/3/Provably Fair/1',
                translate: 'ProvablyFair',
                end: true,
            },
            // {
            //     id: 'Option',
            //     title: 'Option',
            //     type: 'collapse',
            //     icon: 'heroicons-outline:Option',
            //     translate: 'Option',
            //     children: [
            //         {
            //             id: 'Crypto',
            //             title: 'Crypto',
            //             type: 'item',
            //             icon: 'heroicons-outline:Crypto',
            //             url: 'comingSoon/Crypto',
            //             translate: 'Crypto',
            //             end: true,
            //         },
            //         {
            //             id: 'Forex',
            //             title: 'Forex',
            //             type: 'item',
            //             icon: 'heroicons-outline:Forex',
            //             url: 'comingSoon/Forex',
            //             translate: 'Forex',
            //             end: true,
            //         },
            //         {
            //             id: 'Stocks',
            //             title: 'Stocks',
            //             type: 'item',
            //             icon: 'heroicons-outline:Stocks',
            //             url: 'comingSoon/Stocks',
            //             translate: 'Stocks',
            //             end: true,
            //         },
            //     ]
            // },
            {
                id: 'LiveCasino',
                title: 'LiveCasino',
                type: 'item',
                icon: 'heroicons-outline:Live Casino',
                url: 'gameOther/2/Live Casino/1/',
                translate: 'LiveCasino',
                end: true,
            },
            {
                id: 'Provider',
                title: 'Provider',
                type: 'item',
                icon: 'heroicons-outline:Provider',
                url: 'provider/',
                translate: 'Provider',
                end: true,
            },
        ],
    },
    // {
    //     id: 'Web3.0',
    //     title: 'Web3.0',
    //     subtitle: '',
    //     type: 'group',
    //     icon: 'heroicons-outline:home',
    //     translate: 'Web3.0',
    //     children: [
    //         {
    //             id: 'NFTFuniCard',
    //             title: 'NFTFuniCard',
    //             subtitle: 'StakeNFT',
    //             type: 'collapse',
    //             icon: 'heroicons-outline:NFT',
    //             translate: 'NFTFuniCard',
    //             children: [
    //                 {
    //                     id: 'NFTStake',
    //                     title: 'NFTStake',
    //                     type: 'item',
    //                     icon: 'heroicons-outline:NFT Stake',
    //                     url: 'comingSoon/NFT Stake',
    //                     translate: 'NFTStake',
    //                     end: true,
    //                 },
    //                 {
    //                     id: 'Marketplace',
    //                     title: 'Marketplace',
    //                     type: 'item',
    //                     icon: 'heroicons-outline:Marketplace',
    //                     url: 'comingSoon/Marketplace',
    //                     translate: 'Marketplace',
    //                     end: true,
    //                 },
    //                 {
    //                     id: 'NFTMint',
    //                     title: 'NFTMint',
    //                     type: 'item',
    //                     icon: 'heroicons-outline:NFT Mint',
    //                     url: 'comingSoon/NFT Mint',
    //                     translate: 'NFTMint',
    //                     end: true,
    //                 },
    //             ],
    //         },
    //         {
    //             id: 'BettoEarn',
    //             title: 'BettoEarn',
    //             subtitle: 'EarnUSGT',
    //             type: 'collapse',
    //             icon: 'heroicons-outline:Bet',
    //             translate: 'BettoEarn',
    //             children: [
    //                 {
    //                     id: 'BetMining',
    //                     title: 'BetMining',
    //                     type: 'item',
    //                     icon: 'heroicons-outline:Bet Mining',
    //                     url: 'comingSoon/Bet Mining',
    //                     translate: 'BetMining',
    //                     end: true,
    //                 },
    //                 {
    //                     id: 'ToBeBanker',
    //                     title: 'ToBeBanker',
    //                     type: 'item',
    //                     icon: 'heroicons-outline:To Be Banker',
    //                     url: 'comingSoon/To Be Banker',
    //                     translate: 'ToBeBanker',
    //                     end: true,
    //                 },
    //                 {
    //                     id: 'Profit Pool',
    //                     title: 'Profit Pool',
    //                     type: 'item',
    //                     icon: 'heroicons-outline:Profit Pool',
    //                     url: 'comingSoon/Profit Pool',
    //                     translate: 'ProfitPool',
    //                     end: true,
    //                 },
    //             ],
    //         },
    //         {
    //             id: 'DeFi',
    //             title: 'DeFi',
    //             subtitle: 'EarnUSGT',
    //             type: 'collapse',
    //             icon: 'heroicons-outline:DeFi',
    //             translate: 'DeFi',
    //             children: [
    //                 {
    //                     id: 'USGTLiquidityMining',
    //                     title: 'USGTLiquidityMining',
    //                     type: 'item',
    //                     icon: 'heroicons-outline:USGT Liquidity Mining',
    //                     url: 'comingSoon/DeFi',
    //                     translate: 'USGTLiquidityMining',
    //                     end: true,
    //                 },
    //                 {
    //                     id: 'BGTLiquidityMining',
    //                     title: 'BGTLiquidityMining',
    //                     type: 'item',
    //                     icon: 'heroicons-outline:BGT Liquidity Mining',
    //                     url: 'comingSoon/BGT Liquidity Mining',
    //                     translate: 'BGTLiquidityMining',
    //                     end: true,
    //                 },
    //             ],
    //         },
    //         {
    //             id: 'ToBeVolunteer',
    //             title: 'ToBeVolunteer',
    //             type: 'item',
    //             icon: 'heroicons-outline:Volunteer',
    //             url: 'comingSoon/To Be Volunteer',
    //             translate: 'ToBeVolunteer',
    //             end: true,
    //         },
    //     ],
    // },
    {
        id: 'SYSTEM',
        title: 'SYSTEM',
        subtitle: '',
        type: 'group',
        icon: 'heroicons-outline:home',
        translate: 'SYSTEM',
        children: [
            {
                id: 'Affiliate',
                title: 'Affiliate',
                type: 'item',
                icon: 'heroicons-outline:Affiliate',
                url: 'Affiliate',
                translate: 'Affiliate',
                end: true,
            },
            {
                id: 'News',
                title: 'News',
                type: 'item',
                icon: 'heroicons-outline:clipboard-list',
                url: 'documentation/getting-started/introduction/79',
                translate: 'News',
                end: true,
            },
            {
                id: 'FAQs',
                title: 'FAQs',
                type: 'item',
                icon: 'heroicons-outline:exclamation-circle',
                url: 'apps/help-center/faqs',
                translate: 'FAQs',
                end: true,
            },
        ],
    },
    {
        id: 'DOWNLOAD',
        title: 'DOWNLOAD',
        subtitle: '',
        type: 'group',
        icon: 'heroicons-outline:home',
        translate: 'DOWNLOAD',
        children: [
            {
                id: 'Android APK',
                subtitle: 'DOWNLOADApp',
                title: 'AndroidAPP',
                type: 'item',
                icon: 'heroicons-outline:AndroidAPP',
                url: 'OfficialAPP',
                end: true,
            },
            {
                id: 'Googleplay',
                subtitle: 'DOWNLOADApp',
                title: 'Googleplay',
                type: 'item',
                icon: 'heroicons-outline:google',
                url: 'comingSoon/Setting',
                end: true,
            },
            {
                id: 'Appstore',
                subtitle: 'DOWNLOADApp',
                title: 'Appstore',
                type: 'item',
                icon: 'heroicons-outline:Appstore',
                url: 'comingSoon/Appstore',
                end: true,
            },
            {
                id: 'Windows/Mac',
                subtitle: 'Savetodesktop',
                title: 'Windows/Mac',
                type: 'item',
                icon: 'heroicons-outline:Windows/Mac',
                url: 'Course',
                translate: 'Savetodesktop',
                end: true,
            },
        ],
    },
];

export default navigationConfig;
