import { createSlice } from '@reduxjs/toolkit';
import history from '@history';
import { setSpreaderInfo } from "app/store/user/userThunk";

// state
const initialState = {
    zoomRatio: 1,
    spreaderID: '',
    customerServiceUrl: 'https://go.crisp.chat/chat/embed/?website_id=e30a94cf-704d-4331-ae34-093533e37fd6'
};

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        updateSpreaderID: (state, action) => {
            let res = action.payload;
            state.spreaderID = res;
        },

        updateZoomRatio: (state, action) => {
            let res = action.payload;
            state.zoomRatio = res;
        },
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateSpreaderID, updateZoomRatio } = configSlice.actions;

export const selectConfigData = ({ config }) => config;

export default configSlice.reducer;
