const locale = {
    home_StakeNFT: 'Stakez des NFT pour gagner plus',
    home_everyday: 'Chaque jour',
    home_Beton: 'Miser',
    home_mining: 'Miner',
    home_shareholder: 'Devenir actionnaire',
    home_Stake: 'Stake',
    home_dividend: 'Obtenir des dividendes',
    home_Banker: "Devenir l\'un des partenaires",
    home_SIGNUPAND: 'SIGN UP & ',
    home_GET: 'GET',
    home_UPTO: 'UP TO ',
    home_FuniHash: 'Jeux de blockchain',
    home_Option: 'Option',
    
    home_Reward: ' Reward',
    home_Reward1: 'Reward',
    home_signUpNow: 'Inscrivez-vous maintenant',
    home_Casino: 'Casino',
    home_Sports: 'Sports',
    home_PROMOTION: 'Promotions',
    home_Checkin: 'Checkin',
    home_Bouns: 'Bonus',
    home_CheckinBouns: 'Check in Bouns',
    home_SPIN: 'Tourner',
    home_Relief: 'Secours',
    home_Share: 'Partager',
    home_Deposit: 'Dépôt',
    home_Fund: 'Fonds',
    home_VIPClub: 'Club VIP',
    home_FastAndEasy: 'Achetez rapidement et facilement des cryptomonnaies pour obtenir un bonus de dépôt allant jusqu’à USDT',
    home_Game: 'Jeu',
    home_User: 'Utilisateur',
    home_Time: 'Temps',
    home_BetAmount: 'Montant de la mise',
    home_Multiplier: 'Multiplicateur',
    home_Payout: 'Paiement',
    home_JoinOur: 'Rejoignez notre communauté de fanatiques de sport, d’amateurs de crypto et de joueurs',
    home_JOIN: 'Rejoindre',
    home_LICENSES: 'Licences',
    home_OurPlatforms: 'Nos plateformes',
    home_Marketplac: 'Place de marché',
    home_Howtoearn: 'Comment gagner',
    home_Games: 'Jeux',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: "Plateforme d'investisseur",
    home_News: 'Actualités',
    home_Aboutsus: 'À propos de nous',
    home_Industry: 'Industrie',
    home_Roadmap: 'Feuille de route',
    home_Partner: 'Partenaire',
    home_FAQs: 'FAQ',
    home_Affiliate: 'Affilié',
    home_Stayintouch: 'Rester en contact',
    home_ChatRoom: 'Chat en direct',
    home_Selectacontact: 'Sélectionnez un contact pour commencer la discussion.',
    home_BetSlip: 'Coupon de pari',
    home_Typeyourmessage: 'Tapez votre message',
    home_AcceptAnyOdds: "Accepter n'importe quelle cote",
    home_ClearAll: 'Tout effacer',
    home_Live: 'En direct',
    home_Winner: 'Gagnant',
    home_EstPayout: 'Estimation du paiement',
    home_TotalOdds: 'Cote totale',
    home_RegistertoBet: 'S’inscrire pour parier',
    home_Single: 'Pari simple',
    home_Multi: 'Pari multiple',
    home_Checkineveryweek: 'Vérifiez chaque semaine pour accumuler des récompenses correspondantes.',
    home_Day: 'Jour',
    home_Sunday: '1er jour',
    home_Monday: '2ème jour',
    home_Tuesday: '3ème jour',
    home_Wednesday: '4ème jour',
    home_Thursday: '5ème jour',
    home_Friday: '6ème jour',
    home_Saturday: '7ème jour',
    home_FreeSpin: 'Tour gratuit',
    home_Turntheturntable: 'Tournez la table chaque jour pour gagner des récompenses en USGT.',
    home_DepositFift: 'Récompense',
    home_ANYAMOUNT: 'Récompense assurée pour votre premier dépôt, sans minimum requis.',
    home_DEPOSIT: 'Dépôt',
    home_First: 'Premier(e)',
    home_ONLY: 'Seulement',
    home_1ST: '1ER',
    home_2ND: '2ÈME',
    home_3TH: '3ÈME',
    home_ANYAMOUNT: "N'IMPORTE QUEL MONTANT!",
    home_EXTRA: 'Supplémentaire',
    home_Relief: 'Aide',
    home_Getrelief: 'Obtenez de l’aide pour avoir une chance de vous redresser.',
    home_YOUCANCLAIM: 'Lorsque le solde est inférieur à ',
    home_YOUCANReceive: ', vous pouvez recevoir une aide pour augmenter votre solde à ',
    home_threeTimes: ' 3 fois',
    home_everydayTimes: ' / chaque jour!',
    home_Times: 'Fois:',
    home_TotalAssets: 'Total des actifs',
    home_receive: 'Recevoir',
    home_TODAY: 'Aujourd’hui',
    home_Share: 'Partager',
    home_PLATFORMSHARING: 'Partager',
    home_Sharewith: 'Share FuniBet every day and get ',
    home_SHARINGGIFT: 'Cadeau de partage',
    home_CODE: 'Code de partage',
    home_Selectplatform: 'Sélectionnez une plateforme de partage:',
    home_Fund: 'Fonds',
    home_FundBonus: 'Fund Bonus',
    home_Improveyour: 'Improve your VIP level and get more fund rewards every day.',
    home_BuyFund: 'Acheter',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'status',
    home_Received: 'Reçu',
    home_noReceived: 'unmet',
    home_ExclusiveBenefits: 'Avantages exclusifs',
    home_Asawayof: "En guise de reconnaissance et d'appréciation envers nos joueurs VIP, FuniBet vous invite à rejoindre notre club VIP où vous pourrez bénéficier de cadeaux, d'avantages exclusifs, de remises en argent plus élevées et de fonctionnalités uniques. Ne manquez pas toute l'excitation et les surprises !",
    home_Yourcurrent: 'Votre dépôt total actuel',
    home_WhyUpgrade: 'Pourquoi passer au niveau VIP ?',
    home_VIPhave: "Les VIP ont un service de conciergerie exclusif 24/7, des bonus plus élevés, des canaux de dépôt/retrait rapides et des réunions d'échange avec des joueurs de haut niveau pour proposer de nouvelles fonctionnalités.",
    home_UpgradeYour: 'Mise à niveau de votre niveau VIP',
    home_ASaFunibet: "En tant que VIP de FUNIBet, vous obtenez des récompenses généreuses et des cadeaux personnalisés. Jouez plus pour progresser dans les niveaux VIP et bénéficier d'avantages de luxe. Atteignez le statut de membre VIP Elite pour des privilèges sur mesure.",
    home_YOUCANREACH: 'Vous pouvez atteindre',
    home_LEVELS: 'niveaux',
    home_eachthehigh: "Tout le monde peut bénéficier de 25% de cashback et profiter de tous les avantages de la plateforme. Rejoignez FUNIBET pour profiter de la meilleure expérience de jeu et d'un service de qualité ! ",
    home_Cashback: 'Cashback',
    home_Nowater: '1.No water pumping in the game',
    home_Moregrowth: '2.More growth funds',
    home_Increasein: '3.Increase in the number of benefits',
    home_Doublethenumber: '4.Double the number of turntables',
    home_Doublethesign: '5.Double the sign in reward',
    home_Doublesharingrewards: '6.Double sharing rewards',
    home_DoubletheAffiliate: '7.Double the Affiliate reward',
    home_Rechargerewardamount: '8.Recharge reward amount X 1.5',
    home_ABOUTVIPCLUB: 'ABOUT VIP CLUB',
    home_HightopRank: 'High top Rank has 25% cashback bonus and the best benefits. Stay with BeDao to receive the best gaming experience and high level of service!',
    home_Beingamember: 'Being a member of the VIP club means that you have access to:',
    home_PersonalVIPmanager: 'Personal VIP manager',
    home_Highercahbackrewards: 'Higher cahback rewards',
    home_Otheradditionalperks: 'Other additional perks',
    home_Insights: 'Leadership',
    home_WeeklyandMonthlyBonuses: 'Bonus hebdomadaires et mensuels',
    home_Showhighgaming: 'Affichez des activités de jeu de haut niveau et obtenez une invitation à notre club VIP.',
    home_BindMobile: 'Lié mobile',
    home_Pleasecheckthe: 'Vérifiez vos USGT dans votre portefeuille de jeu.',
    home_BINDMOBILENUMBER: 'lier le numéro',
    home_Get1USDT: 'Obtenez 1 USDT',
    home_Bind: 'Lier',
    home_GiftCode: 'Code cadeau',
    home_EnteryourGiftCode: 'Entrez votre code cadeau',
    home_EnterCode: 'Entrer le code',
    home_Paste: 'Coller',
    home_confirms: 'Confirmer',
    home_BuyCrypto: 'Acheter',
    home_SignedIn: 'Connecté',
    home_Wallet: 'Wallet',
    home_turnTimes: 'Tours : ',
    home_bonus: ' bonus',
    home_FirstDepositBonus: 'Bonus de premier dépôt',
    home_Hi: 'Bonjour ',
    home_welcomeAboard: ' bienvenue à bord',
    

    
    home_CommissionRules: 'Règles de commission',
    home_AffiliateTerms: "Conditions d'affiliation",
    home_AffiliateDashboard: "Tableau de bord d'affiliation",
    home_AFFILIATE: 'Affilié',
    home_COMMISSONREADYEVERYDAY: 'Commission dès recommandation, 1 000 $ à tout moment.',
    home_MYREFERRALCODE: 'Mon code de recommandation',
    home_MYREFERRALLink: 'Mon lien de recommandation',
    home_AffiliateReward: 'Récompense pour les membres',
    home_EXTRA: 'Supplémentaire',
    home_USDREWARDS: 'Récompenses en dollars',
    home_USDRewardsRules: 'Règles des récompenses en dollars',
    home_JUSAREFERRALAWAY: 'Juste une recommandation',
    home_NewlyAvailableRewards: 'Nouvelles récompenses disponibles',
    home_ReceivedMao: 'Reçu :',
    home_Locked: 'Verrouillé :',
    home_Yourcurrentfriends: 'Vos amis actuels restent actifs pour débloquer plus de récompenses pour vous',
    home_Withdraw: 'Retirer',
    home_MY: 'Mon',
    home_COMMISSIONREWARDS: 'Récompenses de commission',
    home_CommissionRewardRules: 'Règles de récompense de commission',
    home_NewlyAvailableCommissionRewards: 'Nouvelles récompenses de commission disponibles',
    home_CommissionYouhaveReceivedinTotal: 'Commission reçue au total:',
    home_REWARDSSENTTODATE: 'Récompenses envoyées à ce jour',
    home_USDrewardssenttodate: 'Récompenses en USD envoyées à ce jour',
    home_Commissionsenttodatet: 'Commission envoyée à ce jour',
    home_Areyouablogger: "Êtes-vous un blogueur avec de nombreux lecteurs et abonnés? Nous vous offrons un programme d'affiliation unique avec des récompenses de recommandation plus élevées. Nous créerons un programme séparé avec des conditions spéciales pour vous. Contactez notre gestionnaire pour discuter des termes.",
    home_onlythoseusers: "Remarque importante: seuls les utilisateurs demandés et approuvés par l'administrateur peuvent participer à ce programme.",
    home_notice: 'Remarque',
    home_bindPhone: 'Lier un téléphone',
    home_SignIn: 'Se connecter',
    home_SignUp: "S'inscrire",
    home_SignOut: 'Déconnexion',
    home_CookiesPolicy: 'Politique des cookies',
    home_isUsingCookies: 'Funibet.com utilise des cookies pour offrir un meilleur service à chaque utilisateur. En utilisant notre site, vous acceptez l’utilisation de cookies. Veuillez consulter la politique de confidentialité et les conditions de service.',
    home_SETTING: 'Réglage',
    home_LiveSupport: 'Support en direct',
    home_Accept: 'Accepter',
    home_FrequentlyAskedQuestions: 'Questions fréquemment posées',
    home_SiteUnnderConstruction: 'Site en construction',
    home_ComingSoon: 'Bientôt disponible',
    home_UserId: 'ID : ',
    home_UserName: 'Nom : ',
    home_Bonusincreased: 'Bonus augmenté de ',
    home_SPINBONUSTOTAL: 'SPIN BONUS TOTAL',
    home_WIN: 'Gains :',
    home_In: 'in',
    home_Outoftime: 'Nombre de tours épuisé pour aujourd\'hui',
    
    game_search: 'rechercher',
    game_SortBy: 'Trier par',
    game_Created: 'Créé',
    game_Lobby: 'Lobby',
    game_LiveCasino: 'Casino en direct',
    game_FeaturedSlots: 'Machines à sous en vedette',
    game_GameShows: 'Jeux télévisés',
    game_FuniBetOriginals: 'FuniBet Originaux',
    game_BigWins: 'Gros gains',
    game_LcukyWins: 'Gains chanceux',
    game_Challenges: 'Défis',
    game_Description: 'Description',
    game_Slots: 'Machines à sous',
    game_Rank: 'Rang',
    game_User: 'Utilisateur',
    game_Date: 'Date',
    game_BetAmount: 'Mise',
    game_lucky: 'Multiplicateur max.',
    game_Multiplier: 'Multiplicateur',
    game_Payout: 'Paiement',
    game_Recommend: 'Recommander',
    game_Providers: 'Fournisseurs',
    game_startGame: 'Start',
    game_balance: 'Solde',
    game_provider: 'Tag',
    game_Selectyourdisplay: 'Sélectionnez votre solde d\'affichage',
    game_Balancein: 'Solde en',
    game_FunPlay: 'Jeu amusant',
    game_RealPlay: 'Jeu réel',
    game_DispLaying: "Affichage",
    game_LoadMore: "Charger plus",
    game_NoData: 'Oops! Aucune donnée maintenant.',
    
    message_SigningIn:"Connexion avec JWT",
    message_CourseSaved:"Cours enregistré",
    message_PleaseBind:"Veuillez d'abord lier votre téléphone",
    message_UserData:"Données utilisateur enregistrées avec l'API",
    message_Success:"succès",
    message_VIPlevel:"Niveau VIP inférieur à V3",
    message_ListOrder:"Commande de liste enregistrée",
    message_CardOrder:"Commande de carte enregistrée",
    message_HiHow:"Salut, comment vas-tu?",

    Funibet_web: 'Funibet-Web3.0,Crypto-monnaie,Pari,Sports,E-sport,Machines à sous, Casino en direct,Poker',

    home_ThereAre: "Il n'y a pas de notifications pour le moment.",

    play_no_balance: "Le solde de la devise actuelle est insuffisant, veuillez changer de devise ou recharger",
    
    home_News_1: 'Actualités',
    home_News_2: 'Événement',
    home_News_3: 'Crypto',
    home_News_4: 'Jeux',
    
    home_SuggRep_1: 'Sugg.&Rep.',
    home_SuggRep_2: 'Suggestions et rapports',
    home_SuggRep_3: 'Partagez votre feedback pour améliorer !',
    home_SuggRep_4: 'Signaler un site web vendant des objets de jeu.',
    home_SuggRep_5: 'Saisissez des suggestions ou soumettez un rapport',
    home_SuggRep_6: 'Capture d\'écran exemple',
    home_SuggRep_7: 'récompense pour les suggestions et rapports adoptés !',
    home_SuggRep_8: 'Soumettre',
    
    home_subscription_1: 'Voulez-vous vous abonner aux notifications push ?',
    home_subscription_2: 'Vous pouvez toujours vous désabonner à tout moment.',
    home_subscription_3:'Web push by WonderPush',
    home_subscription_4: 'Plus tard',
    home_subscription_5: 'S\'abonner',
    
    home_Affiliate_1: 'Nous contacter',
    home_Affiliate_2: 'Chaque ami que vous invitez vous rapportera 10U, plus vous invitez, plus vous gagnez !',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: 'Email',

    home_banner_1: "Jeu Crypto",
    home_banner_2: "Prise en charge de plus de 100 crypto et 50 NFT",
    home_banner_3:'Decentralized Betting',
    home_banner_4:'Smart contract betting from decentralized wallet',
    home_banner_5: "Mise pour gagner",
    home_banner_6: "Misez, exploitez et investissez pour des bénéfices stables",
    home_banner_7: "Web3.0 + Casino",
    home_banner_8: "Le joueur est également banquier et propriétaire",

    home_EventDetails: "Détails",

    home_vip_1: "1.Gagnez des revenus en vous connectant+",
    home_vip_2: "2.Gagnez des revenus en tournant+",
    home_vip_3: "3.Gagnez des revenus en partageant+",
    home_vip_4: "4.Gagnez des revenus en rechargeant+",
    home_vip_5: "5.Chat ouvert, envoi de messages quotidien possible",
    home_vip_6: "Messages",

    ERROR_628: 'Code cadeau déjà utilisé',
    ERROR_629: 'Code cadeau entièrement utilisé',
    ERROR_630: 'Code cadeau non actif',
    ERROR_631: 'Code cadeau expiré',
    ERROR_633: 'Impossible d\'utiliser un code cadeau d\'un autre agent',

    home_Maintaining_1: "Maintenance",
    home_Maintaining_2: "Cette fonctionnalité est en cours de maintenance",
    home_Maintaining_3: "END",
    home_Maintaining_4: "START",

    home_Slots: 'Machines à sous',
    home_LiveCasino: 'Casino en direct',
    home_CountryFiat: 'Pays et Fiat',
    home_CryptNFTs: 'Crypto et NFT',
        
    home_GoTo:'Aller à',

    home_gameout_1: "Êtes-vous actuellement dans un autre jeu? Souhaitez-vous quitter?",
    home_gameout_2: "Annuler",

     home_BuyFund1:'Souhaitez-vous acheter un fonds de croissance?',

     home_BetFor:'Parier sur',

     tongzhi_1: "Suggestion",
     tongzhi_2: "Répondre à la suggestion et au représentant.",
     tongzhi_3: "Bien joué ! C'est fantastique.",
     tongzhi_4: "En attente de révision.",
     tongzhi_5: "Révision approuvée.",
     tongzhi_6: "Traitement du paiement en cours.",
     tongzhi_7: "Paiement terminé avec succès.",
     tongzhi_8: "Révision non approuvée.",
     tongzhi_9: "Échec du paiement.",
     tongzhi_10: "Échec du retrait.",
     tongzhi_11: "Payé.",
     tongzhi_12: "Terminé.",
     tongzhi_13: "Échec du traitement.",
     tongzhi_14: "Acheter",
     tongzhi_15: "À",
     tongzhi_16: "Retirer",
     tongzhi_17: "Cryptomonnaie",
     tongzhi_18: "Monnaie Fiat",
     tongzhi_19: "Téléchargez ",
     tongzhi_20: "La connexion peut être récompensée",
     tongzhi_21: "Télécharger",
     tongzhi_22: "Aucune version iOS disponible pour le moment",
     tongzhi_23: "Envoyer",
     tongzhi_24: "Envoyer des Conseils",

     home_banner_9: "Courses de chevaux",
     home_banner_10: "Combat de coqs",
     home_banner_11: "Excitant et palpitant, gagnez de gros prix",
     home_banner_12: "Énergique et plein d'esprit combatif",
     
     home_wanfa_1:"Problème de gameplay",
     home_wanfa_2:"Problème de transfert",
     home_wanfa_3:"Autre problème",
     play_not_balance: "Les paris sportifs avec eUSDT ne sont pas possibles. Souhaitez-vous changer de devise ?",







    
    
    
    };
    
    export default locale;
    
    
