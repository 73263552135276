  const locale = {
  GAME: 'Juegos',
  Casino: "Casino",
  ProvablyFair: "Justo Probable",
  Option: 'Opción',
  Crypto: 'Cripto',
  Forex: 'Forex',
  Stocks: 'Acciones',
  NFTFuniCard: 'NFT FuniCard',
  StakeNFT: 'Apostar  NFT para Ganar USDT',
  NFTStake: 'Apostar',
  Marketplace: 'intercambio',
  NFTMint: 'NFT acuñación',
  BettoEarn: 'Parier Apuesta para Ganar',
  EarnUSGT: 'Ganar USDT',
  DeFi: 'DeFi',
  ToBeVolunteer: 'Ser Voluntario',
  SYSTEM: 'SISTEMA',
  Affiliate: 'Afiliado',
  News: 'Noticias',
  FAQs: 'Preguntas Frecuentes',
  DOWNLOAD: 'DESCARGAR',
  DOWNLOADApp: 'Descargar App',
  Savetodesktop: 'Guardar en Escritorio',
  BetMining: 'Minería de Apuestas',
  ToBeBanker: 'Ser Banquero',
  ProfitPool: 'Pozo de Beneficios',
  USGTLiquidityMining: 'USDT Liquidity Mining',
  BGTLiquidityMining: 'BGT Liquidity Mining',
  Slots: 'Tragamonedas',
  Favorite: 'Favorito',
  Recent: 'Reciente',
  Popular: "Popular",
  LiveCasino: "Casino en Vivo",
  TableGames: "Juegos de Mesa",
  BlackJack: "BlackJack",
  Baccarat: "Baccarat",
  Roulette: "Ruleta",
  MoreGames: "Más Juegos",
  Provablyfair: "Justo Probable",
  Special: "Especial",
  Recommend: "Recomendado",
  FeatureBuyin: "Justo Probable",
  EnableTrialPlay: "Juego de prueba",
  GameShow: "Game Show",
  RGSVSB: "RGS - VSB",
  Scratch: "Rascar",
  Bingo: "Bingo",
  Fishing: "Pesca",
  Turntable: "Ruleta",
  Dice: "Dados",
  Poker: "Póquer",
  AllGames: "Todos los Juegos",
  EuropeanView: "Vista europea",
  AfricanView: "Vista africana",
  LatinView: "Vista latina",
  AsianView: "Vista asiática",
  LsportView: "Liga deportiva",
  StandardView: "Vista estándar",
  EsportView: "Deportes electrónicos",
  LiveBetting: 'Apuestas en vivo',
  Provider: 'Proveedor',
  Sport: "Deporte",



};

export default locale;
