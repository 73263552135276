import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    score: {}
};

const userGameScoreSlice = createSlice({
    name: 'userGameScore',
    initialState,
    reducers: {
        updateUserSettingScore: (state, action) => {
            let res = action.payload;
            if (JSON.stringify(res) !== '{}' && JSON.stringify(res) !== JSON.stringify(state.score)) {
                state.score = res;
            }
        },

    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateUserSettingScore } = userGameScoreSlice.actions;

export const selectUserGameScore = ({ userGameScore }) => userGameScore;

export default userGameScoreSlice.reducer;
