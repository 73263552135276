import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    allAvatars: { //所有头像
        0: { url: 'assets/images/head/0.png', id: 0 },
        1: { url: 'assets/images/head/1.png', id: 1 },
        2: { url: 'assets/images/head/2.png', id: 2 },
        3: { url: 'assets/images/head/3.png', id: 3 },
        4: { url: 'assets/images/head/4.png', id: 4 },
        5: { url: 'assets/images/head/5.png', id: 5 },
        6: { url: 'assets/images/head/6.png', id: 6 },
        7: { url: 'assets/images/head/7.png', id: 7 },
        8: { url: 'assets/images/head/8.png', id: 8 },
        9: { url: 'assets/images/head/9.png', id: 9 },
        10: { url: 'assets/images/head/10.png', id: 10 },
        11: { url: 'assets/images/head/11.png', id: 11 },
        12: { url: 'assets/images/head/12.png', id: 12 },
        13: { url: 'assets/images/head/13.png', id: 13 },
        14: { url: 'assets/images/head/14.png', id: 14 },
        15: { url: 'assets/images/head/15.png', id: 15 },
        16: { url: 'assets/images/head/16.png', id: 16 },
        17: { url: 'assets/images/head/17.png', id: 17 },
        18: { url: 'assets/images/head/18.png', id: 18 },
        19: { url: 'assets/images/head/19.png', id: 19 },
        20: { url: 'assets/images/head/20.png', id: 20 },
        21: { url: 'assets/images/head/21.png', id: 21 },
        22: { url: 'assets/images/head/22.png', id: 22 },
        23: { url: 'assets/images/head/23.png', id: 23 },
        24: { url: 'assets/images/head/24.png', id: 24 },
        25: { url: 'assets/images/head/25.png', id: 25 },
        26: { url: 'assets/images/head/26.png', id: 26 },
        27: { url: 'assets/images/head/27.png', id: 27 },
        28: { url: 'assets/images/head/28.png', id: 28 },
        29: { url: 'assets/images/head/29.png', id: 29 },
        30: { url: 'assets/images/head/30.png', id: 30 },
        31: { url: 'assets/images/head/31.png', id: 31 },
        32: { url: 'assets/images/head/32.png', id: 32 },
        33: { url: 'assets/images/head/33.png', id: 33 },
        34: { url: 'assets/images/head/34.png', id: 34 },
        35: { url: 'assets/images/head/35.png', id: 35 },
        36: { url: 'assets/images/head/36.png', id: 36 },
        37: { url: 'assets/images/head/37.png', id: 37 },
        38: { url: 'assets/images/head/38.png', id: 38 },
        39: { url: 'assets/images/head/39.png', id: 39 },
        40: { url: 'assets/images/head/40.png', id: 40 },
        41: { url: 'assets/images/head/41.png', id: 41 },
        42: { url: 'assets/images/head/42.png', id: 42 },
        43: { url: 'assets/images/head/43.png', id: 43 },
        44: { url: 'assets/images/head/44.png', id: 44 },
        45: { url: 'assets/images/head/45.png', id: 45 },
        46: { url: 'assets/images/head/46.png', id: 46 },
        47: { url: 'assets/images/head/47.png', id: 47 },
    }
};

const userAvatarsSlice = createSlice({
    name: 'userAvatars',
    initialState,
    reducers: {},
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const {  } = userAvatarsSlice.actions;

export const selectUserAvatars = ({ userAvatars }) => userAvatars;

export default userAvatarsSlice.reducer;
