import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    allFiats: {},   // 所有法币
};

const fiatsSlice = createSlice({
    name: 'fiats',
    initialState,
    reducers: {

        updateAllFiats: (state, action) => {
            let res = action.payload;
            let tmpData = {};
            res.map((item) => {
                tmpData[item.currencyCode] = item
            });
            state.allFiats = tmpData;
        },
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateAllFiats } = fiatsSlice.actions;

export const selectFiats = ({ fiats }) => fiats;

export default fiatsSlice.reducer;
