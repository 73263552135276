import OfficialAPP from './OfficialAPP';


const OfficialAPPConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'officialAPP',
            element: <OfficialAPP />,
        },
    ],
};

export default OfficialAPPConfig;