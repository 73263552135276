import "../../../styles/index.css";
import "../../../styles/game.css";

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { useTranslation } from "react-i18next";
import {getTagCasinoGameRecord, sportBetRecord} from "../../store/casino/casinoThunk";
import clsx from "clsx";
import {arrayLookup, isMobile, judgeLangFontSize} from "../../util/tools/function";
import { selectSymbols } from "app/store/config/symbols";
import { selectFiats } from "app/store/config/fiats";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#0E1421",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,

    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#161C2A",
    },
}));

const Common_gameReport = (props) => {
    const gameChannel = props.gameChannel || '';
    const gameTag = props.gameTag || '';
    const actionType = props.actionType || '';
    const startTime = props.startTime || '';
    const endTime = props.endTime || '';

    const dispatch = useDispatch();
    const { t } = useTranslation('mainPage');
    const [selectedTab, setSelectedTab] = useState(0);

    const symbols = useSelector(selectSymbols).allSymbols;
    const fiats = useSelector(selectFiats).allFiats;
    const [rows, setRows] = useState([]);

    function handleTabChange(event, value) {
        setSelectedTab(value);
    }

    function formatTime(value) {
        if (value) {
            // let date = new Date(value * 1000)	// 时间戳为秒：10位数
            let date = new Date(value);	// 时间戳为毫秒：13位数
            let year = date.getFullYear();
            let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
            let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            let hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
            let minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
            let second = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`
        } else {
            return ''
        }
    }

    useEffect(() => {
        if (JSON.stringify(symbols) !== '{}' && JSON.stringify(fiats) !== {}) {
            if (selectedTab === 0) {
                setTimeout(() => {
                    dispatch(sportBetRecord()).then((res) => {
                        let result = res.payload;
                        let tmpRecords = [];
                        if (result.records) {
                            result.records.map((item) => {
                                var multiplier = 0;
                                if (item.winAmount !== 0) {
                                    multiplier = (((item.winAmount + item.betAmount) / item.betAmount)).toFixed(2) + 'x'
                                } else {
                                    multiplier = '0.00x'
                                    // if (item.winAmount === 0) {
                                    //     multiplier = '0.00x'
                                    // } else {
                                    //     multiplier = (1 + item.winAmount / item.betAmount).toFixed(2) + 'x'
                                    // }
                                }
                                tmpRecords.push({
                                    recordID: item.recordID,
                                    name: item.sportChannel,
                                    role: item.nickName,
                                    time: formatTime(item.collectDate),
                                    bet: (item.betAmount / 100).toFixed(2),
                                    betCurrencyImg: symbols[item.currency]?.avatar ?? fiats[item.currency]?.avatar,
                                    multiplier: multiplier,
                                    payout: (item.winAmount / 100).toFixed(2),
                                })
                            });
                        }
                        setRows(tmpRecords);
                    })
                }, 1000)
            } else if (selectedTab === 1) {
                setTimeout(() => {
                    dispatch(getTagCasinoGameRecord({
                        gameChannel,
                        gameTag,
                        actionType,
                        startTime,
                        endTime,
                    })).then((res) => {
                        let result = res.payload;
                        let tmpRecords = [];
                        if (result.records) {
                            result.records.map((item) => {
                                var multiplier = 0;
                                if (item.winScore !== 0) {
                                    multiplier = (((item.winScore + item.betScore) / item.betScore)).toFixed(2) + 'x'
                                } else {
                                    multiplier = '0.00x'
                                    // if (item.winScore === 0) {
                                    //     multiplier = '0.00x'
                                    // } else {
                                    //     multiplier = (1 + (item.winScore - item.betScore) / item.betScore).toFixed(2) + 'x'
                                    // }
                                }
                                tmpRecords.push({
                                    recordID: item.recordID,
                                    name: item.gameName,
                                    gameIcon: item.gameIcon,
                                    role: item.nickName,
                                    time: formatTime(item.insertTime),
                                    bet: (item.betScore / 100).toFixed(2),
                                    betCurrencyImg: symbols[item.currency]?.avatar ?? fiats[item.currency]?.avatar,
                                    multiplier: multiplier,
                                    payout: (item.winScore / 100).toFixed(2),
                                })
                            });
                        }
                        setRows(tmpRecords);
                    })
                }, 1000)
            }
        }
    }, [selectedTab, symbols, fiats]);

    return (
        <div className="zuQiu mt-20 gameMt2 funbetTxt  backGround-index ">
            <div className="activitybanner marginJuZhong">
                <div className="mt-20" style={{ width: '200px' }}>
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="inherit"
                        variant="scrollable"
                        scrollButtons={false}
                        className="min-h-40  backGround-foot borderRadius "
                        classes={{ indicator: 'flex justify-center bg-transparent w-full h-full ' }}
                        TabIndicatorProps={{
                            children: (
                                <Box
                                    sx={{ bgcolor: 'text.disabled' }}
                                    className="w-full h-full rounded-full gamesListBtnBackground-Color opacity-100 "
                                />
                            ),
                        }}
                    >
                        <Tab
                            className={clsx("text-14 font-semibold min-h-40 width-100 Zindex  flex funbetTxt", judgeLangFontSize(['tmr']) && "font-12")}
                            disableRipple
                            label={t("home_Sports")}
                        />
                        <Tab
                            className="text-14 font-semibold min-h-40 width-100 Zindex funbetTxt"
                            disableRipple
                            label={t("home_Casino")}
                        />
                        {/*<Tab*/}
                        {/*  className="text-14 font-semibold min-h-40 width-100 Zindex  flex"*/}
                        {/*  disableRipple*/}
                        {/*  label="Races"*/}
                        {/*/>*/}
                    </Tabs>
                </div>

                <Paper sx={{ width: '100%', marginTop: '16px' }} className="displayPhoneSwitch1">
                    <TableContainer >
                        <Table stickyHeader aria-label="customized table" className="backGroundColorNode">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className="funbetTxt" sx={{ border: 'none', color: "#B8BABF" }}>{t("home_Game")}</StyledTableCell>
                                    <StyledTableCell align="right" className="funbetTxt" sx={{ border: 'none', color: "#B8BABF" }}>{t("home_User")}</StyledTableCell>
                                    <StyledTableCell align="right" className="funbetTxt" sx={{ border: 'none', color: "#B8BABF" }}>{t("home_Time")}</StyledTableCell>
                                    <StyledTableCell align="right" className="funbetTxt" sx={{ border: 'none', color: "#B8BABF" }}>{t("home_BetAmount")}</StyledTableCell>
                                    <StyledTableCell align="right" className="funbetTxt" sx={{ border: 'none', color: "#B8BABF" }}>{t("home_Multiplier")}</StyledTableCell>
                                    <StyledTableCell align="right" className="funbetTxt" sx={{ border: 'none', color: "#B8BABF" }}>{t("home_Payout")}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.length > 0 && rows.map((row) => (
                                    <StyledTableRow key={row.recordID} >
                                        <StyledTableCell component="th" scope="row" className="funbetTxt" sx={{ borderBottom: "0px", display: "flex" }}> {row.gameIcon && <img style={{ width: '20px', height: '20px', borderRadius: '50%', marginRight: '5px' }} src={row.gameIcon}></img>} {row.name} </StyledTableCell>
                                        <StyledTableCell align="right" className="funbetTxt " sx={{ borderBottom: "0px" }}>{row.role}</StyledTableCell>
                                        <StyledTableCell align="right" className="funbetTxt" sx={{ borderBottom: "0px" }}>{row.time}</StyledTableCell>
                                        <StyledTableCell align="right" className="funbetTxt" scope="row" sx={{ borderBottom: "0px", display: "flex" }}>  {row.betCurrencyImg && <img style={{ width: '20px', height: '20px', borderRadius: '50%', marginLeft: '5px' }} src={row.betCurrencyImg}></img>} {row.bet}</StyledTableCell>
                                        <StyledTableCell align="right" className="funbetTxt" sx={{ borderBottom: "0px" }}>{row.multiplier}</StyledTableCell>
                                        <StyledTableCell align="right" className="funbetTxt" scope="row" sx={{ borderBottom: "0px", display: "flex" }}>{row.betCurrencyImg && <img style={{ width: '20px', height: '20px', borderRadius: '50%', marginLeft: '5px' }} src={row.betCurrencyImg}></img>} {row.payout}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Paper sx={{ width: '100%', marginTop: '16px' }} className="displayPhoneSwitch">
                    <TableContainer >
                        <Table stickyHeader aria-label="customized table" className="backGroundColorNode ">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className="funbetTxt" sx={{ border: 'none', color: "#B8BABF" }}>{t("home_Game")}</StyledTableCell>
                                    <StyledTableCell className="funbetTxt" align="right" sx={{ border: 'none', color: "#B8BABF" }}>{t("home_Payout")}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="tabCell">
                                {rows.map((row) => (
                                    <StyledTableRow key={row.recordID}>
                                        <StyledTableCell className="funbetTxt overflowHidden" component="th" scope="row" sx={{ borderBottom: "0px", maxWidth: "210px", whiteSpace: "nowrap" }}> <div className="flex">{row.gameIcon && <img style={{ width: '20px', height: '20px', borderRadius: '50%', marginRight: '5px' }} src={row.gameIcon}></img>} {row.name} </div> </StyledTableCell>
                                        <StyledTableCell className="funbetTxt " align="right" scope="row" sx={{ borderBottom: "0px", display: "flex" }}> {row.betCurrencyImg && <img style={{ width: '20px', height: '20px', borderRadius: '50%', marginLeft: '5px' }} src={row.betCurrencyImg}></img>} {row.bet}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

            </div>
        </div>

    );
};

export default Common_gameReport;
