import { createSlice } from '@reduxjs/toolkit';
import history from '@history';
import { setSpreaderInfo } from "app/store/user/userThunk";

// state
const initialState = {
    maintainConfig: {
        isShow: false,
        startTime: '',
        endTime: '',
    }
};

const maintainConfigSlice = createSlice({
    name: 'maintainConfig',
    initialState,
    reducers: {
        showMaintainPage: (state, action) => {
            let res = action.payload;
            state.maintainConfig = res;
        },

        closeMaintainPage: (state, action) => {
            state.maintainConfig = {
                isShow: false,
                startTime: '',
                endTime: '',
            };
        },
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { showMaintainPage, closeMaintainPage } = maintainConfigSlice.actions;

export const selectMaintainConfig = ({ maintainConfig }) => maintainConfig;

export default maintainConfigSlice.reducer;
