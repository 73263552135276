import { createAsyncThunk } from '@reduxjs/toolkit';
import React from "react";
import { updateChatMsg } from "./index";


export const saveChatMsg = createAsyncThunk(
    'chat/saveChatMsg',
    (settings, { dispatch, getState }) => {
        dispatch(updateChatMsg(settings?.msg));

        // 更新今日已发送次数 调接口
        if (settings.vIPNumLimit) {
            React.$api("gamecenter.setUserSetting", {
                vIPNumLimit: settings.vIPNumLimit ?? 0
            })
        }
    }
);
