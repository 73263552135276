import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    tags: [],       // 所有标签（用户可以看到展示的标签）
    tagList: [],    // 所有的标签(管理员可以编辑的所有标签)
};

const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        updateTags: (state, action) => {
            state.tags = action.payload;
        },
        updateTagList: (state, action) => {
            let res = action.payload;

            let tmpTagList = {};
            for (var item of res.tagList) {
                if (item.tagGroup !== 1) {
                    tmpTagList[item.tagID] = item;
                }
            }

            state.tagList = tmpTagList;
        }
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateTags, updateTagList } = tagsSlice.actions;

export const selectTags = ({ tags }) => tags;

export default tagsSlice.reducer;
