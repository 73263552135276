import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    userFiats: {},  // 用户法币余额
    userSymbols: {},// 用户虚拟币余额
};

const userAssetsSlice = createSlice({
    name: 'userAssets',
    initialState,
    reducers: {
        updateUserFiats: (state, action) => {
            let res = action.payload;
            let tmpData = {};
            res.map((item) => {
                tmpData[item.currencyCode] = item
            });
            state.userFiats = tmpData;
        },

        updateUserSymbols: (state, action) => {
            let res = action.payload;
            let tmpData = {};
            res.inner.map((item) => {
                tmpData[item.symbol] = item
            });
            state.userSymbols = tmpData;
        },

    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateUserFiats, updateUserSymbols } = userAssetsSlice.actions;

export const selectUserAssets = ({ userAssets }) => userAssets;

export default userAssetsSlice.reducer;
