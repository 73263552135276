import { alpha, styled } from '@mui/material/styles';
import ListItemText from "@mui/material/ListItemText/ListItemText";
import ListItem from "@mui/material/ListItem";
import clsx from "clsx";
import { isMobile, judgeLangFontSize } from "../../util/tools/function";
import "../../../styles/common.css";
import { border } from '@mui/system';
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useState, useEffect, forwardRef, default as React } from 'react';
import Slide from '@mui/material/Slide';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import {
    getSignInConfig,
    querySignInInfo,
    signInDone,
    queryDailyShareInfo,
    getChargeGiftStatus,
    queryBaseEnsureConfig,
    getGrowthFundConfig,
} from "../../store/activity/activityThunk";
import { selectUserData } from "../../store/user";
import { useDispatch, useSelector } from "react-redux";
import history from '@history';
import AnimateModal from '../../components/FuniModal';
import { showMessage } from 'app/store/fuse/messageSlice';
import Spin from '../../main/spin/Spin';
import Relief from '../../main/relief/Relief';
import Share from '../../main/share/Share';
import Deposit from '../../main/deposit/Deposit';

import { countUserAllAssets, onLogin } from "../../store/user/userThunk";
import AwardPop from "../../components/AwardPop";
import vip from "../../main/vip/Vip";
import {selectUserSetting} from "app/store/user/userSetting";
import {selectUserProperties} from "app/store/user/userProperties";
import {selectUserInfo} from "app/store/user/userInfo";
const Root = styled('div')(({ theme }) => ({
    '& .username, & .email': {
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },

    '& .avatar': {
        background: theme.palette.background.default,
        transition: theme.transitions.create('all', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
        bottom: 0,
        '& > img': {
            borderRadius: '50%',
        },
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const container = {
    show: {
        transition: {
            staggerChildren: 0.1,
        },
    },
};
const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
};


const Root2 = styled(ListItem)(({ theme, itempadding, ...props }) => ({
    width: '100%',
    borderRadius: '6px',
    margin: '0 0 0 0',
    paddingRight: 16,
    paddingLeft: props.itempadding > 80 ? 80 : props.itempadding,
    paddingTop: 10,
    paddingBottom: 10,
    color: alpha(theme.palette.text.primary, 0.7),
    fontWeight: 600,
    letterSpacing: '0.025em',
}));

function UserNavbarHeader(props) {
    const { t } = useTranslation('mainPage');
    const dispatch = useDispatch();
    const userData = useSelector(selectUserData);
    const userSetting = useSelector(selectUserSetting);
    const userProperties = useSelector(selectUserProperties);
    const userInfo = useSelector(selectUserInfo).userInfo;

    const [openChangeStatus, setOpenChangeStatus] = useState({
        checkIn: false,
        spin: false,
        relief: false,
        share: false,
        deposit: false,
        fund: false,
        vip: false,
        phone: false,
        spinReward: false,
    });

    const [signInConfig, setSignConfig] = useState([]);
    const [popSignAward, setPopSignAward] = useState(false);
    const [userSignData, setUserSignData] = useState({});
    const signDate = {
        1: { name: t("home_Sunday"), url: 'https://scource-static.funibet.com/funibet/images/index/jinBi1.png' },
        2: { name: t("home_Monday"), url: 'https://scource-static.funibet.com/funibet/images/index/jinBi1.png' },
        3: { name: t("home_Tuesday"), url: 'https://scource-static.funibet.com/funibet/images/index/jinBi2.png' },
        4: { name: t("home_Wednesday"), url: 'https://scource-static.funibet.com/funibet/images/index/jinBi2.png' },
        5: { name: t("home_Thursday"), url: 'https://scource-static.funibet.com/funibet/images/index/jinBi3.png' },
        6: { name: t("home_Friday"), url: 'https://scource-static.funibet.com/funibet/images/index/jinBi3.png' },
        7: { name: t("home_Saturday"), url: 'https://scource-static.funibet.com/funibet/images/index/jinBi4.png' },
    };

    const [userTurnTimes, setUserTurnTime] = useState(0);
    const [signInRewardBalance, setSignInRewardBalance] = useState({
        balance: 0,
        symbol: 'eUSDT'
    });

    const [shareData, setShareData] = useState({});
    const [canTakeTimes, setCanTakeTimes] = useState(0);
    const [chargeGiftStatus, setChargeGiftStatus] = useState({});

    const [userAssets, setUserAssets] = useState(0);
    const [baseEnsureConfig, setBaseEnsureConfig] = useState({});
    const [receiveStatus, setReceiveStatus] = useState(0);

    const [ fundConfig, setFundConfig ] = useState({});
    const [ isShowFundRed, setIsShowFundRed ] = useState(false);
    const [ userVipLevel, setUserVipLevel ] = useState(0);

    // 签到数据
    const handleGetSignInConfig = () => {
        // 签到config
        dispatch(getSignInConfig()).then((res) => {
            let result = res.payload;
            setSignConfig(result);
        });

        // 签到情况
        dispatch(querySignInInfo()).then((res) => {
            let result = res.payload;
            setUserSignData(result);
        });
    };

    // 签到
    const handleCheckIn = () => {
        if (!userSignData?.SignedToday) {
            dispatch(signInDone()).then((res) => {
                let result = res.payload;
                if (result) {
                    console.log(result, 'result');
                    setUserSignData({ ...userSignData, SignTimes: result.SignTimes, SignedToday: true });
                    setSignInRewardBalance({
                        balance: result?.RewardCashCoupon / 100,
                        symbol: 'eUSDT'
                    });
                    setTimeout(() => {
                        setPopSignAward(true);
                    }, 300);
                }
            });
        } else {
            dispatch(showMessage({ message: t('home_SignedIn'), code: 1 }))
        }
    };

    const onChangeStatus = (key, value) => {
        // //打包记得打开
        if (value) {
            if (!userData.token) {
                dispatch(onLogin({ type: 'login' }));
                return
            }
        }

        // 判断是否绑定过手机
        if (userInfo?.nation === '0') {
            dispatch(showMessage({ message: t('message_PleaseBind'), code: 3 }));
            return
        }

        let tmpChangeStatus = { ...openChangeStatus };
        Object.keys(tmpChangeStatus).forEach((index) => {
            if (index === key) {
                tmpChangeStatus[index] = value
            } else {
                tmpChangeStatus[index] = false
            }
        });
        setOpenChangeStatus(tmpChangeStatus);

        if (!value) {
            return
        }

        switch (key) {
            case 'checkIn':
                if (signInConfig.length === 0) {
                    handleGetSignInConfig();
                }
                break;
        }
    };

    // 获取分享情况
    const handleQueryDailyShareInfo = () => {
        dispatch(queryDailyShareInfo()).then((res) => {
            let result = res.payload;
            setShareData(result);
        })
    };

    // 获取充值礼包领取状态
    const handleGetChargeGiftStatus = () => {
        dispatch(getChargeGiftStatus()).then((res) => {
            let result = res.payload;
            if (result) {
                setChargeGiftStatus(result);
            }
        })
    };

    //当天开始时间
    const curStartTime = () => {
        let time = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        return Math.ceil(time / 1000);
    };

    // 获取用户资产
    // const getUserAssets = () => {
    //     dispatch(countUserAllAssets()).then((userRes) => {
    //         let asstes = userRes.payload || 0;
    //         setUserAssets(asstes);
    //     });
    // };

    // 获取救济金配置
    const handleQueryBaseEnsureConfig = () => {
        dispatch(queryBaseEnsureConfig()).then((res) => {
            let result = res.payload;
            if (result) {
                setBaseEnsureConfig(result.data);
                let walletAmount = result.wallet.Crypto + result.wallet.Fiat;
                let gameAmount = result.score.cashCoupon + result.score.insureScore + result.score.score;
                // 需要计算汇率额金额(如果是法币需要计算,虚拟币不用计算)
                let currencyScore = result.score.currencyScore;

                let userAmount = walletAmount + gameAmount / 100; // 缺少计算(currencyScore)
                setUserAssets(userAmount);
            }
        });
    };

    // 获取救济金领取状态
    const dealWithStatus = () => {
        // 开始时间（0点 + startTime） + 领取次数 （配置文件takeTimes - getSetting.properties.CanTakeTimes） * 间隔时间（秒） = 下一次领取的时间
        let startTime = 0 + baseEnsureConfig.StartTime + (baseEnsureConfig.TakeTimes - canTakeTimes) * baseEnsureConfig.IntervalTime;
        startTime = curStartTime() + startTime;
        let curTime = Math.ceil(Date.now() / 1000);

        if (userAssets >= (baseEnsureConfig.ScoreCondition / 100)) { // 余额大于领取条件,不可领取
            setReceiveStatus(1);
        } else if (canTakeTimes <= 0) { //今日已全部领取,不可领取
            setReceiveStatus(2);
        } else if (curTime < startTime) {
            setReceiveStatus(3);
        } else {
            setReceiveStatus(0);
        }

        setTimeout(() => {
            console.log(receiveStatus, 'ReceiveStatus......')
        }, 3000)

    };

    // 成长基金配置
    const getFundConfig = () => {
        dispatch(getGrowthFundConfig()).then((res) => {
            let result = res.payload;
            setFundConfig(result);
        })
    }

    // 判断是否购买过成长基金
    const isCanGetReward = (vipLevel) => {
        // 当前等级 <= 购买等级 不可领取
        if (vipLevel <= userProperties?.properties?.BuyVIPGrowthFundLevel) {
            return false
        }

        // 用户等级 < 当前等级 不可领取
        if (userProperties?.properties?.MemberOrder < vipLevel) {
            return false
        }

        // 判断当前等级奖励是否已领取
        if ((userProperties?.properties?.VIPGrowthFundStatus & vipLevel) != 0) {
            return false
        }

        return true
    }

    // 成长基金红点状态
    const getFundRedStatus = () => {
        if (userProperties.properties) {

            // 没购买过
            if (!userProperties?.properties?.HasBuyVIPGrowthFund) {
                return true
            }

            let isGetReward = false;
            fundConfig.config.map((item) => {
                if (isCanGetReward(item.vipLevel)) {
                    isGetReward = true
                }
            })

            if (isGetReward) {
                return true
            }
        }
        return false
    }

    useEffect(() => {
        if (JSON.stringify(baseEnsureConfig) !== '{}' && JSON.stringify(userProperties.properties) !== '{}') {
            dealWithStatus();
        }
    }, [canTakeTimes, baseEnsureConfig, userAssets]);

    useEffect(() => {
        if (JSON.stringify(fundConfig) !== '{}') {
            setIsShowFundRed(getFundRedStatus());
        }
    }, [userVipLevel, fundConfig, userProperties?.properties?.VIPGrowthFundStatus])

    useEffect(() => {
        if (userSetting.user) {
            // 获取用户资产
            // getUserAssets();

            // 签到情况
            dispatch(querySignInInfo()).then((res) => {
                let result = res.payload;
                setUserSignData(result);
            });

            // 分享情况
            handleQueryDailyShareInfo();

            // 首充情况
            handleGetChargeGiftStatus();

            // 救济金情况
            handleQueryBaseEnsureConfig();
        }
    }, [userSetting]);

    useEffect(() => {
        // 转盘次数
        if (userProperties?.properties?.TurntableRemain) {
            setUserTurnTime(userProperties.properties.TurntableRemain);
        }

        // 救济金领取次数
        if (userProperties?.properties?.CanTakeTimes) {
            setCanTakeTimes(userProperties?.properties?.CanTakeTimes);
        }

        // vipLevel
        if (userProperties?.properties?.MemberOrder) {
            setUserVipLevel(userProperties?.properties?.MemberOrder);
        }
    }, [userProperties]);

    useEffect(() => {
        getFundConfig();
    }, []);

    return (
        <Root className="user relative flex flex-col items-center justify-center p-16 pb-14 shadow-0">
            <div className="flex items-center justify-center" style={{ width: '100%' }}>
                <Root2
                    // eslint-disable-next-line react/jsx-curly-brace-presence
                    component={'li'}
                    itempadding={80}
                    className={clsx(
                        'fuse-list-subheader flex items-center  py-10'
                    )}
                    role="button"
                    sx={{
                        width: '100%'
                    }}
                >
                    <ListItemText
                        className="fuse-list-subheader-text"
                        sx={{
                            width: '100%',
                            textAlign: 'left',
                            margin: 0,
                            '& > .MuiListItemText-primary': {
                                fontSize: 13,
                                color: 'secondary.light',
                                fontWeight: 600,
                                textTransform: 'uppercase',
                                letterSpacing: '.05em',
                                lineHeight: '20px',
                            },

                            '& > .MuiListItemText-secondary': {
                                fontSize: 11,
                                color: 'text.disabled',
                                letterSpacing: '.06px',
                                fontWeight: 500,
                                lineHeight: '1.5',
                            },
                        }}
                        // eslint-disable-next-line react/jsx-curly-brace-presence
                        primary={t("home_PROMOTION")}
                    />
                </Root2>
            </div>

            {/*签到弹窗活动*/}

            <AnimateModal className="checkInDi" closeClass="closeBtnCheckIn" open={openChangeStatus.checkIn} onClose={() => onChangeStatus('checkIn', false)}>
                {/* <BootstrapDialog
                onClose={() => { onChangeStatus('checkIn', false) }}
                aria-labelledby="customized-dialog-title"
                open={openChangeStatus.checkIn}
                className="noBackGroundCheckIn checkInDiWH"
            > */}
                {/* <DialogContent sx={{ border: "none" }} className='checkInDi' dividers> */}
                <div>
                    <motion.div
                        variants={container}
                        initial="hidden"
                        animate="show"
                    >
                        <Typography id="customized-dialog-title" className='textAlignRight'>
                            <span style={{ display: "block", color: "#FFD569" }} className={clsx("checkInTitleMt text-align titleTxt", judgeLangFontSize(['fr', 'ru']) && "font-22")} >{t("home_CheckinBouns")}</span>
                        </Typography>

                        <Box>
                            <motion.div variants={item} className="">
                                <Typography className="text-align checkInTxtmtMiaoShu" >
                                    {t("home_Checkineveryweek")}
                                </Typography>
                            </motion.div>

                            <div style={{ position: "relative", marginTop: "70px", marginLeft: "32px" }}>
                                <div className='borderRadius ' style={{ width: "648px", height: "13px", backgroundColor: "#0F1520", position: "absolute" }}>
                                    <div className='borderRadius' style={{ width: 14.3 * userSignData?.SignTimes + '%', height: "13px", backgroundColor: "#EA9B13", position: "absolute" }}> </div>
                                </div>
                                <div className='flex ' style={{ position: "absolute", top: "-60px", left: "194px" }}>
                                    <motion.div variants={item} className='width-85 align-item text-align'>
                                        <div className='text-16 font-weight500' style={{ color: "#FFD569" }}>
                                            3{t("home_Day")}
                                        </div>
                                        <img src={`${React.$imgUrl}/images/index/jinBi2.png`}></img>
                                        <div className='text-14' >
                                            {signInConfig[2]?.cashCoupon / 100 || 0}U
                                        </div>
                                    </motion.div>

                                    <motion.div variants={item} className='width-85 align-item text-align ml-120 '>
                                        <div className='text-16 font-weight500' style={{ color: "#FFD569" }}>
                                            5{t("home_Day")}
                                        </div>
                                        <img src={`${React.$imgUrl}/images/index/jinBi3.png`}></img>
                                        <div className='text-14' >
                                            {signInConfig[4]?.cashCoupon / 100 || 0}U
                                        </div>
                                    </motion.div>

                                    <motion.div variants={item} className='width-85 align-item text-align ml-120'>
                                        <div className='text-16 font-weight500' style={{ color: "#FFD569" }}>
                                            7{t("home_Day")}
                                        </div>
                                        <img src={`${React.$imgUrl}/images/index/jinBi3.png`}></img>
                                        <div className='text-14' >
                                            {signInConfig[6]?.cashCoupon / 100 || 0}U
                                        </div>
                                    </motion.div>
                                </div>
                            </div>

                            <div
                                className='promotion-list1 px-20'
                                style={{ marginTop: "150px" }}
                            >
                                {signInConfig.length > 0 && signInConfig.map((sign, index) => {
                                    return (
                                        <motion.div
                                            key={sign.dayID}
                                            variants={item}
                                            className='align-item text-align  btnPointer txtBrightness  checkInPcDi'
                                            style={{ position: "relative", width: "95px", height: "141px" }}
                                        >
                                            <div className='text-14  marginJuZhong pt-6' style={{ width: "95px" }}>
                                                {signDate[sign.dayID].name}
                                            </div>
                                            <img className='my-4  marginJuZhong' src={signDate[sign.dayID].url} />
                                            <div className='text-14  marginJuZhong' >
                                                {sign.cashCoupon / 100}U
                                            </div>
                                            {(index + 1 <= userSignData?.SignTimes) && <img className='positionAb' style={{ top: "0px", left: "0px" }} src={`${React.$imgUrl}/images/index/checkOver.png`} />}
                                            {(!userSignData?.SignedToday && index + 1 == userSignData?.SignTimes + 1) && <img
                                                className='positionAb'
                                                style={{ top: "0px", left: "0px" }}
                                                src={`${React.$imgUrl}/images/index/checkOver_1.png`}
                                                onClick={() => {
                                                    handleCheckIn();
                                                }}
                                            />}

                                        </motion.div>
                                    )
                                })}
                            </div>

                            {/* {!userSignData?.SignedToday && <div className='text-align mt-36'>
                                    <Button
                                        className="width-160 borderRadius text-20 py-24"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            handleCheckIn();
                                        }}
                                    >
                                        {t("home_Checkin")}
                                    </Button>
                                </div>} */}
                        </Box>
                    </motion.div>
                </div>
                {/* </DialogContent>
            </BootstrapDialog> */}
            </AnimateModal>

            {/*奖励弹窗*/}
            <AwardPop
                open={popSignAward}
                onClose={() => {
                    setPopSignAward(false);
                }}
                symbol={signInRewardBalance.symbol}
                symbolImg={`assets/images/symbol/${signInRewardBalance.symbol}.png`}
                balance={signInRewardBalance.balance}
            />



            {/*首充弹窗活动*/}
            <Deposit open={openChangeStatus.deposit} onClose={() => onChangeStatus('deposit', false)} />

            {/*救济金弹窗活动*/}
            <Relief open={openChangeStatus.relief} onClose={() => onChangeStatus('relief', false)} />

            {/*分享弹窗活动*/}
            <AnimateModal className="shareDi" closeClass="closeBtnShare" open={openChangeStatus.share} onClose={() => onChangeStatus('share', false)}>
                <Share />
            </AnimateModal>

            {/*转盘弹窗活动*/}
            <AnimateModal className="spinDi" closeClass="closeBtnspin" open={openChangeStatus.spin} onClose={() => onChangeStatus('spin', false)}>
                <Spin />
            </AnimateModal>

            <div className="px-12 promotion-list" style={{ width: '100%' }} >
                <div className="promotion-item p-8 mt-8 bg1Color" onClick={() => { onChangeStatus('checkIn', true) }}>
                    <div className="promotion-item-img navbarImgZindex" >
                        <img src={`${React.$imgUrl}/images/nav/1.png`} alt="" />
                    </div>
                    <div>
                        <div className={clsx("navbarFontSize", judgeLangFontSize(['fi', 'my', 'ru']) && "font-12")}>{t("home_Checkin")}</div>
                        <div className="" style={{ fontSize: "13px", fontWeight: "500", color: "#94A3B8" }}>{t("home_Bouns")}</div>
                    </div>
                    {(userSignData?.SignedToday === false && userData.token) && <img
                        className="positionAb"
                        style={{
                            right: 0,
                            top: 0,
                            width: '16px',
                            height: '16px'
                        }}
                        src="assets/images/activity/redPoint.png"
                        alt=""
                    />}
                </div>

                <div className="promotion-item promotion-item-bg2 p-8 mt-8" onClick={() => { onChangeStatus('spin', true) }}>

                    <div className="promotion-item-img navbarImgZindex">
                        <img className='turnAni' src={`${React.$imgUrl}/images/nav/2.png`} alt="" />
                    </div>

                    <div className="posZhiZhenAbsolute" style={{ width: "60px", height: "60px" }}>
                        <img src={`${React.$imgUrl}/images/nav/2_1.png`} alt="" />
                    </div>

                    <div>
                        <div className={clsx("navbarFontSize", judgeLangFontSize(['tr']) && "font-12")}>{t("home_SPIN")}</div>
                        <div className="" style={{ fontSize: "13px", fontWeight: "500", color: "#94A3B8" }}>{t("home_Bouns")}</div>
                    </div>

                    {(userTurnTimes > 0 && userData.token) && <img
                        className="positionAb"
                        style={{
                            right: 0,
                            top: 0,
                            width: '16px',
                            height: '16px'
                        }}
                        src="assets/images/activity/redPoint.png"
                        alt=""
                    />}
                </div>

                <div className="promotion-item promotion-item-bg3 p-8 mt-8" onClick={() => { onChangeStatus('relief', true) }} >

                    <div className="promotion-item-img navbarImgZindex">
                        <img src={`${React.$imgUrl}/images/nav/3.png`} alt="" />
                    </div>
                    <div>
                        <div className={clsx("navbarFontSize", judgeLangFontSize(['de']) && "font-12")}>{t("home_Relief")}</div>
                        <div className="" style={{ fontSize: "13px", fontWeight: "500", color: "#94A3B8" }}>{t("home_Bouns")}</div>
                    </div>
                    {(receiveStatus === 0 && userData.token) && <img
                        className="positionAb"
                        style={{
                            right: 0,
                            top: 0,
                            width: '16px',
                            height: '16px'
                        }}
                        src="assets/images/activity/redPoint.png"
                        alt=""
                    />}
                </div>

                <div className="promotion-item promotion-item-bg4 p-8 mt-8" onClick={() => { onChangeStatus('share', true) }} >
                    <div className="promotion-item-img navbarImgZindex">
                        <img src={`${React.$imgUrl}/images/nav/4.png`} alt="" />
                    </div>
                    <div>
                        <div className={clsx("navbarFontSize", judgeLangFontSize(['pl']) && "font-12")}>{t("home_Share")}</div>
                        <div className="" style={{ fontSize: "13px", fontWeight: "500", color: "#94A3B8" }}>{t("home_Bouns")}</div>
                    </div>
                    {(shareData?.CanGetRewardTdy === true && userData.token) && <img
                        className="positionAb"
                        style={{
                            right: 0,
                            top: 0,
                            width: '16px',
                            height: '16px'
                        }}
                        src="assets/images/activity/redPoint.png"
                        alt=""
                    />}
                </div>

                <div className="promotion-item promotion-item-bg5 p-8 mt-8" onClick={() => { onChangeStatus('deposit', true) }}>

                    <div className="promotion-item-img navbarImgZindex">
                        <img src={`${React.$imgUrl}/images/nav/5.png`} alt="" />
                    </div>

                    <div>
                        <div className={clsx("navbarFontSize", judgeLangFontSize(['de']) && "font-12")}>{t("home_Deposit")}</div>
                        <div className="" style={{ fontSize: "13px", fontWeight: "500", color: "#94A3B8" }}>{t("home_Bouns")}</div>
                    </div>

                    {(
                        (
                            chargeGiftStatus?.HasGotFirst === false ||
                            chargeGiftStatus?.RechargeCountAll === 0 ||
                            (chargeGiftStatus?.HasTodayReward === true && chargeGiftStatus?.RechargeCount <= 3 && chargeGiftStatus?.HasGotDailyThird == false)
                        ) &&
                        userData.token
                    ) && <img
                            className="positionAb"
                            style={{
                                right: 0,
                                top: 0,
                                width: '16px',
                                height: '16px'
                            }}
                            src="assets/images/activity/redPoint.png"
                            alt=""
                        />}
                </div>

                <div className="promotion-item promotion-item-bg6 p-8 mt-8" onClick={() => {
                    history.push('/fund')
                }}>
                    <div className="promotion-item-img navbarImgZindex">
                        <img src={`${React.$imgUrl}/images/nav/6.png`} alt="" />
                    </div>
                    <div>
                        <div className={clsx("navbarFontSize", judgeLangFontSize(['tmr']) && "font-12")}>{t("home_Fund")}</div>
                        <div className="" style={{ fontSize: "13px", fontWeight: "500", color: "#94A3B8" }}>{t("home_Bouns")}</div>
                    </div>

                    {
                        isShowFundRed && <img
                            className="positionAb"
                            style={{
                                right: 0,
                                top: 0,
                                width: '16px',
                                height: '16px'
                            }}
                            src="assets/images/activity/redPoint.png"
                            alt=""
                        />
                    }
                </div>
            </div>
            <div className="vipDiv btnPointer" style={{ backgroundImage: `url(${React.$imgUrl}/images/nav/vip_bg.png)` }} onClick={() => {
                history.push('/vip')
            }} >
                <div className="vip-item-img">
                    <img src={`${React.$imgUrl}/images/nav/vip.png`} alt="" />
                </div>
                <div className="vip-item-text " style={{ color: "#d7dae0" }}>{t("home_VIPClub")}</div>
            </div>
        </Root>
    );
}

export default UserNavbarHeader;
