import "../../../styles/index.css";
import "../../../styles/xianStyle.css";
import utils from '../../util/tools/utils'
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { doSaveToWallet } from "app/store/user/userThunk";
import { useState, useEffect, forwardRef, default as React } from 'react';
import user from "app/store/user";
import FuseLoading from "@fuse/core/FuseLoading";

function SportTips(props) {
    const { t } = useTranslation('mainPage');
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };

    const onSuccess = () => {
        setOpen(false)
        if (props.successFun) {
            props.successFun()
        }
    }

    const onExit = () => {
        setIsLoading(true)
        dispatch(doSaveToWallet()).then((res) => {
            if (res.payload) {
                setIsLoading(false)
                setOpen(false)
                if (props.errorFun) {
                    props.errorFun()
                }
            }
        })
    }

    useEffect(() => {
        if (props.open) {
            setOpen(true)
        }
    }, [props]);

    return (
        <>
            {open && (
                <div className="sportTipsSty" >
                    <motion.div
                        variants={container}
                        initial="hidden"
                        animate="show"
                    >
                        <motion.div variants={item} className="gameTipsSty px-20 py-24 mx-auto" >
                            <div className="cookiesTxt1">
                                <div className="" style={{ fontSize: "20px", marginBottom: "4px", width: "330px", wordWrap: 'break-word' }}>{t("play_not_balance")}</div>
                            </div>
                            <div className="mt-20">
                                {isLoading ? (
                                    <div className='flex justify-center items-center' style={{ height: '40px' }}>
                                        <FuseLoading />
                                    </div>
                                ) : (<>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Button className="whitespace-nowrap borderRadius px-24 gameTipsBtn  funbetTxt " variant="contained" color="secondary" style={{ fontSize: "16px", minWidth: "115px" }}
                                            onClick={() => {
                                                onSuccess()
                                            }}
                                        >
                                            {t("home_confirms")}
                                        </Button>
                                        <Button className="whitespace-nowrap borderRadius px-24 gameTipsBtn  funbetTxt " variant="contained" color="secondary" style={{ fontSize: "16px", minWidth: "115px" }}
                                            onClick={() => {
                                                onExit()
                                            }}
                                        >
                                            {t("home_gameout_2")}
                                        </Button>
                                    </div>
                                </>)}
                            </div>
                        </motion.div>
                    </motion.div>
                </div>
            )}
        </>
    );
}
export default SportTips;


