const locale = {
  GAME: 'Permainan',
  Casino: "Kasino",
  ProvablyFair: "Provably Fair",
  Option: 'Pilihan',
  Crypto: 'Kripto',
  Forex: 'Forex',
  Stocks: 'Saham',
  NFTFuniCard: 'NFT Kartu Funi',
  StakeNFT: 'Stake NFT Hasilkan USDT',
  NFTStake: 'NFT Stake',
  Marketplace: 'Pasar',
  NFTMint: 'NFT Mencetak',
  BettoEarn: 'Bet to Hasilkan',
  EarnUSGT: 'Hasilkan USDT',
  DeFi: 'DeFi',
  ToBeVolunteer: 'Menjadi Sukarelawan',
  SYSTEM: 'SISTEM',
  Affiliate: 'Afiliasi',
  News: 'Berita',
  FAQs: 'FAQs',
  DOWNLOAD: 'UNDUH',
  DOWNLOADApp: 'Unduh App',
  Savetodesktop: 'Simpan ke Desktop',
  BetMining: 'Ikut kolam staking',
  ToBeBanker: 'Menjadi Bankir',
  ProfitPool: 'Kolam Keuntungan',
  USGTLiquidityMining: 'USDT Liquidity Mining',
  BGTLiquidityMining: 'BGT Liquidity Mining',
  Slots: 'Slot',
  Favorite: 'Favorit',
  Recent: 'Terbaru',
  Popular: "Populer",
  LiveCasino: "Kasino Langsung",
  TableGames: "Permainan Meja",
  BlackJack: "BlackJack",
  Baccarat: "Baccarat",
  Roulette: "Roulette",
  MoreGames: "Lebih Banyak",
  Provablyfair: "Provably fair",
  Special: "Special",
  Recommend: "Khusus",
  FeatureBuyin: "Rekomendasi",
  EnableTrialPlay: "Beli Fitur",
  GameShow: "Permainan Show",
  RGSVSB: "RGS - VSB",
  Scratch: "Kikuk",
  Bingo: "Bingo",
  Fishing: "Memancing",
  Turntable: "Putar",
  Dice: "Dadu",
  Poker: "Poker",
  AllGames: "Semua Permainan",
  EuropeanView: "Tampilan Eropa",
  AfricanView: "Tampilan Afrika",
  LatinView: "Tampilan Latin",
  AsianView: "Asia",
  LsportView: "Liga",
  StandardView: "Standar",
  EsportView: "E-sports",
  LiveBetting: 'Taruhan Langsung',
  Provider: 'Pemasok',
  Sport: "Olahraga",



};

export default locale;
