  const locale = {
  GAME: 'SPIEL',
  Casino: "Kasino",
  ProvablyFair: "Nachweislich fair",
  Option: 'Option',
  Crypto: 'Virtuelle Währung',
  Forex: 'Devisen',
  Stocks: 'Aktien',
  NFTFuniCard: 'NFT FuniKarte',
  StakeNFT: 'Wetten NFT verdienen USDT',
  NFTStake: 'NFT Wetten',
  Marketplace: 'Marktplatz',
  NFTMint: 'NFT Minze',
  BettoEarn: 'Wette zu verdienen',
  EarnUSGT: 'verdienen USDT',
  DeFi: 'DeFi',
  ToBeVolunteer: 'Freiwillig zu sein',
  SYSTEM: 'SYSTEM',
  Affiliate: 'Agent',
  News: 'Nachrichten',
  FAQs: 'häufiges Problem',
  DOWNLOAD: 'herunterladen',
  DOWNLOADApp: 'herunterladen App',
  Savetodesktop: 'Auf dem Desktop speichern',
  BetMining: 'Wette Bergbau',
  ToBeBanker: 'Bankier sein',
  ProfitPool: 'Gewinn-pool',
  USGTLiquidityMining: 'USDT Liquidity Mining',
  BGTLiquidityMining: 'BGT Liquidity Mining',
  Slots: 'Spielautomaten',
  Favorite: 'sammeln',
  Recent: 'Kürzlich',
  Popular: "Beliebt",
  LiveCasino: "Live Kasino",
  TableGames: "Tischspiele",
  BlackJack: "BlackJack",
  Baccarat: "Bakkarat",
  Roulette: "Roulette",
  MoreGames: "Weitere Spiele",
  Provablyfair: "Nachweislich fair",
  Special: "Besonderes",
  Recommend: "Empfehlen",
  FeatureBuyin: "Merkmal Einkaufen",
  EnableTrialPlay: "Demo",
  GameShow: "Spiel zeigen",
  RGSVSB: "RGS - VSB",
  Scratch: "Rubbelkarte",
  Bingo: "Bingo",
  Fishing: "Fische fangen",
  Turntable: "Drehscheibe",
  Dice: "Würfel",
  Poker: "Poker",
  AllGames: "Alle Spiele",
  EuropeanView: "Europäische",
  AfricanView: "Afrikanische",
  LatinView: "Lateinamerikanische",
  AsianView: "Asienansicht",
  LsportView: "Ligasportansicht",
  StandardView: "Standardansicht",
  EsportView: "E-Sport",
  LiveBetting: 'Live-Wetten',
  Provider: 'Anbieter',
  Sport: "Sport",



};

export default locale;
