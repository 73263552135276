import SportMatch from './SportMatch';

const SportMatchConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'sport-match',
            element: <SportMatch />,
        },
    ],
};

export default SportMatchConfig;