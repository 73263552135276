  const locale = {
  GAME: 'เกม',
  Casino: "คาสิโน",
  ProvablyFair: 'Provably fair',
  Option: 'ตัวเลือก',
  Crypto: 'เครื่องหมายเงินดิจิทัล',
  Forex: 'การซื้อขายฟอเร็กซ์',
  Stocks: 'หุ้น',
  NFTFuniCard: 'บัตร NFT เติมเงิน',
  StakeNFT: 'เดิมพัน NFT รับ USDT',
  NFTStake: 'เดิมพัน NFT',
  Marketplace: 'ตลาด',
  NFTMint: 'การสร้าง NFT',
  BettoEarn: 'เดิมพันเพื่อรับเงิน',
  EarnUSGT: 'รับ USDT',
  DeFi: 'การเงินไร้ศูนย์กลาง',
  ToBeVolunteer: 'เป็นอาสาสมัคร',
  SYSTEM: 'ระบบ',
  Affiliate: 'พันธมิตร',
  News: 'ข่าว',
  FAQs: 'คำถามที่พบบ่อย',
  DOWNLOAD: 'ดาวน์โหลด',
  DOWNLOADApp: 'ดาวน์โหลด App',
  Savetodesktop: 'บันทึกลงเดสก์ท็อป',
  BetMining: 'การขุดเหมืองเดิมพัน',
  ToBeBanker: 'เป็นผู้ถือหุ้น',
  ProfitPool: 'บ่อกำไร',
  USGTLiquidityMining: 'USDTการขุด',
  BGTLiquidityMining: 'BGTการขุด',
  Slots: 'สล็อต',
  Favorite: 'รายการโปรด',
  Recent: 'ล่าสุด',
  Popular: 'ยอดนิยม',
  LiveCasino: 'คาสิโนสด',
  TableGames: 'เกมโต๊ะ',
  BlackJack: 'แบล็กแจ็ก',
  Baccarat: 'บาคาร่า',
  Roulette: 'รูเล็ต',
  MoreGames: 'เกมเพิ่มเติม',
  Provablyfair: 'Provably fair',
  Special: 'พิเศษ',
  Recommend: "แนะนำ",
  FeatureBuyin: "ซื้อคุณสมบัติ",
  EnableTrialPlay: "เปิดใช้การเล่นทดลอง",
  GameShow: "รายการเกม",
  RGSVSB: "RGS - VSB",
  Scratch: "สแกรตช์",
  Bingo: "บิงโก",
  Fishing: "ตกปลา",
  Turntable: "หมุนวงล้อ",
  Dice: "ลูกเต๋า",
  Poker: "โป๊กเกอร์",
  AllGames: "เกมทั้งหมด",
  EuropeanView: "มุมมองยุโรป",
  AfricanView: "มุมมองแอฟริกา",
  LatinView: "มุมมองละติน",
  AsianView: "มุมมองเอเชีย",
  LsportView: "มุมมองกีฬา",
  StandardView: "มุมมองมาตรฐาน",
  EsportView: "มุมมองอีสปอร์ต",
  LiveBetting:'การเดิมพันสด',
  Provider:'ผู้ให้บริการ',
  Sport:'กีฬา',


};

export default locale;
