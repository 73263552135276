  const locale = {
GAME: 'খেলা',
Casino: "ক্যাসিনো",
ProvablyFair: "প্রমাণযোগ্য ন্যায়বিচার",
Option: 'অপশন',
Crypto: 'ক্রিপ্টো',
Forex: 'বিদেশী মুদ্রা বিনিময়',
Stocks: 'শেয়ার',
NFTFuniCard: 'NFT টপআপ কার্ড',
StakeNFT: 'NFT বাজি USDT উপার্জন',
NFTStake: 'NFT স্টেক',
Marketplace: 'মার্কেটপ্লেস',
NFTMint: 'NFT মিন্ট',
BettoEarn: 'বাজি দিয়ে টাকা উপার্জন করুন',
EarnUSGT: 'USDT উপার্জন করুন',
DeFi: 'ডিফাই',
ToBeVolunteer: 'সেবাদানকারী হতে',
SYSTEM: 'সিস্টেম',
Affiliate: 'এফিলিয়েট',
News: 'সংবাদ',
FAQs: 'প্রশ্ন উত্তর',
DOWNLOAD: 'ডাউনলোড',
DOWNLOADApp: 'অ্যাপ ডাউনলোড করুন',
Savetodesktop: 'ডেস্কটপে সংরক্ষণ করুন',
BetMining: 'বেট মাইনিং',
ToBeBanker: 'ব্যাংকার হতে',
ProfitPool: 'লাভ পুল',
USGTLiquidityMining: 'USDT লিকুইডিটি মাইনিং',
BGTLiquidityMining: 'BGT লিকুইডিটি মাইনিং',
Slots: 'স্লট মেশিন',
Favorite: 'প্রিয়',
Recent: 'সাম্প্রতিক',
Popular: "জনপ্রিয়",
LiveCasino: "লাইভ ক্যাসিনো",
TableGames: "টেবিল গেম",
BlackJack: "ব্ল্যাকজ্যাক",
Baccarat: "বাক্যারাট",
Roulette: "রুলেট",
MoreGames: "আরও গেমস",
Provablyfair: "বিকেন্দ্রীভূত গেম",
Special: "বিশেষ",
Recommend: "প্রস্তাবিত",
FeatureBuyin: "বৈশিষ্ট্য কেনার বিকল্প",
EnableTrialPlay: "ট্রায়াল প্লে সক্ষম করুন",
GameShow: "গেম শো",
RGSVSB: "আরজিএস - ভিএসবি",
Scratch: "খাদ্য মোছা",
Bingo: "বিংগো গেম",
Fishing: "মাছ ধরার গেম",
Turntable: "ঘূর্ণি",
Dice: "পাচ্চি",
Poker: "পোকার",
AllGames: "সকল গেমস",
EuropeanView: "ইউরোপীয় ",
AfricanView: "আফ্রিকান ",
LatinView: "ল্যাটিন আমেরিকার ",
AsianView: "এশিয়ান দৃষ্টিভঙ্গি",
LsportView: "লিগ দৃষ্টিভঙ্গি",
StandardView: "মানদণ্ড দৃষ্টিভঙ্গি",
EsportView: "ই-স্পোর্টস দৃষ্টিভঙ্গি",
LiveBetting: 'লাইভ বেটিং',
Provider: 'প্রদানকারী',
Sport:'ক্রীড়াة',


};

export default locale;
