  const locale = {
  GAME: 'Laro',
  Casino: "Kasino",
  ProvablyFair: "Patunay na Patas",
  Option: 'Pagpipilian',
  Crypto: 'Crypto',
  Forex: 'Forex',
  Stocks: 'Stocks',
  NFTFuniCard: 'NFT FuniCard',
  StakeNFT: 'panalo NFT at Kita USDT',
  NFTStake: 'NFT panalo',
  Marketplace: 'tindahan',
  NFTMint: 'NFT magsusukli',
  BettoEarn: 'Taya upang Kita',
  EarnUSGT: 'Kita USDT',
  DeFi: 'DeFi',
  ToBeVolunteer: 'Maging Boluntaryo',
  SYSTEM: 'SISTEMA',
  Affiliate: 'Afilasyon',
  News: 'Balita',
  FAQs: 'Mga Tanong',
  DOWNLOAD: 'MAG-DOWNLOAD',
  DOWNLOADApp: 'I-download angApp',
  Savetodesktop: 'I-save sa desktop',
  BetMining: 'Pagmimina ng Taya',
  ToBeBanker: 'Maging Bankero',
  ProfitPool: 'Pool ng Kita',
  USGTLiquidityMining: 'USDT Liquidity Mining',
  BGTLiquidityMining: 'BGT Liquidity Mining',
  Slots: 'Mga Slots',
  Favorite: 'Paborito',
  Recent: 'Huling Aktibidad',
  Popular: "Pamosong Laro",
  LiveCasino: "Live na Kasino",
  TableGames: "Mga Laro sa Mesa",
  BlackJack: "BlackJack",
  Baccarat: "Baccarat",
  Roulette: "Roulette",
  MoreGames: "Mga Ibang Laro",
  Provablyfair: "Patunay na Patas",
  Special: "Espesyal",
  Recommend: "Rekomendado",
  FeatureBuyin: "Tampok na Pagbili",
  EnableTrialPlay: "Patunay na Patas",
  GameShow: "palabas na palaro",
  RGSVSB: "RGS - VSB",
  Scratch: "Kiskisan",
  Bingo: "Bingo",
  Fishing: "Pangingisda",
  Turntable: "Pirulito",
  Dice: "Dice",
  Poker: "Poker",
  AllGames: "Lahat ng Laro",
  EuropeanView: "Vue européenne",
  AfricanView: "Vue africaine",
  LatinView: "Vue latine",
  AsianView: "Asyanong Tanawin",
  LsportView: "Ligang Pampalakasan",
  StandardView: "Standard na Tanawin",
  EsportView: "E-sports Tanawin",
  LiveBetting:'LiveBetting',
  Provider:'Provider',
  Sport: "Laro",


};

export default locale;
