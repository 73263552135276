import Maintenance from './Maintenance';


const MaintenanceConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'maintenance',
            element: <Maintenance />,
        },
    ],
};

export default MaintenanceConfig;