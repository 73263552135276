import Spin from './Spin';


const SpinConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'spin',
            element: <Spin />,
        },
    ],
};

export default SpinConfig;