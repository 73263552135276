import SportProject from './SportProject';

const SportProjectConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'sport-project',
            element: <SportProject />,
        },
    ],
};

export default SportProjectConfig;