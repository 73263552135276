import "../../../styles/index.css";
import "../../../styles/xianStyle.css";
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import { useState, useEffect, forwardRef, default as React } from 'react';
import history from '@history';
import { useSelector, useDispatch } from "react-redux";
import { getGameActivityNotice } from "../../store/activity/activityThunk";
import { selectUserLoginCountry } from "app/store/user/userLoginCountry";
import countryLang from "../../json/country";


const container = {
    show: {
        transition: {
            staggerChildren: 0.1,
        },
    },
};

const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
};

function ActivePopup(props) {
    const { t } = useTranslation('mainPage');
    const dispatch = useDispatch();

    const [isClickActivePopup, setIsClickActivePopup] = useState(false);
    const [isHaveActivePopup, setIsHaveActivePopup] = useState(true);
    const [activityConfig, setActivityConfig] = useState({});
    const loginCountry = useSelector(selectUserLoginCountry);
    const [isTimeDely, setIsTimeDely] = useState(false);

    const saveActivePopup = () => {
        setTimeout(() => {
            setIsHaveActivePopup(true)
        }, 400)
    }

    const startGames = () => {
        history.push(`/play/${activityConfig.gameChannel}/${activityConfig.freeGameId}/${activityConfig.casinoID}`)
        setIsHaveActivePopup(true)
    }
    const jump = () => {
        history.push(`documentation/getting-started/introduction/${activityConfig.newsID}`)
        setIsHaveActivePopup(true)
    }

    const countryLangChange = (lang) => {
        const tempLang = [];
        Object.keys(countryLang).map((item) => {
            if (countryLang[item].langCode == lang) {
                tempLang.push(item)
            }
        })
        if (tempLang.length > 0) {
            return tempLang[0];
        } else {
            return Object.keys(countryLang)[0];
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setIsTimeDely(true)
        }, 3000)
    }, []);




    useEffect(() => {
        if (loginCountry?.currentCountryData?.countryCode) {
            dispatch(getGameActivityNotice({
                country: loginCountry.currentCountryData.countryCode,
                lang: countryLangChange(window.localStorage.getItem('lang'))
            })).then((res) => {
                let result = res.payload;
                if (result) {
                    let timestamp = Date.parse(new Date());
                    if (result.beginTime <= timestamp && timestamp < result.endTime) {
                        setActivityConfig(result)
                        setIsHaveActivePopup(false)//显示该弹窗
                    }
                }
            })
            setTimeout(() => {
                setIsClickActivePopup(true)
            }, 4000)
        }
    }, [loginCountry?.currentCountryData, window.localStorage.getItem('lang')]);



    return (
        <>
            {(!isHaveActivePopup && isTimeDely) && <div id="activePopupPage" style={{ zIndex: "1000" }} className={clsx("activePopupSty", isClickActivePopup && "phoneMove5")} >
                <motion.div
                    variants={container}
                    initial="hidden"
                    animate="show"
                    className=""
                >
                    <motion.div variants={item} className="promotion-list1 activePopupDi">
                        <div className="activePopupTxt1 txtGreenColor">
                            {activityConfig.activityTitle}
                        </div>
                        <img className="btnPointer chaCloseBtn w-24 h-24" src="assets/images/index/closeBtn1.png" onClick={() => {
                            saveActivePopup()
                            setIsClickActivePopup(true)
                        }}></img>
                    </motion.div>
                    <motion.div variants={item} className="promotion-list1 mt-12">
                        <img className="borRadius-10 activeImageDi" src={activityConfig?.movingPicture ?? "assets/images/game/6.png"}></img>
                        <div className="activeTxt3">
                            <div className="activeTxt2">{activityConfig.activityContent}</div>
                            <div className="flex justifyContent" style={{ flexWrap: "nowrap" }}>
                                <Button className="whitespace-nowrap borderRadius funbetTxt activeBtn" variant="contained" color="secondary" style={{ fontSize: "18px" }} onClick={() => {
                                    jump()
                                }}>
                                    {t("home_EventDetails")}
                                </Button>
                                <Button className="whitespace-nowrap borderRadius funbetTxt activeBtn" variant="contained" color="secondary" style={{ fontSize: "18px", marginLeft: "10px" }} onClick={() => {
                                    startGames()
                                }}>
                                    {t("game_startGame")}
                                </Button>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            </div>}
        </>

    );
}
export default ActivePopup;


