import Deposit from './Deposit';


const DepositConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'deposit',
            element: <Deposit />,
        },
    ],
};

export default DepositConfig;