import Home from './Home';
import Test from './Test';
import i18next from 'i18next';
import en from '../../lang/en';
import de from '../../lang/de';
import fr from '../../lang/fr';
import ind from '../../lang/in';
import mld from '../../lang/mld';
import tmr from '../../lang/tmr';
import br from '../../lang/br';
import tr from '../../lang/tr';
import ph from '../../lang/ph';
import jp from '../../lang/jp';
import pl from '../../lang/pl';
import vn from '../../lang/vn';
import kr from '../../lang/kr';
import id from '../../lang/id';
import es from '../../lang/es';
import ru from '../../lang/ru';
import fi from '../../lang/fi';
import th from '../../lang/th';
import ae from '../../lang/ae';
import my from '../../lang/my';
import bd from '../../lang/bd';
import hk from '../../lang/hk';
import cn from '../../lang/cn';
import mm from '../../lang/mm';
import pak from '../../lang/pak';


import nav_en from '../../configs/navigation-i18n/en';
import nav_de from '../../configs/navigation-i18n/de';
import nav_fr from '../../configs/navigation-i18n/fr';
import nav_ind from '../../configs/navigation-i18n/in';
import nav_mld from '../../configs/navigation-i18n/mld';
import nav_tmr from '../../configs/navigation-i18n/tmr';
import nav_br from '../../configs/navigation-i18n/br';
import nav_tr from '../../configs/navigation-i18n/tr';
import nav_ph from '../../configs/navigation-i18n/ph';
import nav_jp from '../../configs/navigation-i18n/jp';
import nav_pl from '../../configs/navigation-i18n/pl';
import nav_vn from '../../configs/navigation-i18n/vn';
import nav_kr from '../../configs/navigation-i18n/kr';
import nav_id from '../../configs/navigation-i18n/id';
import nav_es from '../../configs/navigation-i18n/es';
import nav_ru from '../../configs/navigation-i18n/ru';
import nav_fi from '../../configs/navigation-i18n/fi';
import nav_th from '../../configs/navigation-i18n/th';
import nav_ae from '../../configs/navigation-i18n/ae';
import nav_my from '../../configs/navigation-i18n/my';
import nav_bd from '../../configs/navigation-i18n/bd';
import nav_hk from '../../configs/navigation-i18n/hk';
import nav_cn from '../../configs/navigation-i18n/cn';
import nav_mm from '../../configs/navigation-i18n/mm';
import nav_pak from '../../configs/navigation-i18n/pak';





i18next.addResourceBundle('en', 'mainPage', en);
i18next.addResourceBundle('de', 'mainPage', de);
i18next.addResourceBundle('fr', 'mainPage', fr);
i18next.addResourceBundle('in', 'mainPage', ind);
i18next.addResourceBundle('mld', 'mainPage', mld);
i18next.addResourceBundle('tmr', 'mainPage', tmr);
i18next.addResourceBundle('br', 'mainPage', br);
i18next.addResourceBundle('tr', 'mainPage', tr);
i18next.addResourceBundle('ph', 'mainPage', ph);
i18next.addResourceBundle('jp', 'mainPage', jp);
i18next.addResourceBundle('pl', 'mainPage', pl);
i18next.addResourceBundle('vn', 'mainPage', vn);
i18next.addResourceBundle('kr', 'mainPage', kr);
i18next.addResourceBundle('id', 'mainPage', id);
i18next.addResourceBundle('es', 'mainPage', es);
i18next.addResourceBundle('ru', 'mainPage', ru);
i18next.addResourceBundle('fi', 'mainPage', fi);
i18next.addResourceBundle('th', 'mainPage', th);
i18next.addResourceBundle('ae', 'mainPage', ae);
i18next.addResourceBundle('my', 'mainPage', my);
i18next.addResourceBundle('bd', 'mainPage', bd);
i18next.addResourceBundle('hk', 'mainPage', hk);
i18next.addResourceBundle('cn', 'mainPage', cn);
i18next.addResourceBundle('mm', 'mainPage', mm);
i18next.addResourceBundle('pak', 'mainPage', pak);



i18next.addResourceBundle('en', 'navPage', nav_en);
i18next.addResourceBundle('de', 'navPage', nav_de);
i18next.addResourceBundle('fr', 'navPage', nav_fr);
i18next.addResourceBundle('in', 'navPage', nav_ind);
i18next.addResourceBundle('mld', 'navPage', nav_mld);
i18next.addResourceBundle('tmr', 'navPage', nav_tmr);
i18next.addResourceBundle('br', 'navPage', nav_br);
i18next.addResourceBundle('tr', 'navPage', nav_tr);
i18next.addResourceBundle('ph', 'navPage', nav_ph);
i18next.addResourceBundle('jp', 'navPage', nav_jp);
i18next.addResourceBundle('pl', 'navPage', nav_pl);
i18next.addResourceBundle('vn', 'navPage', nav_vn);
i18next.addResourceBundle('kr', 'navPage', nav_kr);
i18next.addResourceBundle('id', 'navPage', nav_id);
i18next.addResourceBundle('es', 'navPage', nav_es);
i18next.addResourceBundle('ru', 'navPage', nav_ru);
i18next.addResourceBundle('fi', 'navPage', nav_fi);
i18next.addResourceBundle('th', 'navPage', nav_th);
i18next.addResourceBundle('ae', 'navPage', nav_ae);
i18next.addResourceBundle('my', 'navPage', nav_my);
i18next.addResourceBundle('bd', 'navPage', nav_bd);
i18next.addResourceBundle('hk', 'navPage', nav_hk);
i18next.addResourceBundle('cn', 'navPage', nav_cn);
i18next.addResourceBundle('mm', 'navPage', nav_mm);
i18next.addResourceBundle('pak', 'navPage', nav_pak);

const HomeConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    // auth: authRoles.home,
    routes: [
        {
            path: 'home',
            element: <Home />,
        },
        {
            path: 'test',
            element: <Test />,
        },
    ],
};

export default HomeConfig;
