const locale = {
    home_StakeNFT: 'नेफ्टी निवेश करें और अधिक कमाएँ',
    home_everyday: 'हर दिन',
    home_Beton: 'शर्त लगाएँ',
    home_mining: 'खनन',
    home_shareholder: 'शेयरहोल्डर बनें',
    home_Stake: 'निवेश करें',
    home_dividend: 'लाभांश प्राप्त करें',
    home_Banker: 'साझेदार में से एक बनें',
    home_SIGNUPAND: 'अभी साइन अप करें',
    home_GET: 'प्राप्त करें',
    home_UPTO: 'कुल',
    home_FuniHash: 'ब्लॉकचेन गेम',
    home_Option: 'विकल्प',
    
    home_Reward: ' पुरस्कार',
    home_Reward1: 'पुरस्कार',
    home_signUpNow: 'अभी साइन अप करें',
    home_Casino: 'कैसीनो',
    home_Sports: 'खेल',
    home_PROMOTION: 'पदोन्नति',
    home_Checkin: 'चेक-इन',
    home_Bouns: 'बोनस',
    home_CheckinBouns: 'चेक-इन बोनस',
    home_SPIN: 'स्पिन',
    home_Relief: 'राहत',
    home_Share: 'साझा करें',
    home_Deposit: 'जमा',
    home_Fund: 'फंड',
    home_VIPClub: 'वीआईपी क्लब',
    home_FastAndEasy: 'क्रिप्टोकरेंसी खरीदने और USDT जमा बोनस प्राप्त करने के लिए त्वरित और आसान',
    home_Game: 'खेल',
    home_User: 'उपयोगकर्ता',
    home_Time: 'समय',
    home_BetAmount: 'बेट राशि',
    home_Multiplier: 'गुणक',
    home_Payout: 'भुगतान',
    home_JoinOur: 'हमारे खेल, क्रिप्टो और जुआरी समुदाय में शामिल हों',
    home_JOIN: 'शामिल हों',
    home_LICENSES: 'लाइसेंस',
    home_OurPlatforms: 'हमारे प्लेटफॉर्म',
    home_Marketplac: 'मार्केटप्लेस',
    home_Howtoearn: 'कसे कमवावे',
    home_Games: 'गेम्स',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'इन्वेस्टर डेक',
    home_News: 'न्यूज',
    home_Aboutsus: 'आमच्याबद्दल',
    home_Industry: 'उद्योग',
    home_Roadmap: 'रोडमॅप',
    home_Partner: 'साथी',
    home_FAQs: 'सामान्य प्रश्न',
    home_Affiliate: 'सहकारी',
    home_Stayintouch: 'संपर्कात रहा',
    home_ChatRoom: 'चॅट रूम',
    home_Selectacontact: 'एक संपर्क निवडा आरंभ करण्यासाठी.',
    home_BetSlip: 'बेट स्लिप',
    home_Typeyourmessage: 'आपले संदेश टाइप करा',
    home_AcceptAnyOdds: 'कोणत्याही ऑड्स स्वीकारा',
    home_ClearAll: 'सर्व साफ करा',
    home_Live: 'गोल रेट',
    home_Winner: 'विजयी',
    home_EstPayout: 'अनुमानित भुगतान',
    home_TotalOdds: 'कुल दांव',
    home_RegistertoBet: 'शर्त लगाने के लिए रजिस्टर करें',
    home_Single: 'एकल शर्त',
    home_Multi: 'एकाधिक शर्त',
    home_Checkineveryweek: 'प्रत्येक सप्ताह हस्ताक्षर की जांच, संबंधित पुरस्कार प्राप्त करें।',
    home_Day: 'दिन',
    home_Sunday: 'पहला दिन',
    home_Monday: 'दूसरा दिन',
    home_Tuesday: 'तीसरा दिन',
    home_Wednesday: 'चौथा दिन',
    home_Thursday: 'पांचवां दिन',
    home_Friday: 'छठा दिन',
    home_Saturday: 'सातवां दिन',
    home_FreeSpin: 'चक्रवृद्धि',
    home_Turntheturntable: 'हर दिन चक्रवृद्धि को घुमाकर USGT पुरस्कार प्राप्त करें।',
    home_DepositFift: 'जमा रिवार्ड',
    home_ANYAMOUNT: 'आप पहली जमा किसी भी राशि का लाभ उठा सकते हैं',
    home_DEPOSIT: 'जमा',
    home_First: 'पहली बार',
    home_ONLY: 'केवल',
    home_1ST: '1ST',
    home_2ND: '2ND',
    home_3TH: '3TH',
    home_ANYAMOUNT: 'कोई भी राशि!',
    home_EXTRA: 'अतिरिक्त',
    home_Relief: 'राहत',
    home_Getrelief: 'राहत प्राप्त करें और मौका प्राप्त करें।',
    home_YOUCANCLAIM: 'जब आपका शेष राशि',
    home_YOUCANReceive: 'से कम होता है, तब आप राहत प्राप्त कर सकते हैं जिससे आपका शेष राशि',
    home_threeTimes: ' 3 बार',
    home_everydayTimes: '/ हर दिन!',
    home_Times: 'बार:',
    home_TotalAssets: 'कुल संपत्ति',
    home_receive: 'प्राप्त करें',
    home_TODAY: 'आज',
    home_Share: 'शेयर',
    home_PLATFORMSHARING: 'अन्य प्लेटफ़ॉर्मों के साथ साझा करें',
    home_Sharewith: 'प्रतिदिन FuniBet साझा करने से प्राप्त करें',
    home_SHARINGGIFT: 'शेयर गिफ्ट',
    home_CODE: 'शेयर कोड',
    home_Selectplatform: 'शेयर प्लेटफ़ॉर्म का चयन करें:',
    home_Fund: 'वृद्धि कोष',
    home_FundBonus: 'वृद्धि कोष बोनस',
    home_Improveyour: 'अपने VIP स्तर को बढ़ाएं और हर दिन अधिक फंड बोनस प्राप्त करें।',
    home_BuyFund: 'कोष खरीदें',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'प्राप्ति स्थिति',
    home_Received: 'प्राप्त किया',
    home_noReceived: 'unmet',
    home_ExclusiveBenefits: 'अनन्य लाभ',
    home_Asawayof: 'हमारे VIP खिलाड़ियों के प्रति हमारी आभार व्यक्त करने और सराहना करने के लिए, FuniBet आपको हमारे VIP क्लब में शामिल होने के लिए आमंत्रित करता है, जहाँ उपहारों, मुफ्त उपहार, अधिक कैशबैक और अनन्य सुविधाएं होती हैं। सभी रोमांचक और आश्चर्यजनक मौजों से बचना नहीं।',
    home_Yourcurrent: 'आपका वर्तमान कुल जमा',
    home_WhyUpgrade: 'VIP बनने के लिए क्यों अपग्रेड करें? ',
    home_VIPhave: 'VIP लोकांना 24*7 चांगली सेवा देणारे विशेष व्यवस्थापक आणि विशेष व्यवस्थापन मिळतो. आधारांकावर परत व रक्कम भरण्याच्या पेक्षा, VIP लोकांना सर्वोच्च दर्जाचे घुमट, प्रत्येक आठवड्याचे/महिन्याचे बोनस आणि सबसे जलद भरणा आणि विनामूल्य बाह्य चालू. VIP खिळवणार्यांना शीर्ष खिळवणार्यांच्या संवाद संचार सत्रांमध्ये सहभागी होण्याची संधी आहे आणि नवीन वैशिष्ट्ये आणि कार्यक्षमता FUNIBet टीमला प्रस्ताव देण्यासाठी आहे. ',
    home_UpgradeYour: 'तुमचे VIP श्रेणी अपग्रेड करा',
    home_ASaFunibet: 'FUNIBet VIP सभासद म्हणून, तुम्ही जबरदस्त पुरस्कार आणि वैयक्तिक गिफ्ट मिळवू शकता, पण हे सगळं नाही. जेव्हा आपण अधिक खेळता, तेव्हा जेव्हा आपण VIP श्रेणीच्या स्तरावर सुधारण करता जातो, तेव्हा आपण VIP विशेषाधिकारांचा अधिक वेगळा लाभ मिळवणार आहात, FUNIBET आपल्यासाठी VIP लाभांचे तयारी करते. ',
    home_YOUCANREACH: 'आप उन्नयन कर सकते हैं',
    home_LEVELS: 'स्तर',
    home_eachthehigh: 'हर व्यक्ति 25% कैशबैक बोनस और प्लेटफ़ॉर्म के सभी फायदों से लाभ उठा सकता है। FUNIBET में आकर सर्वोत्तम खेल अनुभव और उच्च गुणवत्ता की सेवाओं का आनंद लें! ',
    home_Cashback: 'कैशबैक',
    home_Nowater: '1. खेल में अब और कोई चार्ज नहीं हैं',
    home_Moregrowth: '2. अधिक विकास के लिए फंड',
    home_Increasein: '3. लाभ की संख्या में वृद्धि',
    home_Doublethenumber: '4. घुमाने की संख्या दोगुनी',
    home_Doublethesign: '5. साइन अप संख्या दोगुनी',
    home_Doublesharingrewards: '6. साझा करने का पुरस्कार दोगुना',
    home_DoubletheAffiliate: '7. संबंधित पुरस्कार दोगुने',
    home_Rechargerewardamount: '8. रिचार्ज रिवार्ड राशि 1.5 गुणा हो जाएगी',
    home_ABOUTVIPCLUB: 'VIP क्लब के बारे में',
    home_HightopRank: 'उच्च रैंकिंग पर 25% कैशबैक बोनस और सर्वोत्तम लाभ होते हैं। BeDao में आवास करके सर्वोत्तम खेल अनुभव और उच्च स्तर की सेवाओं का आनंद लें! ',
    home_Beingamember: 'VIP क्लब के सदस्य बनना यह मतलब होता है कि आपको पहुँच होगी:',
    home_PersonalVIPmanager: 'व्यक्तिगत वीआईपी मॅनेजर',
    home_Insights: 'नेतृत्व',
    home_Highercahbackrewards: 'अधिक नकदी रिटर्न',
    home_Otheradditionalperks: 'अन्य अतिरिक्त भत्ते',
    home_WeeklyandMonthlyBonuses: 'साप्ताहिक और मासिक बोनस',
    home_Showhighgaming: 'उच्च गेमिंग गतिविधियों को दिखाएं और हमारे वीआईपी क्लब के निमंत्रण को प्राप्त करें।',
    home_BindMobile: 'मोबाइल बाइंड करें',
    home_Pleasecheckthe: 'कृपया खेल से प्राप्त किए गए आईएसजीटी की जाँच करें।',
    home_BINDMOBILENUMBER: 'मोबाइल नंबर बाइंड करें',
    home_Get1USDT: '1USDT प्राप्त करें',
    home_Bind: 'बाइंड',
    home_GiftCode: 'उपहार कोड',
    home_EnteryourGiftCode: 'अपना उपहार कोड दर्ज करें',
    home_EnterCode: 'कोड दर्ज करें',
    home_Paste: 'पेस्ट',
    home_confirms: 'पुष्टि करें',
    home_BuyCrypto: 'क्रिप्टो खरीदें',
    home_SignedIn: 'हस्ताक्षर किए गए',
    home_CommissionRules: 'कमीशन नियम',
    home_AffiliateTerms: 'संबंधित शर्तें',
    home_AffiliateDashboard: 'संबंधित डैशबोर्ड',
    home_AFFILIATE: 'संबंधित',
    

    
    home_COMMISSONREADYEVERYDAY: 'कमीशन नियमावली',
    home_MYREFERRALCODE: 'माझे सिफारिस कोड',
    home_MYREFERRALLink: 'माझा सिफारिस लिंक',
    home_AffiliateReward: 'संबंधित पुरस्कार',
    home_EXTRA: 'अतिरिक्त',
    home_USDREWARDS: 'डॉलर पुरस्कार',
    home_USDRewardsRules: 'डॉलर पुरस्कार नियम',
    home_JUSAREFERRALAWAY: 'एक सिफारिस मात्र असा',
    home_NewlyAvailableRewards: 'नवीनतम पुरस्कारे',
    home_ReceivedMao: 'प्राप्त:',
    home_Locked: 'लॉक केले:',
    home_Yourcurrentfriends: 'तुमच्या सध्याच्या मित्रांनी अधिक पुरस्कारांसाठी अपयशी राहण्याची त्यांची जतन करणार आहे',
    home_Withdraw: 'धन निकाल',
    home_MY: 'माझे',
    home_COMMISSIONREWARDS: 'कमिशन पुरस्कारे',
    home_CommissionRewardRules: 'कमिशन पुरस्कार नियम',
    home_NewlyAvailableCommissionRewards: 'नवीन कमिशन पुरस्कारे',
    home_CommissionYouhaveReceivedinTotal: 'प्राप्त केलेला कमिशन:',
    home_REWARDSSENTTODATE: 'जेव्हा पुरस्कारे पाठविले गेले तेव्हा',
    home_USDrewardssenttodate: 'डॉलर पुरस्कार पाठविले गेले तेव्हा',
    home_Commissionsenttodatet: 'कमिशन पाठविल्या तारखा',
    home_Areyouablogger: 'आप एक ब्लॉगर हैं, जिसके पास बहुत से पाठक और अनुयायी हैं? हम आपके लिए एक विशेष छूट पेश करते हैं जो एक अद्वितीय संघ योजना है, जिसमें अधिक रेफरल पुरस्कार होंगे। हम एक विशेष शर्तों वाला अलग प्रोग्राम तैयार करेंगे। टर्म्स पर चर्चा करने के लिए हमारे मैनेजर से संपर्क करें',
    home_onlythoseusers: 'महत्वपूर्ण सूचना: इस योजना में भाग लेने के लिए केवल वे उपयोगकर्ता ही शामिल हो सकते हैं जिन्हें व्यवस्थापक द्वारा मांगा जाता है और जिन्हें व्यवस्थापक द्वारा अनुमोदित किया जाता है। ',
    home_notice: 'नोटिस',
    home_bindPhone: 'फ़ोन बाइंड करें',
    home_SignIn: 'साइन इन करें',
    home_SignUp: 'रजिस्टर करें',
    home_SignOut: 'साइन आउट करें',
    home_CookiesPolicy: 'कुकीज़ नीति',
    home_isUsingCookies: 'Funibet.com दररोज वापरकर्त्यांना उत्तम सेवा देण्यासाठी कुकीज वापरते. आमच्या वेबसाइट वापरण्याचे मत देताना, आपण कुकी वापरण्याच्या शर्तींची सहमती देता येते. कृपया गोपनीयता धोरण आणि सेवा शर्ती पहा. ',
    home_SETTING: 'सेटिंग',
    home_LiveSupport: 'ऑनलाईन सहाय्य',
    home_Accept: 'सहमत',
    home_FrequentlyAskedQuestions: 'विनामूल्य संदर्भशब्दांसह केलेले प्रश्न',
    home_SiteUnnderConstruction: 'साइट निर्माणाधीन',
    home_ComingSoon: 'लवकरच येणार',
    home_Wallet: 'बटुआ',
    home_UserId: 'आयडी: ',
    home_UserName: 'नाव: ',
    home_turnTimes: 'चालू वेळा:',
    home_Bonusincreased: "पुरस्कार अधिक +",
    home_bonus: 'पुरस्कार',
    home_SPINBONUSTOTAL: 'स्पिन बोनस एकूण',
    home_WIN: 'जीत:',
    home_In: 'येथून',
    home_FirstDepositBonus: 'पहिला जमा पुरस्कार',
    home_Hi: 'नमस्ते',
    home_welcomeAboard: 'तुमचं स्वागत आहे',
    home_Outoftime: 'आजच्या दिवसातील अधिक मोकं वापरलेले आहेत',
    
    game_search: 'शोध',
    game_SortBy: 'वर्गीकरण',
    game_Created: 'निर्मित',
    game_Lobby: 'लॉबी',
    game_LiveCasino: 'लाइव कैसीनो',
    game_FeaturedSlots: 'विशेष लालची मशीनें',
    game_GameShows: 'गेम शो',
    game_FuniBetOriginals: 'फणीबेट मूल गेम्स',
    game_BigWins: 'बड़ी जीत',
    game_LcukyWins: 'भाग्यशाली जीत',
    game_Challenges: 'चुनौतियाँ',
    game_Description: 'विवरण',
    game_Slots: 'स्लॉट',
    game_Rank: 'श्रेणी',
    game_User: 'उपयोगकर्ता',
    game_Date: 'तिथि',
    game_BetAmount: 'राशि',
    game_lucky: 'अधिकतम महत्त्व',
    game_Multiplier: 'गुणक',
    game_Payout: 'भुगतान',
    game_Recommend: 'सुझाव',
    game_Providers: 'प्रदाता',
    game_startGame: 'प्रारंभ करारे',
    game_balance: 'शेष राशि',
    game_provider: 'टैग',
    game_Selectyourdisplay: 'अपनी प्रदर्शिति का चयन करें',
    game_Balancein: 'शेष राशि',
    game_FunPlay: 'मजेदार खेल',
    game_RealPlay: 'रियल मनी मोड',
    game_DispLaying: "प्रदर्शन",
    game_LoadMore: "और लोड करें",
    game_NoData: 'अभी तक कोई डेटा उपलब्ध नहीं है',
    
    message_SigningIn: "JWT सहित लॉगिन होत आहे",
    message_CourseSaved: "कोर्स साठविले आहे",
    message_PleaseBind: "कृपया आपला फोन बाईंड करा",
    message_UserData: "युजर डेटा API ने साठवला आहे",
    message_Success: "यशस्वी",
    message_VIPlevel: "व्हीआयपी स्तर व्ही3 पेक्षा कमी आहे",
    message_ListOrder: "लिस्ट ऑर्डर साठविले आहे",
    message_CardOrder: "कार्ड ऑर्डर साठविले आहे",
    message_HiHow: "हाय, कसा आहेस?",


    Funibet_web: 'Funibet-वेब 3.0,क्रिप्टो,शर्त,क्रीडा,ई-क्रीडा,स्लॉट्स,लाइव कॅसीनो,पोकर',

    home_ThereAre: "अभी कोई सूचनाएं नहीं हैं।",

    play_no_balance: "वर्तमान मुद्रा शेष अपर्याप्त है, कृपया मुद्रा बदलें या रिचार्ज करें",
    
    home_News_1: 'समाचार',
    home_News_2: 'घटना',
    home_News_3: 'क्रिप्टो',
    home_News_4: 'गेमिंग',
    
    home_SuggRep_1: 'सुझाव और रिपोर्ट',
    home_SuggRep_2: 'सुझाव और रिपोर्ट',
    home_SuggRep_3: 'सुधार के लिए फीडबैक साझा करें!',
    home_SuggRep_4: 'गेम में बेचने वाली वेबसाइट की रिपोर्ट करें।',
    home_SuggRep_5: 'सुझाव दर्ज करें या रिपोर्ट सबमिट करें',
    home_SuggRep_6: 'उदाहरण स्क्रीनशॉट',
    home_SuggRep_7: 'स्वीकृत सुझाव और रिपोर्ट के लिए इनाम!',
    home_SuggRep_8: 'सबमिट करें',
    
    home_subscription_1: 'क्या आप पुश सूचनाएं सब्सक्राइब करना चाहते हैं?',
    home_subscription_2: 'आप हमेशा किसी भी समय सदस्यता रद्द कर सकते हैं।',
    home_subscription_3: 'WonderPush द्वारा वेब पुश',
    home_subscription_4: 'बाद में',
    home_subscription_5: 'सदस्यता लें',

    home_Affiliate_1:'आमच्याशी संपर्क साधा',
    home_Affiliate_2:'प्रत्येक मित्राला आपण 10U मिळतील, आपण जे अधिक मित्रांना आमच्याशी सामील करता त्यामध्ये आपण अधिक मिळविणार आहात!',
    home_Affiliate_3:'टेलिग्राम',
    home_Affiliate_4:'ईमेल',

    home_banner_1: 'क्रिप्टो गेमिंग',
    home_banner_2: '100+ क्रिप्टो और 50+ NFTs समर्थित',
    home_banner_3: 'डीसेंट्रलाइज्ड बेटिंग',
    home_banner_4: 'डेसेंट्रलाइज्ड वॉलेट से स्मार्ट कॉन्ट्रैक्ट बेटिंग',
    home_banner_5: 'बेट 2 कमाई',
    home_banner_6: 'स्थिर लाभ के लिए बेट, खनन और निवेश करें',
    home_banner_7: 'Web3.0 + कैसीनो',
    home_banner_8: 'खिलाड़ी भी बैंकर और मालिक हैं',

    home_EventDetails: 'विवरण',

    home_vip_1: "1.वापर वापरता आय मिळवा+",
    home_vip_2: "2.चक्र वापरता आय मिळवा+",
    home_vip_3: "3.सामायिक मारण्याद्वारे आय मिळवा+",
    home_vip_4: "4.पुनर्जीवितपणे आय मिळवा+",
    home_vip_5: "5.चॅट उघडा, प्रतिदिन संदेश पाठवू शकता",
    home_vip_6: "संदेश",

    
    ERROR_628: 'उपहार कोड आधीच घेतला जातला आहे',
    ERROR_629: 'उपहार कोड पूर्णपणे घेतला गेला आहे',
    ERROR_630: 'उपहार कोड सक्रिय नाही',
    ERROR_631: 'उपहार कोड संपला आहे',
    ERROR_633: 'इतर प्रतिनिधीच्या उपहार कोडाचा वापर करण्याची संधी नाही',

    home_Maintaining_1: "अभिरक्षण",
    home_Maintaining_2: "ही सुविधा अभिरक्षणात आहे",
    home_Maintaining_3: "समाप्त",
    home_Maintaining_4: "सुरू करा",

    home_Slots: 'स्लॉट्स',
    home_LiveCasino: 'लाइव कॅसिनो',
    home_CountryFiat: 'देश आणि फिएट',
    home_CryptNFTs: 'क्रिप्ट आणि NFT',

    home_GoTo:'जा',

    home_gameout_1: "आपण वर्तमानपत्रित इतर खेळामध्ये आहात का? बाहेर पडण्याची इच्छा आहे का?",
    home_gameout_2: "रद्द करा",

     home_BuyFund1:'ग्रोथ फंड खरेदी करू आहेस का?',

     home_BetFor:'साठी दांडा',

     tongzhi_1: "सुचना",
     tongzhi_2: "सुचना आणि प्रतिनिधीला प्रतिसाद द्या.",
     tongzhi_3: "छान काम! खूप छान आहे.",
     tongzhi_4: "पुनरावलोकन सदर आहे.",
     tongzhi_5: "पुनरावलोकन मान्य आहे.",
     tongzhi_6: "भरणा प्रक्रिया चालू आहे.",
     tongzhi_7: "भरणा सफळतेने पूर्ण झाले.",
     tongzhi_8: "पुनरावलोकन नकारला.",
     tongzhi_9: "भरणा अयशस्वी झाले.",
     tongzhi_10: "विळखाव अयशस्वी झाले.",
     tongzhi_11: "दिले गेले आहे.",
     tongzhi_12: "पूर्ण झाले.",
     tongzhi_13: "क्रियान्विती अयशस्वी.",
     tongzhi_14: "खरेदी करा",
     tongzhi_15: "पर्यंत",
     tongzhi_16: "काढा",
     tongzhi_17: "क्रिप्टोकरेंसी",
     tongzhi_18: "फिएट मुद्रा",
     tongzhi_19: "डाउनलोड ",
     tongzhi_20: "लॉगिन केल्यास पुरस्कारित केले जाऊ शकते",
     tongzhi_21: "डाउनलोड",
     tongzhi_22: "सध्या iOS आवृत्ती उपलब्ध नाही",
     tongzhi_23: "पाठवा",
     tongzhi_24: "सुचना पाठवा",
    
     home_banner_9: "घोड्याच्या धावा",
     home_banner_10: "कॉकफाइटिंग",
     home_banner_11: "आवश्यक आणि तितक्याच तितक्याच, मोठे पुरस्कार जिंका",
     home_banner_12: "उत्साहित आणि उत्साही",
     
     home_wanfa_1:"खेळाची समस्या",
     home_wanfa_2:"हस्तांतरण समस्या",
     home_wanfa_3:"इतर समस्या",
     play_not_balance: "eUSDT सहित क्रीडा बेट करणे असंभव आहे. आपल्याला दुसर्या व्यवस्थापनेत बदलायचं आहे का?",
    

    };
    
    export default locale;
    
    
