  const locale = {
  GAME: 'Pelit',
  Casino: "Kasino",
  ProvablyFair: "Provably fair",
  Option: 'Vaihtoehto',
  Crypto: 'Krypto',
  Forex: 'Forex',
  Stocks: 'Varastot',
  NFTFuniCard: 'NFT FuniCard',
  StakeNFT: 'Panos NFT ansaita USDT',
  NFTStake: 'NFT Panos',
  Marketplace: 'Markkinapaikka',
  NFTMint: 'NFT Mint',
  BettoEarn: 'Vedonlyönti ansaita',
  EarnUSGT: 'Ansaita USDT',
  DeFi: 'DeFi',
  ToBeVolunteer: 'ser voluntário',
  SYSTEM: 'JÄRJESTELMÄ',
  Affiliate: 'Liittyvä yritys',
  News: 'Uutiset',
  FAQs: 'UKK',
  DOWNLOAD: 'LATAA',
  DOWNLOADApp: 'Lataa App',
  Savetodesktop: 'Tallenna työpöydälle',
  BetMining: 'Veto kaivostoiminta',
  ToBeBanker: 'Pankin virkaan',
  ProfitPool: 'Voittopotti',
  USGTLiquidityMining: 'USDT Liquidity Mining',
  BGTLiquidityMining: 'BGT Liquidity Mining',
  Slots: 'Lähtöpaikat',
  Favorite: 'Suosikki',
  Recent: 'Viimeaikainen',
  Popular: "Suosittu",
  LiveCasino: "Live-kasino",
  TableGames: "Pöytäpelit",
  BlackJack: "Musta-Jack",
  Baccarat: "Baccarat",
  Roulette: "Ruletti",
  MoreGames: "Lisää pelejä",
  Provablyfair: "Provably fair",
  Special: "Erityinen",
  Recommend: "Suositella",
  FeatureBuyin: "Osta toiminto",
  EnableTrialPlay: "demo",
  GameShow: "Pelimuoto",
  RGSVSB: "RGS - VSB",
  Scratch: "Naarmuta",
  Bingo: "Bingo",
  Fishing: "Kalastus",
  Turntable: "Pyöritä",
  Dice: "Noppa",
  Poker: "Pokeri",
  AllGames: "Kaikki pelit",
  EuropeanView: "Eurooppalainen ",
  AfricanView: "Afrikkalainen ",
  LatinView: "Latinalainen ",
  AsianView: "Aasian katsomo",
  LsportView: "Liiga katsomo",
  StandardView: "Standardikatsomo",
  EsportView: "E-urheilu katsomo",
  LiveBetting: 'Live-vedonlyönti',
  Provider: 'Tarjoaja',
  Sport: "Urheilu",

};

export default locale;
