const locale = {
    GAME: 'ဂိမ်းများ',
    Casino: 'ကာစီနို',
    ProvablyFair: 'ကြီးပြာမွန်သော ဂိမ်းများ',
    Option: 'ရွေးချယ်ခြင်း',
    Crypto: 'ဂွေယောင်စု',
    Forex: 'ဖော်ပြန်စာ',
    Stocks: 'ဘဏ်စီးပွား',
    NFTFuniCard: 'NFT ငွေပေးကျစရာ',
    StakeNFT: 'NFT ပေးရန် USDT ရရှိမှု',
    NFTStake: 'NFT ပေးရန်',
    Marketplace: 'အရောင်းအဝယ်ကုန်စီ',
    NFTMint: 'NFT ရေထုထည့်ရန်',
    BettoEarn: 'ဖြန့်ဝှက်ခြင်းကိုရှာရန်',
    EarnUSGT: 'USDT ကိုရရှိရန်',
    DeFi: 'ဒီနှင့်ချိုးမြောင်းအဖြစ်',
    ToBeVolunteer: 'လှူဒါန်းသမားရှိရန်',
    SYSTEM: 'စနစ်',
    Affiliate: 'အသုံးပြုသူ',
    News: 'သတင်းများ',
    FAQs: 'မေးခွန်းများများ',
    DOWNLOAD: 'ဒေါင်းလုပ်ရန်',
    DOWNLOADApp: 'အက်ပ်ကိုဒေါင်းလုပ်ပါ',
    Savetodesktop: 'ဌာနရသို့သိမ်းဆည်းပါ',
    BetMining: 'ဘဏ်အစုပေးရန်',
    ToBeBanker: 'ဘဏ်ရောက်သွားရန်',
    ProfitPool: 'အစာရင်းအစာကိုရေင်းပြန်ခြင်း',
    USGTLiquidityMining: 'USDT လျှော့ချက်အရွယ်ဆွဲမှု',
    BGTLiquidityMining: 'BGT လျှော့ချက်အရွယ်ဆွဲမှု',
    Slots: 'ရွှေ့ဆိုင်အမျိုးအစား',
    Favorite: 'အကြိုကြိုကြီး',
    Recent: 'နောက်ဆုံး',
    Popular: 'ထက်စြာကြီး',
    LiveCasino: 'တွဲမြောက်ကစားနှင့်မြှောက်လာကောင်း',
    TableGames: 'စားနှစ်ကားများ',
    BlackJack: 'ဘီအ်ဂျက်',
    Baccarat: 'ဘက်ရြတ်',
    Roulette: 'ရွေးလုန်',
    MoreGames: 'ပိုမိုဂိမ်းများ', 
    Provablyfair: 'အကြောင်းအလဲများရှင်းစားရန်',
    Special: 'အထူး',
    Recommend: 'အကြံပြုရန်',
    FeatureBuyin: 'အကြောင်းအားတက်သင့်ကို',
    EnableTrialPlay: 'အကြှနျုပ်ကိုဖွငျ့ပါ',
    GameShow: 'ဂိမ်းမြောက်ပါ',
    RGSVSB: 'RGS - VSB',
    Scratch: 'ကျောက်ပြန်',
    Bingo: 'ဂျင်းသန်း',
    Fishing: 'အင်ဂျင်း',
    Turntable: 'လက်ခံမြောက်',
    Dice: 'သံလိုက်',
    Poker: 'ပုကိုက်',
    Sport: 'အားကစား',
    AllGames: 'အားလုံးဂိမ်းများ',
    EuropeanView: 'ဥရောပ',
    AfricanView: 'အာဖရိက',
    LatinView: 'လတင်း',
    AsianView: 'အရှေ့',
    LsportView: 'လုံခြုံရေး',
    StandardView: 'စတိုးစက်',
    EsportView: 'အကျိုးကျေးရွာ',
    LiveBetting: 'တိုက်ရိုက်လုပ်ဆောင်ခြင်း',
    Provider: 'ပေးရန်',
};

export default locale;
