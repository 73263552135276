// 公共方法
export const getUrlParam = (param) => {
    const res = window.location.href;
    const URL = res.split('?')[1];
    if (!URL) {
        return
    }
    let obj = {}; // 声明参数对象
    let arr = URL.split("&");
    for (let i = 0; i < arr.length; i++) {
        let arrNew = arr[i].split("=");
        obj[arrNew[0]] = arrNew[1];
    }
    return obj[param]
};

export const arrayLookup = (data, key, value, targetKey) => {
    var targetValue = "";
    if (data) {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] == value) {
                targetValue = data[i][targetKey];
                break;
            }
        }
    }
    return targetValue;
};

export const randomString = (length) => {
    var str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i)
        result += str[Math.floor(Math.random() * str.length)];
    return result;
};

export const judgeIosOrAndroid = () => {
    var u = navigator.userAgent
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
    if (isiOS) {
        return 'ios'
    } else if (isAndroid) {
        return 'android'
    }
};

// 判读是否是手机
export const isMobile = () => {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        return true
    } else {
        return false
    }
};


export const changeLanguage = (countryLang, lang) => {
    const tempLang = [];
    Object.keys(countryLang).map((item) => {
        if (countryLang[item].langCode == lang) {
            tempLang.push(item)
        }
    })
    if (tempLang.length > 0) {
        return tempLang[0];
    } else {
        return Object.keys(countryLang)[0];
    }
};



// 读取ID将滚动条置底
export const scrollHeightDi = () => {
    var div = document.getElementById("liaoTianKuang");
    div.scrollTop = div.scrollHeight;
};



// 全屏
export const gotoFullscreen = (dom) => {
    if (dom.requestFullscreen) {
        dom.requestFullscreen()
    } else if (dom.mozRequestFullScreen) {
        dom.mozRequestFullScreen()
    } else if (dom.webkitRequestFullscreen) {
        dom.webkitRequestFullscreen()
    } else if (dom.msRequestFullscreen) {
        dom.msRequestFullscreen()
    } else {
        console.error('当前浏览器不支持部分全屏！')
    }
}

// 退出全屏
export const exitFullscreen = (dom) => {
    if (dom.exitFullscreen) {
        dom.exitFullscreen()
    } else if (dom.webkitExitFullscreen) {
        dom.webkitExitFullscreen()
    } else if (dom.msExitFullscreen) {
        dom.msExitFullscreen()
    }
}

export const judgeLangFontSize = (langArr) => {
    let currentLang = window.localStorage.getItem("lang");

    let langArrLength = langArr.length;
    let result = false;
    for (let i = 0; i < langArrLength; i++) {
        if (langArr[i] == currentLang) {
            result = true
            break;
        }
    }

    return result
}

// 获取接口返回的错误码
export const getErrorCode = (errorCode) => {
    // [=10184]
    let res = '';
    if (errorCode.length <= 0) {
        return res
    }
    res = errorCode.replace('[=', '').replace(']', '');

    return res
}


