import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    searchCasinoChannel: '', //搜索供应商
};

const searchCasinoChannelSlice = createSlice({
    name: 'searchCasinoChannel',
    initialState,
    reducers: {
        updateSearchCasinoChannel: (state, action) => {
            let res = action.payload;
            state.searchCasinoChannel = res;
        }
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateSearchCasinoChannel } = searchCasinoChannelSlice.actions;

export const selectSearchCasinoChannel = ({ searchCasinoChannel }) => searchCasinoChannel;

export default searchCasinoChannelSlice.reducer;
