const locale = {
    home_StakeNFT: 'より多くを獲得するためにNFTをステーキングする',
    home_everyday: '毎日',
    home_Beton: 'ベット',
    home_mining: 'マイニング',
    home_shareholder: '株主になる',
    home_Stake: 'ステーキング',
    home_dividend: '利益配当を受け取る',
    home_Banker: 'パートナーになる',
    home_SIGNUPAND: '今すぐ登録',
    home_GET: '入手する',
    home_UPTO: '合計',
    home_FuniHash: 'ブロックチェーンゲーム',
    home_Option: 'オプション',
    
    home_Reward: ' 報酬',
    home_Reward1: '報酬',
    home_signUpNow: '今すぐ登録',
    home_Casino: 'カジノ',
    home_Sports: 'スポーツ',
    home_PROMOTION: 'キャンペーン',
    home_Checkin: 'チェックイン',
    home_Bouns: 'ボーナス',
    home_CheckinBouns: 'チェックインボーナス',
    home_SPIN: 'スピン',
    home_Relief: '救済',
    home_Share: 'シェア',
    home_Deposit: '入金',
    home_Fund: 'ファンド',
    home_VIPClub: 'VIPクラブ',
    home_FastAndEasy: '高額なUSDTデポジットボーナスを簡単かつ迅速に仮想通貨で購入することができます。',
    home_Game: 'ゲーム',
    home_User: 'ユーザー',
    home_Time: '時間',
    home_BetAmount: '賭け金額',
    home_Multiplier: '乗数',
    home_Payout: '払戻し',
    home_JoinOur: 'スポーツファン、暗号通貨愛好家、そしてギャンブラーのコミュニティに参加してください。',
    home_JOIN: '参加する',
    home_LICENSES: 'ライセンス',
    home_OurPlatforms: '私たちのプラットフォーム',
    home_Marketplac: 'マーケットプレイス',
    home_Howtoearn: '収益の出し方',
    home_Games: 'ゲーム',
    home_WhitePaper: 'ホワイトペーパー',
    home_InvestorDeck: '投資家向けプレゼンテーション',
    home_News: 'ニュース',
    home_Aboutsus: '私たちについて',
    home_Industry: '産業',
    home_Roadmap: 'ロードマップ',
    home_Partner: 'パートナー',
    home_FAQs: 'よくある質問',
    home_Affiliate: 'アフィリエイト',
    home_Stayintouch: '連絡を取り合う',
    home_ChatRoom: 'チャットルーム',
    home_Selectacontact: 'チャット相手を選んでください。',
    home_BetSlip: 'ベットスリップ',
    home_Typeyourmessage: 'メッセージを入力してください。',
    home_AcceptAnyOdds: 'どのオッズでも受け入れる',
    home_ClearAll: 'すべてクリア',
    home_Live: 'ライブ',
    home_Winner: '勝者',
    home_EstPayout: '支払い見積もり',
    home_TotalOdds: '総オッズ',
    home_RegistertoBet: 'ベット登録する',
    home_Single: '単一のベット',
    home_Multi: 'マルチベット',
    home_Checkineveryweek: '毎週チェックインすると、それに応じた報酬が得られます。',
    home_Day: '日',
    home_Sunday: '第1日',
    home_Monday: '第2日',
    home_Tuesday: '第3日',
    home_Wednesday: '第4日',
    home_Thursday: '第5日',
    home_Friday: '第6日',
    home_Saturday: '第7日',
    home_FreeSpin: 'スピンを回す',
    home_Turntheturntable: '毎日スピンを回すと、USGTの報酬が得られます。',
    home_DepositFift: '入金ボーナス',
    home_ANYAMOUNT: 'どの金額でも、初回入金に対してボーナスを受け取れます。',
    home_DEPOSIT: '入金',
    home_First: '初回',
    home_ONLY: 'たった',
    home_1ST: '1回目',
    home_2ND: '2回目',
    home_3TH: '3回目',
    home_ANYAMOUNT: '何でもOK!',
    home_EXTRA: '追加',
    home_Relief: '救済金',
    home_Getrelief: '救済金を受け取り、再起のチャンスを手に入れましょう。',
    home_YOUCANCLAIM: '残高が',
    home_YOUCANReceive: 'になった場合、救済金を受け取ることができます',
    home_threeTimes: ' 3回',
    home_everydayTimes: '/毎日!',
    home_Times: '回数：',
    home_TotalAssets: '総資産',
    home_receive: '受け取る',
    home_TODAY: '今日',
    home_Share: '共有',
    home_PLATFORMSHARING: '他のプラットフォームと共有する',
    home_Sharewith: 'FuniBetをシェアして獲得する',
    home_SHARINGGIFT: '共有ギフト',
    home_CODE: '共有コード',
    home_Selectplatform: '共有プラットフォームを選択：',
    home_Fund: '成長基金',
    home_FundBonus: '成長基金の報酬',
    home_Improveyour: 'VIPランクを向上させ、より多くの資金報酬を毎日獲得してください。',
    home_BuyFund: '買う',
    home_Receivingconditions: '受取条件',
    home_Receivingstatus: '受取状況',
    home_Received: '受け取り済み',
    home_noReceived: '未達成',
    home_ExclusiveBenefits: '独占的な特典',
    home_Asawayof: 'VIPプレイヤーへの感謝と賞賛を表すために、FuniBetはVIPクラブへの参加をお勧めします。そこには豊富なギフト、プレゼント、より高いキャッシュバック、そしてユニークな機能があります。エキサイティングで驚くべきすべてを見逃さないでください！',
    home_Yourcurrent: '現在の総チャージ金額',
    home_WhyUpgrade: 'VIPにアップグレードする理由は何ですか？',
    home_VIPhave: 'VIPになると、専属の管理者が24時間365日サポートしてくれるので、基本的なキャッシュバックとチャージ以外にも、最高水準の回転数、毎週/毎月のボーナス、最速の入金/出金チャネルなど、最高水準のサービスを提供します。 VIPは、トッププレイヤー交流会に参加し、FuniBetチームに新しい特徴や機能を提供することでカジノを構築する機会を得ることができます。',
    home_UpgradeYour: 'あなたのVIPレベルをアップグレードする',
    home_ASaFunibet: 'FUNIBet VIPクラブのメンバーとして、豊富な報酬と個別のギフトを提供しますが、それだけではありません。プレイするほど、VIPレベルを上げてエリートVIPメンバーになると、より良い贅沢な特典を提供します。 FUNIBETは、あなたに合わせたVIP特権を提供します。',
    home_YOUCANREACH: 'あなたは次のレベルにアップグレードできます',
    home_LEVELS: 'レベル',
    home_eachthehigh: '全員が25％のキャッシュバックボーナスを受け取り、プラットフォームのすべての利点を享受することができます。 FUNIBETに参加して、最高のゲーム体験と高品質のサービスをお楽しみください！',
    home_Cashback: 'キャッシュバック',
    home_Nowater: '1. ゲーム中に水を取りません。',
    home_Moregrowth: '2. より多くの成長基金',
    home_Increasein: '3. 福利の数量が増えます。',
    home_Doublethenumber: '4. ルーレットの数が2倍に増えます。',
    home_Doublethesign: '5. サインアップの数が2倍に増えます。',
    home_Doublesharingrewards: '6. 共有報酬が2倍に増えます。',
    home_DoubletheAffiliate: '7. アフィリエイト報酬が2倍に増えます。',
    home_Rechargerewardamount: '8. チャージ金額が1.5倍になります。',
    home_ABOUTVIPCLUB: 'VIPクラブについて',
    home_HightopRank: '高位ランクには25％のキャッシュバックボーナスと最高の特典があります。BeDaoに入居して、最高のゲーム体験と高水準のサービスをお楽しみください！',
    home_Beingamember: 'VIPクラブのメンバーになることは、以下にアクセスできることを意味します：',
    home_PersonalVIPmanager: 'プロのマネージャー',
    home_Insights: '洞察力',
    home_Highercahbackrewards: 'より高いキャッシュバック報酬',
    home_Otheradditionalperks: 'その他の追加特典',
    home_WeeklyandMonthlyBonuses: '週間および月次ボーナス',
    home_Showhighgaming: 'ハイエンドのゲームアクティビティを表示し、VIPクラブへの招待を受け取ります。',
    home_BindMobile: 'バインドする',
    home_Pleasecheckthe: 'ゲームから取得したUSGTが財布にあることを確認してください。',
    home_BINDMOBILENUMBER: '携帯電話番号をバインドする',
    home_Get1USDT: '1USDTを取得する',
    home_Bind: 'バインド',
    home_GiftCode: 'ギフトコード',
    home_EnteryourGiftCode: 'ギフトコードを入力してください',
    home_EnterCode: 'コードを入力',
    home_Paste: '貼り付ける',
    home_confirms: '確認',
    home_BuyCrypto: '購入する',
    home_SignedIn: 'ログイン済み',
    home_CommissionRules: '手数料ルール',
    home_AffiliateTerms: 'アフィリエイト規約',
    home_AffiliateDashboard: 'アフィリエイトダッシュボード',
    home_AFFILIATE: 'アフィリエイト',
    
    

    home_COMMISSONREADYEVERYDAY: 'いつでも準備ができている紹介手数料$1,000',
    home_MYREFERRALCODE: '私の紹介コード',
    home_MYREFERRALLink: '私の紹介リンク',
    home_AffiliateReward: 'アフィリエイト報酬',
    home_EXTRA: '追加',
    home_USDREWARDS: 'USD報酬',
    home_USDRewardsRules: 'USD報酬規則',
    home_JUSAREFERRALAWAY: 'ただの紹介',
    home_NewlyAvailableRewards: '最新の報酬',
    home_ReceivedMao: '受け取った金額：',
    home_Locked: 'ロックされました：',
    home_Yourcurrentfriends: '現在の友達がアクティブである場合、より多くの報酬がロック解除されます',
    home_Withdraw: '引き出し',
    home_MY: '私の',
    home_COMMISSIONREWARDS: '紹介手数料報酬',
    home_CommissionRewardRules: '紹介手数料報酬規則',
    home_NewlyAvailableCommissionRewards: '新しい紹介手数料報酬',
    home_CommissionYouhaveReceivedinTotal: '受け取った紹介手数料：',
    home_REWARDSSENTTODATE: '今までに送られた報酬',
    home_USDrewardssenttodate: '迄今までに送られたUSD報酬',
    home_Commissionsenttodatet: '佣金送信日',
    home_Areyouablogger: 'ブロガーですか？多くの読者やフォロワーを持っていますか？私たちは、特別な条件を備えた独自のプログラムを作成し、より大きな紹介報酬を提供する特別なアフィリエイトプログラムを提供しています。マネージャーにお問い合わせいただき、条件を話し合ってください。',
    home_onlythoseusers: '重要なお知らせ：管理者の要請と承認を受けたユーザーのみがこのプログラムに参加できます。',
    home_notice: 'お知らせ',
    home_bindPhone: '電話番号をバインド',
    home_SignIn: 'ログイン',
    home_SignUp: '登録する',
    home_SignOut: 'ログアウト',
    home_CookiesPolicy: 'Cookieポリシー',
    home_isUsingCookies: 'Funibet.comは、すべてのユーザーにより良いサービスを提供するためにCookieを使用しています。当社のウェブサイトを使用することで、Cookieの使用に同意したことになります。プライバシーポリシーおよびサービス利用規約をご覧ください。',
    home_SETTING: '設定',
    home_LiveSupport: 'オンラインサポート',
    home_Accept: '同意する',
    home_FrequentlyAskedQuestions: 'よくある質問',
    home_SiteUnnderConstruction: 'サイト建設中',
    home_ComingSoon: '近日公開予定',
    home_Wallet: 'Wallet',
    home_UserId: 'ID：',
    home_UserName: '名前：',
    home_turnTimes: '回転回数：',
    home_Bonusincreased: "ボーナス追加+",
    home_bonus: 'ボーナス',
    home_SPINBONUSTOTAL: 'スピンボーナス総額',
    home_WIN: 'WIN:',
    home_In: '〜で',
    home_FirstDepositBonus: '初回入金ボーナス',
    home_Hi: 'こんにちは',
    home_welcomeAboard: 'お帰りなさい',
    home_Outoftime: '本日の回転回数がすべて使用されました',
    
    game_search: '検索',
    game_SortBy: '並び替え',
    game_Created: '作成日時',
    game_Lobby: 'ロビー',
    game_LiveCasino: 'ライブカジノ',
    game_FeaturedSlots: 'おすすめスロット',
    game_GameShows: 'ゲームショー',
    game_FuniBetOriginals: 'FuniBetオリジナル',
    game_BigWins: 'ビッグウィン',
    game_LcukyWins: 'ラッキーウィン',
    game_Challenges: 'チャレンジ',
    game_Description: '説明',
    game_Slots: 'スロット',
    game_Rank: 'ランク',
    game_User: 'ユーザー',
    game_Date: '日時',
    game_BetAmount: 'ベット金額',
    game_lucky: '最大乗数',
    game_Multiplier: '乗数',
    game_Payout: '払い戻し',
    game_Recommend: 'おすすめ',
    game_Providers: 'プロバイダー',
    game_startGame: '始める',
    game_balance: '残高',
    game_provider: 'タグ',
    game_Selectyourdisplay: '表示する残高を選択',
    game_Balancein: '残高',
    game_FunPlay: 'フリープレイ',
    game_RealPlay: 'リアルプレイ',
    game_DispLaying: "表示中",
    game_LoadMore: "もっと読み込む",
    game_NoData: 'データがありません',
    
    message_SigningIn: "JWTでサインイン中",
    message_CourseSaved: "コースが保存されました",
    message_PleaseBind: "まず携帯電話をバインドしてください",
    message_UserData: "ユーザーデータがAPIで保存されました",
    message_Success: "成功",
    message_VIPlevel: "VIPレベルがV3未満です",
    message_ListOrder: "リストオーダーが保存されました",
    message_CardOrder: "カードオーダーが保存されました",
    message_HiHow: "こんにちは、お元気ですか？",

    Funibet_web: 'Funibet-Web3.0,暗号通貨,ベット,スポーツ,Eスポーツ,スロット,ライブカジノ,ポーカー',

    home_ThereAre:"現時点で通知はありません。" ,

    play_no_balance: "現在の通貨残高が不十分です。通貨を切り替えるか、チャージしてください。",

    home_News_1: 'ニュース',
    home_News_2: 'イベント',
    home_News_3: '暗号通貨',
    home_News_4: 'ゲーム',
    
    home_SuggRep_1: '提案と報告',
    home_SuggRep_2: '提案と報告',
    home_SuggRep_3: '改善のためのフィードバックを共有してください！',
    home_SuggRep_4: 'ゲーム内でウェブサイトを販売する報告',
    home_SuggRep_5: '提案を入力するか、報告を送信する',
    home_SuggRep_6: 'サンプルスクリーンショット',
    home_SuggRep_7: '採用された提案や報告に対する報酬！',
    home_SuggRep_8: '提出する',
    
    home_subscription_1: 'プッシュ通知を購読しますか？',
    home_subscription_2: 'いつでも購読を解除できます。',
    home_subscription_3: 'WonderPushのプッシュ通知',
    home_subscription_4: '後で',
    home_subscription_5: '購読する',
    
    home_Affiliate_1: 'お問い合わせ',
    home_Affiliate_2: '招待した友達1人につき10Uがもらえます。招待する人が多いほど、もらえるポイントが増えます！',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: 'Eメール',

    home_banner_1:'クリプトゲーム',
    home_banner_2:'100種類以上の暗号通貨と50種類以上のNFTをサポート',
    home_banner_3:'分散型ベッティング',
    home_banner_4:'分散型ウォレットからのスマートコントラクトベッティング',
    home_banner_5:'Bet 2 Earn',
    home_banner_6:'安定した利益のためのベット、マイニング、投資',
    home_banner_7:'Web3.0 + カジノ',
    home_banner_8:'プレイヤーはバンカーでありオーナーでもある',
    
    home_EventDetails:'詳細',

    home_vip_1: "1.収益をチェックインで獲得+",
    home_vip_2: "2.収益を回転で獲得+",
    home_vip_3: "3.収益を共有で獲得+",
    home_vip_4: "4.収益をリチャージで獲得+",
    home_vip_5: "5.チャットが開放され、毎日メッセージを送信できます",
    home_vip_6: "メッセージ",

    
    ERROR_628: 'ギフトコードはすでに使用されています',
    ERROR_629: 'ギフトコードは完全に使用されています',
    ERROR_630: 'ギフトコードは有効ではありません',
    ERROR_631: 'ギフトコードの有効期限が切れています',
    ERROR_633: '他のエージェントのギフトコードは使用できません',

    home_Maintaining_1: "メンテナンス",
    home_Maintaining_2: "この機能はメンテナンス中です",
    home_Maintaining_3: "終わり",
    home_Maintaining_4: "始める",

    home_Slots: 'スロット',
    home_LiveCasino: 'ライブカジノ',
    home_CountryFiat: '国と法定通貨',
    home_CryptNFTs: '暗号通貨とNFT',

    home_GoTo:'行く',

    home_gameout_1: "他のゲームに参加中ですか？終了しますか？",
    home_gameout_2: "キャンセル",

     home_BuyFund1:'成長ファンドを購入しますか?',

     home_BetFor:'に賭ける',

     tongzhi_1: "提案",
     tongzhi_2: "提案と代表への返信。",
     tongzhi_3: "お疲れ様！これは素晴らしいです。",
     tongzhi_4: "審査待ち。",
     tongzhi_5: "審査承認済み。",
     tongzhi_6: "支払い処理中。",
     tongzhi_7: "支払い完了。",
     tongzhi_8: "審査不承認。",
     tongzhi_9: "支払い失敗。",
     tongzhi_10: "引き出し失敗。",
     tongzhi_11: "支払い済み。",
     tongzhi_12: "完了。",
     tongzhi_13: "処理失敗。",
     tongzhi_14: "購入",
     tongzhi_15: "まで",
     tongzhi_16: "引き出す",
     tongzhi_17: "暗号通貨",
     tongzhi_18: "法定通貨",
     tongzhi_19: "ダウンロード",
     tongzhi_20: "ログインすると報酬がもらえます",
     tongzhi_21: "ダウンロード",
     tongzhi_22: "現在iOSバージョンは利用できません",

     home_banner_9: "競馬",
     home_banner_10: "闘鶏",
     home_banner_11: "刺激的でエキサイティング、大きな賞金を獲得",
     home_banner_12: "情熱的で闘志に満ちて",
     play_not_balance: "eUSDTを使用したスポーツベッティングはできません。通貨を切り替えますか？",


    };
    
    export default locale;
    
    
