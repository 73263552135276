import "../../../styles/index.css";
import "../../../styles/xianStyle.css";
import utils from "../../util/tools/utils";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { getNewsList } from "../../store/config/configThunk";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState, useEffect, forwardRef, default as React } from "react";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import history from "@history";
import { selectCasinoData } from "../../store/casino";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../../store/user/userThunk";
import { selectUserData } from "../../store/user";
import AnimateModal from "../../components/FuniModal";
import {
  getSignInConfig,
  getTurnConfig,
  queryBaseEnsureConfig,
  querySignInInfo,
  signInDone,
  doTurn,
} from "../../store/activity/activityThunk";
import { changeLanguage } from "../../util/tools/function";
import countryLang from "../../json/country";

function Subscription(props) {
  const { t } = useTranslation("mainPage");
  const [isHaveSubscription, setIsHaveSubscription] = useState(
    window.localStorage.getItem("isHaveSubscription") || true
  );
  const dispatch = useDispatch();
  const [notificationLists, setNotificationLists] = useState([]);
  const [permis, setPermis] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [isTimeDely, setIsTimeDely] = useState(false);

  const saveSubscription = () => {
    setTimeout(() => {
      setIsClickSubscription(true)
    }, 400)

  };

  useEffect(() => {
    setTimeout(() => {
      setIsTimeDely(true)
    }, 6000)
  }, []);

  useEffect(() => {
    if (Notification?.permission == "granted") {
      setIsHaveSubscription(true);
    } else {
      setIsHaveSubscription(false);
    }
  }, []);
  const getNotifications = () => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          console.log("用户已授权接收推送通知！");
          setPermis("granted");
          dispatch(
            getNewsList({
              classID: '1,2',
              lang: changeLanguage(countryLang, window.localStorage.getItem('lang')),
              brush: true
            })
          ).then((res) => {
            setTimeout(() => {
              setNotifications(res.payload);
              for (var i = 0; i < res.payload.length; i++) {
                var notification = new Notification(res.payload[i].subject, {
                  body: res.payload[i].subject1,
                  icon: "/icon.png",
                });
                // notification.onclick = function () {
                //   console.log("用户单击了通知。");
                //   window.focus();
                //   this.close();
                // };
                // notification.onclose = function () {
                //   console.log("通知已关闭。");
                // };
              }
            }, 1500)

          });
        } else {
          console.log("用户未授权接收推送通知。");
        }
      });
    }
    setTimeout(() => {
      setIsHaveSubscription(true);
    }, 400)
  };

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  const [isClickSubscription, setIsClickSubscription] = useState(false);

  return (
    <>
      {(!isHaveSubscription && isTimeDely) && (
        <div
          id="subscriptionPage"
          style={{
            position: "fixed",
            top: "0%",
            left: "0%",
            right: "0%",
            zIndex: "999",
            margin: "0 auto",
          }}
          className={clsx(
            "subscriptionSty",
            isClickSubscription && "phoneMove4"
          )}
        >
          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className="subscriptionDiv"
          >
            <motion.div variants={item} className="">
              <div className="subscriptMg flex">
                <div
                  style={{
                    width: "66px",
                    height: "66px",
                    borderRadius: "10px",
                    backgroundColor: "#334155",
                  }}
                >
                  <img
                    style={{ width: "50px", height: "50px", margin: "8px 8px" }}
                    src={`${React.$imgUrl}/images/logo/logo-prefix.png`}
                  ></img>
                </div>
                <div>
                  <div className="subscriptionTxt1 ml-10">
                    {t("home_subscription_1")}
                  </div>
                  <div className="subscriptionTxt2 ml-10">
                    {t("home_subscription_2")}
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div
              variants={item}
              className="cookies-list1 mt-4 subscriptionMg2"
            >
              <div
                className="subscriptionTxt2 mt-12 displayPhoneSwitch1"
                style={{ color: "#B9BBC0" }}
              >
                {t("home_subscription_3")}
              </div>
              <div className="flex phoneSubscriptionMg">
                <Button
                  className="whitespace-nowrap borderRadius px-24 funbetTxt txtBrightness"
                  variant="contained"
                  color="secondary"
                  style={{ fontSize: "14px", backgroundColor: "#334155" }}
                  onClick={() => {
                    // saveSubscription()
                    // setIsHaveSubscription(true);
                    setIsClickSubscription(true);
                  }}
                >
                  {t("home_subscription_4")}
                </Button>
                <Button
                  className="whitespace-nowrap borderRadius px-24 ml-10 funbetTxt"
                  variant="contained"
                  color="secondary"
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    // saveSubscription()
                    setIsClickSubscription(true);
                    getNotifications();
                  }}
                >
                  {t("home_subscription_5")}
                </Button>
              </div>
            </motion.div>
          </motion.div>
        </div>
      )}
    </>
  );
}
export default Subscription;
