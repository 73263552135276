const locale = {
    GAME: 'کھیل',
    Casino: 'کیسینو',
    ProvablyFair: 'پروویبلی فیئر',
    Option: 'اختیار',
    Crypto: 'کرپٹو کرنسی',
    Forex: 'فاریکس',
    Stocks: 'اسٹاکس',
    NFTFuniCard: 'این ایف ٹی فنی کارڈ',
    StakeNFT: 'اسٹیک این ایف ٹی کرنے سے USDT کمائیں',
    NFTStake: 'اس این ایف ٹی کرنے سے USDT کمائیں',
    Marketplace: 'مارکیٹ پلیس',
    NFTMint: 'این ایف ٹی کو دھات بنانا',
    BettoEarn: 'شرط لگائیں اور کمائیں',
    EarnUSGT: 'USDT کمائیں',
    DeFi: 'ڈیفائی',
    ToBeVolunteer: 'رضاکاری کرنے والے',
    SYSTEM: 'سسٹم',
    Affiliate: 'وابستہ کرنے والے',
    News: 'خبریں',
    FAQs: 'عمومی سوالات',
    DOWNLOAD: 'ڈاؤن لوڈ',
    DOWNLOADApp: 'اپلیکیشن ڈاؤن لوڈ کریں',
    Savetodesktop: 'ڈیسک ٹاپ میں محفوظ کریں',
    BetMining: 'شرط مائننگ',
    ToBeBanker: 'بینکر بنیں',
    ProfitPool: 'منافع کا پول',
    USGTLiquidityMining: 'USDT لکویڈٹی مائننگ',
    BGTLiquidityMining: 'BGT لکویڈٹی مائننگ',
    Slots: 'اسلوٹس',
    Favorite: 'پسندیدہ',
    Recent: 'تازہ ترین',
    Popular: 'مشہور',
    LiveCasino: 'لائیو کیسینو',
    TableGames: 'ٹیبل گیمز',
    BlackJack: 'بلیک جیک',
    Baccarat: 'بکرا',
    Roulette: 'رولیٹ',
    MoreGames: 'مزید کھیلیں',
    Provablyfair: 'پروویبلی فیئر',
    Special: 'خصوصی',
    Recommend: 'تجویز کرتا ہے',
    FeatureBuyin: 'خصوصی خریداری',
    EnableTrialPlay: 'تجربے کی کھیلنے کی اجازت دیں',
    GameShow: 'گیم شو',
    RGSVSB: 'آر جی ایس - وی ایس بی',
    Scratch: 'اسکریچ',
    Bingo: 'بنگو',
    Fishing: 'مچھلی پکڑنا',
    Turntable: 'ٹرنٹیبل',
    Dice: 'پاسا',
    Poker: 'پوکر',
    Sport: 'کھیل',
    AllGames: 'تمام کھیلیں',
    EuropeanView: 'یورپی منظر',
    AfricanView: 'افریقی منظر',
    LatinView: 'لاطینی منظر',
    AsianView: 'ایشیائی منظر',
    LsportView: 'لیگ کی منظر',
    StandardView: 'اسٹینڈرڈ منظر',
    EsportView: 'ای اسپورٹ منظر',
    LiveBetting: 'لائیو بیٹنگ',
    Provider: 'فراہم کنندہ',
    
};

export default locale;
