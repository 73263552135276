import Vip from './Vip';


const VipConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'vip',
            element: <Vip />,
        },
    ],
};

export default VipConfig;