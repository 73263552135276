import ComingSoon from './ComingSoon';

const ComingSoonConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'comingSoon/:type',
            element: <ComingSoon />,
        },
    ],
};

export default ComingSoonConfig;
