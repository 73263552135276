import Affiliate from './Affiliate';


const AffiliateConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'affiliate',
            element: <Affiliate />,
        },
    ],
};

export default AffiliateConfig;