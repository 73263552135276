const locale = {
    home_StakeNFT: 'NFT တစ်ခုကို Stake လုပ်နေသောအကြောင်းရင်းနှုန်းစာမျက်နှာ',
    home_everyday: 'အပြင်မဆုံးနေ့မှာ',
    home_Beton: 'ထွက်ခွားခြင်း',
    home_mining: 'ရေအလင်း',
    home_shareholder: 'မြင်ကွင်းသောရှုံးဆရာမ',
    home_Stake: 'Stake လုပ်ရန်',
    home_dividend: 'အခွန်ကြားရယူရန်',
    home_Banker: 'ဘဏ်ကိုယ်ရေးသူအဖြစ်အားဖြစ်ရပ်သည်',
    home_SIGNUPAND: 'ယခုမှတ်ပုံတင်ရန်',
    home_GET: 'ရယူရန်',
    home_UPTO: 'အကြောင်းဆုံး',
    home_FuniHash: 'ဂျပန်ဘာလူနွေးကွန်း',
    home_Option: 'ရွေးချယ်မှု',

    home_Reward: 'ဆုတောင်းခ',
    home_Reward1: 'ဆုတောင်းခ',
    home_signUpNow: 'အချက်အလက်မှ စတင်ပါ',
    home_Casino: 'ကာစီးရီ',
    home_Sports: 'အားကစား',
    home_PROMOTION: 'စျေးကွက်',
    home_Checkin: 'အချဉ်းတင်မည်',
    home_Bouns: 'ပိုင်ဆိုင်',
    home_CheckinBouns: 'အချဉ်းတင်ပိုင်ဆိုင်',
    home_SPIN: 'ပြင်ဆင်မည်',
    home_Relief: 'အပြင်အဆင်',
    home_Share: 'မျှဝေရန်',
    home_Deposit: 'ငွေလွှဲမည်',
    home_Fund: 'စုစုပေါင်း',
    home_VIPClub: 'VIP ကလေးအသင်း',
    home_FastAndEasy: 'အာကောင်းဆုံး USDT ငွေလွှဲခြင်းပြုပြင်ဆင်မည်',
    home_Game: 'ဂိမ်း',
    home_User: 'အသုံးပြုသူ',
    home_Time: 'အချိန်',
    home_BetAmount: 'အမြတ်တံဆိပ်',
    home_Multiplier: 'မွန်ကွင်းရန်',
    home_Payout: 'အစာရှင်',
    home_JoinOur: 'ကိုယ်ရေးအဖွဲ့အစည်းတွင် အားအသင်း အကြိုက်ဆုံးသူများ၊ ရွှေဘေးကိုယ်ကိုယ်ရေးအသံဖိုင်များ၊ နတ်သမိုင်းများအဖြစ် အမြစ်ကြားလွန်းရေးပြန်လည်စတင်မည်',
    home_JOIN: 'ပြန်ဆုံမည်',
    home_LICENSES: 'ဂဏန်းများ',
    home_OurPlatforms: 'ကွန်ယက်များကို',
    home_Marketplac: 'စျေးနှုန်းရေး',
    home_Howtoearn: 'ဘယ်လိုယူရန်ဆိုင်းများ',
    home_Games: 'ဂိမ်းများ',
    home_WhitePaper: 'အဖွဲ့အစည်းဖြေရှင်း',
    home_InvestorDeck: 'အလုပ်သူအထူး',
    home_News: 'သတင်းများ',
    home_Aboutsus: 'ကြီးကြပ်များ',
    home_Industry: 'စီမံကိန်း',
    home_Roadmap: 'လမ်းကြောင်းမြေပုံ',
    home_Partner: 'မိတ်ဆွေများ',
    home_FAQs: 'မေးခွန်းများ',
    home_Affiliate: 'အသင်းဝင်များ',
    home_Stayintouch: 'ဆက်လက်ဆောင်ရွက်မည်',
    home_ChatRoom: 'ချစ်သူကြီး',
    home_Selectacontact: 'ဆက်သွယ်ရန်အဆက်အသွယ်ရွေးပါ။',
    home_BetSlip: 'ပြန်ကြောင်းကြေး',
    home_Typeyourmessage: 'သင့်ရဲ့မက်ဆေ့ခ်ျကိုရိုက်ထည့်ပါ',
    home_AcceptAnyOdds: 'အကယ်၍ လက်ခံသင့်ကိုလည်းယက်ခံယူပါ',
    home_ClearAll: 'အားလုံးရှင်းလင်းပါ',
    home_Live: 'တိုက်ရိုက်',
    home_Winner: 'ဖြစ်သူ',
    home_EstPayout: 'ပြောင်းရာငံအတွက် ပေးရာင်းခံကြမည်',
    home_TotalOdds: 'စုစုပေါင်းကွက်များ',
    home_RegistertoBet: 'ဖြန့်ကွက်ရန် အတည်ပြုပါ',
    home_Single: 'တစ်သောင်းခြင်း',
    home_Multi: 'အပြုံးခြင်း',
    home_Checkineveryweek: 'အပတ်တက်တာကြိုက်မှ စုစုပေါင်းပတ်သက်တာကြိုက်ရယူနိုင်သည်များအတွက် အကြိုက်ဆုံးသုံးပါ။',
    home_Day: 'ရက်',
    home_Sunday: 'တနင်္လာ',
    home_Monday: 'တနင်္လာနေ့',
    home_Tuesday: 'အင်္ဂါနေ့',
    home_Wednesday: 'ဗုဒ္ဓဟူးနေ့',
    home_Thursday: 'ကြာသပတေးနေ့',
    home_Friday: 'သောကြာနေ့',
    home_Saturday: 'စနေနေ့',
    home_FreeSpin: 'အခမဲ့ပြင်ဆင်မည်',
    home_Turntheturntable: 'အတည်ပြုခြင်း မကောင်းပဲဖြင့် USDT ကိုအခမဲ့ရရှိနိုင်ပါသည်။',
    home_DepositFift: 'ငွေလွှဲရန်လူသောအများဆုံး',
    home_ANYAMOUNT: 'သင်တစ်ဦးတစ်ကိုယ်ရည်ပြီးခံယူနိုင်မည်',
    home_DEPOSIT: 'ငွေလွှဲမည်',
    home_First: 'ပထမ',
    home_ONLY: 'အကယ်၍',
    home_1ST: '1ST',
    home_2ND: '2ND',
    home_3TH: '3TH',
    home_ANYAMOUNT: 'ANY AMOUNT!',
    home_EXTRA: 'အပြင်အဆင်',
    home_Relief: 'အပြင်ပျံအမြောင်း',
    home_Getrelief: 'အမြောင်းကိုယ်ရရှိနိုင်ဖြစ်သည်မှ စီးနင်းရေးအားကြည့်ရှုနိုင်ပါသည်။',
    home_YOUCANCLAIM: 'လက်ကျန်နေရာအနည်းဆုံးကိုယ်ရည်မျှတမ်းထားသို့',
    home_YOUCANReceive: ' သွားမည်သောလက်ကျန်နှုန်းကို ရရှိနိုင်ပါသည်။',
    home_threeTimes: ' 3 ကြိမ်',
    home_everydayTimes: ' / နေ့မှစပ်',
    home_Times: 'ကြိမ်:',
    home_TotalAssets: 'စုစုပေါင်းပမာဏ',
    home_receive: 'ရရှိနိုင်',
    home_TODAY: 'ယနေ့',
    home_Share: 'မျှဝေရန်',
    home_PLATFORMSHARING: 'စီးပွားလုံးမှာယူရန်',
    home_Sharewith: 'FuniBet ကိုမိတ်ဆွေးပါသည်အတွက် မေးခွန်းကိုယ်ရေးပြီး ရရှိလွယ်ကူစေသည်။',
    home_SHARINGGIFT: 'မေးခွန်းကင်းပမာဏ',
    home_CODE: 'မောင်းယူသည့်ကုဒ်',
    home_Selectplatform: 'မေးခွန်းချို့မှာယူရန်စာရင်း:',
    home_Fund: 'ပေးခွင်းစုစည်း',
    home_FundBonus: 'ပေးခွင်းစုစည်းအနိမ့်',
    home_Improveyour: 'VIP အဆင့်ဆင့်တင်နိုင်ရန်သင်တစ်ကိုယ်ရည် လျှောက်လွန်ကြောင်းကိုရရှိနိုင်သည်။',
    home_BuyFund: 'ပေးခွင်းစုစည်းဝယ်ရန်',
    home_Receivingconditions: 'ရရှိခဲ့သောလျှပ်ကြမ်းခြင်း',
    home_Receivingstatus: 'ရရှိနိုင်သည့်အခြေအနေ',
    home_Received: 'ရရှိနိုင်ပြီး',
    home_noReceived: 'ရရှိနိုင်ခဲ့ပြီး',
    home_ExclusiveBenefits: 'လူသားများအတွက် အမျိုးသားအနောက်ကို',
    home_Asawayof: 'VIP ရဲ့ကဲ့သို့ တစ်ခုမှစတင်တာနဲ့တူးလှယ်ရေးကို အသွေးမှတ်ပြီး FuniBet သည် VIP ကဲ့သို့ဖြစ်စေသူများအတွက် ကိုယ်ရေး VIP အဖွဲ့အပြင်အဆင်အတိုက် အချက်များအားကြည့်ရှုနိုင်ပါသည်။ သင်သည်တွေ့ရမှုနှင့် ပြင်ရမှု၊ ငွေပေးရမှု၊ အကောင်းဆုံးထွက်မှုများအားကြည့်ရှုရန်နိုင်သည်။ မည်သည်အာမခံနိုင်သောအားမကြောင်း အိပ်မက်မှုနှင့် အခမဲ့မှုများကိုရရှိမည်။ အားလုံးများသည် လက်ရှိနေသည့်လက်ကျန်သူများကိုမပြင်မနေသောအတိုက်အလွယ်အဆင် ကြယ်ကျေးနှုန်းများနှင့် မိမိစိတ်ချိန်ခွက်အတွက်ဖြစ်ပြီး အထူးလျှောက်ကွက်များ၊ တက်ရောက်ကြွက်များ၊ အသင်းဝင်မှုများကိုရရှိရန်လိုက်ခြင်း၊ ကြီးကြပ်သော VIP လက်ဆောင်သူအဖွဲ့အစည်းများကို FuniBet ကို အထူးစိတ်မပူးပေါင်းသို့ ကြည့်ရှုခံနိုင်ရန် တော်တော်မှန်သိမ်းမှုများအားရရှိနိုင်သည်။',
    home_Yourcurrent: 'သင်လက်ကျန်သည်နေရာအလောက်အကြှေးစားပေးရေးအကောင်းတခု',
    home_WhyUpgrade: 'VIP အဆင့်ကိုသာတရားစွာကြည့်ရန်လက်ရှိကြောင်းရမှသွားရန်လိုအပ်သည်။',
    home_VIPhave: 'VIP သည် အသေးစားပင်ဖြစ်စေသူများအတွက် ၂၄*၇ နာရီစဉ်သားအသေးအချက်များကိုရရှိသည်။ အရှေ့အတွက်လွန်ခွဲမှုနှင့် ငွေပေးမှုသည်ပိုက်ဆံမူသည်၊ VIP သည် အကောင်းဆုံးအဆင့်အတန်းအကြောင်း တစ်ရက်/တစ်လပိုင်းရေးလမ်းများ၊ အကောင်းဆုံးထွက်/နေ့ထွက်လပိုင်းများကိုရရှိသည်၊ VIP သည် အများပြည်သူလက်ခံခွင့်ကို ပြင်ဆင်ရန် ရရှိသည်။ FuniBet အဖွဲ့အပြင်အဆင်အတိုက်မှာ အကောင်းဆုံးအမျိုးသားသဖြင့် ငွေပေးမှုအနေနဲ့ စာရင်းနှုန်းကိုရရှိသည်။',
    home_UpgradeYour: 'VIP အဆင့်တက်ရန်ခြင်း',
    home_ASaFunibet: 'FUNIBet VIP အဖွဲ့အပြင်အဆင်အတိုက်အဖြစ်သည် သင်သည် မိမိ VIP အဖွဲ့အပြင်အဆင်အတိုက်အား ပူးပေါင်းသတ်မှတ်ပြးရေးအမျိုးသားများ၏ကောင်းကြောင်းအနေဖြင့် အသွေးစားရယူနိုင်စေသည်။ သင်အနက်အရွယ်အမျိုးသားအနေနဲ့ ကျန်းမာရေးကိုရယူနိုင်သည်။ သင်ကအကောင်းဆုံးကြာသောမှာ အကောင်းဆုံးလွန်ခဲ့ရန် စာရင်းနှုန်းကိုရယူနိုင်ပါသည်။ သင်ရှိနေသည့်အနေဖြင့် ကောင်းကြောင်းအမျိုးသားကို သင်ကိုက်ညီမည်အကြောင်း၊ FUNIBET သည် သင့်အနေဖြင့် VIP အမျိုးမျိုးကို ကြေင်းဆုံသင့်အဖြစ်ပြောင်းသောအခမဲ့မှုအား ပေးလွှားသူများဖြစ်သည်။',
    home_YOUCANREACH: 'သင်လက်ခံမည်နှင့်ရန်ကောင်းကြာသည်',
    home_LEVELS: 'အဆင့်',
    home_eachthehigh: 'မိမိသည် ၂၅% အများပြည်သူနှင့် နှစ်သက်သည်အား ကျွန်ုပ်တို့၏ပါဝင်သည်များအားရရှိနိုင်စေသည်။ FuniBet မှာ အကောင်းဆုံးပေါင်းအတိုက် အမျိုးမျိုးအနေနဲ့ ကျန်းမာရေးကို ရယူနိုင်သည်။ FuniBet သည် FUNIBET သဖြင့် ကောင်းကြားအစောင်အဆင့်အတိုက် အသက်အကြောင်းများကို ရရှိနိုင်ပါသည်။',
    home_Cashback: 'ငွေပေးသွင်း',
    home_Nowater: '၁။ ဂီတတွေအတွက်သက်သာသောမှုကိုမရှိသောအခါ',
    home_Moregrowth: '၂။ အဆင့်တစ်လမ်းအပေါင်းကိုပိုက်ဆံပြီး',
    home_Increasein: '၃။ လတ်တလောတက်သွားသောအမျိုးမျိုးကိုကျွန်ုပ်တို့ရှိရန်',
    home_Doublethenumber: '၄။ ပုံမှန်အရိုးအားတစ်ကြောင်းကိုနှစ်ကြောင်းရရှိရန်',
    home_Doublethesign: '၅။ စာရင်းတွေအရိုးအားတစ်ကြောင်းကိုနှစ်ကြောင်းရရှိရန်',
    home_Doublesharingrewards: '၆။ မကြာမှီသောပိုက်ဆံပေးသွင်းအရိုးအားတစ်ကြောင်းကိုနှစ်ကြောင်းရရှိရန်',
    home_DoubletheAffiliate: '၇။ ပစ္စည်းသွင်းရန်ရရှိနိုင်သောအရိုးအားတစ်ကြောင်းကိုနှစ်ကြောင်းရရှိရန်',
    home_Rechargerewardamount: '၈။ ငွေပေးသွင်းပေးသွင်းပေးသွင်းအကောင်းကို ၁.၅ ကျပ်ကိုရရှိရန်',
    home_ABOUTVIPCLUB: 'VIP အဖွဲ့အပြင်အဆင့်အကြောင်းအလောတက်ကြီးများ', 
    home_HightopRank: 'အမြင်းကြီးအမျိုးမျိုးသည် ၂၅% အများပြည်သူနှင့် အကောင်းဆုံးအခမဲ့အနေအထားများကိုရယူနိုင်သည်ဟု BeDao မှပါဝင်သည်များအားရရှိနိုင်စေသည်။',
    home_Beingamember: 'VIP အဖွဲ့အပြင်အဆင့်ကိုဖြတ်ခြင်းသည်မှာ သင်သည်အကောင်းဆုံး လျှို့ဝှက်ခြင်းသည်များအားရယူနိုင်သည်ဟုအသုံးပြုသောအခမဲ့များကိုကြည့်ရန်ကောင်းသည်။',
    home_PersonalVIPmanager: 'ပင်နေ VIP အားလုံးအကောင်း',
    home_Insights: 'အထွေထွေများ',
    home_Highercahbackrewards: 'ကျောင်းသားနှုန်းရှိနိုင်သောအခမဲ့အနေအထားများ',
    home_Otheradditionalperks: 'အခြားနည်းနည်းများ',
    home_WeeklyandMonthlyBonuses: 'နေပစ္စည်းနှင့် လစဉ်နေပစ္စည်းများ',
    home_Showhighgaming: 'အပြင်အလောအထားတို့၏ VIP အဖွဲ့အပြင်အဆင့်ကိုပြပေးပါက အပြင်အလောအထားရပ်ကွက်များကိုရယူနိုင်စေသည်။',
    home_BindMobile: 'ဖုန်းခေါ်ဆိုင်',
    home_Pleasecheckthe: 'အကောင်းဆုံးကိုယ်တိုင်အကြောင်း USDT ကိုကိုယ်တိုင်ကမှတ်မသိပါ။',
    home_BINDMOBILENUMBER: 'ဖုန်းနံပါတ်ကိုချိတ်ဆက်ပါ',
    home_Get1USDT: '1 USDT ရရှိပါက',
    home_Bind: 'ချိတ်ဆက်ပါ',
    home_GiftCode: 'အကြောင်းကုဒ်',
    home_EnteryourGiftCode: 'အကြောင်းကုဒ်ကိုရိုက်ပါ',
    home_EnterCode: 'ကုဒ်ကိုရိုက်ပါ',
    home_Paste: 'မီးထွင်း',
    home_confirms: 'အတည်ပြုပါ',
    home_BuyCrypto: 'ကုမ်းကူးရန်',
    home_SignedIn: 'အပြီးသတိပေးပါပြီ',
    home_FirstDepositBonus: 'အကောင်းဆုံးအကောင်းကိုရယူနိုင်သည်',
    home_Hi: 'မင်္ဂလာပါ',
    home_welcomeAboard: 'ပြုပြင်သောမီဒီယာမှကြိုဆိုက်ကိုကောင်းသည်',
    

    home_CommissionRules: 'ကုမ်းတစ်ကုမ်းစီးပြီး',
    home_AffiliateTerms: 'အဖွဲ့အစည်းများ',
    home_AffiliateDashboard: 'အဖွဲ့အစည်းရုံးစာရင်း',
    home_AFFILIATE: 'အဖွဲ့',
    home_COMMISSONREADYEVERYDAY: 'ကုမ်းတစ်ကုမ်းကိုနောက်သို့သောအချက်အလက်$1,000 အားသက်ပြီးသော',
    home_MYREFERRALCODE: 'ကျွန်ုပ်ရောင်းကုဒ်',
    home_MYREFERRALLink: 'ကျွန်ုပ်လင့်ခ်ကို',
    home_AffiliateReward: 'အဖွဲ့ချုပ်',
    home_EXTRA: 'အပြင်အဆင်ပြော',
    home_USDREWARDS: 'အမျိုးသားပြင်ပမာဏ',
    home_USDRewardsRules: 'အမျိုးသားပြင်ပမာဏကို',
    home_JUSAREFERRALAWAY: 'သင်ကျောင်းလဲရောက်လာရန်အကြံပေးနေသည်',
    home_NewlyAvailableRewards: 'အသစ်ဖြစ်သောလျှင်မြန်ဆန်မှုများ',
    home_ReceivedMao: 'ရယူသည်:',
    home_Locked: 'ခွောက်ထားသည်:',
    home_Yourcurrentfriends: 'သင်၏လက်ရှိမိတော်သောသူများသည်အောက်တိုဘက်ထပ်စောင့်သွားသည်ပြီးအမျှားဆုံးပမာဏကိုရရှိနိုင်သည်',
    home_Withdraw: 'ထွက်ရန်',
    home_MY: 'ကျွန်ုပ်',
    home_COMMISSIONREWARDS: 'ကုမ်းတစ်ယောက်ကုမ်းများ',
    home_CommissionRewardRules: 'ကုမ်းတစ်ယောက်ကုမ်းများကိုရယူနိုင်သည်',
    home_NewlyAvailableCommissionRewards: 'အသစ်ဖြစ်သောကုမ်းတစ်ယောက်များ',
    home_CommissionYouhaveReceivedinTotal: 'စုစုပေါင်းရယူသည်:',
    home_REWARDSSENTTODATE: 'နောက်ဆုံးရယူသောကုမ်းများအားကိုရယူနိုင်သည်',
    home_USDrewardssenttodate: 'နောက်ဆုံးပစ္စည်းအားကိုရယူနိုင်သည်',
    home_Commissionsenttodatet: 'နောက်ဆုံးကုမ်းအားကိုရယူနိုင်သည်',    
    home_Areyouablogger: 'သင်တစ်သက်ကျယ်များနှင့်သက်ဆိုင်ရာကြောင့်အကောင်းဆုံးရယူနိုင်သလား? သင်ကိုကျွန်ုပ်တို့သည်အကြီးကြောင့်အမြဲတမ်းအကြံဆုံးကြောင့်အချက်အလက်ဖြစ်သောအလုပ်ခွင့်ကိုပေးမည်။ သင်တစ်သက်ကျယ်များကိုကြောင့်သင်သတိပြုသလား။ သင်ကိုမင်ကျွန်းဆုံးဆရာအပ်ဒိုင်ယူလက်ကျန်ပါ။',
    home_onlythoseusers: 'အပြင်သရုပ်သို့အားလုံးမှကိုယ်ကျွန်းသိရှိသူတစ်ယောက်ကိုအကောင်းဆုံးသတိပြုခြင်းလိုက်ခြင်းမရှိပါ။',
    home_notice: 'အသေးစိတ်',
    home_bindPhone: 'ဖုန်းခေါ်ပါ',
    home_SignIn: 'အကောင့်ဝင်ရန်',
    home_SignUp: 'မှတ်ပုံတင်ရန်',
    home_SignOut: 'ထွက်ရန်',
    home_CookiesPolicy: 'ကွတ်ကောတ်တိုက်စာမျက်နှာ',
    home_isUsingCookies: 'Funibet.com ကိုကျွန်းမာသောအချက်အလက်ကိုသင်ပြောနိုင်ရန် cookies ကိုသုံးသပ်ခြင်းမရှိပါ။ ကျွန်ုပ်မူတည်ပြီးလက်ရှိသုံးစွဲချက်နှင့်ဝန်ဆောင်မှုကိုကြည့်ပါ။',
    home_SETTING: 'ဆက်တင်အပြင်',
    home_LiveSupport: 'အပေါ်သို့ရောက်ရှိနိုင်သည်',
    home_Accept: 'လက်ခံသည်',
    home_FrequentlyAskedQuestions: 'အကဲမြတ်များ',
    home_SiteUnnderConstruction: 'ဆိုက်ရိုက်စီကြုတ်ဖွဲ့လွှာရှင်းလင်းခြင်း',
    home_ComingSoon: 'မကြာမီသောနောက်',
    home_Wallet: 'ပိုက်ဆံခြင်း',
    home_UserId: 'အသုံးပြုသူအမှတ်:',
    home_UserName: 'အသုံးပြုသူအမည်:',
    home_turnTimes: 'အကြိုးစားရန်အချိန်:',
    home_Bonusincreased: "လျော့ကျော့များကိုကြည့်ခြင်း+",
    home_bonus: 'လျော့ကျော့',
    home_SPINBONUSTOTAL: 'လက်ကျော့အမြင်ပီးလက်ကျော့အခြားများ',
    home_WIN: 'ရောင်းပြီးသူ:',
    home_In: 'မှ',
    home_Outoftime: 'ယနေ့အကြိုးစားကိုဖျက်ခြင်းပြီးပြီ',    

    game_search: 'ရှာဖွေရန်',
    game_SortBy: 'အမျိုးအစားဖြင့်စီမံခန့်ခွဲပြီးများ',
    game_Created: 'ထည့်သွင်းပြီး',
    game_Lobby: 'လောင်အိုးစင်',
    game_LiveCasino: 'တိုက်ကစားကုဒ်အစည်းအဝေး',
    game_FeaturedSlots: 'ဖော်ပြချက်ရှိတိုက်ကစားကုဒ်များ',
    game_GameShows: 'ဂိမ်းကြောင်းများ',
    game_FuniBetOriginals: 'FuniBet မြောက်စုံသစ်များ',
    game_BigWins: 'ကြောင်းမြန်းဆုံးရှင်များ',
    game_LcukyWins: 'မဲလက်မြန်းသူများ',
    game_Challenges: 'ကျောင်းသူများ',
    game_Description: 'ဖော်ပြချက်',
    game_Slots: 'ကွတ်ကစားကုဒ်များ',
    game_Rank: 'အမတ်',
    game_User: 'အသုံးပြုသူ',
    game_Date: 'နေ့စွဲ',
    game_BetAmount: 'ဘောက်ကျော်ကျမှု',
    game_lucky: 'အားလံပေးသွင်း',
    game_Multiplier: 'ပေါင်းတူရင်း',
    game_Payout: 'ပေးရန်',
    game_Recommend: 'သောမြောက်စွာလျော့ခ်မှု',
    game_Providers: 'ပေးရာဆရာများ',
    game_startGame: 'စတင်',
    game_balance: 'ကျန်ရှိအမှတ်အသား',
    game_provider: 'တံခါးဖော်ပြချက်',
    game_Selectyourdisplay: 'သင်၏ကြော်ငြာကိုရွေးချယ်ပါ',
    game_Balancein: 'ကျန်ရှိကြော်ငြာ',
    game_FunPlay: 'ဂိမ်းအရှည်ဆက်တည့်ရန်',
    game_RealPlay: 'အပြင်သွင်းရန်',    
    game_DispLaying: "မြင့်ဆုံးကြောင်းကိုရွေးခြယ်ရန်",
    game_LoadMore: "ပိတ်ဆောင်းရန်အသစ်ကိုဖွင့်ရန်",
    game_NoData: 'ယခုအကောင့်အချက်အလက်မရှိပါ',
    
    
    message_SigningIn:"JWT ကိုအသုံးပြုရန်ရောင်းချရန်",
    message_CourseSaved:"သင့်ရဲ့ကော်တာသည်သို့သိမ်းဆည်းထားသည်",
    message_PleaseBind:"ဖော်ပြပါသင့်ဖုန်းကိုချိတ်ဆက်ခြင်း",
    message_UserData:"အသုံးပြုသူအချက်အလက်ကို API တွင်သိမ်းဆည်းထားသည်",
    message_Success:"အောင်မြင်သည်",
    message_VIPlevel:"VIP အဆင့်အတန်းအနေအထား V3 ထက်သင့်ရှိပါက",
    message_ListOrder:"စာရင်းများအမှားတစ်ခုကိုသိမ်းဆည်းထားသည်",
    message_CardOrder:"ကဏ္ဍများအမှားတစ်ခုကိုသိမ်းဆည်းထားသည်",
    message_HiHow:"မင်းကတော့ မင်္ဂလာသောကမ္ဘာနဲ့ပါတယ်။",
    
    Funibet_web: 'Funibet-Web3.0,ရုပ်သံခိုင်း,အားလပ်ပေးသွင်း,အကြိုက်ကွန်းတစ်ရပ်,အိုက်စားကွန်း,စပိန်းကိုင်း,ပုဂ္ဂိုလ်',
    
    home_ThereAre:"ယခုမှာရှိသောအသိုင်းများမရှိပါ" ,
    
    play_no_balance: "လက်ရှိငွေကြေးစားမှုမပြုရပါ။ ငွေကြေးစားကိုပြောင်းစွာပြန်ရိုက်ထပ်ရန်သင့်ပါကကိုင်ကြားပါက သွားရန်နေရာကိုရွေးချယ်ပါ",    

    home_News_1:'သတင်း',
    home_News_2:'အစီရင်ခံ',
    home_News_3:'ရောင်းချမ်းသားနည်းလမ်း',
    home_News_4:'ဂိမ်းများ',
    
    home_SuggRep_1:'အကြောင်းအမြင်',
    home_SuggRep_2:'အကြောင်းအမြင်',
    home_SuggRep_3:'ပြုစုပြီးတားဆီးချက်များကိုမြှင့်ခံပါသလား!',
    home_SuggRep_4:'ရောင်းချမ်းကိုရေးထားသည်မှာရောင်းသွင်းသည့်ကိန်းများအပေါ်ကိုသင့်ရဲ့ဝက်ဘ်စောင်းလေးကိုတင်ပါ။',
    home_SuggRep_5:'အကြံပြုချက်သို့မဟုတ်ပြောင်းလဲပြီးတင်ပါ',
    home_SuggRep_6:'ပုံများအတွက်အတွက်ပြင်ဆင်ခြင်း',
    home_SuggRep_7:'အကြံပြုချက်နှင့်အမြင်နှင့်အတူ တက်ရောက်ရောက်ရှိသည့်အခါများကိုရယူပါမည်!',
    home_SuggRep_8:'တင်ပို့ပါ',
    
    home_subscription_1:'မှတ်တမ်းကိုထုတ်ဖော်ပြရန်ရှာသောပြဿနာသောအဖြစ်သလို?',
    home_subscription_2:'သင့်ကိုသင်တင်မှာမထပ်ဖြန်ပါနှင့်။',
    home_subscription_3:'WonderPush ကွန်တော်ကိုမြှင့်ချမ်းစောင့်ရှားပါ',
    home_subscription_4:'နောက်ထပ်',
    home_subscription_5:'ထုတ်ယူခွင့်ပြုရန်',    
    
    home_Affiliate_1:'ဆက်သွယ်ပါ',
    home_Affiliate_2:'မိတ်ဆွေပြီးတင်ပြီးသူတွေကို 10U ရရှိရန်, မိတ်ဆွေသူအားလွန်ကြောင်း သက်တမ်းကိုရရှိနိုင်ပါတယ်။',
    home_Affiliate_3:'တယ်လီမှား',
    home_Affiliate_4:'အီးမေးလ်',
    
    home_banner_1: 'ဂရင်တိုက်ရေးဂိမ်း',
    home_banner_2: '100+ အမြဲကောင်းငွေကြေးရေးနှင့် 50+ အမြဲ NFT ကိုပြုပြီးကိုင်တွယ်ပေးပါ',
    home_banner_3: 'မြောက်ပစ်ချက်မှုကိုအဖြေဖြေပေးရန်',
    home_banner_4: 'မြောက်ပစ်ချက်အားစနစ်တစ်ကနေစက်ဖြင့်လိုက်ခံရန်ကြည့်ရှုနိုင်ပါ',
    home_banner_5: 'အသွေးချက်လက်လီသေး',
    home_banner_6: 'မြောက်ပစ်နိုင်ရန်, မြောက်ပစ်နှင့်မှာဖြန်ရှာနိုင်ခြင်းနှင့်အောင်ဒေတာကိုဖြစ်သည်',
    home_banner_7: 'Web3.0 + ဂရင်တိုက်ရေးဂိမ်း',
    home_banner_8: 'ပရောဂျက်ဖြစ်ပြီး အကြောင်းသုတ်တိုက်တွယ်ကိုပြပေးပါ',

    home_EventDetails: 'အဖြေချထားသည်',
    
    home_vip_1:'1. တွေ့ရမည့်ရန်ကြောင်းလှမ်းအားရရှိရန်+',
    home_vip_2:'2. အနုပ်ရယူရန် +',
    home_vip_3:'3. မြှောက်လာရယူရန် +',
    home_vip_4:'4. ငွေလက်ခံရယူရန် +',
    home_vip_5:'5. ခေတ်မရရှိရန်အတွက် စက်မှုပြတိုက်ဖွင့်ပါ',
    home_vip_6:' ကျေးဇူးကိုင်စာသားပိုက်ဆံရန် တနေ့အတွက်',
    
    ERROR_628: 'ဤလက်ကျန်ဆုံးကို ရရှိပြီးဖြစ်သည်',
    ERROR_629: 'ဤလက်ကျန်ဆုံးကို ရရှိထားသည်ကြပါမည်',
    ERROR_630: 'ဤလက်ကျန်ဆုံးကို ရရှိခဲ့သည်မဟုတ်သောကြပါ',
    ERROR_631: 'ဤလက်ကျန်ဆုံးကို ရရှိသောကြကြာမဟုတ်သောကြပါ',
    ERROR_633: 'အခြားဌာနသို့ ဤလက်ကျန်ဆုံးကို အသုံးပြုနိုင်ပါသည်',
    
    home_Maintaining_1: "တိုးတက်အဆင့်ဖြစ်နေသည်",
    home_Maintaining_2: "ဤအချက်အလက်ကို တစ်စုတစ်ပုံဖြန်ရယူထားသည်",
    home_Maintaining_3: "ပြီးဆုံး",
    home_Maintaining_4: "စတင်ထားရယူထားသည်",
    
    home_Slots: 'အပြင်အဆင်',
    home_LiveCasino: 'တွဲမြောက်ကစားနှင့်မြှောက်လာကောင်း',
    home_CountryFiat: 'နိုင်ငံအမြဲကောင်း',
    home_CryptNFTs: 'ရွှေ့ဆိုင်အမြဲကောင်းနှင့် NFTs',
    
    home_GoTo:'သို့ထပ်',
    
    home_gameout_1: "သင်ယခုအချက်အလက်တစ်ခုတည်းအတွင်လက်ခံထားသည်။ ထွက်မည်သောအကြောင်းအချက်အလက်မရပါနဲ့သေချာပါပြီလား?",
    home_gameout_2: "မလုပ်သော",
    
    home_BuyFund1:'သင်သည် 50 USDT ကို အဆောက်ဖြည့်ရန် ကျောင်းသားအဖြစ်ကာကွယ်မလဲ?',
    
    home_BetFor:'အကြိုးအသားသို့ ထပ်မံကြည့်ပါ',
    
    tongzhi_1: "အကြံပေး",
    tongzhi_2: "အကြံပေးနှင့်ရွေးချယ်ခြင်း။",
    tongzhi_3: "ကျန်းမာရပ်! ဒီလက်ရှိရှိသောပရိုဂရမ်ဖြစ်ပါတယ်။",
    tongzhi_4: "စစ်ရွက်မှုကြောင့်သြားရမှာသက်သာပါ။",
    tongzhi_5: "စစ်ရွက်မှုအတည်ပြုပြီး။",
    tongzhi_6: "ငွေပေးခြင်းတင်မြားနေဆဲပါသည်။",
    tongzhi_7: "ငွေပေးခြင်းအောင်မြင်ပါသည်။",
    tongzhi_8: "စစ်ရွက်ကြောင့်မအတည်ပြုသင့်ပါ။",
    tongzhi_9: "ငွေပေးခြင်းမအောင်မြင့်ပါ။",
    tongzhi_10: "အထွက်ဖျက်ခြင်းမအောင်မြင့်ပါ။",
    tongzhi_11: "ငွေပေးခြင်းပြီးပါပြီ။",
    tongzhi_12: "ပြီးပြီ။",
    tongzhi_13: "အလုပ်လုပ်ခြင်းမအောင်မြင့်ပါ။",
    tongzhi_14: "ဝယ်ယူပါ",
    tongzhi_15: "အဆင်သင့်",
    tongzhi_16: "ထွက်ပစ်",
    tongzhi_17: "ကရိယာင်း",
    tongzhi_18: "ဖဲကစျေး",
    tongzhi_19: "ဒေါင်းယူ",
    tongzhi_20: "အက်ပလီမှအဝင်များဆုံးရှုံးနိုင်သည်",
    tongzhi_21: "ဒေါင်းယူ",
    tongzhi_22: "ဤနေရာတွင် iOS ဗားရှင်းအက်ပလီကို ရရှိနိုင်ပါမည်",
    tongzhi_23: "ပို့ပါ",
    tongzhi_24: "အကြောင်းအရာများပို့ပါ",

    home_banner_9: "ရှေ့စာ",
    home_banner_10: "မြက်စွန့်အက်",
    home_banner_11: "အမွတ်လှောင်းကြားလာသည်၊ ကျွန်တော့ငြင်းများရောက်သည်",
    home_banner_12: "အမွှေ့ကျောက်နာလှောင်းအဖွား",
    
    home_wanfa_1:"ဂိမ္မပလာတဲ့အဖြဲ့",
    home_wanfa_2:"ငွေလွှဲနေ့နိုင်ပါသည်",
    home_wanfa_3:"အျခားနည်းစား",
    play_not_balance: "အခြားငွေပေးပစ်ခြင်းနှင့် eUSDT အသုံးပြုခြင်းမရှိပါ။ ငွေကြေးပြောင်းလဲလိုပါသလား။",
};

export default locale;
