import Provider from './Provider';


const ProviderConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'provider/',
            element: <Provider />,
        }
    ],
};

export default ProviderConfig;
