const locale = {
    home_StakeNFT: 'เข้ามาพัน NFT เพื่อรับรางวัลมากขึ้น',
    home_everyday: 'ทุกวัน',
    home_Beton: 'เดิมพัน',
    home_mining: 'ขุด',
    home_shareholder: 'เป็นผู้ถือหุ้น',
    home_Stake: 'พัน',
    home_dividend: 'ได้รับเงินปันผล',
    home_Banker: 'เป็นหนึ่งในพาร์ทเนอร์',
    home_SIGNUPAND: 'สมัครเลย',
    home_GET: 'ได้รับ',
    home_UPTO: 'รวม',
    home_FuniHash: 'เกมบล็อกเชน',
    home_Option: 'ตัวเลือก',
    
    home_Reward: ' รางวัล',
    home_Reward1: 'รางวัล',
    home_signUpNow: 'สมัครเลย',
    home_Casino: 'คาสิโน',
    home_Sports: 'กีฬา',
    home_PROMOTION: 'โปรโมชั่น',
    home_Checkin: 'เช็คอิน',
    home_Bouns: 'โบนัส',
    home_CheckinBouns: 'โบนัสเช็คอิน',
    home_SPIN: 'หมุนวงล้อ',
    home_Relief: 'เงินช่วยเหลือ',
    home_Share: 'แชร์',
    home_Deposit: 'ฝากเงิน',
    home_Fund: 'กองทุน',
    home_VIPClub: 'คลับวีไอพี',
    home_FastAndEasy: 'ซื้อสกุลเงินดิจิตอลเพื่อฝากเงินโบนัสขนาดUSDT ได้อย่างรวดเร็วและง่ายดาย',
    home_Game: 'เกม',
    home_User: 'ผู้เล่น',
    home_Time: 'เวลา',
    home_BetAmount: 'จำนวนเงินเดิมพัน',
    home_Multiplier: 'ตัวคูณ',
    home_Payout: 'การจ่ายเงิน',
    home_JoinOur: 'เข้าร่วมกลุ่มผู้รักกีฬา ผู้ที่หลงไหลในการเชื่อมต่อกับสกุลเงินดิจิทัลและนักพนันของเรา',
    home_JOIN: 'เข้าร่วม',
    home_LICENSES: 'ใบอนุญาต',
    home_OurPlatforms: 'แพลตฟอร์มของเรา',
    home_Marketplac: 'ตลาด',
    home_Howtoearn: 'วิธีการรับรางวัล',
    home_Games: 'เกม',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'ผู้ลงทุน',
    home_News: 'ข่าวสาร',
    home_Aboutsus: 'เกี่ยวกับเรา',
    home_Industry: 'อุตสาหกรรม',
    home_Roadmap: 'แผนที่',
    home_Partner: 'พาร์ทเนอร์',
    home_FAQs: 'คำถามที่พบบ่อย',
    home_Affiliate: 'สมาชิก',
    home_Stayintouch: 'ติดต่อเรา',
    home_ChatRoom: 'ห้องแชท',
    home_Selectacontact: 'เลือกติดต่อเพื่อเริ่มสนทนา',
    home_BetSlip: 'ใบเสร็จการเดิมพัน',
    home_Typeyourmessage: 'พิมพ์ข้อความของคุณ',
    home_AcceptAnyOdds: 'ยอมรับราคาใด ๆ',
    home_ClearAll: 'ล้างทั้งหมด',
    home_Live: 'ล่าสุด',
    home_Winner: 'ชนะเลิศ',
    home_EstPayout: 'จำนวนเงินที่คาดว่าจะได้รับ',
    home_TotalOdds: 'เงินเดิมพันรวม',
    home_RegistertoBet: 'สมัครเพื่อเดิมพัน',
    home_Single: 'เดิมพันเดี่ยว',
    home_Multi: 'เดิมพันคู่',
    home_Checkineveryweek: 'เช็คอินทุกสัปดาห์เพื่อรับรางวัล',
    home_Day: 'วัน',
    home_Sunday: 'วันที่ 1',
    home_Monday: 'วันที่ 2',
    home_Tuesday: 'วันที่ 3',
    home_Wednesday: 'วันที่ 4',
    home_Thursday: 'วันที่ 5',
    home_Friday: 'วันที่ 6',
    home_Saturday: 'วันที่ 7',
    home_FreeSpin: 'หมุนวงล้อ',
    home_Turntheturntable: 'หมุนวงล้อทุกวันเพื่อรับรางวัล USGT',
    home_DepositFift: 'ฝากเงิน',
    home_ANYAMOUNT: 'คุณสามารถได้รับโบนัสการฝากเงินครั้งแรกในจำนวนเงินใดก็ได้',
    home_DEPOSIT: 'ฝากเงิน',
    home_First: 'ครั้งแรก',
    home_ONLY: 'เพียง',
    home_1ST: '1ST',
    home_2ND: '2ND',
    home_3TH: '3TH',
    home_ANYAMOUNT: 'ANY AMOUNT!',
    home_EXTRA: 'พิเศษ',
    home_Relief: 'เงินช่วยเหลือ',
    home_Getrelief: 'รับเงินช่วยเหลือเพื่อมีโอกาสกลับมาในเกม',
    home_YOUCANCLAIM: 'เมื่อยอดเงินคงเหลือน้อยกว่า',
    home_YOUCANReceive: ', คุณสามารถรับเงินช่วยเหลือเพื่อเติมเงินในบัญชีของคุณได้ถึง',
    home_threeTimes: ' 3 ครั้ง',
    home_everydayTimes: '/ ต่อวัน!',
    home_Times: 'ครั้ง:',
    home_TotalAssets: 'สินทรัพย์รวม',
    home_receive: 'รับ',
    home_TODAY: 'วันนี้',
    home_Share: 'แชร์',
    home_PLATFORMSHARING: 'แชร์ไปยังแพลตฟอร์มอื่นๆ',
    home_Sharewith: 'แชร์ FuniBet ทุกวัน เพื่อรับ',
    home_SHARINGGIFT: 'ของขวัญการแชร์',
    home_CODE: 'โค้ดการแชร์',
    home_Selectplatform: 'เลือกแพลตฟอร์มที่จะแชร์:',
    home_Fund: 'กองทุน',
    home_FundBonus: 'Fund Bonus',
    home_Improveyour: 'เพิ่มระดับ VIP ของคุณ เพื่อรับเงินรางวัลเพิ่มเติมทุกวัน',
    home_BuyFund: 'ซื้อ',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'status',
    home_Received: 'ได้รับแล้ว',
    home_noReceived: 'ยังไม่ได้รับ',
    home_ExclusiveBenefits: 'สิทธิประโยชน์พิเศษ',
    home_Asawayof: 'เพื่อแสดงความขอบคุณและยินดีต้อนรับสู่ VIP ของ FuniBet ที่มีของขวัญมากมาย เครื่องใช้ฟรี การคืนเงินเงินสดที่สูงขึ้นและฟีเจอร์ที่ไม่เหมือนใคร อย่าพลาดทุกความสนุกและความมหัศจรรย์! ',
    home_Yourcurrent: 'ยอดฝากของคุณในปัจจุบัน',
    home_WhyUpgrade: 'ทำไมต้องอัปเกรดเป็น VIP? ',
    home_VIPhave: 'VIP ได้รับการดูแลจากผู้ช่วยส่วนตัว 24 ชั่วโมงต่อวัน 7 วันต่อสัปดาห์ นอกจากการคืนเงินและการฝากเงินทั่วไปแล้ว VIP ยังได้รับการฝากเงินที่สูงสุดของระดับสูงสุด โบนัสรายสัปดาห์ / เดือนและช่องทางฝากถอนเงินที่รวดเร็วที่สุด VIP มีโอกาสเข้าร่วมการประชุมผู้เล่นชั้นนำและช่วยกันสร้างคาสิโนโดยการนำเสนอคุณสมบัติและฟีเจอร์ใหม่ๆ ให้กับทีม FuniBet ',
    home_UpgradeYour: 'อัปเกรดระดับ VIP ของคุณ',
    home_ASaFunibet: 'ในฐานะสมาชิกของ FUNIBet VIP Club คุณจะได้รับของขวัญที่อุดมสมบูรณ์และโปรโมชั่นที่กำหนดเอง แต่นี่ยังไม่เพียงพอ โดยเมื่อคุณเล่นมากขึ้น คุณจะได้รับสิทธิพิเศษ VIP ที่ดีกว่าเมื่อคุณได้รับการโปรโมทเป็นสมาชิก VIP ชั้นนำ FUNIBET จะให้สิทธิพิเศษ VIP ที่ออกแบบเฉพาะสำหรับคุณ',
    home_YOUCANREACH: 'คุณสามารถอัพเกรดเป็น',
    home_LEVELS: 'ระดับ',
    home_eachthehigh: 'ทุกคนสามารถได้รับโบนัส Cashback 25% และเพลิดเพลินกับความสะดวกสบายที่ FUNIBET มีให้! มาเข้าร่วม FUNIBET เพื่อสนุกกับประสบการณ์การเล่นเกมที่ดีที่สุดและการบริการที่มีคุณภาพ! ',
    home_Cashback: 'การคืนเงินสด',
    home_Nowater: '1.ไม่มีการหักค่าน้ำในเกมอีกต่อไป',
    home_Moregrowth: '2.กองทุนเพิ่มเติม',
    home_Increasein: '3.จำนวนสิ่งของพิเศษเพิ่มขึ้น',
    home_Doublethenumber: '4.จำนวนสปินหมุนเพิ่มขึ้นสองเท่า',
    home_Doublethesign: '5.จำนวนการเข้าสู่ระบบเพิ่มขึ้นสองเท่า',
    home_Doublesharingrewards: '6.สิทธิ์ในการแบ่งปันรางวัลเพิ่มขึ้นสองเท่า',
    home_DoubletheAffiliate: '7.รางวัลของตัวแทนเพิ่มขึ้นสองเท่า',
    home_Rechargerewardamount: '8.จำนวนเงินเติมเงินเพิ่มขึ้นเป็น 1.5 เท่า',
    home_ABOUTVIPCLUB: 'เกี่ยวกับสโมสร VIP',
    home_HightopRank: 'อันดับสูงสุดมีสิทธิ์ได้รับโบนัสคืนเงิน 25% และสิ่งที่ดีที่สุดในสิทธิประโยชน์ เข้าพักที่ BeDao สนุกกับประสบการณ์เกมที่ดีที่สุดและบริการระดับสูง!',
    home_Beingamember: 'การเป็นสมาชิกของสโมสร VIP หมายความว่าคุณสามารถเข้าถึง:',
    home_PersonalVIPmanager: 'ผู้จัดการส่วนบุคคล',
    home_Insights: 'ความเป็นผู้นำ',
    home_Highercahbackrewards: 'การคืนเงินสูงขึ้น',
    home_Otheradditionalperks: 'สิ่งส่งเสริมเพิ่มเติม',
    home_WeeklyandMonthlyBonuses: 'โบนัสรายสัปดาห์และรายเดือน',
    home_Showhighgaming: 'แสดงกิจกรรมเกมสูงและได้รับเชิญให้เข้าร่วม VIP Club ของเรา',
    home_BindMobile: 'ผูกมือถือ',
    home_Pleasecheckthe: 'โปรดตรวจสอบ USGT ที่ได้รับจากเกมในกระเป๋าเงินของคุณ',
    home_BINDMOBILENUMBER: 'ผูกหมายเลขโทรศัพท์มือถือ',
    home_Get1USDT: 'รับ 1 USDT',
    home_Bind: 'ผูก',
    home_GiftCode: 'รหัสของขวัญ',
    home_EnteryourGiftCode: 'โปรดป้อนรหัสของขวัญของคุณ',
    home_EnterCode: 'ป้อนรหัส',
    home_Paste: 'วาง',
    home_confirms: 'ยืนยัน',
    home_BuyCrypto: 'ซื้อ',
    home_SignedIn: 'เข้าสู่ระบบแล้ว',
    home_CommissionRules: 'กฎคอมมิชชั่น',
    home_AffiliateTerms: 'ข้อกำหนดสมาชิก',
    home_AffiliateDashboard: 'แดชบอร์ดสมาชิก',
    home_AFFILIATE: 'สมาชิก',

    
    
    home_COMMISSONREADYEVERYDAY: 'พร้อมคอมมิชชั่นทุกวัน',
    home_MYREFERRALCODE: 'รหัสการแนะนำของฉัน',
    home_MYREFERRALLink: 'ลิงก์การแนะนำของฉัน',
    home_AffiliateReward: 'รางวัลสมาชิก',
    home_EXTRA: 'พิเศษ',
    home_USDREWARDS: 'รางวัลดอลลาร์สหรัฐ',
    home_USDRewardsRules: 'กฎเกณฑ์รางวัลดอลลาร์สหรัฐ',
    home_JUSAREFERRALAWAY: 'เพียงแค่การแนะนำเท่านั้น',
    home_NewlyAvailableRewards: 'รางวัลล่าสุด',
    home_ReceivedMao: 'ได้รับแล้ว:',
    home_Locked: 'ล็อก:',
    home_Yourcurrentfriends: 'เพื่อนของคุณที่ยังคงมีชีวิตชีวายังคงปลดล็อกรางวัลเพิ่มเติมให้กับคุณ',
    home_Withdraw: 'ถอน',
    home_MY: 'ของฉัน',
    home_COMMISSIONREWARDS: 'รางวัลคอมมิชชั่น',
    home_CommissionRewardRules: 'กฎเกณฑ์รางวัลคอมมิชชั่น',
    home_NewlyAvailableCommissionRewards: 'รางวัลคอมมิชชั่นล่าสุด',
    home_CommissionYouhaveReceivedinTotal: 'ได้รับค่าคอมมิชชั่น:',
    home_REWARDSSENTTODATE: 'รางวัลที่ส่งจนถึงปัจจุบัน',
    home_USDrewardssenttodate: 'รางวัลดอลลาร์สหรัฐที่ส่งจนถึงปัจจุบัน',
    home_Commissionsenttodatet: 'วันที่ส่งค่าคอมมิชชั่น',
    home_Areyouablogger: 'คุณเป็นบล็อกเกอร์ที่มีผู้อ่านจำนวนมากและผู้ติดตามหลายคนหรือไม่? เรามีโปรแกรมพันธมิตรที่เป็นเอกลักษณ์เฉพาะเจาะจงสำหรับคุณ มีการแนะนำที่มีข้อเสนอพิเศษและรางวัลตอบแทนที่มากขึ้น เราจะดำเนินการเตรียมโปรแกรมเป็นรายบุคคลที่มีเงื่อนไขพิเศษ ติดต่อผู้จัดการของเราเพื่อพูดคุยเกี่ยวกับข้อกำหนด',
    home_onlythoseusers: 'หมายเหตุสำคัญ: เฉพาะผู้ใช้ที่ได้รับคำขอจากผู้ดูแลระบบและได้รับการอนุมัติจากผู้ดูแลระบบเท่านั้นที่จะเข้าร่วมโปรแกรมนี้',
    home_notice: 'ประกาศ',
    home_bindPhone: 'ผูกโทรศัพท์',
    home_SignIn: 'เข้าสู่ระบบ',
    home_SignUp: 'สมัครสมาชิก',
    home_SignOut: 'ออกจากระบบ',
    home_CookiesPolicy: 'นโยบายคุกกี้',
    home_isUsingCookies: 'Funibet.com ใช้คุกกี้เพื่อให้บริการที่ดีขึ้นสำหรับทุกผู้ใช้งาน การใช้งานเว็บไซต์ของเราแสดงว่าคุณยินยอมใช้คุกกี้ โปรดดูนโยบายความเป็นส่วนตัวและข้อกำหนดการให้บริการ',
    home_SETTING: 'การตั้งค่า',
    home_LiveSupport: 'สนับสนุนออนไลน์',
    home_Accept: 'ยอมรับ',
    home_FrequentlyAskedQuestions: 'คำถามที่พบบ่อย',
    home_SiteUnnderConstruction: 'เว็บไซต์อยู่ระหว่างการปรับปรุง',
    home_ComingSoon: 'เร็วๆ นี้',
    home_Wallet: 'Wallet',
    home_UserId: 'รหัสผู้ใช้:',
    home_UserName: 'ชื่อผู้ใช้:',
    home_turnTimes: 'จำนวนครั้งที่หมุน:',
    home_Bonusincreased: "โบนัสเพิ่มเติม+",
    home_bonus: 'โบนัส',
    home_SPINBONUSTOTAL: 'รวมโบนัสการหมุน',
    home_WIN: 'WIN:',
    home_In: 'จาก',
    home_FirstDepositBonus: 'โบนัสฝากครั้งแรก',
    home_Hi: 'สวัสดี',
    home_welcomeAboard: 'ยินดีต้อนรับกลับมา',
    home_Outoftime: 'จำนวนครั้งวันนี้หมดแล้ว',
    
    game_search: 'ค้นหา',
    game_SortBy: 'เรียงลำดับโดย',
    game_Created: 'วันที่สร้าง',
    game_Lobby: 'โบนัส',
    game_LiveCasino: 'คาสิโนสด',
    game_FeaturedSlots: 'สล็อตเด่น',
    game_GameShows: 'โชว์เกม',
    game_FuniBetOriginals: 'เกมดั้งเดิมของ FuniBet',
    game_BigWins: 'ชนะเยอะ',
    game_LcukyWins: 'ชนะโชคดี',
    game_Challenges: 'ท้าทาย',
    game_Description: 'คำอธิบาย',
    game_Slots: 'สล็อต',
    game_Rank: 'อันดับ',
    game_User: 'ผู้ใช้',
    game_Date: 'วันที่',
    game_BetAmount: 'จำนวนเงินเดิมพัน',
    game_lucky: 'คูณต่ำสุด',
    game_Multiplier: 'คูณ',
    game_Payout: 'ผลลัพธ์',
    game_Recommend: 'แนะนำ',
    game_Providers: 'ผู้ให้บริการ',
    game_startGame: 'เริ่มเกม',
    game_balance: 'ยอดเงินคงเหลือ',
    game_provider: 'แท็ก',
    game_Selectyourdisplay: 'เลือกแสดงยอดเงินคงเหลือของคุณ',
    game_Balancein: 'ยอดเงินคงเหลือ',
    game_FunPlay: 'ลองเล่นเกม',
    game_RealPlay: 'โหมดเล่นเพื่อเล่นด้วยเงินจริง',
    game_DispLaying: "แสดง",
    game_LoadMore: "โหลดเพิ่มเติม",
    game_NoData: 'ไม่มีข้อมูลชั่วคราว',
    
    message_SigningIn: "ลงชื่อเข้าใช้ด้วย JWT",
    message_CourseSaved: "บันทึกคอร์สเรียนแล้ว",
    message_PleaseBind: "กรุณาผูกโทรศัพท์ของคุณก่อน",
    message_UserData: "บันทึกข้อมูลผู้ใช้ด้วย api แล้ว",
    message_Success: "สำเร็จ",
    message_VIPlevel: "ระดับ VIP ต่ำกว่า V3",
    message_ListOrder: "บันทึกการสั่งซื้อรายการแล้ว",
    message_CardOrder: "บันทึกการสั่งซื้อการ์ดแล้ว",
    message_HiHow: "สวัสดีครับ สบายดีไหมครับ?",

    Funibet_web: 'Funibet-เว็บ 3.0,คริปโต,เดิมพัน,กีฬา,กีฬาอีสปอร์ต,สล็อต,คาสิโนสด,โป๊กเกอร์',

    home_ThereAre: "ขณะนี้ไม่มีการแจ้งเตือน",

    play_no_balance: "ยอดเงินคงเหลือไม่เพียงพอ โปรดสลับสกุลเงินหรือเติมเงิน",
    
    home_News_1: 'ข่าวสาร',
    home_News_2: 'เหตุการณ์',
    home_News_3: 'คริปโต',
    home_News_4: 'เกม',
    
    home_SuggRep_1: 'คำแนะนำและรายงาน',
    home_SuggRep_2: 'คำแนะนำและรายงาน',
    home_SuggRep_3: 'แบ่งปันความคิดเห็นเพื่อปรับปรุง!',
    home_SuggRep_4: 'รายงานเว็บไซต์ขายเกม',
    home_SuggRep_5: 'กรอกคำแนะนำหรือส่งรายงาน',
    home_SuggRep_6: 'ภาพหน้าจอตัวอย่าง',
    home_SuggRep_7: 'รางวัลสำหรับคำแนะนำและรายงานที่ได้รับการนำไปใช้จริง!',
    home_SuggRep_8: 'ส่ง',
    
    home_subscription_1: 'คุณต้องการสมัครรับการแจ้งเตือนแบบพุชหรือไม่?',
    home_subscription_2: 'คุณสามารถยกเลิกการสมัครได้ตลอดเวลา',
    home_subscription_3: 'พุชเว็บโดย WonderPush',
    home_subscription_4: 'อย่างหลัง',
    home_subscription_5: 'สมัครรับ',
    
    home_Affiliate_1: 'ติดต่อเรา',
    home_Affiliate_2: 'ทุกคนที่คุณเชิญจะได้รับ 10U ของคุณ ยิ่งคุณเชิญเพิ่ม ยิ่งได้รับมากขึ้น!',
    home_Affiliate_3: 'เทเลแกรม',
    home_Affiliate_4: 'อีเมล์',
    

    home_banner_1: 'เกมคริปโต',
    home_banner_2: 'รองรับเหรียญ Crypto 100+ และ NFT 50+',
    home_banner_3: 'การเดิมพันดีซีนทรัพย์',
    home_banner_4: 'การเดิมพันสัญญาอัจฉริยะจากกระเป๋าเงินดีซีนทรัพย์ที่ไม่มีการกำหนด',
    home_banner_5: 'วางเดิมพันเพื่อหากำไร',
    home_banner_6: 'วางเดิมพัน, ขุดและลงทุนเพื่อกำไรที่มั่นคง',
    home_banner_7: 'Web3.0 + คาสิโน',
    home_banner_8: 'ผู้เล่นเป็นเจ้าของธนาคารและเจ้าของ',
    
    home_EventDetails: 'รายละเอียด',

    home_vip_1: "1.รับรายได้จากการเช็คอิน+",
    home_vip_2: "2.รับรายได้จากการหมุน+",
    home_vip_3: "3.รับรายได้จากการแชร์+",
    home_vip_4: "4.รับรายได้จากการเติมเงิน+",
    home_vip_5: "5.เปิดใช้งานแชทสามารถส่งข้อความได้ทุกวัน",
    home_vip_6: "ข้อความ",

    
    ERROR_628: 'รหัสของขวัญถูกใช้แล้ว',
    ERROR_629: 'รหัสของขวัญถูกใช้ครบแล้ว',
    ERROR_630: 'รหัสของขวัญไม่ได้เปิดใช้งาน',
    ERROR_631: 'รหัสของขวัญหมดอายุแล้ว',
    ERROR_633: 'ไม่สามารถใช้รหัสของขวัญจากตัวแทนอื่นได้',

    home_Maintaining_1: "การบำรุงรักษา",
    home_Maintaining_2: "คุณลักษณะนี้กำลังอยู่ในขั้นตอนการบำรุงรักษา",
    home_Maintaining_3: "จบ",
    home_Maintaining_4: "เริ่ม",

    home_Slots: 'สล็อต',
    home_LiveCasino: 'คาสิโนสด',
    home_CountryFiat: 'ประเทศและเงินฟีด',
    home_CryptNFTs: 'เหรียญโครงการและ NFT',

    home_GoTo:'ไปที่',

    home_gameout_1: "คุณกำลังเล่นเกมอื่นในขณะนี้หรือไม่? คุณต้องการออกไหม?",
    home_gameout_2: "ยกเลิก",

     home_BuyFund1:'คุณต้องการซื้อกองทุนการเติบโตหรือไม่?',

     home_BetFor:'พนันสำหรับ',

     tongzhi_1: "คำแนะนำ",
     tongzhi_2: "ตอบกลับคำแนะนำและตัวแทน",
     tongzhi_3: "ทำดีมาก! นี่เยี่ยมมาก",
     tongzhi_4: "กำลังรอการตรวจสอบ",
     tongzhi_5: "การตรวจสอบผ่าน",
     tongzhi_6: "กำลังประมวลผลการชำระเงิน",
     tongzhi_7: "ชำระเงินเสร็จสิ้นเรียบร้อย",
     tongzhi_8: "การตรวจสอบไม่ผ่าน",
     tongzhi_9: "การชำระเงินล้มเหลว",
     tongzhi_10: "การถอนเงินล้มเหลว",
     tongzhi_11: "จ่ายเงินแล้ว",
     tongzhi_12: "เสร็จแล้ว",
     tongzhi_13: "การประมวลผลล้มเหลว",
     tongzhi_14: "ซื้อ",
     tongzhi_15: "ถึง",
     tongzhi_16: "ถอน",
     tongzhi_17: "เงินดิจิทัล",
     tongzhi_18: "เงินฟีแอต",
     tongzhi_19: "ดาวน์โหลด",
     tongzhi_20: "การเข้าสู่ระบบสามารถได้รับรางวัลได้",
     tongzhi_21: "ดาวน์โหลด",
     tongzhi_22: "ไม่มีเวอร์ชัน iOS ที่มีให้ใช้ในขณะนี้",
     tongzhi_23: "ส่ง",
     tongzhi_24: "ส่งเคล็ดลับ",

     home_banner_9: "แข่งม้า",
     home_banner_10: "ชกไก่",
     home_banner_11: "น่าตื่นเต้นและน่าตื่นเต้น ชนะรางวัลใหญ่",
     home_banner_12: "เต็มไปด้วยความกระตือรือร้นและจิตสู้รบ",
     
     home_wanfa_1:"ปัญหาการเล่นเกม",
     home_wanfa_2:"ปัญหาการโอนเงิน",
     home_wanfa_3:"ปัญหาอื่น ๆ",
     play_not_balance: "ไม่สามารถเดิมพันกีฬาด้วย eUSDT ได้ คุณต้องการสลับสกุลเงินหรือไม่?",




    };
    
    export default locale;
    
    
