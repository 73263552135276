export const labelColors = [
  'gray',
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'blue',
  'indigo',
  'purple',
  'pink',
];

export const labelColorDefs = {
  gray: {
    text: 'text-gray-500',
    bg: 'bg-gray-500',
    combined: 'text-gray-800 bg-gray-100',
  },
  red: {
    text: 'text-red-500',
    bg: 'bg-red-500',
    combined: 'text-red-800 bg-red-100',
  },
  orange: {
    text: 'text-orange-500',
    bg: 'bg-orange-500',
    combined: 'text-orange-800 bg-orange-100',
  },
  yellow: {
    text: 'text-yellow-500',
    bg: 'bg-yellow-500',
    combined: 'text-yellow-800 bg-yellow-100',
  },
  green: {
    text: 'text-green-500',
    bg: 'bg-green-500',
    combined: 'text-green-800 bg-green-100',
  },
  teal: {
    text: 'text-teal-500',
    bg: 'bg-teal-500',
    combined: 'text-teal-800 bg-teal-100',
  },
  blue: {
    text: 'text-blue-500',
    bg: 'bg-blue-500',
    combined: 'text-blue-800 bg-blue-100',
  },
  indigo: {
    text: 'text-indigo-500',
    bg: 'bg-indigo-500',
    combined: 'text-indigo-800 bg-indigo-100',
  },
  purple: {
    text: 'text-purple-500',
    bg: 'bg-purple-500',
    combined: 'text-purple-800 bg-purple-100',
  },
  pink: {
    text: 'text-pink-500',
    bg: 'bg-pink-500',
    combined: 'text-pink-800 bg-pink-100',
  },
};
