const locale = {
  GAME: 'Games',
  Casino: "Casino",
  ProvablyFair: "Provably Fair",
  Option: 'Option',
  Crypto: 'Crypto',
  Forex: 'Forex',
  Stocks: 'Stocks',
  NFTFuniCard: 'NFT FuniCard',
  StakeNFT: 'Stake NFT earn USDT',
  NFTStake: 'NFT Stake',
  Marketplace: 'Marketplace',
  NFTMint: 'NFT Mint',
  BettoEarn: 'Bet to Earn',
  EarnUSGT: 'Earn USDT',
  DeFi: 'DeFi',
  ToBeVolunteer: 'To Be Volunteer',
  SYSTEM: 'SYSTEM',
  Affiliate: 'Affiliate',
  News: 'News',
  FAQs: 'FAQs',
  DOWNLOAD: 'DOWNLOAD',
  DOWNLOADApp: 'Download App',
  Savetodesktop: 'Save to desktop',
  BetMining: 'Bet Mining',
  ToBeBanker: 'To Be Banker',
  ProfitPool: 'Profit Pool',
  USGTLiquidityMining: 'USDT Liquidity Mining',
  BGTLiquidityMining: 'BGT Liquidity Mining',
  Slots: 'Slots',
  Favorite: 'Favorite',
  Recent: 'Recent',
  Popular: "Popular",
  LiveCasino: "Live Casino",
  TableGames: "Table Games",
  BlackJack: "BlackJack",
  Baccarat: "Baccarat",
  Roulette: "Roulette",
  MoreGames: "MoreGames",
  Provablyfair: "Provably fair",
  Special: "Special",
  Recommend: "Recommend",
  FeatureBuyin: "Feature Buy-in",
  EnableTrialPlay: "Enable Trial Play",
  GameShow: "Game Show",
  RGSVSB: "RGS - VSB",
  Scratch: "Scratch",
  Bingo: "Bingo",
  Fishing: "Fishing",
  Turntable: "Turntable",
  Dice: "Dice",
  Poker: "Poker",
  Sport: "Sport",
  AllGames: "AllGames",
  EuropeanView: "European View",
  AfricanView: "African View",
  LatinView: "Latin View",
  AsianView: "Asian View",
  LsportView: "Lsport View",
  StandardView: "Standard View",
  EsportView: "Esport View",
  LiveBetting:'LiveBetting',
  Provider:'Provider',


};

export default locale;
