import Play from './Play';

const PlayConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'play/:channel/:gameId/:casinoID',
            element: <Play />,
        },
    ],
};

export default PlayConfig;
