  const locale = {
  GAME: 'Jogos',
  Casino: "Cassino",
  ProvablyFair: "Provably Fair",
  Option: 'Opção',
  Crypto: 'Cripto',
  Forex: 'Forex',
  Stocks: 'Ações',
  NFTFuniCard: 'NFT FuniCard',
  StakeNFT: 'Aposta NFT ganhar USDT',
  NFTStake: 'Aposta NFT',
  Marketplace: 'Mercado',
  NFTMint: 'NFTMint',
  BettoEarn: 'Aposte para ganhar',
  EarnUSGT: 'Ganhar USDT',
  DeFi: 'DeFi',
  ToBeVolunteer: 'Ser voluntário',
  SYSTEM: 'SISTEMA',
  Affiliate: 'Afiliação',
  News: 'Notícias',
  FAQs: 'FAQs',
  DOWNLOAD: 'BAIXAR',
  DOWNLOADApp: 'Baixar App',
  Savetodesktop: 'Salvar na área de trabalho',
  BetMining: 'Mineração de aposta',
  ToBeBanker: 'Ser banqueiro',
  ProfitPool: 'Piscina de lucro',
  USGTLiquidityMining: 'Liquidity mining USDT',
  BGTLiquidityMining: 'Liquidity mining BGT',
  Slots: 'caça-níqueis',
  Favorite: 'Favorito',
  Recent: 'Recente',
  Popular: "Popular",
  LiveCasino: "Cassino ao vivo",
  TableGames: "Jogos de mesa",
  BlackJack: "Blackjack",
  Baccarat: "Bacará",
  Roulette: "Roleta",
  MoreGames: "Mais jogos",
  Provablyfair: "Provably Fair",
  Special: "Especial",
  Recommend: "Recomendar",
  FeatureBuyin: "Feature Buy-in",
  EnableTrialPlay: "Ativar teste de jogo",
  GameShow: "Jogo Show",
  RGSVSB: "RGS - VSB",
  Scratch: "Raspadinha",
  Bingo: "Bingo",
  Fishing: "Pesca",
  Turntable: "Giradiscos",
  Dice: "Dados",
  Poker: "Pôquer",
  AllGames: "Todos os jogos",
  EuropeanView: "Vista europeia",
  AfricanView: "Vista africana",
  LatinView: "Vista latina",
  AsianView: "Visão Asiática",
  LsportView: "Liga Esportiva",
  StandardView: "Visão Padrão",
  EsportView: "Esportes eletrônicos",
  LiveBetting: 'Apostas ao vivo',
  Provider: 'Fornecedor',
  Sport: "Esporte",



};

export default locale;
