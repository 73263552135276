import { createSlice } from '@reduxjs/toolkit';
import history from '@history';
import { setSpreaderInfo } from "app/store/user/userThunk";

// state
const initialState = {
    faqs: {}
};

const faqsSlice = createSlice({
    name: 'faqs',
    initialState,
    reducers: {
        updateFaqs: (state, action) => {
            let res = action.payload;
            state.faqs = res;
        },
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateFaqs } = faqsSlice.actions;

export const selectFaqs = ({ faqs }) => faqs;

export default faqsSlice.reducer;
