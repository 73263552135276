import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    slots: 1,           // Slots ID
    liveCasino: 2,      // LiveCasino ID
    provablyFair: 3,    // ProvablyFair ID
    special: 4,         // special ID
    recommondTag: 5,    // 推荐标签ID
    favoriteTag: 6,    // Favorite ID
    tagConfig: {
        tagType: 1,                         // 分类标签
        tagPlatform: 2,                     // 平台标签
        tagRule: 3,                         // 规则标签
        tagToPlay: 4,                       // 玩法标签
        tagLang: 100,                       // 语言标签
        tagCurrency: 200,                   // 法币标签
        tagCountry: 300,                    // 国家标签
        tagSymbol: 400,                     // 加密货币标签
        tagLangGroup: [10000, 19999],       // 语言分组标签
        tagCurrencyGroup: [20000, 29999],   // 法币分组标签
        tagCountryGroup: [30000, 39999],    // 国家分组标签
        tagSymbolGroup: [40000, 49999],     // 加密货币分组标签
    },
};

const casinoSlice = createSlice({
    name: 'casino',
    initialState,
    reducers: {
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { } = casinoSlice.actions;

export const selectCasinoData = ({ casino }) => casino;

export default casinoSlice.reducer;
