import "../../../styles/game.css";
import * as React from 'react';
import { useEffect, useState } from "react";
import history from '@history';

function OfficialAPP(props) {
    useEffect(() => {
        window.open('https://static-scource.funibet.com/funibet/downLoad/funibet.apk')
        history.back();
    }, []);
    return (
        <></>
    );
}
export default OfficialAPP;
