const locale = {
    home_StakeNFT: 'Stake NFT tienaa ilmaiseksi ',
    home_everyday: 'Joka päivä',
    home_Beton: 'vedonlyönti ',
    home_mining: 'Louhinta',
    home_shareholder: ' Tule osakkaaksi',
    home_Stake: 'Panosta ',
    home_dividend: ' Saat osuuden voitosta',
    home_Banker: 'Tule yhdeksi kumppaneistamme',
    home_SIGNUPAND: 'SIGN UP & ',
    home_GET: 'GET',
    home_UPTO: 'UP TO ',
    home_FuniHash: 'Lohkoketjupelit',
    home_Option: 'Optio',
    
    home_Reward: ' Reward',
    home_Reward1: 'Reward',
    home_signUpNow: 'Rekisteröidy nyt',
    home_Casino: 'Kasino',
    home_Sports: 'Urheilu',
    home_PROMOTION: 'Tarjoukset',
    home_Checkin: 'ilmoittautua',
    home_Bouns: 'Bonus',
    home_CheckinBouns: 'Kirjautumisbonus',
    home_SPIN: 'Pyöräytä',
    home_Relief: 'Helpotus',
    home_Share: 'Jaa',
    home_Deposit: 'Talletus',
    home_Fund: 'Sijoitusrahasto',
    home_VIPClub: 'VIP-klubi',
    home_FastAndEasy: 'Osta kryptovaluuttaa nopeasti ja helposti saadaksesi USDT-talletusbonuksen',
    home_Game: 'Peli',
    home_User: 'Käyttäjä',
    home_Time: 'Aika',
    home_BetAmount: 'Panosmäärä',
    home_Multiplier: 'Kerroin',
    home_Payout: 'Voitto',
    home_JoinOur: 'Liity urheiluhullujen, kryptovaluutta-fanien ja uhkapelien yhteisöömme',
    home_JOIN: 'Liity',
    home_LICENSES: 'Lisenssit',
    home_OurPlatforms: 'Alustamme',
    home_Marketplac: 'Markkinapaikka',
    home_Howtoearn: 'Miten ansaita',
    home_Games: 'Pelit',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'Sijoittajien alusta',
    home_News: 'Uutiset',
    home_Aboutsus: 'Tietoa meistä',
    home_Industry: 'Teollisuus',
    home_Roadmap: 'Tiekartta',
    home_Partner: 'Kumppani',
    home_FAQs: 'Usein kysytyt kysymykset',
    home_Affiliate: 'Jäsen',
    home_Stayintouch: 'Pysy yhteydessä',
    home_ChatRoom: 'Keskusteluhuone',
    home_Selectacontact: 'Valitse yhteystieto aloittaaksesi keskustelun. ',
    home_BetSlip: 'Vetolappu',
    home_Typeyourmessage: 'Kirjoita viestisi',
    home_AcceptAnyOdds: 'Hyväksy minkä tahansa kertoimen',
    home_ClearAll: 'Tyhjennä kaikki',
    home_Live: 'Live',
    home_Winner: 'Voittaja',
    home_EstPayout: 'Arvioitu maksu',
    home_TotalOdds: 'Kokonaiskerroin',
    home_RegistertoBet: 'Rekisteröidy lyömään vetoa',
    home_Single: 'Yksittäinen veto',
    home_Multi: 'Moninkertainen veto',
    home_Checkineveryweek: 'Kerää pisteitä joka viikko ja saat vastaavan palkinnon. ',
    home_Day: 'Päivä',
    home_Sunday: '1. päivä',
    home_Monday: '2. päivä',
    home_Tuesday: '3. päivä',
    home_Wednesday: '4. päivä',
    home_Thursday: '5. päivä',
    home_Friday: '6. päivä',
    home_Saturday: '7. päivä',
    home_FreeSpin: 'Pyöräytä',
    home_Turntheturntable: 'Pyöritä pyörää joka päivä saadaksesi USGT-palkinnon.',
    home_DepositFift: 'Talletus',
    home_ANYAMOUNT: 'Saat ensimmäisestä talletuksestasi minkä tahansa summan',
    home_DEPOSIT: 'Talletus',
    home_First: 'Ensimmäinen',
    home_ONLY: 'Vain',
    home_1ST: '1.',
    home_2ND: '2.',
    home_3TH: '3.',
    home_ANYAMOUNT: 'MIKÄ TAHANSA SUMMA!',
    home_EXTRA: 'Lisäbonus',
    home_Relief: 'Helpotus',
    home_Getrelief: 'Hae helpotusta ja saat tilaisuuden kääntää tilanteesi ympäri.',
    home_YOUCANCLAIM: 'Voit saada helpotusta, kun saldosi on alle',
    home_YOUCANReceive: ', saat lisäksi helpotusta tasalle',
    home_threeTimes: ' 3 kertaa',
    home_everydayTimes: '/päivä!',
    home_Times: 'Kertoja:',
    home_TotalAssets: 'Kokonaisvarat',
    home_receive: 'Vastaanota',
    home_TODAY: 'Tänään',
    home_Share: 'Jaa',
    home_PLATFORMSHARING: 'Jaa muihin alustoihin',
    home_Sharewith: 'Jaa FuniBet joka päivä ja saat',
    home_SHARINGGIFT: 'Jakamislahja',
    home_CODE: 'Jakokoodi',
    home_Selectplatform: 'Valitse jaettava alusta:',
    home_Fund: 'Kasvurahasto',
    home_FundBonus: 'Fund Bonus',
    home_Improveyour: 'Improve your VIP level and get more fund rewards every day.',
    home_BuyFund: 'Ostaa',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'status',
    home_Received: 'Vastaanotettu',
    home_noReceived: 'unmet',
    home_ExclusiveBenefits: 'Yksinoikeudella olevat edut',
    home_Asawayof: 'VIP-klubin jäsenyyden avulla FuniBet haluaa ilmaista kiitollisuutensa ja arvostuksensa VIP-pelaajiaan kohtaan. VIP-jäsenet pääsevät nauttimaan ainutlaatuisista lahjoista, ilmaisista tuotteista, paremmista käteispalautuksista ja erityisistä ominaisuuksista. Älä missaa mitään jännittävää ja hämmästyttävää! ',
    home_Yourcurrent: 'Your current Total wager',
    home_WhyUpgrade: 'Miksi päivittää VIP-jäsenyyteen? ',
    home_VIPhave: 'VIP-jäsenet saavat henkilökohtaista palvelua 24/7 -palveluneuvojaltaan. VIP-pelaajat saavat korkeimmat tasot ilmaiskierroksia, viikoittaisia / kuukausittaisia bonuksia ja nopeimmat talletus- ja nostokanavat. VIP-jäsenillä on mahdollisuus osallistua huippuluokan pelaajatapaamisiin ja auttaa kehittämään kasinoa ehdottamalla uusia toimintoja ja ominaisuuksia FuniBet-tiimille. ',
    home_UpgradeYour: 'Päivitä VIP-jäsenyytesi',
    home_ASaFunibet: 'FUNIBet VIP-klubin jäsenenä saat runsaasti palkintoja ja yksilöllisiä lahjoja, mutta se ei ole kaikki. Mitä enemmän pelaat, sitä parempia luksusetuja saat, ja kun päivität VIP-tasosi Elite VIP -jäsenyyteen, saat räätälöityjä VIP-etuja. ',
    home_YOUCANREACH: 'Voit päivittää VIP-tasosi',
    home_LEVELS: 'tasoille',
    home_eachthehigh: 'Kaikki voivat saada 25% käteispalautuksen ja nauttia kaikista FuniBetin eduista. Liity FUNIBETiin ja nauti parhaasta pelikokemuksesta ja laadukkaasta palvelusta! ',
    home_Cashback: 'Käteispalautus',
    home_Nowater: '1. Ei enää vedonlyöntikuluja pelissä',
    home_Moregrowth: '2. Enemmän kasvurahastoja',
    home_Increasein: '3. Etuja enemmän',
    home_Doublethenumber: '4. Kaksinkertainen määrä onnenpyöriä',
    home_Doublethesign: '5. Kaksinkertainen kirjautumismäärä',
    home_Doublesharingrewards: '6. Kaksinkertainen jako-palkinto',
    home_DoubletheAffiliate: '7. Kaksinkertainen kumppanipalkinto',
    home_Rechargerewardamount: '8. Talletusmäärä 1,5-kertainen',
    home_ABOUTVIPCLUB: 'ABOUT VIP CLUB',
    home_HightopRank: 'High top Rank has 25% cashback bonus and the best benefits. Stay with BeDao to receive the best gaming experience and high level of service!',
    home_Beingamember: 'Being a member of the VIP club means that you have access to:',
    home_PersonalVIPmanager: 'Personal VIP manager',
    home_Highercahbackrewards: 'Higher cahback rewards',
    home_Otheradditionalperks: 'Other additional perks',
    home_Insights: 'Tietämykset',
    home_WeeklyandMonthlyBonuses: 'Viikoittaiset ja kuukausittaiset bonukset',
    home_Showhighgaming: 'Näytä korkean tason pelitoimintaa ja saa kutsu VIP-klubiimme. ',
    home_BindMobile: 'Liittää',
    home_Pleasecheckthe: 'Tarkista lompakostasi saamasi USGT.',
    home_BINDMOBILENUMBER: 'Liittää numero',
    home_Get1USDT: 'Saat 1 USDT',
    home_Bind: 'Liitä',
    home_GiftCode: 'Lahjakoodi',
    home_EnteryourGiftCode: 'Syötä lahjakoodisi',
    home_EnterCode: 'Syötä koodi',
    home_Paste: 'Liitä',
    home_confirms: 'Vahvista',
    home_BuyCrypto: 'Ostaa',
    home_SignedIn: 'Kirjautunut sisään',
    home_CommissionRules: 'Palkkiosäännöt',
    home_AffiliateTerms: 'Jäsenyysehdot',
    home_AffiliateDashboard: 'Jäsenkohtainen hallintapaneeli',
    home_AFFILIATE: 'Jäsenyys',
    

    
    home_COMMISSONREADYEVERYDAY: 'Valmis provisio joka päivä.',
    home_MYREFERRALCODE: 'Suosittelukoodini',
    home_MYREFERRALLink: 'Suosittelulinkkini',
    home_AffiliateReward: 'Kumppanipalkkio',
    home_EXTRA: 'Lisää',
    home_USDREWARDS: 'USD-palkkiot',
    home_USDRewardsRules: 'USD-palkkioiden säännöt',
    home_JUSAREFERRALAWAY: 'Vain yksi suositus',
    home_NewlyAvailableRewards: 'Uudet palkkiot',
    home_ReceivedMao: 'Vastaanotettu:',
    home_Locked: 'Lukittu:',
    home_Yourcurrentfriends: 'Aktiivisten ystäviesi avulla voit avata lisää palkintoja',
    home_Withdraw: 'Nosta',
    home_MY: 'Oma',
    home_COMMISSIONREWARDS: 'Kumppanipalkkio',
    home_CommissionRewardRules: 'Kumppanipalkkioiden säännöt',
    home_NewlyAvailableCommissionRewards: 'Uudet kumppanipalkkiot',
    home_CommissionYouhaveReceivedinTotal: 'Vastaanotetut kumppanipalkkiot yhteensä:',
    home_REWARDSSENTTODATE: 'Tähän mennessä lähetetyt palkkiot',
    home_USDrewardssenttodate: 'Tähän mennessä lähetetyt USD-palkkiot',
    home_Commissionsenttodatet: 'Lähetyspäivämäärä',
    home_Areyouablogger: 'Oletko bloggaaja, jolla on suuri lukijakunta ja paljon seuraajia? Tarjoamme sinulle erikoisalennuksen ainutlaatuisessa kumppaniohjelmassa, jossa on suuremmat suositusbonukset. Laadimme erityisehdot sisältävän erillisen ohjelman. Ota yhteyttä johtajaamme keskustellaksesi ehdoista.',
    home_onlythoseusers: 'Tärkeä huomautus: Vain hallinnon pyynnöstä ja hallinnon hyväksynnän saaneet käyttäjät voivat osallistua tähän ohjelmaan.',
    home_notice: 'Ilmoitus',
    home_bindPhone: 'Sitoo puhelin',
    home_SignIn: 'Kirjaudu sisään',
    home_SignUp: 'Rekisteröidy',
    home_SignOut: 'Kirjaudu ulos',
    home_CookiesPolicy: 'Evästeiden politiikka',
    home_isUsingCookies: 'Funibet.com käyttää evästeitä tarjotakseen parempaa palvelua jokaiselle käyttäjälle. Käyttämällä sivustoamme hyväksyt evästeiden käytön. Tutustu tietosuojakäytäntöön ja palveluehtoihin.',
    home_SETTING: 'Asetukset',
    home_LiveSupport: 'Live-tuki',
    home_Accept: 'Hyväksyä',
    home_FrequentlyAskedQuestions: 'Usein kysytyt kysymykset',
    home_SiteUnnderConstruction: 'Sivusto on rakenteilla',
    home_ComingSoon: 'Tulossa pian',
    home_Wallet: 'Wallet',
    home_UserId: 'ID: ',
    home_UserName: 'Nimi: ',
    home_turnTimes: 'Pyörityskerrat:',
    home_Bonusincreased: "Bonus lisätty +",
    home_bonus: 'Bonus',
    home_SPINBONUSTOTAL: 'SPIN BONUS TOTAL',
    home_WIN: 'Voitto:',
    home_In: 'Sisään',
    home_FirstDepositBonus: 'Ensitalletusbonus',
    home_Hi: 'Hei',
    home_welcomeAboard: 'Tervetuloa takaisin',
    home_Outoftime: 'Tänään ei ole enää pyörityksiä jäljellä',
    
    game_search: 'haku',
    game_SortBy: 'Lajittele',
    game_Created: 'Luotu',
    game_Lobby: 'Lobby',
    game_LiveCasino: 'Live-kasino',
    game_FeaturedSlots: 'Esittelyssä olevat peliautomaatit',
    game_GameShows: 'Pelimuodot',
    game_FuniBetOriginals: 'FuniBetin alkuperäiset pelit',
    game_BigWins: 'Isoja voittoja',
    game_LcukyWins: 'Onnekkaat voitot',
    game_Challenges: 'Haasteet',
    game_Description: 'Kuvaus',
    game_Slots: 'Peliautomaatit',
    game_Rank: 'Sijoitus',
    game_User: 'Käyttäjä',
    game_Date: 'Päivämäärä',
    game_BetAmount: 'Panos',
    game_lucky: 'Suurin kerroin',
    game_Multiplier: 'Kerroin',
    game_Payout: 'Voitto',
    game_Recommend: 'Suositus',
    game_Providers: 'Pelintarjoajat',
    game_startGame: 'aloittaa',
    game_balance: 'Saldo',
    game_provider: 'Tagi',
    game_Selectyourdisplay: 'Valitse näytettävä saldo',
    game_Balancein: 'Saldo',
    game_FunPlay: 'Hauska peli',
    game_RealPlay: 'Oikea peli',
    game_DispLaying: "Näytetään",
    game_LoadMore: "Lataa lisää",
    game_NoData: 'Oops! Tietoja ei nyt ole saatavilla.',
    
    message_SigningIn: "Kirjaudutaan JWT:n avulla",
    message_CourseSaved: "Kurssi tallennettu",
    message_PleaseBind: "Sitouta puhelinnumerosi ensin",
    message_UserData: "Käyttäjän tiedot tallennettu API:n avulla",
    message_Success: "onnistunut",
    message_VIPlevel: "VIP-taso on alle V3",
    message_ListOrder: "Luettelon järjestys tallennettu",
    message_CardOrder: "Kortin tilaus tallennettu",
    message_HiHow: "Hei, miten voit?",

    Funibet_web: 'Funibet-Web3.0,Kryptovaluutta,Vedonlyönti,Urheilu,E-urheilu,Kolikkopelit,Live-kasino,Pokeri',

    home_ThereAre:"Tällä hetkellä ei ole ilmoituksia." ,

    play_no_balance: "Nykyinen valuuttasaldo on riittämätön, vaihda valuuttaa tai lataa lisää rahaa",
    
    home_News_1:'Uutiset',
    home_News_2:'Tapahtuma',
    home_News_3:'Kryptovaluutta',
    home_News_4:'Pelaaminen',
    
    home_SuggRep_1:'Ehdotus ja raportointi',
    home_SuggRep_2:'Ehdotukset ja raportit',
    home_SuggRep_3:'Jaa palautteesi parantaaksemme!',
    home_SuggRep_4:'Ilmoita verkkosivusto, joka myy pelissä olevia esineitä.',
    home_SuggRep_5:'Anna ehdotuksesi tai lähetä raportti',
    home_SuggRep_6:'Näytteen kuvakaappaus',
    home_SuggRep_7:'Palkkio hyväksytyistä ehdotuksista ja raporteista!',
    home_SuggRep_8:'Lähetä',
    
    home_subscription_1:'Haluaisitko tilata push-ilmoituksia?',
    home_subscription_2:'Voit aina peruuttaa tilauksen milloin tahansa.',
    home_subscription_3:'Web push - WonderPush',
    home_subscription_4:'Myöhemmin',
    home_subscription_5:'Tilaa',
    
    home_Affiliate_1:'Ota yhteyttä',
    home_Affiliate_2:'Kutsu jokainen kaveri, jonka kutsumasi henkilö saa sinulle 10U:ta, mitä enemmän kutsut, sitä enemmän saat!',
    home_Affiliate_3:'Telegram',
    home_Affiliate_4:'Sähköposti',

    home_banner_1: 'Krypto-pelaaminen',
    home_banner_2: 'Tukee yli 100 kryptovaluuttaa ja yli 50 NFT:tä',
    home_banner_3:'Decentralized Betting',
    home_banner_4:'Smart contract betting from decentralized wallet',
    home_banner_5:'Bet 2 Earn',
    home_banner_6:'Bet, mine, and invest for steady profits',
    home_banner_7: 'Web3.0 + kasino',
    home_banner_8: 'Pelaaja on myös pankkiiri ja omistaja',

    home_EventDetails: 'tietää',

    home_vip_1: "1.Ansaitse tuloja kirjautumisesta+",
    home_vip_2: "2.Ansaitse tuloja pyöräyttämällä+",
    home_vip_3: "3.Ansaitse tuloja jakamalla+",
    home_vip_4: "4.Ansaitse tuloja lataamalla+",
    home_vip_5: "5.Chat avattu, viestejä voi lähettää päivittäin",
    home_vip_6: "Viestit",

    ERROR_628: 'Lahjakoodi on jo lunastettu',
    ERROR_629: 'Lahjakoodi on lunastettu kokonaan',
    ERROR_630: 'Lahjakoodi ei ole aktiivinen',
    ERROR_631: 'Lahjakoodi on vanhentunut',
    ERROR_633: 'Ei voi käyttää toisen välittäjän lahjakoodia',

    home_Maintaining_1: "Ylläpito",
    home_Maintaining_2: "Tämä ominaisuus on huollossa",
    home_Maintaining_3: "LOPPU",
    home_Maintaining_4: "ALKAA",

    home_Slots: 'Kolikkopelit',
    home_LiveCasino: 'Live-kasino',
    home_CountryFiat: 'Maa ja Fiat',
    home_CryptNFTs: 'Krypto & NFTt',
    
    home_GoTo:'Mene',

    home_gameout_1: "Oletko tällä hetkellä toisessa pelissä? Haluatko poistua?",
    home_gameout_2: "Peruuta",

     home_BuyFund1:'Haluaisitko ostaa kasvurahaston?',

     home_BetFor:' Panosta',

     tongzhi_1: "Ehdotus",
     tongzhi_2: "Vastaa ehdotukseen ja edustajaan.",
     tongzhi_3: "Hyvin tehty! Tämä on mahtavaa.",
     tongzhi_4: "Odottaa tarkastelua.",
     tongzhi_5: "Tarkastelu hyväksytty.",
     tongzhi_6: "Maksu käsitteillä.",
     tongzhi_7: "Maksu suoritettu onnistuneesti.",
     tongzhi_8: "Tarkastelu hylätty.",
     tongzhi_9: "Maksu epäonnistui.",
     tongzhi_10: "Nosto epäonnistui.",
     tongzhi_11: "Maksettu.",
     tongzhi_12: "Valmis.",
     tongzhi_13: "Käsittely epäonnistui.",
     tongzhi_14: "Osta",
     tongzhi_15: "Asti",
     tongzhi_16: "Nosta",
     tongzhi_17: "Kryptovaluutta",
     tongzhi_18: "Fiat-valuutta",
     tongzhi_19: "Lataa ",
     tongzhi_20: "Sisäänkirjautuminen voi olla palkitsevaa",
     tongzhi_21: "Lataa",
     tongzhi_22: "iOS-versiota ei ole tällä hetkellä saatavilla",

     home_banner_9: "Hevoskilpailut",
     home_banner_10: "Kukkotappelu",
     home_banner_11: "Jännittävää ja kiehtovaa, voita suuria palkintoja",
     home_banner_12: "Verenkuumetta ja täynnä taistelutahtoa",
     play_not_balance: "Urheiluvedonlyönti eUSDT: llä ei ole mahdollista. Haluatko vaihtaa valuuttaa?",



    
    };
    
    export default locale;
    
    
