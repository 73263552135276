const locale = {
  GAME: 'ゲーム',
  Casino: "カジノ",
  ProvablyFair: "公平を証明できる",
  Option: '選択肢',
  Crypto: '仮想通貨',
  Forex: '為替',
  Stocks: 'ストック',
  NFTFuniCard: 'NFT フニカード',
  StakeNFT: 'ベット NFT 得る USDT',
  NFTStake: 'NFT ベット',
  Marketplace: '市場',
  NFTMint: 'NFTミント',
  BettoEarn: '賭けて稼ぐ',
  EarnUSGT: '得る USDT',
  DeFi: '分散型金融',
  ToBeVolunteer: 'ボランティアになる',
  SYSTEM: 'システム',
  Affiliate: 'メンバー',
  News: 'ニュース',
  FAQs: 'よくある問題',
  DOWNLOAD: 'ダウンロード',
  DOWNLOADApp: 'ダウンロード App',
  Savetodesktop: 'デスクトップに保存',
  BetMining: '賭けプール',
  ToBeBanker: '株主になる',
  ProfitPool: '利益プール',
  USGTLiquidityMining: 'USDT Liquidity Mining',
  BGTLiquidityMining: 'BGT Liquidity Mining',
  Slots: 'スロットマシン',
  Favorite: 'お気に入り',
  Recent: '最近の',
  Popular: "ポピュラー",
  LiveCasino: "ライブカジノ",
  TableGames: "テーブルゲーム",
  BlackJack: "ブラックジャック",
  Baccarat: "バカラ",
  Roulette: "ルーレット",
  MoreGames: "他のゲーム",
  Provablyfair: "公平を証明できる",
  Special: "特別",
  Recommend: "お勧め",
  FeatureBuyin: "機能購入",
  EnableTrialPlay: "デモ",
  GameShow: "ゲームショー",
  RGSVSB: "RGS - VSB",
  Scratch: "スクラッチカード",
  Bingo: "ビンゴ",
  Fishing: "魚を捕まえる",
  Turntable: "ターンテーブル",
  Dice: "サイコロ",
  Poker: "ポーカー",
  AllGames: "すべてのゲーム",
 EuropeanView: "ヨーロッパビュー",
  AfricanView: "アフリカビュー",
  LatinView: "ラテンビュー",
  AsianView: "アジアビュー",
  LsportView: "リーグビュー",
  StandardView: "スタンダードビュー",
  EsportView: "eスポーツビュー",
  LiveBetting: 'ライブベット',
  Provider: 'プロバイダー',
  Sport: "スポーツ",



};

export default locale;
