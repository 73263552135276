import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import withReducer from 'app/store/withReducer';
import { useSnackbar } from 'notistack';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Button from '@mui/material/Button';
import NotificationTemplate from 'app/theme-layouts/shared-components/notificationPanel/NotificationTemplate';
import NotificationModel from './model/NotificationModel';
import NotificationCard from './NotificationCard';
import { useTranslation } from "react-i18next";
import { getFeedBack, getTransRecord } from "../../../store/user/userThunk"
import { changeLanguage } from "../../../util/tools/function";
import countryLang from "../../../json/country";
import {
    addNotification,
    dismissAll,
    dismissItem,
    getNotifications,
    selectNotifications,
} from './store/dataSlice';
import reducer from './store';
import {
    closeNotificationPanel,
    selectNotificationPanelState,
    toggleNotificationPanel,
} from './store/stateSlice';
import { getNewsList } from "../../../store/config/configThunk";
import { concat } from 'lodash';

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        backgroundColor: theme.palette.background.default,
        width: 320,
    },
}));

function NotificationPanel(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const state = useSelector(selectNotificationPanelState);
    const notifications = useSelector(selectNotifications);
    const { t } = useTranslation('mainPage');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [notificationLists, setNotificationLists] = useState([]);
    const [feedBackList, setFeedBackList] = useState([]);
    const [transRecordList, setTransRecordList] = useState([]);
    const [notice, setNotice] = useState([]);

    function sendTipsLanguage(amount, symbol, targetMchUser) {
        let tips = "";
        tips = t("tongzhi_24") + " " + amount + symbol + " " + t("tongzhi_15") + targetMchUser;
        return tips;
    }



    function cryptoDepositLanguage(toAddress, amount, symbol, orderStatus) {
        let tips = "";
        if (orderStatus == 2) {
            tips = t("tongzhi_14") + " " + amount + symbol + " " + t("tongzhi_15") + toAddress;
        } else if (orderStatus == 3) {
            tips = t("tongzhi_14") + " " + amount + symbol + " " + t("tongzhi_15") + toAddress + " " + t("tongzhi_12");
        } else if (orderStatus == -3) {
            tips = t("tongzhi_14") + " " + amount + symbol + " " + t("tongzhi_15") + toAddress + " " + t("tongzhi_13");
        }
        return tips;
    }

    function fiatDepositLanguage(amount, currency, orderStatus) {
        let tips = "";
        if (orderStatus == 2) {
            tips = t("home_DEPOSIT") + " " + amount + currency + " " + t("tongzhi_11");
        } else if (orderStatus == 3) {
            tips = t("home_DEPOSIT") + " " + amount + currency + " " + t("tongzhi_12");
        } else if (orderStatus == -3) {
            tips = t("home_DEPOSIT") + " " + amount + currency + " " + t("tongzhi_13");
        } else if (orderStatus == -2) {
            tips = t("home_DEPOSIT") + " " + amount + currency + " " + t("tongzhi_13");
        }
        return tips;
    }

    function cryptoWithdrewLanguage(toAddress, amount, symbol, orderStatus) {
        let tips = "";
        if (orderStatus == 0) {
            tips = t("tongzhi_16") + " " + amount + symbol + " " + t("tongzhi_15") + toAddress + " " + t("tongzhi_4");
        } else if (orderStatus == 1) {
            tips = t("tongzhi_16") + " " + amount + symbol + " " + t("tongzhi_15") + toAddress + " " + t("tongzhi_5");
        } else if (orderStatus == 2) {
            tips = t("tongzhi_16") + " " + amount + symbol + " " + t("tongzhi_15") + toAddress + " " + t("tongzhi_6");
        } else if (orderStatus == 3) {
            tips = t("tongzhi_16") + " " + amount + symbol + " " + t("tongzhi_15") + toAddress + " " + t("tongzhi_7");
        } else if (orderStatus == -1) {
            tips = t("tongzhi_16") + " " + amount + symbol + " " + t("tongzhi_15") + toAddress + " " + t("tongzhi_8");
        } else if (orderStatus == -2) {
            tips = t("tongzhi_16") + " " + amount + symbol + " " + t("tongzhi_15") + toAddress + " " + t("tongzhi_9");
        } else if (orderStatus == -3) {
            tips = t("tongzhi_16") + " " + amount + symbol + " " + t("tongzhi_15") + toAddress + " " + t("tongzhi_10");
        }
        return tips;
    }

    function fiatWithdrewLanguage(amount, currency, orderStatus) {
        let tips = "";
        if (orderStatus == 0) {
            tips = t("tongzhi_16") + " " + amount + currency + " " + t("tongzhi_4");
        } else if (orderStatus == 1) {
            tips = t("tongzhi_16") + " " + amount + currency + " " + t("tongzhi_5");
        } else if (orderStatus == 2) {
            tips = t("tongzhi_16") + " " + amount + currency + " " + t("tongzhi_6");
        } else if (orderStatus == 3) {
            tips = t("tongzhi_16") + " " + amount + currency + " " + t("tongzhi_7");
        } else if (orderStatus == -1) {
            tips = t("tongzhi_16") + " " + amount + currency + " " + t("tongzhi_8");
        } else if (orderStatus == -2) {
            tips = t("tongzhi_16") + " " + amount + currency + " " + t("tongzhi_9");
        } else if (orderStatus == -3) {
            tips = t("tongzhi_16") + " " + amount + currency + " " + t("tongzhi_10");
        }
        return tips;
    }

    useEffect(() => {
        /*
            Get Notifications from db
             */
        // dispatch(getNotifications());
        setNotificationLists([]);
        setNotice([]);
        setFeedBackList([]);
        setTransRecordList([]);

        setTimeout(() => {
            dispatch(getFeedBack({
                IsProcessed: 1
            })).then((resed) => {
                let tmpFeed = []
                if (resed.payload) {
                    resed.payload.map((item) => {
                        tmpFeed.push({
                            formattedBody: item.revertContent,
                            // imageUrl:"http"+item.feedbackContent.split("http")[1],
                            newsID: t("tongzhi_1") + "-" + item.feedbackID,
                            // subject: item.feedbackTitle
                            subject: t("tongzhi_2")
                        })
                    })

                    setFeedBackList(tmpFeed);
                }
            })

            dispatch(getTransRecord()).then((res) => {
                let tmpTrans = []
                if (res.payload) {
                    let sendTipsRecord = res.payload.sendTips;
                    let cryptoDepositRecord = res.payload.deposite.crypto;
                    let fiatDepositRecord = res.payload.deposite.fiat;
                    let cryptoWithdrewRecord = res.payload.withdrew.crypto;
                    let fiatWithdrewRecord = res.payload.withdrew.fiat;
                    // 0 等待审核 1 审核通过 2 打款中 3 打款完成 -1 审核不通过 -2 打款失败 -3 提现失败
                    // 2 已付款 3 已完成 -3 处理失败
                    sendTipsRecord.map((item) => {
                        tmpTrans.push({
                            // body: `${item.amount} ${item.symbol}, ${item.targetMchUser}`,
                            // imageUrl:"http"+item.feedbackContent.split("http")[1],
                            body: sendTipsLanguage(item.amount, item.symbol, item.targetMchUser),
                            newsID: t("tongzhi_23") + "-" + item.id,
                            subject: t("tongzhi_24")
                        })
                    })

                    cryptoDepositRecord.map((item) => {
                        tmpTrans.push({
                            // body: `toAddress ${item.toAddress}, amount ${item.amount} ${item.symbol}, status ${item.orderStatus}`,
                            body: cryptoDepositLanguage(item.toAddress, item.amount, item.symbol, item.orderStatus),
                            newsID: t("home_Deposit") + "-" + item.orderNo,
                            subject: t("home_Deposit")
                        })
                    })

                    fiatDepositRecord.map((item) => {
                        tmpTrans.push({
                            // body: `amount ${item.amount} ${item.currency}, status ${item.orderStatus}`,
                            body: fiatDepositLanguage(item.amount, item.currency, item.orderStatus),
                            newsID: t("home_Deposit") + "-" + item.orderNo,
                            subject: t("home_Deposit")
                        })
                    })

                    cryptoWithdrewRecord.map((item) => {
                        tmpTrans.push({
                            // body: `toAddress ${item.toAddress}, amount ${item.amount} ${item.symbol}, status ${item.orderStatus}`,
                            body: cryptoWithdrewLanguage(item.toAddress, item.amount, item.symbol, item.orderStatus),
                            newsID: t("home_Withdraw") + "-" + item.orderNo,
                            subject: t("home_Withdraw")
                        })
                    })

                    fiatWithdrewRecord.map((item) => {
                        tmpTrans.push({
                            // body: `amount ${item.amount} ${item.symbol}, status ${item.orderStatus}`,
                            // imageUrl:"http"+item.feedbackContent.split("http")[1],
                            body: fiatWithdrewLanguage(item.amount, item.currency, item.orderStatus),
                            newsID: t("home_Withdraw") + "-" + item.orderNo,
                            subject: t("home_Withdraw")
                        })
                    })


                    setTransRecordList(tmpTrans);
                }

            })

            dispatch(getNewsList({
                classID: 2,
                lang: changeLanguage(countryLang, window.localStorage.getItem('lang')),
                brush: true
            })).then((res) => {
                let result = res.payload
                if (result) {
                    let tmpNotice = [];
                    result.map((item) => {
                        tmpNotice.push({
                            body: item.body,
                            // description: item.formattedBody,
                            imageUrl: item.imageUrl,
                            newsID: item.newsID,
                            read: false,
                            time: "",
                            subject: item.subject,
                        })
                    });
                    setNotice(tmpNotice);
                }
            })
        }, 300)

    }, [dispatch, window.localStorage.getItem('lang')]);

    useEffect(() => {
        let allNotice = concat(notice, transRecordList, feedBackList)

        let tmpNotificationLists = []
        allNotice.map((item) => {
            tmpNotificationLists.push({
                description: item.body,
                // description: item.formattedBody,
                icon: item.imageUrl,
                id: item.newsID,
                read: false,
                time: "",
                title: item.subject,
            })
        });
        setNotificationLists(tmpNotificationLists)
    }, [notice, transRecordList, feedBackList])

    useEffect(() => {
        if (state) {
            dispatch(closeNotificationPanel());
        }
        // eslint-disable-next-line
    }, [location, dispatch]);

    function handleClose() {
        dispatch(closeNotificationPanel());
    }

    function handleDismiss(id) {
        dispatch(dismissItem(id));
    }
    function handleDismissAll() {
        dispatch(dismissAll());
    }


    function demoNotification() {
        const item = NotificationModel({ title: t("tongzhi_3") });

        enqueueSnackbar(item.title, {
            key: item.id,
            // autoHideDuration: 3000,
            content: () => (
                <NotificationTemplate
                    item={item}
                    onClose={() => {
                        closeSnackbar(item.id);
                    }}
                />
            ),
        });
        dispatch(addNotification(item));
    }

    return (
        <StyledSwipeableDrawer
            open={state}
            anchor="right"
            onOpen={(ev) => { }}
            onClose={(ev) => dispatch(toggleNotificationPanel())}
            disableSwipeToOpen
        >
            <IconButton className="m-4 absolute top-0 right-0 z-999" onClick={handleClose} size="large">
                <FuseSvgIcon color="action">heroicons-outline:x</FuseSvgIcon>
            </IconButton>
            {notificationLists.length > 0 ? (
                <FuseScrollbars className="p-16">
                    <div className="flex flex-col">
                        {/*<div className="flex justify-between items-end pt-136 mb-36">*/}
                        <div className="flex justify-between items-end mb-36">
                            {/*<Typography className="text-28 font-semibold leading-none">Notifications</Typography>*/}
                            {/*<Typography*/}
                            {/*  className="text-12 underline cursor-pointer"*/}
                            {/*  color="secondary"*/}
                            {/*  onClick={handleDismissAll}*/}
                            {/*>*/}
                            {/*  dismiss all*/}
                            {/*</Typography>*/}
                        </div>
                        {notificationLists.map((item) => (
                            <NotificationCard
                                key={item.id}
                                className="mb-16"
                                item={item}
                            // onClose={handleDismiss}
                            />
                        ))}
                    </div>
                </FuseScrollbars>
            ) : (
                <div className="flex flex-1 items-center justify-center p-16">
                    <Typography className="text-24 text-center" color="text.secondary">
                        {t("home_ThereAre")}
                    </Typography>
                </div>
            )}
            {/*<div className="flex items-center justify-center py-16">*/}
            {/*  <Button size="small" variant="outlined" onClick={demoNotification}>*/}
            {/*    Create a notification example*/}
            {/*  </Button>*/}
            {/*</div>*/}
        </StyledSwipeableDrawer>
    );
}

export default withReducer('notificationPanel', reducer)(memo(NotificationPanel));
