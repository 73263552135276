import Course from './Course';


const CourseConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'course',
            element: <Course />,
        },
    ],
};

export default CourseConfig;