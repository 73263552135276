const locale = {
    home_StakeNFT: 'Stake NFT earn free ',
    home_everyday: 'every day',
    home_Beton: 'Bet on ',
    home_mining: 'mining',
    home_shareholder: ' To be a shareholder',
    home_Stake: 'Stake',
    home_dividend: ' Get profit dividend',
    home_Banker: ' To be one of Banker',
    home_SIGNUPAND: 'SIGN UP & ',
    home_GET: 'GET',
    home_UPTO: 'UP TO ',
    home_FuniHash: 'Provably Fair',
    home_Option: 'Option',

    home_Reward: ' reward',
    home_Reward1: 'reward',
    home_signUpNow: 'Sign Up Now',
    home_Casino: 'Casino',
    home_Sports: 'Sports',
    home_PROMOTION: 'PROMOTION',
    home_Checkin: 'Check in',
    home_Bouns: 'bouns',
    home_CheckinBouns: 'Check in Bouns',
    home_SPIN: 'SPIN',
    home_Relief: 'Relief',
    home_Share: 'Share',
    home_Deposit: 'Deposit',
    home_Fund: 'Fund',
    home_VIPClub: 'VIP Club',
    home_FastAndEasy: 'Fast and easy way to buy crypto currency up to USDT deposit bonus',
    home_Game: 'Game',
    home_User: 'User',
    home_Time: 'Time',
    home_BetAmount: 'Bet Amount',
    home_Multiplier: 'Multiplier',
    home_Payout: 'Payout',
    home_JoinOur: 'Join our community of sporting fanatics,crypto enthusiasts and gamblers',
    home_JOIN: 'JOIN',
    home_LICENSES: 'LICENSES',
    home_OurPlatforms: 'Our Platforms',
    home_Marketplac: 'Marketplac',
    home_Howtoearn: 'How to earn',
    home_Games: 'Games',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'Investor Deck',
    home_News: 'News',
    home_Aboutsus: 'Abouts us',
    home_Industry: 'Industry',
    home_Roadmap: 'Roadmap',
    home_Partner: 'Partner',
    home_FAQs: 'FAQs',
    home_Affiliate: 'Affiliate',
    home_Stayintouch: 'Stay in touch',
    home_ChatRoom: 'Chat Room',
    home_Selectacontact: 'Select a contact to start a conversation.',
    home_BetSlip: 'Bet Slip',
    home_Typeyourmessage: 'Type your message',
    home_AcceptAnyOdds: 'Accept Any Odds',
    home_ClearAll: 'Clear All',
    home_Live: 'Live',
    home_Winner: 'Winner',
    home_EstPayout: 'Est. Payout',
    home_TotalOdds: 'TotalOdds',
    home_RegistertoBet: 'Register to Bet',
    home_Single: 'Single',
    home_Multi: 'Multi',
    home_Checkineveryweek: 'Check in every week accumulatively and get corresponding rewards.',
    home_Day: 'Day',
    home_Sunday: 'Day1',
    home_Monday: 'Day2',
    home_Tuesday: 'Day3',
    home_Wednesday: 'Day4',
    home_Thursday: 'Day5',
    home_Friday: 'Day6',
    home_Saturday: 'Day7',
    home_FreeSpin: 'Free Spin',
    home_Turntheturntable: 'Turn the turntable every day to get USGT reward.',
    home_DepositFift: 'Deposit Gift',
    home_ANYAMOUNT: 'ANY AMOUNT OF FIRST DEPOSIT YOU CAN GET',
    home_DEPOSIT: 'DEPOSIT',
    home_First: 'First',
    home_1ST: '1ST',
    home_2ND: '2ND',
    home_3TH: '3TH',
    home_ANYAMOUNT: 'ANY AMOUNT!',
    home_EXTRA: 'EXTRA',
    home_ONLY: 'ONLY',
    home_Relief: 'Relief',
    home_PLATFORMSHARING: 'PLATFORM SHARING',
    home_Getrelief: 'Get a chance to turn over from the dole.',
    home_YOUCANCLAIM: 'When the balance is less than ',
    home_YOUCANReceive: ', you can receive relief and supplement the balance to ',
    home_threeTimes: ' 3 times',
    home_Times: 'times:',
    home_everydayTimes: '/day!',
    home_TotalAssets: 'Total Assets',
    home_receive: 'receive',
    home_TODAY: 'TODAY',
    home_Share: 'Share',
    home_Sharewith: 'Share FuniBet every day and get ',
    home_SHARINGGIFT: 'SHARING GIFT PACKAGE',
    home_CODE: 'CODE',
    home_Selectplatform: 'Select platform to share:',
    home_Fund: 'Fund',
    home_FundBonus: 'Fund Bonus',
    home_Improveyour: 'Improve your VIP level and get more fund rewards every day.',
    home_BuyFund: 'Buy Fund',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'status',
    home_Received: 'Received',
    home_noReceived: 'unmet',
    home_ExclusiveBenefits: 'Exclusive Benefits',
    home_Asawayof: 'As a way of showing our gratitude and appreciation to our VIP players, FuniBet invites you to join our VIP Club where there is an abundance of gifts, giveaways, higher cash back and unique features. Dont miss out on all the exciting and amazing fun!',
    home_Yourcurrent: 'Your current Total wager ',
    home_WhyUpgrade: 'Why Upgrade To VIP?',
    home_VIPhave: 'VIP have 24*7 considerate service provided by the exclusive host. In addition to the basic Rakeback and Recharge, VIP also enjoy the highest level of spin, Weekly/Monthly Bonus, and the fastest channel for deposit and withdrawal. VIP have the opportunity to participate in the top players exchange conference, and building the Casino to by putting forward new features & functions to the FuniBet team.',
    home_UpgradeYour: 'Upgrade Your VIP Level',
    home_ASaFunibet: 'As a FUNIBet VIP Club member, you get exclusive access to generous rewards and personalized gifts, but that’s not all. The more you play, the better luxury benefits you’ll receive as you rise through the VIP ranks to become an Elite VIP member where there are no limits to the custom tailored VIP perks FUNIBET will shower you with.',
    home_YOUCANREACH: 'YOU CAN REACH',
    home_LEVELS: 'LEVELS',
    home_eachthehigh: 'Reach the high top Rank to get a 25% cashback bonus and open all the benefits of the platform. Stay with FUNIBET to receive the best gaming experience and excellent service!',
    home_Cashback: 'Cashback',
    home_Nowater: '1.No water pumping in the game',
    home_Moregrowth: '2.More growth funds',
    home_Increasein: '3.Increase in the number of benefits',
    home_Doublethenumber: '4.Double the number of turntables',
    home_Doublethesign: '5.Double the sign in reward',
    home_Doublesharingrewards: '6.Double sharing rewards',
    home_DoubletheAffiliate: '7.Double the Affiliate reward',
    home_Rechargerewardamount: '8.Recharge reward amount X 1.5',
    home_ABOUTVIPCLUB: 'ABOUT VIP CLUB',
    home_HightopRank: 'High top Rank has 25% cashback bonus and the best benefits. Stay with BeDao to receive the best gaming experience and high level of service!',
    home_Beingamember: 'Being a member of the VIP club means that you have access to:',
    home_PersonalVIPmanager: 'Personal VIP manager',
    home_Highercahbackrewards: 'Higher cahback rewards',
    home_Otheradditionalperks: 'Other additional perks',
    home_Insights: 'Insights',
    home_WeeklyandMonthlyBonuses: 'Weekly and Monthly Bonuses',
    home_Showhighgaming: 'Show high gaming activity and get your invitation to our VIP Club.',
    home_BindMobile: 'Bind Mobile',
    home_Pleasecheckthe: 'Please check the USGT obtained from the game in your wallet.',
    home_BINDMOBILENUMBER: 'BIND MOBILE NUMBER',
    home_Get1USDT: 'Get 1USDT',
    home_Bind: 'Bind',
    home_GiftCode: 'Gift Code',
    home_EnteryourGiftCode: 'Enter your Gift Code',
    home_EnterCode: 'Enter Code',
    home_Paste: 'Paste',
    home_confirms: 'confirms',
    home_BuyCrypto: 'Buy Crypto',
    home_SignedIn: 'Signed In',
    home_Wallet: 'Wallet',
    home_turnTimes: 'Turns: ',
    home_bonus: ' bonus',
    home_FirstDepositBonus: 'First Deposit Bonus',
    home_Hi: 'Hi ',
    home_welcomeAboard: ' welcome aboard',



    home_CommissionRules: 'Commission Rules',
    home_AffiliateTerms: 'Affiliate Terms',
    home_AffiliateDashboard: 'Affiliate Dashboard',
    home_AFFILIATE: 'AFFILIATE',
    home_COMMISSONREADYEVERYDAY: 'COMMISSON READY EVERYDAY',
    home_MYREFERRALCODE: 'MY REFERRAL CODE',
    home_MYREFERRALLink: 'MY REFERRAL Link',
    home_AffiliateReward: 'Affiliate Reward',
    home_EXTRA: 'EXTRA',
    home_USDREWARDS: ' USD REWARDS',
    home_USDRewardsRules: 'USD Rewards Rules',
    home_JUSAREFERRALAWAY: ' JUS A REFERRAL AWAY',
    home_NewlyAvailableRewards: 'Newly Available Rewards',
    home_ReceivedMao: 'Received:',
    home_Locked: 'Locked: ',
    home_Yourcurrentfriends: 'Your current friends staying active will unlock you more Rewards',
    home_Withdraw: 'Withdraw',
    home_MY: 'MY',
    home_COMMISSIONREWARDS: 'COMMISSION REWARDS',
    home_CommissionRewardRules: 'Commission Reward Rules',
    home_NewlyAvailableCommissionRewards: 'Newly Commission Rewards',
    home_CommissionYouhaveReceivedinTotal: 'Commission in Total:',
    home_REWARDSSENTTODATE: 'REWARDS SENT TODATE',
    home_USDrewardssenttodate: 'USD rewards sent to-date',
    home_Commissionsenttodatet: 'Commission sent to-datet',
    home_Areyouablogger: 'Are you a blogger, have a large audience, many followers? We have a special offer for you - a unique affiliate program with bigger referral rewards. We will compile an individual program with special conditions.Contact our manager to discuss the terms affiliate@beDao.io',
    home_onlythoseusers: 'Important: only those users who have passed the requirements of the manager and only after approval with the manager will be able to participate in the program.',
    home_notice: 'Notice',
    home_bindPhone: 'Bind phone',
    home_SignIn: 'Login In',
    home_SignUp: 'Sign Up',
    home_SignOut: 'Sign Out',
    home_CookiesPolicy: 'Cookies Policy',
    home_isUsingCookies: 'Funibet.com is using cookies to provide better service for every user. By using our site you agree to the use of cookies. Kindly check Privacy Policy and Terms Of Service.',
    home_SETTING: 'SETTING',
    home_LiveSupport: 'Live Support',
    home_Accept: 'Accept',
    home_FrequentlyAskedQuestions: 'Frequently Asked Questions',
    home_SiteUnnderConstruction: 'Site Unnder Construction',
    home_ComingSoon: 'ComingSoon',
    home_UserId: 'UserId: ',
    home_UserName: 'UserName: ',
    home_Bonusincreased: "Bonus increased +",
    home_SPINBONUSTOTAL: 'SPIN BONUS TOTAL',
    home_WIN: 'WIN: ',
    home_In: 'in ',
    home_Outoftime: 'Out of times today',

    game_search: 'search',
    game_SortBy: 'Sort By',
    game_Created: 'Created',
    game_Lobby: 'Lobby',
    game_LiveCasino: 'Live Casino',
    game_FeaturedSlots: 'Featured Slots',
    game_GameShows: 'Games Shows',
    game_FuniBetOriginals: 'FuniBet Originals',
    game_BigWins: 'Big Wins',
    game_LcukyWins: 'Lcuky Wins',
    game_Challenges: 'Challenges',
    game_Description: 'Description',
    game_Slots: 'Slots',
    game_Rank: 'Rank',
    game_User: 'User',
    game_Date: 'Date',
    game_BetAmount: 'Bet Amount',
    game_lucky: 'Max Multiplier',
    game_Multiplier: 'Multiplier',
    game_Payout: 'Payout',
    game_Recommend: 'Recommend',
    game_Providers: 'Providers',
    game_startGame: 'Start',
    game_balance: 'Balance',
    game_provider: 'Tag',
    game_Selectyourdisplay: 'Select your display balance',
    game_Balancein: 'Balance in',
    game_FunPlay: 'Fun Play',
    game_RealPlay: 'Real Play',
    game_DispLaying: "Displaying",
    game_LoadMore: "Load More",
    game_NoData: 'Oops! No data now.',

    message_SigningIn: "Signing in with JWT",
    message_CourseSaved: "Course Saved",
    message_PleaseBind: "Please bind your phone first",
    message_UserData: "User data saved with api",
    message_Success: "success",
    message_VIPlevel: "VIP level is less than V3",
    message_ListOrder: "List Order Saved",
    message_CardOrder: "Card Order Saved",
    message_HiHow: "Hi, how are you?",

    Funibet_web: 'Funibet-Web3.0,Crypto,Bet,Sports,E-sports,Slots,Live casino,Poker',

    home_ThereAre: "There are no notifications for now.",

    play_no_balance: "The current currency balance is insufficient, please switch currency or recharge",

    home_News_1: 'News',
    home_News_2: 'Event',
    home_News_3: 'Crypto',
    home_News_4: 'Gaming',

    home_SuggRep_1: 'Sugg.&Rep.',
    home_SuggRep_2: 'Suggestions & Reports',
    home_SuggRep_3: 'Share feedback to improve!',
    home_SuggRep_4: 'Report website selling in-game.',
    home_SuggRep_5: 'Enter Suggestions or submit report',
    home_SuggRep_6: 'Sample screenshot',
    home_SuggRep_7: 'reward for adopted suggestions & reports!',
    home_SuggRep_8: 'submit',

    home_subscription_1: 'Would you like to subscribe to push notifications?',
    home_subscription_2: 'you can always unsubscribe at any time.',
    home_subscription_3: 'Web push by WonderPush',
    home_subscription_4: 'Later',
    home_subscription_5: 'Subscribe',

    home_Affiliate_1: 'Contact Us',
    home_Affiliate_2: 'Every friend you invite, will get you 10U, the more you invite, the more you will get!',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: 'Email',

    home_banner_1: 'Crypto Gaming',
    home_banner_2: '100+ crypto & 50+  NFTs supported',
    home_banner_3: 'Decentralized Betting',
    home_banner_4: 'Smart contract betting from decentralized wallet',
    home_banner_5: 'Bet 2 Earn',
    home_banner_6: 'Bet, mine, and invest for steady profits',
    home_banner_7: 'Web3.0 + Casino',
    home_banner_8: 'Player is also Banker & Owner',

    home_EventDetails: 'Details',

    home_vip_1: "1.Earn income by checking in+",
    home_vip_2: "2.Earn income by spinning+",
    home_vip_3: "3.Earn income by sharing+",
    home_vip_4: "4.Earn income by recharging+",
    home_vip_5: "5.Chat enabled, can send messages daily",
    home_vip_6: "Messages",

    ERROR_628: 'Gift code already claimed',
    ERROR_629: 'Gift code fully claimed',
    ERROR_630: 'Gift code not active',
    ERROR_631: 'Gift code expired',
    ERROR_633: 'Cannot use gift code from another agent',

    home_Maintaining_1: 'Maintaining',
    home_Maintaining_2: 'This feature is under maintenance',
    home_Maintaining_3: 'END',
    home_Maintaining_4: 'START',

    home_Slots: 'Slosts',
    home_LiveCasino: 'LiveCasino',
    home_CountryFiat: 'Country & Fiat',
    home_CryptNFTs: 'Crypt & NFTs',

    home_GoTo: 'Go To',

    home_gameout_1: "Are you currently in another game? Would you like to exit?",
    home_gameout_2: "Cancel",

    home_BuyFund1: 'Would you like to buy a growth fund?',
    home_BetFor: 'Bet for',

    tongzhi_1: "Suggestion",
    tongzhi_2: "Reply to suggestion and representative.",
    tongzhi_3: "Well done! This is fantastic.",
    tongzhi_4: "Awaiting review.",
    tongzhi_5: "Review approved.",
    tongzhi_6: "Payment processing.",
    tongzhi_7: "Payment completed successfully.",
    tongzhi_8: "Review not approved.",
    tongzhi_9: "Payment failed.",
    tongzhi_10: "Withdrawal failed.",
    tongzhi_11: "Paid.",
    tongzhi_12: "Completed.",
    tongzhi_13: "Processing failed.",
    tongzhi_14: "Buy",
    tongzhi_15: "To",
    tongzhi_16: "Withdraw",
    tongzhi_17: "Cryptocurrency",
    tongzhi_18: "Fiat Currency",
    tongzhi_19: "Download ",
    tongzhi_20: "Login can be rewarded",
    tongzhi_21: "Download",
    tongzhi_22: "No iOS version available at the moment",
    tongzhi_23: "Send",
    tongzhi_24: "Send Tips",

    home_banner_9: "Horse Racing",
    home_banner_10: "Cockfighting",
    home_banner_11: "Exciting and Thrilling, Win Big Prizes",
    home_banner_12: "Energetic and High-spirited",
    
    home_wanfa_1:" Gameplay Issue",
    home_wanfa_2:"Transfer Issue",
    home_wanfa_3:"Other Issue",
    play_not_balance: "Sports betting with eUSDT is not possible. Would you like to switch currencies?",



};

export default locale;
