import "../../../styles/game.css";
import * as React from 'react';
import utils from '../../util/tools/utils'
import Common_foot from '../common/Common_foot';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import history from '@history';
import { useSelector, useDispatch } from "react-redux";
import {useEffect, useState} from "react";
import {getBusinessConfig} from "app/store/config/configThunk";



function Affiliate(props) {
    const { t } = useTranslation('mainPage');
    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };

    const dispatch = useDispatch();
    const [ config, setConfig ] = useState({});

    useEffect(() => {
        dispatch(getBusinessConfig()).then((res) => {
            res = res.payload
            if (res.errno === 0) {
                setConfig(res.data)
            }
        })
    }, []);

    const goRouter = (route) => {
        history.push(route);
    };

    return (
        <div className="flex w-full container ">
            <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 p-10 md:p-32 pb-0 md:pb-0 affHuoDongDing">
                <div className="flex flex-col flex-auto align-item">
                    <div className="flex grow shrink-0 flex-col items-center container ">
                        <motion.div
                            variants={container}
                            initial="hidden"
                            animate="show"
                            className="activitybanner "
                        >
                            <motion.div variants={item} className="min-w-full">
                                <div className="zuQiu flex mb-12">
                                    <div className="flex txtBrightness btnPointer colorGameListTitle">
                                        <img src="assets/images/affiliate/10011.png"></img>
                                        <div className="flex mt-2 font-weight500 ml-8">{t("home_CommissionRules")}</div>
                                    </div>

                                    <div className="flex txtBrightness ml-40 btnPointer colorGameListTitle">
                                        <img src="assets/images/affiliate/1012.png"></img>
                                        <div className="flex mt-2 font-weight500 ml-8">{t("home_AffiliateTerms")}</div>
                                    </div>
                                </div>

                                <div className="zuQiu affiliateBg">
                                    <div className="promotion-list1 phoneAffp-12">

                                        <div className="flex width-180 height-46 ml-16 mt-20 borderRadius-5 backGroundColorYellow displayPhoneSwitch2 btnPointer txtBrightness">
                                            <img className="height-24 mt-12 ml-4" src="assets/images/affiliate/10010.png"></img>
                                            <div className="mt-12  ml-4 font-weight500  txtAffiliateBtnColor" onClick={() => { window.open('https://test-game-api.funihash.com:8084', '_blank') }}>{t("home_AffiliateDashboard")}</div>
                                        </div>

                                        <div className="affiliateBg2 affiliateMg ">
                                            <div className="sigin-txt-yellow width-280 marginJuZhong titleTxt  phoneTxtAff text-align">{t("home_Affiliate_1")}</div>
                                            <div className="width-280 marginJuZhong phoneTxtAff2  font-weight500 ">{t("home_Affiliate_2")}</div>
                                            <div className="flex justifyContent">
                                                <Button className="whitespace-nowrap phoneTxtAff11  borderRadius funbetTxt" variant="contained" color="secondary" onClick={() => { window.open(config.field1) }}>
                                                    <img style={{ width: "30px", height: "30px", marginRight: "10px" }} src="assets/images/index/tg.png"></img>
                                                    {t("home_Affiliate_3")}
                                                </Button>

                                                <Button className="whitespace-nowrap phoneTxtAff11 phoneMgl  borderRadius funbetTxt" variant="contained" color="secondary" onClick={() => { window.open(config.field2) }}>
                                                    <img style={{ width: "30px", height: "30px", marginRight: "10px" }} src="assets/images/index/mail.png"></img>
                                                    {t("home_Affiliate_4")}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </motion.div>
                            <motion.div variants={item} className="min-w-full">
                                <div className="zuQiu affilamt-40 ">
                                    <div className="flex width-280 marginJuZhong backGround-foot phoneTxtAff6  borderRadius-10 justifyContent">
                                        <img className="height-52 phoneAffmt-8 ml-12" src="assets/images/affiliate/10013.png"></img>
                                        <div className="phoneAffmt-16 ml-6">{t("home_AffiliateReward")}</div>
                                    </div>
                                    <img className="marginJuZhong displayPhoneSwitch1" src="assets/images/affiliate/xianKuang.png"></img>
                                </div>
                            </motion.div>
                            <motion.div variants={item} className="min-w-full">
                                <div className="promotion-list1">
                                    <div className="phoneTxtAff7 borRadius-10  backGround-foot">
                                        <div className="flex justifyContent">
                                            <img className="py-12" src={`${React.$imgUrl}/images/affiliate/usd.png`}></img>
                                        </div>
                                        <div className="zuQiu text-align pl-12 text-24 font-weight500">{t("home_EXTRA")}<span className="txtGreenColor">{t("home_USDREWARDS")}</span></div>
                                        <div className="zuQiu text-align text-16 font-weight500 pb-16 txtGreenColor text-decoration txtBrightness btnPointer">{t("home_USDRewardsRules")}</div>
                                        <div className="marginJuZhong  phoneTxtAff8 backGround-index  borderRadius-10">
                                            <div className="zuQiu text-align text-28  sigin-txt-yellow">$0.00</div>
                                            <div className="zuQiu text-align text-18">{t("home_NewlyAvailableRewards")}</div>
                                        </div>
                                        <div className="zuQiu text-align text-16 phoneTxtAff9">{t("home_ReceivedMao")}<span className="txtGreenColor">$0.00</span ><span className="ml-20"></span> <span className="text-decoration txtBrightness btnPointer">{t("home_Locked")}<span className="txtGreenColor">$0.00</span></span></div>
                                        <div className="phoneTxtAff12 text-align text-16 phoneTxtAff10">{t("home_Yourcurrentfriends")}</div>
                                        <div className="zuQiu text-align mb-10">
                                            <Button className="whitespace-nowrap phoneTxtAff11  borderRadius funbetTxt" variant="contained" color="secondary" onClick={() => { history.push('/comingSoon/affiliate') }}>
                                                {t("home_Withdraw")}
                                            </Button>
                                        </div>
                                    </div>

                                    <div className="phoneTxtAff7 borRadius-10  backGround-foot">
                                        <div className="flex justifyContent">
                                            <img className="py-12" src={`${React.$imgUrl}/images/affiliate/byt.png`}></img>
                                        </div>
                                        <div className="zuQiu text-align text-24 font-weight500">{t("home_MY")}<span className="txtGreenColor">{t("home_COMMISSIONREWARDS")}</span></div>
                                        <div className="zuQiu text-align text-16 font-weight500 pb-16 txtGreenColor text-decoration txtBrightness btnPointer">{t("home_CommissionRewardRules")}</div>
                                        <div className="marginJuZhong phoneTxtAff8 backGround-index borderRadius-10">
                                            <div className="zuQiu text-align text-28  sigin-txt-yellow">$0.00</div>
                                            <div className="zuQiu text-align text-18">{t("home_NewlyAvailableCommissionRewards")}</div>
                                        </div>

                                        <div className="mt-16">
                                            <div className="marginJuZhong backGround-index  ctyptoBtn-txt  phoneTxtAff13  borderRadius-10">
                                                <div className="zuQiu text-align text-16">{t("home_CommissionYouhaveReceivedinTotal")}<span className="text-24 sigin-txt-yellow">$0:00</span></div>
                                            </div>
                                        </div>

                                        <div className="zuQiu  text-align mb-10">
                                            <Button className="whitespace-nowrap phoneTxtAff11  borderRadius funbetTxt" variant="contained" color="secondary" onClick={() => { history.push('/comingSoon/affiliate') }}>
                                                {t("home_Withdraw")}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>

                            <motion.div variants={item} className="min-w-full">
                                <div className="zuQiu phoneTxtAff14  backGround-foot">
                                    <div className="zuQiu text-align phoneTxtAff15">{t("home_REWARDSSENTTODATE")}</div>
                                    <div className="promotion-list1 phoneTxtmx-120 ">

                                        <div className="flex">
                                            <img className="phoneTxtAff16" src="assets/images/affiliate/smallUSD.png"></img>
                                            <div>
                                                <div className="phoneTxtAff17 text-align txtAffiliateBtnColor2  font-weight500">{t("home_USDrewardssenttodate")}</div>
                                                <div className="phoneTxtAff18 height-52 text-align sigin-txt-yellow borderRadius-5 backGround-index">$1,931,299.50</div>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <img className="phoneTxtAff16" src="assets/images/affiliate/smallByt.png"></img>
                                            <div>
                                                <div className="phoneTxtAff17 text-align txtAffiliateBtnColor2 font-weight500">{t("home_Commissionsenttodatet")}</div>
                                                <div className="phoneTxtAff18 text-align height-52 sigin-txt-yellow  borderRadius-5 backGround-index">$1,355,375.52</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="zuQiu  affiliateBg3">
                                    <div className="width-80bai affiliateLaba">{t("home_Areyouablogger")}</div>
                                    <div className="width-80bai affiliateLaba  displayPhoneSwitch1">{t("home_onlythoseusers")}</div>
                                </div>
                            </motion.div>
                        </motion.div>
                    </div>
                    <Common_foot />
                    <div className="mt-32  promotion-list colorGameListTitle mb-20 displayPhoneSwitch1">
                        <div>FuniBet 2022- ALL Rights Reserved</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Affiliate;
