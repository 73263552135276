import Game from './Game';
import NavigationPhone from './NavigationPhone';


const GameConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'game/:tagID',
            element: <Game />,
        },
        {
            path: 'navigationPhone',
            element: <NavigationPhone />,
        },
    ],
};

export default GameConfig;
