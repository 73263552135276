import React from "react";
import ReactDOM from "react-dom";
import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";

function Test() {
  return (
    <Carousel
      showArrows={true}
      showStatus={false}
      infiniteLoop={true}
      autoPlay={true}
      showThumbs={false}
    >
      <div>
        <img className="lunBoTuWh" src={`${React.$imgUrl}/images/index/zuQiu.png`} />
        <div className="legend">Legend xxxx</div>
      </div>
      <div>
        <img className="lunBoTuWh zuQiu-img" src="value" />
        <div className="legend">Legend xxxx</div>
      </div>
      <div>
        <img className="lunBoTuWh zuQiu-img" src="value" />
        <div className="legend">Legend xxxx</div>
      </div>
      <div>
        <img className="lunBoTuWh zuQiu-img" src="value" />
        <div className="legend">Legend xxxx</div>
      </div>
      <div>
        <img className="lunBoTuWh zuQiu-img" src="value" />
        <div className="legend">Legend xxxx</div>
      </div>
      <div>
        <img className="lunBoTuWh zuQiu-img" src="value" />
        <div className="legend">Legend xxxx</div>
      </div>
    </Carousel>
  );
}
export default Test;
