import { createSelector, createSlice } from '@reduxjs/toolkit';
import i18n from 'src/i18n';
import { setDefaultSettings } from './fuse/settingsSlice';

export const changeLanguage = (languageId) => (dispatch, getState) => {
  const { direction } = getState().fuse.settings.defaults;

  const newLangDirection = i18n.dir(languageId);

  /*
    If necessary, change theme direction
     */
  if (newLangDirection !== direction) {
    dispatch(setDefaultSettings({ direction: newLangDirection }));
  }

  /*
    Change Language
     */
  return i18n.changeLanguage(languageId).then(() => {
    dispatch(i18nSlice.actions.languageChanged(languageId));
  });
};

const i18nSlice = createSlice({
  name: 'i18n',
  initialState: {
    language: i18n.options.lng,
    languages: [
      { id: 'en', title: 'English', flag: 'US' },
      { id: 'de', title: 'Deutsch', flag: 'DE' },
      { id: 'fr', title: 'Français', flag: 'FR' },
      { id: 'in', title: 'हिन्दी', flag: 'IN' },
      { id: 'mld', title: 'मराठी', flag: 'IN' },
      { id: 'tmr', title: 'தமிழ்', flag: 'IN' },
      { id: 'br', title: 'Português', flag: 'BR' },
      { id: 'tr', title: 'Türkçe', flag: 'TR' },
      { id: 'ph', title: 'Filipino', flag: 'PH' },
      { id: 'jp', title: '日本語', flag: 'JP' },
      { id: 'pl', title: 'Polski', flag: 'PL' },
      { id: 'vn', title: 'Tiếng Việt', flag: 'VN' },
      { id: 'kr', title: '한국어', flag: 'KR' },
      { id: 'id', title: 'Indonesian', flag: 'ID' },
      { id: 'es', title: 'Español', flag: 'ES' },
      { id: 'ru', title: 'Pусский', flag: 'RU' },
      { id: 'fi', title: 'Suomen', flag: 'FI' },
      { id: 'th', title: 'แบบไทย', flag: 'TH' },
      { id: 'ae', title: 'عربي', flag: 'AE' },
      { id: 'my', title: 'Melayu', flag: 'MY' },
      { id: 'bd', title: 'বাংলা', flag: 'BD' },
      { id: 'mm', title: 'ဗာရမ်', flag: 'MM' },
      { id: 'pak', title: 'اوردو زبان', flag: 'PAK' },
      { id: 'hk', title: '繁体', flag: 'HK' },
      { id: 'cn', title: '简体', flag: 'CN' },
    ],
  },

  reducers: {
    languageChanged: (state, action) => {
      window.localStorage.setItem('lang', action.payload);
      state.language = action.payload;
      FuniBox.Tools.default.setLang(action.payload ?? 'en')

      if (action.payload === "cn") {
        document.querySelector(":root").style.setProperty("--title-family", 'pfht_z');
        document.querySelector(":root").style.setProperty("--title-family2", 'pfht_z');
        document.querySelector(":root").style.setProperty("--title-family3", 'pfht_z');
        document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,虚拟货币,投注,体育,电子竞技,老虎机,真人娱乐场,扑克";
      } else if (action.payload === "kr") {
        document.querySelector(":root").style.setProperty("--title-family", 'krTxt');
        document.querySelector(":root").style.setProperty("--title-family2", 'krTxt');
        document.querySelector(":root").style.setProperty("--title-family3", 'krTxt');
        document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,가상화폐, 베팅, 스포츠, e스포츠, 슬롯머신, 라이브카지노, 포커";
      } else if (action.payload === "jp") {
        document.querySelector(":root").style.setProperty("--title-family", 'jpTxt');
        document.querySelector(":root").style.setProperty("--title-family2", 'jpTxt');
        document.querySelector(":root").style.setProperty("--title-family3", 'jpTxt');
        document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,暗号通貨,ベット,スポーツ,Eスポーツ,スロット,ライブカジノ,ポーカー";
      } else if (action.payload === "ae") {
        document.querySelector(":root").style.setProperty("--title-family", 'aeTxt');
        document.querySelector(":root").style.setProperty("--title-family2", 'aeTxt');
        document.querySelector(":root").style.setProperty("--title-family3", 'aeTxt');
        document.getElementById('funTitleWeb').innerText = "Funibet-3.0العملا, الرقمية , الرهان ,الرياضة , الرياضات الإلكترونية , الفتحات , الكازينو المباشر ,البوكر,ويب";
      } else if (action.payload === "in") {
        document.querySelector(":root").style.setProperty("--title-family", 'inTxt');
        document.querySelector(":root").style.setProperty("--title-family2", 'inTxt');
        document.querySelector(":root").style.setProperty("--title-family3", 'inTxt');
        document.getElementById('funTitleWeb').innerText = "Funibet-वेब 3.0, क्रिप्टो,बेट,खेल,ई-खेल,स्लॉट्स,लाइव कैसीनो,पोकर";
      } else if (action.payload === "mld") {
        document.querySelector(":root").style.setProperty("--title-family", 'inTxt');
        document.querySelector(":root").style.setProperty("--title-family2", 'inTxt');
        document.querySelector(":root").style.setProperty("--title-family3", 'inTxt');
        document.getElementById('funTitleWeb').innerText = "Funibet-वेब 3.0,क्रिप्टो,शर्त,क्रीडा,ई-क्रीडा,स्लॉट्स,लाइव कॅसीनो,पोकर";
      } else if (action.payload === "mm") {
        document.querySelector(":root").style.setProperty("--title-family", 'mmTxt');
        document.querySelector(":root").style.setProperty("--title-family2", 'mmTxt');
        document.querySelector(":root").style.setProperty("--title-family3", 'mmTxt');
        document.getElementById('funTitleWeb').innerText = "Funibet-web 3.0,အတုငွေကြေး၊ လောင်းကစား၊ အားကစား၊ e-sports၊ slot machines၊ တိုက်ရိုက်ကာစီနို၊ ဖဲချပ်";
      } else if (action.payload === "pak") {
        document.querySelector(":root").style.setProperty("--title-family", 'pakTxt');
        document.querySelector(":root").style.setProperty("--title-family2", 'pakTxt');
        document.querySelector(":root").style.setProperty("--title-family3", 'pakTxt');
        document.getElementById('funTitleWeb').innerText = "Funibet-web 3.0,ورچوئل کرنسی، بیٹنگ، کھیل، ای اسپورٹس، سلاٹ مشینیں، لائیو کیسینو، پوکر";
      } else if (action.payload === "tmr") {
        document.querySelector(":root").style.setProperty("--title-family", 'tmrTxt');
        document.querySelector(":root").style.setProperty("--title-family2", 'tmrTxt');
        document.querySelector(":root").style.setProperty("--title-family3", 'tmrTxt');
        document.getElementById('funTitleWeb').innerText = "Funibet-வலைப்பேசி 3.0,கிரிப்டோ,பந்தயம்,விளையாட்டு,மொழியக் காட்சிகள்,ஸ்லாட்கள்,நேரடி காசினோ, போக்கர்";
      } else {
        document.querySelector(":root").style.setProperty("--title-family", 'Inter var,Roboto,"Helvetica",Arial,sans-serif');
        document.querySelector(":root").style.setProperty("--title-family2", 'Furore');
        document.querySelector(":root").style.setProperty("--title-family3", 'falconpunch');
        if (action.payload === "en") {
          document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,Crypto,Bet,Sports,E-sports,Slots,Live casino,Poker";
        }
        else if (action.payload === "de") {
          document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,Kryptowährung,Wette,Sport,E-Sport,Spielautomaten,Live-Casino,Poker";
        }
        else if (action.payload === "fr") {
          document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,Crypto-monnaie,Pari,Sports,E-sport,Machines à sous, Casino en direct,Poker";
        }
        else if (action.payload === "bd") {
          document.getElementById('funTitleWeb').innerText = "Funibet-ওয়েব ৩.০,ক্রিপ্টো,বাজি,খেলা,ই-স্পোর্টস,স্লটস,লাইভ ক্যাসিনো,পোকার";
        }
        else if (action.payload === "br") {
          document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,Criptomoeda,Aposta,Esportes,Esports,Caça-níqueis,Cassino ao vivo,Poker";
        }
        else if (action.payload === "es") {
          document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,Criptomoneda,Apuesta,Deportes,E-sports,Tragamonedas,Casino en vivo,Póker";
        }
        else if (action.payload === "fi") {
          document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,Kryptovaluutta,Vedonlyönti,Urheilu,E-urheilu,Kolikkopelit,Live-kasino,Pokeri";
        }
        else if (action.payload === "id") {
          document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,Mata uang kripto,Taruhan,Olahraga,E-sports,Mesin slot,Kasino langsung,Poker";
        }
        else if (action.payload === "kr") {
          document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,암호 화폐,베팅,스포츠,전자 스포츠,슬롯 머신,라이브 카지노,포커";
        }
        else if (action.payload === "my") {
          document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,Kripto,Pertaruhan,Sukan,E-sukan,Slot,Kasino langsung,Poker";
        }
        else if (action.payload === "ph") {
          document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,Krypto,Pusta,Sports,E-sports,Mga Slot,Live na Casino,Poker";
        }
        else if (action.payload === "pl") {
          document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,Kryptowaluty,Zakłady,Sporty,E-sporty,Automaty do gier,Kasyno na żywo,Poker";
        }
        else if (action.payload === "ru") {
          document.getElementById('funTitleWeb').innerText = "Funibet-Веб 3.0,Криптовалюты,Ставки,Спорт,Киберспорт,Слоты,Живое казино,Покер";
        }
        else if (action.payload === "th") {
          document.getElementById('funTitleWeb').innerText = "Funibet-เว็บ 3.0,คริปโต,เดิมพัน,กีฬา,กีฬาอีสปอร์ต,สล็อต,คาสิโนสด,โป๊กเกอร์";
        }
        else if (action.payload === "tr") {
          document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0,Kripto,Bahis,Spor,E-sporlar,Slotlar,Canlı Casino,Poker";
        }
        else if (action.payload === "vn") {
          document.getElementById('funTitleWeb').innerText = "Funibet-Web3.0, Tiền điện tử, Cá cược, Thể thao, Thể thao điện tử, Máy đánh bạc, Sòng bạc trực tiếp, Poker";
        }
        else if (action.payload === "mm") {
          document.getElementById('funTitleWeb').innerText = "Funibet-web 3.0,အတုငွေကြေး၊ လောင်းကစား၊ အားကစား၊ e-sports၊ slot machines၊ တိုက်ရိုက်ကာစီနို၊ ဖဲချပ်";
        } else if (action.payload === "pak") {
          document.getElementById('funTitleWeb').innerText = "Funibet-web 3.0,ورچوئل کرنسی، بیٹنگ، کھیل، ای اسپورٹس، سلاٹ مشینیں، لائیو کیسینو، پوکر";
        }
      }
    },
  },
});

export const selectCurrentLanguageId = ({ i18n: _i18n }) => _i18n.language;

export const selectLanguages = ({ i18n: _i18n }) => _i18n.languages;

export const selectCurrentLanguageDirection = createSelector([selectCurrentLanguageId], (id) => {
  return i18n.dir(id);
});

export const selectCurrentLanguage = createSelector(
  [selectCurrentLanguageId, selectLanguages],
  (id, languages) => {
    return languages.find((lng) => lng.id === id);
  }
);

export default i18nSlice.reducer;
