import Sport from './Sport';

const SportConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'sport/:bookView',
            element: <Sport />,
        },
    ],
};

export default SportConfig;
