import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    chatMsg: {}

};

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        // 更新Msg列表
        updateChatMsg: (state, action) => {
            let res = action.payload;
            Object.keys(res).map((item) => {
                if (state.chatMsg[item]) {
                    state.chatMsg[item] = [...state.chatMsg[item], ...res[item]];
                } else {
                    state.chatMsg[item] = res[item];
                }
            })
        },
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateChatMsg } = chatSlice.actions;

export const selectChatData = ({ chat }) => chat;

export default chatSlice.reducer;
