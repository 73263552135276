export default {
    user: {
        setbanner: {
            url: "/user/setbanner",
            method: "post",
        },
        login: {
            url: "/user/login",
            method: "post",
        },
        stat: {
            url: "/user/stat",
            method: "post",
        },
        reload: {
            url: "/user/reload",
            method: "post",
        },
        profile: {
            url: "/user/profile",
            method: "post",
        },
        setprofile: {
            url: "/user/setprofile",
            method: "post",
        },
        info: {
            url: "/user/info",
            method: "post",
        },
        match: {
            url: "/user/match",
            method: "post",
        },
        listbyaddr: {
            url: "/user/listbyaddr",
            method: "post",
        },
        contract: {
            url: "/user/contract",
            method: "post",
        },
        onsales: {
            url: "/user/onsales",
            method: "post",
        },
        collections: {
            url: "/user/collections",
            method: "post",
        },
        listcontract: {
            url: "/user/listcontract",
            method: "post",
        },
        created: {
            url: "/user/created",
            method: "post",
        },
        like: {
            url: "/user/like",
            method: "post",
        },
        following: {
            url: "/user/following",
            method: "post",
        },
        follows: {
            url: "/user/follows",
            method: "post",
        },
        bid: {
            url: "/user/bid",
            method: "post",
        },
        listmygame: {
            url: "/user/listmygame",
            method: "post",
        },
        chain: {
            url: "/mobile/networks",
            method: "post",
        },
        selectnetwork: {
            url: "/mobile/selNetwork",
            method: "post",
        },
        sendSms: {
            url: "/mobile/sendSms",
            method: "post",
        },
        sendEmail: {
            url: "/mobile/sendValidate",
            method: "post",
        },
        bindPhone: {
            url: "/mobile/bindPhone",
            method: "post",
        },
        bindEmail: {
            url: "/mobile/bindEmail",
            method: "post",
        },
        register: {
            url: "/mobile/register",
            method: "post",
        },
        emailRegister: {
            url: "/email/register",
            method: "post",
        },
        mobileLogin: {
            url: "/mobile/login",
            method: "post",
        },
        resetPass: {
            url: "/mobile/resetPass",
            method: "post",
        },
        changePass: {
            url: "/mobile/changepass",
            method: "post",
        },
        bindWallet: {
            url: "/mobile/bindWallet",
            method: "post",
        },
        //上传图片
        getUpload: {
            url: "/oauth/storage/upload",
            method: "post"
        },
        //查询建议
        getFeedBack: {
            url: "/gameFeedback/getGameFeedback",
            method: "post"
        },
        //添加建议 gameFeedback/insertGameFeedback
        getInsert: {
            url: "/gameFeedback/insertGameFeedback",
            method: "post"
        },
        // 查询交易记录
        getTransRecord: {
            url: "/funiBox/getTransRecord",
            method: "post"
        },
        //入金通知埋点
        depositNotify: {
            url: "/funiBox/depositNotify",
            method: "post"
        },

    },

    storage: {
        list: {
            url: "/storage/list",
            method: "post",
        },
        upload: {
            url: "/storage/upload",
            method: "post",
        },
        read: {
            url: "/storage/read",
            method: "post",
        },
        update: {
            url: "/storage/update",
            method: "post",
        },
        delete: {
            url: "/storage/delete",
            method: "post",
        },
        multiupload: {
            url: "/storage/multiupload",
            method: "post",
        },
    },

    gamecenter: {
        getCasinoType: {
            url: "/gamecenter/getCasinoType",
            method: "post",
        },
        getCasinoGames: {
            url: "/gamecenter/getCasinoGames",
            method: "post",
        },
        getTagCasinoGames: {
            url: "/gamecenter/getTagCasinoGames",
            method: "post",
        },
        getTagCasinoGameRecord: {
            url: "/gamecenter/getTagCasinoGameRecord",
            method: "post",
        },
        getUserSetting: {
            url: "/gamecenter/getUserSetting",
            method: "post",
        },
        setUserSetting: {
            url: "/gamecenter/setUserSetting",
            method: "post",
        },

        getRankList: {
            url: "/gamecenter/getRankList",
            method: "post"
        },
        getFaqHelp: {
            url: "/gamecenter/getFaqHelp",
            method: "post",
        },
        getNewsList: {
            url: "/gamecenter/getNewsList",
            method: "post",
        },
        getVIPConfig: {
            url: "/gamecenter/getVIPConfig",
            method: "post",
        },
        setGameProfile: {
            url: "/gamecenter/setGameProfile",
            method: "post",
        },
        saveToWallet: {
            url: "/gamecenter/saveToWallet",
            method: "post",
        },
        setGameFavor: {
            url: "/gamecenter/setGameFavor",
            method: "post",
        },
        getCategoryGames: {
            url: "/gamecenter/getCategoryGames",
            method: "post",
        },
        getIPExtendInfo: {
            url: "/gamecenter/getIPExtendInfo",
            method: "post",
        },
        getChatToken: {
            url: "/gamecenter/getChatToken",
            method: "post",
        },
        queryUserToken: {
            url: "/gamecenter/queryUserToken",
            method: "post",
        },
        setSpreaderInfo: {
            url: "/gamecenter/setSpreaderInfo",
            method: "post",
        },
        getBusinessConfig: {
            url: "/gamecenter/getBusinessConfig",
            method: "post",
        },
        getChannelList: {
            url: "/gamecenter/getChannelList",
            method: "post",
        }
    },

    activity: {
        getSignInConfig: {
            url: '/activity/getSignInConfig',
            method: "post"
        },
        querySignInInfo: {
            url: '/activity/querySignInInfo',
            method: "post"
        },
        signInDone: {
            url: '/activity/signInDone',
            method: "post"
        },
        getTurnConfig: {
            url: '/activity/getTurnConfig',
            method: "post"
        },
        doTurn: {
            url: '/activity/doTurn',
            method: "post"
        },
        getTurnRecord: {
            url: '/activity/getTurnRecord',
            method: "post"
        },
        queryBaseEnsureConfig: {
            url: '/activity/queryBaseEnsureConfig',
            method: "post"
        },
        takeBaseEnsure: {
            url: '/activity/takeBaseEnsure',
            method: "post"
        },
        getAwardOfBindPhone: {
            url: '/activity/getAwardOfBindPhone',
            method: "post"
        },
        getRedeemCode: {
            url: '/activity/getRedeemCode',
            method: "post"
        },
        getGrowthFundConfig: {
            url: '/activity/getGrowthFundConfig',
            method: "post"
        },
        buyGrowthFund: {
            url: '/activity/buyGrowthFund',
            method: "post"
        },
        getGrowthFundReward: {
            url: '/activity/getGrowthFundReward',
            method: "post"
        },
        queryDailyShareInfo: {
            url: '/activity/queryDailyShareInfo',
            method: "post"
        },
        updateDailyShareInfo: {
            url: '/activity/updateDailyShareInfo',
            method: "post"
        },
        getDailyShareReward: {
            url: '/activity/getDailyShareReward',
            method: "post"
        },
        getChargeGiftConfig: {
            url: '/activity/getChargeGiftConfig',
            method: "post"
        },
        getChargeGiftStatus: {
            url: '/activity/getChargeGiftStatus',
            method: "post"
        },
        getChargeGiftReward: {
            url: '/activity/getChargeGiftReward',
            method: "post"
        },
        gameActivityNotice: {
            url: '/gameActivity/gameActivityNotice',
            method: "post"
        },
        checkAwardOfDownLoadApp:  {
            url: '/activity/checkAwardOfDownLoadApp',
            method: "post"
        },
        getAwardOfDownLoadApp:  {
            url: '/activity/getAwardOfDownLoadApp',
            method: "post"
        },
    },

    sport: {
        sportBetRecord: {
            url: '/sport/sportBetRecord',
            method: "post"
        },
    },

    oauth: {
        startGame: {
            url: '/oauth/{channel}/startGame',
            method: "post"
        },
        demoGame: {
            url: '/oauth/{channel}/demoGame',
            method: "post"
        }
    },

    admin: {
        setCasinoGameTagList: {
            url: '/admin/setCasinoGameTagList',
            method: "post"
        }
    },

};
