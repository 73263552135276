  const locale = {
  GAME: 'JEU',
  Casino: "Casino",
  ProvablyFair: "Provably Fair",
  Option: 'Option',
  Crypto: 'Crypto',
  Forex: 'Forex',
  Stocks: 'Stocks',
  NFTFuniCard: 'NFT FuniCard',
  StakeNFT: 'Le gage NFT gagner USDT',
  NFTStake: 'NFT Le gage',
  Marketplace: 'Place de marché',
  NFTMint: 'NFT fonderie',
  BettoEarn: 'Parier pour gagner',
  EarnUSGT: 'gagner USDT',
  DeFi: 'DeFi',
  ToBeVolunteer: 'Pour être Bénévoles',
  SYSTEM: 'SYSTEM',
  Affiliate: 'Subordinate',
  News: 'nouvelles',
  FAQs: 'FAQs',
  DOWNLOAD: 'TÉLÉCHARGER',
  DOWNLOADApp: 'Télécharger App',
  Savetodesktop: 'Sauvegarder sur le bureau',
  BetMining: 'Pari minier',
  ToBeBanker: 'Devenir banquier',
  ProfitPool: 'Pool de profits',
  USGTLiquidityMining: 'USDT Liquidity Mining',
  BGTLiquidityMining: 'BGT Liquidity Mining',
  Slots: 'Machines à sous',
  Favorite: 'Favoris',
  Recent: 'Récent',
  Popular: "Populaire",
  LiveCasino: "Casino en direct",
  TableGames: "Jeux de table",
  BlackJack: "BlackJack",
  Baccarat: "Baccarat",
  Roulette: "Roulette",
  MoreGames: "Autres jeux",
  Provablyfair: "Provably Fair",
  Special: "Special",
  Recommend: "Recommend",
  FeatureBuyin: "Feature Buy-in",
  EnableTrialPlay: "Enable Trial Play",
  GameShow: "Jeu télévisé",
  RGSVSB: "RGS - VSB",
  Scratch: "Grattage",
  Bingo: "Bingo",
  Fishing: "Pêche",
  Turntable: "Roue de la fortune",
  Dice: "Jeu de dés",
  Poker: "Poker",
  AllGames: "Tous les jeux",
  EuropeanView: "Vue européenne",
  AfricanView: "Vue africaine",
  LatinView: "Vue latine",
  AsianView: "Vue asiatique",
  LsportView: "Ligue de sport",
  StandardView: "Vue standard",
  EsportView: "e-sport",
  LiveBetting: 'Paris en direct',
  Provider: 'Fournisseur',
  Sport: "Sport",

};

export default locale;
