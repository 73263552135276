import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Typography from '@mui/material/Typography';
import { getFaqs, selectGroupedFaqs } from '../store/faqsSlice';
import { getFaqCategories } from '../store/faqCategoriesSlice';
import FaqList from './FaqList';
import { getFaqHelp } from "../../../../store/config/configThunk";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../../../util/tools/function";
import countryLang from "../../../../json/country";
import { selectCurrentLanguageId } from 'app/store/i18nSlice';

function HelpCenterFaqs() {
    const { t } = useTranslation('mainPage');
    const dispatch = useDispatch();
    const groupedFaqs = useSelector(selectGroupedFaqs);
    const currentLang = useSelector(selectCurrentLanguageId);

    // useEffect(() => {
    //   dispatch(getFaqs());
    //   dispatch(getFaqCategories());
    // }, [dispatch]);

    const [faqs, setFaqs] = useState({});

    useEffect(() => {
        console.log(currentLang, 'currentLang......')
        dispatch(getFaqHelp({
            lang: changeLanguage(countryLang, currentLang),
            bFull: true
        })).then((res) => {
            let result = res.payload;
            if (result) {
                setFaqs(result);
            }
        });
    }, [dispatch, currentLang])

    return (
        <div className="flex flex-col items-center p-24 sm:p-40">
            <div className="flex flex-col w-full max-w-4xl">
                {/*<div className="sm:mt-32">*/}
                {/*  <Button*/}
                {/*    component={Link}*/}
                {/*    to={-1}*/}
                {/*    color="secondary"*/}
                {/*    startIcon={<FuseSvgIcon>heroicons-outline:arrow-narrow-left</FuseSvgIcon>}*/}
                {/*  >*/}
                {/*    Back to Help Center*/}
                {/*  </Button>*/}
                {/*</div>*/}
                <div className="mt-8 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight">
                    {t("home_FrequentlyAskedQuestions")}
                </div>

                {Object.keys(faqs).map((key) => (
                    <div key={key}>
                        <Typography className="mt-48 sm:mt-64 text-3xl font-bold leading-tight tracking-tight">
                            {key === '1' && <>{t("home_wanfa_1")}</>}
                            {key === '2' && <>{t("home_wanfa_2")}</>}
                            {key === '3' && <>{t("home_wanfa_3")}</>}
                        </Typography>
                        <FaqList className="w-full mt-32" list={faqs[key]} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HelpCenterFaqs;
