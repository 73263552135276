  const locale = {
    GAME: 'गेम',
    Casino: "कैसीनो",
    ProvablyFair: "सत्यापित निष्पक्षता",
    Option: 'विकल्प',
    Crypto: 'क्रिप्टोकरेंसी',
    Forex: 'विदेशी मुद्रा व्यापार',
    Stocks: 'स्टॉक',
    NFTFuniCard: 'एनएफटी रिचार्ज कार्ड',
    StakeNFT: 'NFT वापरून USDT कमवा',
    NFTStake: 'एनएफटी पर सट्टे लगाएं',
    Marketplace: 'मार्केटप्लेस',
    NFTMint: 'एनएफटी मिन्ट करें',
    BettoEarn: 'बेट करें और पैसे कमाएं',
    EarnUSGT: 'USDT कमाएं',
    DeFi: 'डीफ़ाइ',
    ToBeVolunteer: 'एक स्वयंसेवक बनें',
    SYSTEM: 'सिस्टम',
    Affiliate: 'एफिलिएट',
    News: 'समाचार',
    FAQs: 'सामान्य प्रश्न',
    DOWNLOAD: 'डाउनलोड',
    DOWNLOADApp: 'ऐप डाउनलोड करें',
    Savetodesktop: 'डेस्कटॉप पर सहेजें',
    BetMining: 'बेट माइनिंग',
    ToBeBanker: 'बैंकर बनें',
    ProfitPool: 'लाभ पूल',
    USGTLiquidityMining: 'USDT लिक्विडिटी माइनिंग',
    BGTLiquidityMining: 'BGT लिक्विडिटी माइनिंग',
    Slots: 'स्लॉट मशीन',
    Favorite: 'पसंदीदा',
    Recent: 'हाल का',
    Popular: "लोकप्रिय",
    LiveCasino: "लाइव कैसीनो",
    TableGames: "टेबल गेम्स",
    BlackJack: "ब्लैकजैक",
    Baccarat: "बकारट",
    Roulette: "रूलेट",
    MoreGames: "अधिक खेल",
    Provablyfair: "सत्यापित निष्पक्षता",
    Special: "विशेष",
    Recommend: "सिफारिश",
    FeatureBuyin: "विशेषता खरीदें",
    EnableTrialPlay: "ट्रायल प्ले सक्षम करें",
    GameShow: "गेम शो",
    RGSVSB: "आरजीएस-वीएसबी",
    Scratch: "स्क्रॅच",
    Bingo: "बिंगो",
    Fishing: "मछली पकडणे",
    Turntable: "टर्नटेबल",
    Dice: "पासा",
    Poker: "पोकर",
    AllGames: "सर्व खेळ",
    EuropeanView: "युरोपियन दृष्टीकोण",
    AfricanView: "अफ्रिकन दृष्टीकोण",
    LatinView: "लॅटिन दृष्टीकोण",
    AsianView: "एशियन दृष्टीकोण",
    LsportView: "लिग दृष्टीकोण",
    StandardView: "मानक दृष्टीकोण",
    EsportView: "ई-स्पोर्ट्स दृष्टीकोण",
    LiveBetting:'लाइव्ह बेटिंग',
    Provider:'प्रदाता',
    Sport: "क्रीडा",
    
    
    };
    
    export default locale;
    
