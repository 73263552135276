import "../../../styles/game.css";
import * as React from "react";
import { useEffect, useState } from "react";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import utils from "../../util/tools/utils";
import Common_foot from "../common/Common_foot";
import Common_gameReport from "../common/Common_gameReport";
import { motion } from "framer-motion";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCasinoGames, getChannelList,
  getTagCasinoGames,
} from "../../store/casino/casinoThunk";
import history from "@history";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { selectCasinoData } from "../../store/casino";
import ReactDom from "react-dom";
import FuseLoading from "@fuse/core/FuseLoading";
import { selectUserData } from "../../store/user";
import { isMobile } from "../../util/tools/function";
import GameTips from "../cookies/GameTips";
import {selectUserSetting} from "app/store/user/userSetting";
import {selectTags} from "app/store/casino/tags";
import {selectSearchCasinoChannel} from "app/store/casino/searchCasinoChannel";
import {selectNav} from "app/store/casino/nav";
import {selectUserLoginCountry} from "app/store/user/userLoginCountry";

function GameOther(props) {
  const { t } = useTranslation("mainPage");
  const navT = useTranslation('navPage').t;
  // navigation
  const dispatch = useDispatch();
  const params = useParams();
  const mainTagID = params.typeId;
  const paramTypeName = params.typeName;
  const isTagGroup = params.isTagGroup;

  const userData = useSelector(selectUserData);
  const userSetting = useSelector(selectUserSetting);
  const userLoginCountry = useSelector(selectUserLoginCountry);
  const [isLoading, setIsLoading] = useState(false);

  const pageSize = isMobile() ? 27 : 28;
  const [page, setPage] = useState(1);

  const [gameList, setGameList] = useState([]);
  const [showGameList, setShowGameList] = useState([]);
  const [searchGameList, setSearchGameList] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [ showGameTips, setShowGameTips ] = useState(false);
  const [ currGame, setCurrGame ] = useState({});

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  const casinoData = useSelector(selectCasinoData);
  const searchCasinoChannel = useSelector(selectSearchCasinoChannel);
  const tagData = useSelector(selectTags);
  const navs = useSelector(selectNav);
  const tags = useSelector(selectNav).allNavTagList;

  const [tagID, setTagID] = useState(mainTagID);
  const [channel, setChannel] = useState("all");
  const [inputVal, setInputVal] = useState({
    searchText: "",
  });
  const [typeName, setTypeName] = useState(paramTypeName);
  const handleChangeInputVal = (prop) => (event) => {
    doSearchData(event.target.value);
    setInputVal({ ...inputVal, [prop]: event.target.value });
  };

  const doSearchData = (searchText) => {
    setSearchGameList([]);
    if (searchText) {
      let tmpSearchArr = [];
      for (var i = 0; i < gameList.length; i++) {
        if (
          gameList[i].gameCombineName
            .toUpperCase()
            .match(searchText.toUpperCase())
        ) {
          let tmpGame = gameList[i];
          tmpSearchArr.push(tmpGame);
        }
      }

      setSearchGameList(tmpSearchArr);
      setPage(1);
      setShowGameList([tmpSearchArr.slice(0, pageSize)]);
    } else {
      setPage(1);
      setSearchGameList([]);
      setShowGameList([gameList.slice(0, pageSize * page)]);
    }
  };

  const nextPage = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      let nextPage = page + 1;
      setPage(nextPage);
      if (searchGameList.length > 0) {
        setShowGameList([
          ...showGameList,
          searchGameList.slice(pageSize * page, pageSize * nextPage),
        ]);
      } else {
        setShowGameList([
          ...showGameList,
          gameList.slice(pageSize * page, pageSize * nextPage),
        ]);
      }
    }, 1000);
  };

  const handleChangeSelect = (event) => {
    setChannel(event.target.value);
  };

  const handleChangeSelectTag = (event) => {
    let tmpTagID = event.target.value;
    let allNavTagList = navs.allNavTagList;
    if (allNavTagList[tmpTagID].tagGroup === 1) {
      history.push(
        `gameOther/${tmpTagID}/${t(
          `navigation:${allNavTagList[tmpTagID].tagName
            .replace(/\s*/g, "")
            .replace(/-/g, "")}`
        )}/1`
      );
    } else {
      history.push(
        `gameOther/${tmpTagID}/${t(
          `navigation:${allNavTagList[tmpTagID].tagName
            .replace(/\s*/g, "")
            .replace(/-/g, "")}`
        )}/0`
      );
    }

    setTagID(tmpTagID);
  };

  const channelSearch = (channel) => {
    let tmpSearchArr = [];
    for (var i = 0; i < gameList.length; i++) {
      if (gameList[i].gameChannel === channel) {
        let tmpGame = gameList[i];
        tmpSearchArr.push(tmpGame);
      }
    }

    setSearchGameList(tmpSearchArr);
    setPage(1);
    setShowGameList([tmpSearchArr.slice(0, pageSize)]);
  };

  const imgOnLoad = (ref, src) => {
    let dom = document.getElementById(ref);
    if (src) {
      dom.src = src;
    } else {
      dom.src = "assets/images/game/default.png";
    }
  };

  const [ channelList, setChannelList ] = useState([]);
  const doGetChannel = () => {
    dispatch(getChannelList()).then((res) => {
      let result = res.payload;
      if (result) {
        console.log(result, 'channleList......')
        setChannelList(result);
      }
    })
  }

  useEffect(() => {
    doGetChannel();
  }, []);

  useEffect(() => {
    if (gameList.length > 0) {
      if (channel === "all") {
        setPage(1);
        setSearchGameList([]);
        setShowGameList([gameList.slice(0, pageSize * 1)]);
      } else {
        channelSearch(channel);
      }
    }
  }, [channel]);

  useEffect(() => {
    setPage(1);
    setChannel("all");
    setTagID(mainTagID);
    setGameList([]);
    setSearchGameList([]);
    setShowGameList([]);
    setShowLoading(true);

    // if (JSON.stringify(userSetting.setting) !== "{}") {
    if (JSON.stringify(userLoginCountry.currentCountryData) !== "{}") {
      setTimeout(() => {
        if (isTagGroup == 1) {
          let data = {};
          if (mainTagID != 0) {
            data.mainTagID = mainTagID;
          }
          dispatch(getCasinoGames(data)).then((res) => {
            const data = res.payload;
            setShowLoading(false);
            if (data) {
              setGameList(data);
              setShowGameList([data.slice(0, pageSize)]);
            }
          });
        } else {
          dispatch(
              getTagCasinoGames({
                tagID: mainTagID,
              })
          ).then((res) => {
            let data = res.payload;
            setShowLoading(false);
            if (data) {
              setGameList(data);
              setShowGameList([data.slice(0, pageSize)]);
            }
          });
        }
      }, 300)
    }
    // }
  }, [mainTagID, userSetting, userLoginCountry]);

  useEffect(() => {
    if (searchCasinoChannel.searchCasinoChannel && gameList.length > 0) {
      setChannel(searchCasinoChannel.searchCasinoChannel);
    }
  }, [searchCasinoChannel.searchCasinoChannel, gameList]);

  useEffect(() => {
    if (paramTypeName && JSON.stringify(tags) != '{}') {
      if (mainTagID == 0) {
        setTypeName(navT("AllGames"))
      } else {
        setTypeName(navT(tags[mainTagID].tagName.replace(/\s*/g, '').replace(/-/g, '')))
      }
    }
  }, [tags, paramTypeName, localStorage.getItem('lang')])

  return (
    <div className="flex w-full container overflow-x-">
      <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 p-10 md:p-10 pb-0 md:pb-0 gameHuoDongDing">
        <div className="flex flex-col flex-auto align-item">
          <div className="flex grow shrink-0 flex-col items-center container ">
            <div className="activitybanner  text-24 mb-20 ">{typeName}</div>
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="activitybanner "
            >
              <motion.div variants={item} className="min-w-full ">
                <div className="zuQiu">
                  <FormControl
                    className="wallet-search"
                    sx={{
                      width: "100%",
                      borderColor: "#94A3B8",
                      maxWidth: "1196px",
                    }}
                    variant="outlined"
                  >
                    {/*<FuseSvgIcon*/}
                    {/*    className="text-48 cursor-pointer font-medium wallet-search-icon"*/}
                    {/*    size={24}*/}
                    {/*    color="action"*/}
                    {/*    onClick={handleChangeInputVal('searchText')}*/}
                    {/*>feather:search</FuseSvgIcon>*/}
                    <OutlinedInput
                      sx={{
                        width: "100%",
                        borderColor: "#94A3B8",
                        borderSize: "1px",
                      }}
                      id="outlined-adornment-address outlined-adornment-address-wallet outlined-adornment-address-wallet-input"
                      value={inputVal.searchText}
                      onChange={handleChangeInputVal("searchText")}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "searchText",
                      }}
                      placeholder={t("game_search")}
                      className="borderRadius"
                    // onBlur={() => { setSearchInput(false) }}
                    />
                  </FormControl>
                </div>

                <div className="zuQiu mt-28 ">
                  <div className="promotion-list activitybanner  flex items-center justify-between ">
                    <div className="flex items-center">
                      <img
                        className="height-24 mt-2"
                        src="assets/images/icon/10009.png"
                      ></img>
                      <div className="font-14  mt-4 ml-4 font-weight500 fontColor displayPhoneSwitch1">
                        {t("game_Providers")}
                      </div>
                      <div
                        className="flex width-140  ml-6 py-2 borderRadius btnPointer"
                        style={{
                          height: "40px",
                          background: "none",
                        }}
                      >
                        <FormControl
                          fullWidth
                          className="gameOtherSelect funbetTxt"
                        >
                          <InputLabel id="demo-simple-select-label">
                            {t("game_Providers")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={channel}
                            label={t("game_Providers")}
                            onChange={handleChangeSelect}
                          >
                            <MenuItem value="all">All</MenuItem>
                            {channelList.map((item) => {
                              return (
                                  <MenuItem key={item.channelName} value={item.channelName}>{item.channelName}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="flex items-center ">
                      <img
                        className="height-24 mt-2"
                        src="assets/images/icon/10009.png"
                      ></img>
                      <div className="font-14  mt-4 ml-4 font-weight500 fontColor displayPhoneSwitch1">
                        {t("game_SortBy")}
                      </div>
                      <div
                        className="flex width-140  ml-6 py-2 borderRadius btnPointer"
                        style={{
                          height: "40px",
                          background: "none",
                        }}
                      >
                        <FormControl
                          fullWidth
                          className="gameOtherSelect funbetTxt"
                        >
                          <InputLabel id="demo-simple-select-label">
                            {t("game_SortBy")}
                          </InputLabel>
                          {JSON.stringify(navs.allNavTagList) !== "{}" && (
                              <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={tagID}
                                  label={t("game_SortBy")}
                                  onChange={handleChangeSelectTag}
                                  sx={{
                                    height: "100px",
                                  }}
                              >
                                {
                                    Object.keys(navs.allNavTagList).map(
                                        (key) => {
                                          return (
                                              <MenuItem
                                                  key={navs.allNavTagList[key].tagID}
                                                  value={
                                                    navs.allNavTagList[key].tagID
                                                  }
                                              >
                                                {t(
                                                    `navigation:${navs.allNavTagList[
                                                        key
                                                        ].tagName
                                                        .replace(/\s*/g, "")
                                                        .replace(/-/g, "")}`
                                                )}
                                              </MenuItem>
                                          );
                                        }
                                    )}
                              </Select>
                          )}
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>

                {showGameList.flat(Infinity).length > 0 ? (
                  <>
                    <div className="zuQiu mt-0 ">
                      {showGameList.map((gameList, index) => {
                        return (
                          <motion.div
                            key={index}
                            className="promotion-list2 activitybanner gameListPl"
                            variants={container}
                          >
                            {gameList.map((game, gameIndex) => {
                              return (
                                <motion.div
                                  variants={item}
                                  key={gameIndex}
                                  className="btnPointer marginXY imgHidden maxWidth borderRadius-8 game-other-game-list game-other-game-list-item"
                                  // style={{
                                  //     margin: '16px 0.75%'
                                  // }}
                                  onClick={() => {
                                    if (userSetting?.setting?.casinoGame) {
                                      setShowGameTips(true)
                                      setCurrGame(game)
                                    } else {
                                      history.push(
                                          `/play/${game.gameChannel}/${game.gameID}/${game.casinoID}`
                                      );
                                    }
                                  }}
                                >
                                  <img
                                    id={`game-${game.casinoID}`}
                                    className="imgMohu imgGameWh "
                                    src={"assets/images/game/default.png"}
                                    onLoad={() => {
                                      imgOnLoad(
                                        `game-${game.casinoID}`,
                                        game.gameIcon
                                      );
                                    }}
                                  />
                                  {userSetting?.user?.canSetGame ? (
                                    <div className="displayImg game-other-game-tag">
                                      {game.allTag.length > 0 &&
                                        game.allTag.map((gameTag) => {
                                          return (
                                            <div
                                              className="m-8"
                                              key={gameTag.tagID}
                                            >
                                              {gameTag.tagName}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  ) : (
                                    <img
                                      className="displayImg index4bigImg"
                                      src="assets/images/index/gameZheZhaoSmall.png"
                                    />
                                  )}
                                </motion.div>
                              );
                            })}
                          </motion.div>
                        );
                      })}
                    </div>
                    <div className="zuQiu ">
                      {searchGameList.length > 0 ? (
                        <>
                          {showGameList.flat(Infinity).length <
                            searchGameList.length && (
                              <>
                                <div className=" width-180 marginJuZhong ctyptoBtn-txt text-16 ">
                                  {t("game_DispLaying")}
                                  {showGameList.flat(Infinity).length} /{" "}
                                  {searchGameList.length}
                                </div>
                                {isLoading && (
                                  <div className="mt-20">
                                    <FuseLoading />
                                  </div>
                                )}
                                {!isLoading && (
                                  <div className=" width-180 marginJuZhong ">
                                    <Button
                                      className="whitespace-nowrap width-180  borderRadius text-18 py-24 funbetTxt"
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => {
                                        nextPage();
                                      }}
                                    >
                                      {t("game_LoadMore")}
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                        </>
                      ) : (
                        <>
                          {showGameList.flat(Infinity).length <
                            gameList.length && (
                              <>
                                <div className=" width-180 marginJuZhong ctyptoBtn-txt text-16">
                                  {t("game_DispLaying")}{" "}
                                  {showGameList.flat(Infinity).length} /{" "}
                                  {gameList.length}
                                </div>
                                {isLoading && (
                                  <div className="mt-20">
                                    <FuseLoading />
                                  </div>
                                )}
                                {!isLoading && (
                                  <div className=" width-180 marginJuZhong ">
                                    <Button
                                      className="whitespace-nowrap width-180  borderRadius text-18 py-24 funbetTxt"
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => {
                                        nextPage();
                                      }}
                                    >
                                      {t("game_LoadMore")}
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {!showLoading && (
                      <div className="zuQiu flex justifyContent">
                        <div
                          className="mt-28 text-20 "
                          style={{
                            height: "32px",
                            lineHeight: "32px",
                            color: "#B8BABF",
                          }}
                        >
                          {t("game_NoData")}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {showLoading && (
                  <div className="zuQiu flex justifyContent ">
                    <div className="mt-20">
                      <FuseLoading />
                    </div>
                  </div>
                )}
              </motion.div>
            </motion.div>
          </div>
          <GameTips
            open={showGameTips}
            successFun={() => {
              setShowGameTips(false)
              history.push(
                  `/play/${currGame.gameChannel}/${currGame.gameID}/${currGame.casinoID}`
              );
            }}
            errorFun={() => {
              setShowGameTips(false)
            }}
          />
          <Common_gameReport />
          <Common_foot />
          <div className="mt-32  promotion-list colorGameListTitle mb-20 displayPhoneSwitch1">
            <div>FuniBet 2022- ALL Rights Reserved</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GameOther;
