const locale = {
  APP_TITLE: 'Posta',
  COMPOSE: 'Yeni E-posta',
  FOLDERS: 'Klasörler',
  FILTERS: 'Filtreler',
  LABELS: 'Etiketler',
  NO_MESSAGES: 'Mesajınız bulunmamakta!',
  SEARCH_PLACEHOLDER: 'E-mail yada bir kişi arayın',
  INBOX: 'Gelen Kutusu',
  SENT: 'Gönderilen',
  DRAFTS: 'Taslaklar',
  SPAM: 'Spam gönderiler',
  TRASH: 'Çöp',
  STARRED: 'Yıldızlı',
  IMPORTANT: 'Önemli',
};

export default locale;
