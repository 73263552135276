  const locale = {
  GAME: 'Oyunlar',
  Casino: "Kumarhane",
  ProvablyFair: "Kanıtlanabilir Adil Oyun",
  Option: 'Seçenek',
  Crypto: 'Kripto',
  Forex: 'Foreks',
  Stocks: 'Hisse Senetleri',
  NFTFuniCard: 'NFT FuniCard',
  StakeNFT: 'Stake NFT kazan USDT',
  NFTStake: 'NFT Stake',
  Marketplace: 'Pazar yeri',
  NFTMint: 'NFTNane',
  BettoEarn: 'Bahis Yaparak Kazan',
  EarnUSGT: 'Kazan USDT',
  DeFi: 'DeFi',
  ToBeVolunteer: 'Gönüllü Olmak İçin',
  SYSTEM: 'SİSTEM',
  Affiliate: 'Bağlı Ortaklık',
  News: 'Haberler',
  FAQs: 'SSS',
  DOWNLOAD: 'İNDİR',
  DOWNLOADApp: 'İndir App',
  Savetodesktop: 'Masaüstüne kaydet',
  BetMining: 'Bahis Madenciliği',
  ToBeBanker: 'Banker Olmak İçin',
  ProfitPool: 'Banker Olmak İçin',
  USGTLiquidityMining: 'USDT Liquidity Mining',
  BGTLiquidityMining: 'BGT Liquidity Mining',
  Slots: 'Yuvalar',
  Favorite: 'Favoriler',
  Recent: 'Son zamanlarda',
  Popular: "Popüler",
  LiveCasino: "Live Casino",
  TableGames: "Masa Oyunları",
  BlackJack: "BlackJack",
  Baccarat: "Baccarat",
  Roulette: "Rulet",
  MoreGames: "Daha Fazla Oyunlar",
  Provablyfair: "Kanıtlanabilir adil",
  Special: "Özel",
  Recommend: "Tavsiye",
  FeatureBuyin: "Özellik Satın Alma",
  EnableTrialPlay: "Deneme Oyunu",
  GameShow: "Oyun Şovu",
  RGSVSB: "RGS - VSB",
  Scratch: "Kazı Kazan",
  Bingo: "Bingo",
  Fishing: "Balıkçılık",
  Turntable: "Dönme çarkı",
  Dice: "Zar",
  Poker: "Poker",
  AllGames: "Tüm Oyunlar",
  EuropeanView: "Avrupa Görünümü",
  AfricanView: "Afrika Görünümü",
  LatinView: "Latin Görünümü",
  AsianView: "Asya Görünümü",
  LsportView: "Lig Görünümü",
  StandardView: "Standart Görünümü",
  EsportView: "E-spor Görünümü",
  LiveBetting:'Canlı Bahis',
  Provider:'Sağlayıcı',
  Sport:'Sporlar',




};

export default locale;
