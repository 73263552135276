const locale = {
    home_StakeNFT: 'NFT 스테이킹으로 더 많은 수익 창출',
    home_everyday: '매일',
    home_Beton: '베팅',
    home_mining: '채굴',
    home_shareholder: '주주가 되기',
    home_Stake: '스테이킹',
    home_dividend: '수익 배당 받기',
    home_Banker: '파트너 중 한 명이 되기',
    home_SIGNUPAND: '지금',
    home_GET: '얻으세요',
    home_UPTO: '최대',
    home_FuniHash: '블록체인 게임',
    home_Option: '옵션',
    
    home_Reward: ' 보상',
    home_Reward1: '보상',
    home_signUpNow: '지금 가입하세요',
    home_Casino: '카지노',
    home_Sports: '스포츠',
    home_PROMOTION: '프로모션',
    home_Checkin: '출석체크',
    home_Bouns: '보너스',
    home_CheckinBouns: '출석체크 보너스',
    home_SPIN: '룰렛',
    home_Relief: '구호',
    home_Share: '공유',
    home_Deposit: '입금',
    home_Fund: '펀드',
    home_VIPClub: 'VIP 클럽',
    home_FastAndEasy: 'USDT 입금 보너스를 받으려면 간편하게 암호화폐를 구매하세요.',
    home_Game: '게임',
    home_User: '사용자',
    home_Time: '시간',
    home_BetAmount: '베팅 금액',
    home_Multiplier: '배수',
    home_Payout: '지급',
    home_JoinOur: '우리와 함께하는 스포츠 팬, 암호화폐 애호가 및 도박 커뮤니티',
    home_JOIN: '참여하기',
    home_LICENSES: '라이선스',
    home_OurPlatforms: '우리의 플랫폼',
    home_Marketplac: '마켓플레이스',
    home_Howtoearn: '수익 방법',
    home_Games: '게임',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: '투자자 덱',
    home_News: '뉴스',
    home_Aboutsus: '회사 소개',
    home_Industry: '산업',
    home_Roadmap: '로드맵',
    home_Partner: '파트너',
    home_FAQs: '자주 묻는 질문',
    home_Affiliate: '제휴',
    home_Stayintouch: '연락을 유지하세요',
    home_ChatRoom: '채팅방',
    home_Selectacontact: '대화를 시작할 연락처를 선택하세요.',
    home_BetSlip: '배팅 쿠폰',
    home_Typeyourmessage: '메시지를 입력하세요',
    home_AcceptAnyOdds: '모든 배당률 수락',
    home_ClearAll: '모두 지우기',
    home_Live: '실시간',
    home_Winner: '승자',
    home_EstPayout: '예상 지급액',
    home_TotalOdds: '총 배당률',
    home_RegistertoBet: '베팅을 위해 등록하세요',
    home_Single: '싱글 베팅',
    home_Multi: '멀티 베팅',
    home_Checkineveryweek: '매주 누적 체크인하면 해당 보상을 받을 수 있습니다.',
    home_Day: '일',
    home_Sunday: '1일차',
    home_Monday: '2일차',
    home_Tuesday: '3일차',
    home_Wednesday: '4일차',
    home_Thursday: '5일차',
    home_Friday: '6일차',
    home_Saturday: '7일차',
    home_FreeSpin: '스핀을 돌리세요',
    home_Turntheturntable: '매일 룰렛을 돌려 USGT 보상을 받으세요.',
    home_DepositFift: '입금 보상',
    home_ANYAMOUNT: '어떤 금액이든 상관 없이 첫 입금으로 보상을 받으세요.',
    home_DEPOSIT: '입금',
    home_First: '첫',
    home_ONLY: '단지',
    home_1ST: '1번째',
    home_2ND: '2번째',
    home_3TH: '3번째',
    home_ANYAMOUNT: '아무런 금액이나 가능!',
    home_EXTRA: '추가',
    home_Relief: '구제금',
    home_Getrelief: '구제금을 받아 돌아오는 기회를 얻으세요.',
    home_YOUCANCLAIM: '잔액이',
    home_YOUCANReceive: '이하로 내려가면 구제금을 받아 잔액을 보충할 수 있습니다. 최대',
    home_threeTimes: '3번',
    home_everydayTimes: '/매일 가능!',
    home_Times: '횟수:',
    home_TotalAssets: '총 자산',
    home_receive: '수령',
    home_TODAY: '오늘',
    home_Share: '공유',
    home_PLATFORMSHARING: '다른 플랫폼에 공유하기',
    home_Sharewith: 'FuniBet을 매일 공유하면',
    home_SHARINGGIFT: '공유 선물',
    home_CODE: '공유 코드',
    home_Selectplatform: '공유할 플랫폼 선택:',
    home_Fund: '성장 기금',
    home_FundBonus: '성장 기금 보너스',
    home_Improveyour: 'VIP 등급을 높여 매일 더 많은 자금 보상을 받으세요.',
    home_BuyFund: '구매하다',
    home_Receivingconditions: '수령 조건',
    home_Receivingstatus: '수령 상태',
    home_Received: '수령함',
    home_noReceived: '미달성',
    home_ExclusiveBenefits: '독점 혜택',
    home_Asawayof: 'VIP 플레이어에 대한 감사와 사랑의 표시로 FuniBet에서 VIP 클럽에 가입하십시오. 거기에는 선물, 무료 행사, 더 높은 현금 반환 및 독특한 기능이 풍부합니다. 모든 흥미로운 것을 놓치지 마세요!',
    home_Yourcurrent: '현재 총 충전액',
    home_WhyUpgrade: '왜 VIP로 승급해야 할까요?',
    home_VIPhave: 'VIP 회원은 전용 매니저의 24 * 7 친절한 서비스를 받을 수 있습니다. 기본 캐시백 및 입금 이외에도 VIP 회원은 최고 레벨의 슬롯, 주간 / 월간 보너스 및 빠른 입출금 채널을 이용할 수 있습니다. VIP 회원은 최고의 플레이어 이벤트에 참여하고 새로운 기능과 특징을 FuniBet 팀에 제안하여 카지노를 함께 만들어갈 수 있습니다.',
    home_UpgradeYour: 'VIP 레벨 업그레이드',
    home_ASaFunibet: 'FUNIBet VIP 클럽 회원으로서, 풍성한 보상과 맞춤형 선물을 받으실 수 있습니다. 그러나 이것만이 전부는 아닙니다. 더 많이 플레이하면 VIP 랭크 업그레이드로 엘리트 VIP 멤버가 될 때 더욱 럭셔리한 혜택을 누릴 수 있습니다. FUNIBET은 당신에게 맞춤형 VIP 특권을 제공할 것입니다.',
    home_YOUCANREACH: '다음 레벨로 업그레이드할 수 있습니다.',
    home_LEVELS: '레벨',
    home_eachthehigh: '모든 사람은 25%의 캐시백 보너스를 받을 수 있으며, 플랫폼의 모든 혜택을 누릴 수 있습니다. FUNIBET에 입장하여 최고의 게임 경험과 우수한 서비스를 즐기세요!',
    home_Cashback: '캐시백',
    home_Nowater: '1. 게임에서 수수료가 없어집니다.',
    home_Moregrowth: '2. 더 많은 성장 펀드를 얻을 수 있습니다.',
    home_Increasein: '3. 혜택 수량이 증가합니다.',
    home_Doublethenumber: '4. 룰렛 수가 두 배로 늘어납니다.',
    home_Doublethesign: '5. 서명 횟수가 두 배로 늘어납니다.',
    home_Doublesharingrewards: '6. 공유 보상이 두 배로 늘어납니다.',
    home_DoubletheAffiliate: '7. 파트너 보상이 두 배로 늘어납니다.',
    home_Rechargerewardamount: '8. 입금 보너스 금액이 1.5배로 늘어납니다.',
    home_ABOUTVIPCLUB: 'VIP 클럽 소개',
    home_HightopRank: '상위 등급은 25%의 캐시백 보너스와 최고의 혜택을 제공합니다. BeDao에 입주하여 최상의 게임 경험과 고수준의 서비스를 누려보세요!',
    home_Beingamember: 'VIP 클럽 회원이 되면 다음을 이용할 수 있습니다:',
    home_PersonalVIPmanager: '전문 매니저',
    home_Insights: '인사이트',
    home_Highercahbackrewards: '보다 높은 캐시백 리워드',
    home_Otheradditionalperks: '기타 추가 혜택',
    home_WeeklyandMonthlyBonuses: '주간 및 월간 보너스',
    home_Showhighgaming: '고급 게임 활동을 표시하고 VIP 클럽 초대를 받으세요.',
    home_BindMobile: '휴대폰 바인딩',
    home_Pleasecheckthe: '게임에서 획득한 USGT를 지갑에서 확인하세요.',
    home_BINDMOBILENUMBER: '휴대 전화 번호 바인딩',
    home_Get1USDT: '1 USDT 받기',
    home_Bind: '바인딩',
    home_GiftCode: '선물 코드',
    home_EnteryourGiftCode: '선물 코드를 입력하세요',
    home_EnterCode: '코드 입력',
    home_Paste: '붙여넣기',
    home_confirms: '확인',
    home_BuyCrypto: '구매하다',
    home_SignedIn: '로그인 완료',
    home_CommissionRules: '커미션 규정',
    home_AffiliateTerms: '제휴 회원 약관',
    home_AffiliateDashboard: '제휴 회원 대시보드',
    home_AFFILIATE: '제휴 회원',
    
    

    home_COMMISSONREADYEVERYDAY: '언제든지 사용 가능한 커미션 $1,000은 추천에 불과합니다',
    home_MYREFERRALCODE: '내 추천 코드',
    home_MYREFERRALLink: '내 추천 링크',
    home_AffiliateReward: '회원 보상',
    home_EXTRA: '추가',
    home_USDREWARDS: '미화 보상',
    home_USDRewardsRules: '미화 보상 규칙',
    home_JUSAREFERRALAWAY: '추천만 하면 됩니다',
    home_NewlyAvailableRewards: '최신 보상',
    home_ReceivedMao: '받은:',
    home_Locked: '잠긴:',
    home_Yourcurrentfriends: '당신의 현재 친구들이 활동을 유지하면 더 많은 보상이 잠금 해제됩니다',
    home_Withdraw: '인출',
    home_MY: '내',
    home_COMMISSIONREWARDS: '커미션 보상',
    home_CommissionRewardRules: '커미션 보상 규칙',
    home_NewlyAvailableCommissionRewards: '새로운 커미션 보상',
    home_CommissionYouhaveReceivedinTotal: '받은 커미션:',
    home_REWARDSSENTTODATE: '지금까지 보낸 보상',
    home_USDrewardssenttodate: '지금까지 보낸 미화 보상',
    home_Commissionsenttodatet: '커미션 전송일',
    home_Areyouablogger: '블로거이며 많은 팔로워와 독자를 보유하고 계십니까? 특별 혜택과 더 큰 추천 보상이 제공되는 고유한 제휴 계획을 제공합니다. 특별 조건을 갖춘 별도의 프로그램이 마련됩니다. 조건에 대해 논의하기 위해 당사 매니저에게 연락하십시오.',
    home_onlythoseusers: '중요 안내: 관리자의 요청을 거쳐 승인된 사용자만이 이 계획에 참여할 수 있습니다.',
    home_notice: '공지사항',
    home_bindPhone: '휴대폰 번호 바인딩',
    home_SignIn: '로그인',
    home_SignUp: '회원가입',
    home_SignOut: '로그아웃',
    home_CookiesPolicy: '쿠키 정책',
    home_isUsingCookies: 'Funibet.com은 쿠키를 사용하여 각 사용자에게 더 나은 서비스를 제공합니다. 당사 웹사이트를 사용하면 쿠키 사용에 동의한 것으로 간주됩니다. 개인정보 취급방침 및 서비스 약관을 확인하십시오.',
    home_SETTING: '설정',
    home_LiveSupport: '실시간 고객 지원',
    home_Accept: '동의',
    home_FrequentlyAskedQuestions: '자주 묻는 질문',
    home_SiteUnnderConstruction: '사이트 건설 중',
    home_ComingSoon: '곧 출시됩니다',
    home_Wallet: '지갑',
    home_UserId: 'ID:',
    home_UserName: '이름:',
    home_turnTimes: '회전 수:',
    home_Bonusincreased: "보너스 추가 +",
    home_bonus: '보너스',
    home_SPINBONUSTOTAL: '회전 보너스 합계',
    home_WIN: '대상:',
    home_In: '에서',
    home_FirstDepositBonus: '첫 입금 보너스',
    home_Hi: '안녕하세요',
    home_welcomeAboard: '다시 돌아와서 반갑습니다',
    home_Outoftime: '오늘 회전 횟수를 모두 사용했습니다.',
    
    game_search: '검색',
    game_SortBy: '분류',
    game_Created: '생성 시간',
    game_Lobby: '로비',
    game_LiveCasino: '라이브 카지노',
    game_FeaturedSlots: '인기 슬롯 머신',
    game_GameShows: '게임 쇼',
    game_FuniBetOriginals: 'FuniBet 오리지널 게임',
    game_BigWins: '큰 승리',
    game_LcukyWins: '운 좋게 이긴',
    game_Challenges: '도전',
    game_Description: '설명',
    game_Slots: '슬롯 머신',
    game_Rank: '순위',
    game_User: '사용자',
    game_Date: '날짜',
    game_BetAmount: '배팅 금액',
    game_lucky: '최대 배수',
    game_Multiplier: '배수',
    game_Payout: '결과',
    game_Recommend: '추천',
    game_Providers: '제공업체',
    game_startGame: '시작하다',
    game_balance: '잔액',
    game_provider: '태그',
    game_Selectyourdisplay: '잔액 표시 방법 선택',
    game_Balancein: '잔액',
    game_FunPlay: '무료 게임 모드',
    game_RealPlay: '유료 게임 모드',
    game_DispLaying: "표시",
    game_LoadMore: "더 보기",
    game_NoData: '데이터 없음',
    
    message_SigningIn: "JWT로 로그인 중입니다",
    message_CourseSaved: "코스가 저장되었습니다",
    message_PleaseBind: "먼저 휴대폰을 연동해주세요",
    message_UserData: "사용자 데이터가 API로 저장되었습니다",
    message_Success: "성공",
    message_VIPlevel: "VIP 레벨이 V3 미만입니다",
    message_ListOrder: "목록 주문이 저장되었습니다",
    message_CardOrder: "카드 주문이 저장되었습니다",
    message_HiHow: "안녕하세요, 어떻게 지내세요?",

    Funibet_web: 'Funibet-Web3.0,암호 화폐,베팅,스포츠,전자 스포츠,슬롯 머신,라이브 카지노,포커',

    home_ThereAre:"현재 알림이 없습니다." ,

    play_no_balance: "현재 통화 잔액이 부족합니다. 통화를 전환하거나 충전하세요.",

    home_News_1:'뉴스',
    home_News_2:'이벤트',
    home_News_3:'암호화폐',
    home_News_4:'게임',
    
    home_SuggRep_1:'건의 및 신고',
    home_SuggRep_2:'건의 및 신고',
    home_SuggRep_3:'피드백을 공유하여 개선하세요!',
    home_SuggRep_4:'게임 내 판매 웹사이트 신고하기',
    home_SuggRep_5:'건의 내용 입력 또는 신고 제출',
    home_SuggRep_6:'샘플 스크린샷',
    home_SuggRep_7:'채택된 건의 및 신고에 대한 보상!',
    home_SuggRep_8:'제출',
    
    home_subscription_1:'푸시 알림을 구독하시겠습니까?',
    home_subscription_2:'언제든지 구독을 취소할 수 있습니다.',
    home_subscription_3:'WonderPush에 의한 웹 푸시',
    home_subscription_4:'나중에',
    home_subscription_5:'구독',
    
    home_Affiliate_1:'문의하기',
    home_Affiliate_2:'친구를 초대하면 10U를 얻을 수 있습니다. 초대하는 친구가 많을수록 더 많은 보상을 받으실 수 있습니다!',
    home_Affiliate_3:'Telegram',
    home_Affiliate_4:'이메일',

    home_banner_1: '크립토 게임',
    home_banner_2: '100개 이상의 암호화폐 및 50개 이상의 NFT 지원',
    home_banner_3: '탈중앙화된 베팅',
    home_banner_4: '분산 지갑에서 스마트 계약 베팅',
    home_banner_5: '배팅으로 수익 창출',
    home_banner_6: '배팅, 채굴 및 투자로 안정적인 수익 창출',
    home_banner_7: 'Web3.0 + 카지노',
    home_banner_8: '플레이어는 동시에 뱅커 및 오너',
    
    home_EventDetails: '세부 정보',

    home_vip_1: "1.체크인으로 수익 증가+",
    home_vip_2: "2.회전으로 수익 증가+",
    home_vip_3: "3.공유로 수익 증가+",
    home_vip_4: "4.충전으로 수익 증가+",
    home_vip_5: "5.채팅 개방, 매일 메시지 전송 가능",
    home_vip_6: "메시지",

    ERROR_628: '이미 사용된 기프트 코드입니다',
    ERROR_629: '기프트 코드가 모두 사용되었습니다',
    ERROR_630: '기프트 코드가 활성화되지 않았습니다',
    ERROR_631: '기프트 코드가 만료되었습니다',
    ERROR_633: '다른 대리인의 기프트 코드를 사용할 수 없습니다',

    home_Maintaining_1: "유지",
    home_Maintaining_2: "이 기능은 유지보수 중입니다",
    home_Maintaining_3: "끝",
    home_Maintaining_4: "시작",
    
    home_Slots : '슬롯',
    home_LiveCasino : '라이브 카지노',
    home_CountryFiat : '국가 및 법정 통화',
    home_CryptNFTs : '암호화폐 및 NFT',

    home_GoTo:'이동',

    home_gameout_1: "현재 다른 게임에서 진행 중입니다. 종료하시겠습니까?",
    home_gameout_2: "취소",

     home_BuyFund1:'성장 펀드를 구매하시겠습니까?',

     home_BetFor:'에 베팅',

     tongzhi_1: "건의",
     tongzhi_2: "건의 및 대표에 대한 답장.",
     tongzhi_3: "잘 했어요! 정말 멋져요.",
     tongzhi_4: "심사 대기 중.",
     tongzhi_5: "심사 승인.",
     tongzhi_6: "지급 처리 중.",
     tongzhi_7: "지급 완료.",
     tongzhi_8: "심사 미승인.",
     tongzhi_9: "지급 실패.",
     tongzhi_10: "인출 실패.",
     tongzhi_11: "지불 완료.",
     tongzhi_12: "완료.",
     tongzhi_13: "처리 실패.",
     tongzhi_14: "구매",
     tongzhi_15: "까지",
     tongzhi_16: "인출",
     tongzhi_17: "암호화폐",
     tongzhi_18: "법정화폐",
     tongzhi_19: "다운로드",
     tongzhi_20: "로그인하면 보상 받을 수 있습니다",
     tongzhi_21: "다운로드",
     tongzhi_22: "현재 iOS 버전이 없습니다",
     tongzhi_23: "보내기",
     tongzhi_24: "팁 보내기",
    
     home_banner_9: "경마",
     home_banner_10: "싸움 닭",
     home_banner_11: "흥미로운 및 팽팽, 큰 상금 획득",
     home_banner_12: "열정적이고 힘찬",
     
     home_wanfa_1:"게임 플레이 문제",
     home_wanfa_2:"이체 문제",
     home_wanfa_3:"다른 문제",
     play_not_balance: "eUSDT로 스포츠 베팅이 불가능합니다. 통화를 변경하시겠습니까? ",
    
    
    
    
    };
    
    export default locale;
    
    
