import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Link, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import history from "@history";
import { useTranslation } from "react-i18next";
import { useState, useEffect, forwardRef, default as React } from "react";

function ComingSoon(props) {
    const { t } = useTranslation('mainPage');
    const params = useParams();

    return (
        <div className="flex flex-col flex-1 items-center justify-center p-16 titleTxt">
            <div className="w-full max-w-3xl text-center ">

                <motion.div
                    initial={{ opacity: 0, scale: 0.6 }}
                    animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
                >
                    <img className='phoneComTop' src={`${React.$imgUrl}/images/index/feiDie.png`}></img>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 40 }}
                    animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
                >
                    <div className='text-28 '>{t("home_SiteUnnderConstruction")}</div>
                    <div className='text-48 txtGreenColor mt-12 mb-20 '>{t("home_ComingSoon")}</div>
                    <div className='widthPhone400 promotion-list1 marginJuZhong'>
                        <img className='btnPointer txtBrightness w-32 h-32' src={`${React.$imgUrl}/images/index/footIcon1.png`} onClick={() => { window.location.href = '' }}></img>
                        <img className='btnPointer txtBrightness w-32 h-32' src={`${React.$imgUrl}/images/index/footIcon2.png`} onClick={() => { window.location.href = '' }}></img>
                        <img className='btnPointer txtBrightness w-32 h-32' src={`${React.$imgUrl}/images/index/footIcon3.png`} onClick={() => { window.location.href = '' }}></img>
                        <img className='btnPointer txtBrightness w-32 h-32' src={`${React.$imgUrl}/images/index/footIcon4.png`} onClick={() => { window.location.href = '' }}></img>
                        <img className='btnPointer txtBrightness w-32 h-32' src={`${React.$imgUrl}/images/index/footIcon5.png`} onClick={() => { window.location.href = '' }}></img>
                        <img className='btnPointer txtBrightness w-32 h-32' src={`${React.$imgUrl}/images/index/footIcon6.png`} onClick={() => { window.location.href = '' }}></img>
                    </div>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 40 }}
                    animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
                >
                </motion.div>
            </div>
        </div>
    );
}

export default ComingSoon;
