import { useTranslation } from "react-i18next";
import { motion } from 'framer-motion';
import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import keycode from 'keycode';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Chat from '../../theme-layouts/shared-components/chatPanel/Chat';
import ContactList from '../../theme-layouts/shared-components/chatPanel/ContactList';
import { getContacts, selectContacts, selectSelectedContactId } from '../../theme-layouts/shared-components/chatPanel/store/contactsSlice';
import { closeChatPanel, openChatPanel, selectChatPanelState } from '../../theme-layouts/shared-components/chatPanel/store/stateSlice';
import { getUserData } from '../../theme-layouts/shared-components/chatPanel/store/userSlice';
import { getChats } from '../../theme-layouts/shared-components/chatPanel/store/chatsSlice';
import history from '@history';
import clsx from 'clsx';


function PhoneChat(props) {
  const { t } = useTranslation('mainPage');
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };



  const dispatch = useDispatch();
  const contacts = useSelector(selectContacts);
  const selectedContactId = useSelector(selectSelectedContactId);
  const state = useSelector(selectChatPanelState);
  const theme = useTheme();

  const [phoneMenuShow, setPhoneMeunShow] = useState(false);

  const ref = useRef();
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      return state && theme.direction === 'rtl' && dispatch(closeChatPanel());
    },
    onSwipedRight: () => {
      return state && theme.direction === 'ltr' && dispatch(closeChatPanel());
    },
  });

  const selectedContact = contacts.find((_contact) => _contact.id === selectedContactId);

  const handleDocumentKeyDown = useCallback(
    (event) => {
      if (keycode(event) === 'esc') {
        dispatch(closeChatPanel());
      }
    },
    [dispatch]
  );


  useEffect(() => {
    dispatch(getUserData());
    dispatch(getContacts());
    dispatch(getChats());
    // document.getElementsByClassName('hiddenIt')[0].style.display = 'none'
    // document.getElementsByClassName('hiddenIt')[1].style.display = 'none'
    // document.getElementsByClassName('betDiColor')[0].classList.remove("phoneContMT")
    return () => {
      document.removeEventListener('keydown', handleDocumentKeyDown);
    };
  }, [dispatch, handleDocumentKeyDown]);

  useEffect(() => {
    if (state) {
      document.addEventListener('keydown', handleDocumentKeyDown);
    } else {
      document.removeEventListener('keydown', handleDocumentKeyDown);
    }
  }, [handleDocumentKeyDown, state]);

  /**
   * Click Away Listener
   */
  useEffect(() => {
    function handleDocumentClick(ev) {
      if (ref.current && !ref.current.contains(ev.target)) {
        dispatch(closeChatPanel());
      }
    }

    if (state) {
      document.addEventListener('click', handleDocumentClick, true);
    } else {
      document.removeEventListener('click', handleDocumentClick, true);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick, true);
    };
  }, [state, dispatch]);


  return (
    <div className={clsx("betDiColor phoneMove", phoneMenuShow && 'phoneMove2')} style={{ width: "100%", height: document.documentElement.clientHeight + "px" }} >
      <div className="panel flex flex-col max-w-full " style={{ height: "100%" }} ref={ref}>
        <AppBar position="static" className="shadow-md">
          <Toolbar className="px-4">
            {(!state || !selectedContactId) && (
              <div className="flex flex-1 items-center  ">
                <IconButton
                  className=""
                  color="inherit" 
                  onClick={() => {
                    // document.getElementsByClassName('hiddenIt')[0].style.display = 'none'
                    // document.getElementsByClassName('hiddenIt')[1].style.display = 'none'
                  }}
                  size="large"
                >
                  <FuseSvgIcon size={24}>heroicons-outline:chat-alt-2</FuseSvgIcon>
                </IconButton>
                {!selectedContactId && (
                  <Typography className="text-16 ml-20" color="inherit">
                    {t("home_ChatRoom")}
                  </Typography>
                )}
              </div>
            )}
            {state && selectedContact && (
              <div className="flex flex-1 items-center px-12">
                <Avatar src={selectedContact.avatar} />
                {selectedContactId === "beec5287-ed50-4504-858a-5dc3f8ce6935" ? (
                  <div className='promotion-list1 ml-12 items-center w-200 myBetBtnColor height-40 borderRadius btnPointer txtBrightness txtFootColor'>
                    <img className='ml-8' src='assets/images/icon/307.png' />
                    <Typography className=" text-16" color="inherit">
                      {selectedContact.name}
                    </Typography>
                    <div className='yuanTips circle txtBlackColor btnGreenColor text-align mr-4 font-weight500 '>3</div>
                    <img className='mr-8' src='assets/images/index/jianTou.png'></img>
                  </div>
                ) : (
                  <div className='promotion-list1 ml-12 items-center '>
                    <Typography className="mx-16 text-16" color="inherit">
                      {selectedContact.name}
                    </Typography>
                  </div>
                )}
              </div>
            )}
            <div className="flex px-4">
              <IconButton onClick={(ev) => {
                setTimeout(() => {
                  history.push('/home')
                  window.localStorage.setItem('phoneTab', 'home');
                  // document.getElementsByClassName('hiddenIt')[0].style.display = 'block'
                  // document.getElementsByClassName('hiddenIt')[1].style.display = 'block'
                  // document.getElementsByClassName('betDiColor')[0].classList.add("phoneContMT")
                }, 400)

              }} color="inherit" size="large">
                <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Paper className="flex flex-1 flex-row min-h-px shadow-0 ">
          <ContactList className="flex shrink-0" />
          {state && selectedContact ? (
            <Chat className="flex flex-1 z-10" />
          ) : (
            <div className="flex flex-col flex-1 items-center justify-center p-24">
              <FuseSvgIcon size={128} color="disabled">
                heroicons-outline:chat
              </FuseSvgIcon>
              <Typography className="px-16 pb-24 mt-24 text-center " color="text.secondary">
                {t("home_Selectacontact")}
              </Typography>
            </div>
          )}
        </Paper>
      </div>

    </div>
  );
}

export default PhoneChat;


