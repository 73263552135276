import React, { useEffect, useState } from "react";
import "./loading.css"
import images from "./images";

const Loading = (props) => {
  const [loadWidth, setLoadWidth] = useState(0.5);

  // 实现加载图片
  function loadImage(url) {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.onload = function () {
        resolve(image);
      };

      image.onerror = function () {
        reject()
        // reject(new Error(`Failed to load image from URL: ${url}`));
      };
      image.src = url;
    });
  }

  // 异步加载所有需要加载的图片
  function preloadImages(images) {
    const promises = [];
    window.localStorage.setItem('renderApp', 0)
    for (let i = 0; i < images.length; i++) {
      loadImage(images[i]).then(() => {
        promises.push(i)
        setTimeout(() => {
          let tmpWidth = Math.ceil(promises.length * 0.425)
          setLoadWidth(tmpWidth)
        }, 100)
      })
    }
  }

  useEffect(() => {
    // 图片加载完成后先做一个假的动画（加载钱包）
    const timerIframe = setInterval(() => {
      if (loadWidth >= 70) {
        setTimeout(() => {
          let loadWithAdd = Math.floor(Math.random()*3 + 1)
          if ((loadWidth + loadWithAdd) > 99) {
            clearInterval(timerIframe)
          } else {
            setLoadWidth(loadWidth + loadWithAdd)
          }
        }, 100)
      }
    }, 800)

    // 图片加载完成后加载钱包
    const timer = setInterval(() => {
      if (loadWidth >= 70) {
        clearInterval(timer)
        const loadFunibox = () => {
          if (!document.getElementById('funiboxLoad')) {
            // const url = "//funibox.com"
            const url = "//beingfi.com"
            var i = document.createElement("iframe");
            i.src = url;
            i.frameborder = "0";
            i.width = "10px";
            i.height = "10px";
            i.id = 'funiboxLoad'
            i.style.display = 'none'
            document.getElementsByTagName('body')[0].appendChild(i);

            i.onload = function () {
              clearInterval(timerIframe)
              setLoadWidth(100)
              setTimeout(() => {
                window.localStorage.setItem('renderApp', 1)
                document.getElementsByTagName('body')[0].removeChild(document.getElementById('funiboxLoad'))
              }, 300)

            };
          }
        }
        // window.localStorage.setItem('renderApp', 1)
        loadFunibox();
      }
    }, 300)

    return () => {
      if (timer) {
        clearInterval(timer)
      }

      if (timerIframe) {
        clearInterval(timerIframe)
      }
    }
  });

  useEffect(() => {
    // var vConsole = new VConsole();
    preloadImages(images);
    // window.addEventListener("load", function () {

    // });
  }, [])

  return (
    <div id='fuse-splash-screen' style={{ zIndex: 1 }}>
      <div className='loading' style={{
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
        height: "7px",
        background: "#16c2a3",
        position: "absolute",
        top: "0",
        left: "0",
        transition: "all 0.3s",
        width: `${loadWidth}%`
      }}>
      </div>
      <div className='logo'>
        <img style={{
          width: "120px",
          maxWidth: "120px"
        }} width='128' src={`${React.$imgUrl}/images/logo/logo-prefix.png`} alt='logo' />
      </div>
      <div className="titleTxt2 text-20 mt-4">
        {loadWidth}%
      </div>
    </div>
  );


}

export default Loading;
