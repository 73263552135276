  const locale = {
  GAME: 'Trò chơi',
  Casino: "Sòng bạc",
  ProvablyFair: "Provably Fair",
  Option: 'Tùy chọn',
  Crypto: 'Tiền điện tử',
  Forex: 'Ngoại hối',
  Stocks: 'Cổ phiếu',
  NFTFuniCard: 'NFT FuniCard',
  StakeNFT: 'Cược NFT với phần thưởng USDT',
  NFTStake: 'NFT Cược',
  Marketplace: 'Chợ trực tuyến',
  NFTMint: 'NFT Đúc',
  BettoEarn: 'Cá cược để kiếm tiền',
  EarnUSGT: 'Kiếm tiền USDT',
  DeFi: 'DeFi',
  ToBeVolunteer: 'Tình nguyện viên',
  SYSTEM: 'HỆ THỐNG',
  Affiliate: 'Liên kết',
  News: 'Tin tức',
  FAQs: 'Câu hỏi thường gặp',
  DOWNLOAD: 'TẢI XUỐNG',
  DOWNLOADApp: 'Tải xuống App',
  Savetodesktop: 'Lưu vào máy tính để bàn',
  BetMining: 'Đào cược',
  ToBeBanker: 'Trở thành ngân hàng',
  ProfitPool: 'Hồ bơi lợi nhuận',
  USGTLiquidityMining: 'Đào tiền USDT',
  BGTLiquidityMining: 'Đào tiền BGT',
  Slots: 'Máy đánh bạc',
  Favorite: 'Yêu thích',
  Recent: 'Mới đây',
  Popular: "Phổ biến",
  LiveCasino: "Live Casino",
  TableGames: "Trò chơi bàn",
  BlackJack: "BlackJack",
  Baccarat: "Baccarat",
  Roulette: "Roulette",
  MoreGames: "Nhiều trò chơi hơn",
  Provablyfair: "Provably fair",
  Special: "Đặc biệt",
  Recommend: "Đề xuất",
  FeatureBuyin: "Mua vào tính năng",
  EnableTrialPlay: "Enable Trial Play",
  GameShow: "game show",
  RGSVSB: "RGS - VSB",
  Scratch: "Gãy",
  Bingo: "Bingo",
  Fishing: "Đánh cá",
  Turntable: "Quay số",
  Dice: "Xúc xắc",
  Poker: "Pôker",
  AllGames: "Tất cả các trò chơi",
  EuropeanView: "Xem châu Âu",
  AfricanView: "Xem châu Phi",
  LatinView: "Chế độ Latinh",
  AsianView: "Á châu",
  LsportView: "Liên đoàn thể thao",
  StandardView: "Tiêu chuẩn",
  EsportView: "Thể thao điện tử",
  LiveBetting:' Cá cược trực tiếp',
  Provider:'Các nhà cung cấp',
  Sport:'Thể thao',


};

export default locale;
