const imgUrl = `https://scource-static.funibet.com/funibet`
export default [
  `${imgUrl}/images/index/casino.png`,
  `${imgUrl}/images/index/sport.png`,
  `${imgUrl}/images/index/hash.png`,
  `${imgUrl}/images/index/option.png`,
  `${imgUrl}/images/index/bigDiZheZhao.png`,
  `${imgUrl}/images/index/foot18_01.png`,
  `${imgUrl}/images/index/foot18_02.png`,
  `${imgUrl}/images/index/foot18_03.png`,
  `${imgUrl}/images/index/footIcon1.png`,
  `${imgUrl}/images/index/footIcon2.png`,
  `${imgUrl}/images/index/footIcon3.png`,
  `${imgUrl}/images/index/footIcon4.png`,
  `${imgUrl}/images/index/footIcon5.png`,
  `${imgUrl}/images/index/footIcon6.png`,
  `${imgUrl}/images/index/footLogo.png`,
  `${imgUrl}/images/index/tu3_1.png`,
  `${imgUrl}/images/index/tu3_2.png`,
  `${imgUrl}/images/index/tu3_3.png`,
  `${imgUrl}/images/index/tu3_4.png`,
  `${imgUrl}/images/index/tu3_5.png`,
  `${imgUrl}/images/index/tu3_6.png`,
  `${imgUrl}/images/index/tu3_7.png`,
  `${imgUrl}/images/index/tu_1.png`,
  `${imgUrl}/images/index/tu_2.png`,
  `${imgUrl}/images/index/tu_3.png`,
  `${imgUrl}/images/index/tu_4.png`,
  `${imgUrl}/images/index/tu_5.png`,
  `${imgUrl}/images/index/tu_6.png`,
  `${imgUrl}/images/index/zuQiu.png`,
  `${imgUrl}/images/index/zuQiu1.png`,
  `${imgUrl}/images/index/zuQiu2.png`,
  `${imgUrl}/images/index/zuQiu3.png`,
  `${imgUrl}/images/index/zuQiu4.png`,
  `${imgUrl}/images/index/zuQiu_1.png`,
  `${imgUrl}/images/index/zuQiu2_1.png`,
  `${imgUrl}/images/index/zuQiu3_1.png`,
  `${imgUrl}/images/index/zuQiu4_1.png`,
  `${imgUrl}/images/index/zuQiu1_1.png`,
  `${imgUrl}/images/index/vipDi.png`,
  `${imgUrl}/images/index/vipDi2.png`,
  `${imgUrl}/images/index/vipDi4.png`,
  `${imgUrl}/images/index/vip1.png`,
  `${imgUrl}/images/index/vip2.png`,
  `${imgUrl}/images/index/vip3.png`,
  `${imgUrl}/images/index/vip4.png`,
  `${imgUrl}/images/index/vip5.png`,
  `${imgUrl}/images/index/vip6.png`,
  `${imgUrl}/images/index/vip7.png`,
  `${imgUrl}/images/logo/logo.png`,
  `${imgUrl}/images/logo/logo-prefix.png`,
  `${imgUrl}/images/icon/1.png`,
  `${imgUrl}/images/icon/2.png`,
  `${imgUrl}/images/icon/3.png`,
  `${imgUrl}/images/icon/4.png`,
  `${imgUrl}/images/nav/1.png`,
  `${imgUrl}/images/nav/2.png`,
  `${imgUrl}/images/nav/2_1.png`,
  `${imgUrl}/images/nav/3.png`,
  `${imgUrl}/images/nav/4.png`,
  `${imgUrl}/images/nav/5.png`,
  `${imgUrl}/images/nav/6.png`,
  `${imgUrl}/images/nav/vip.png`,
  `${imgUrl}/images/nav/vip_bg.png`,
  `${imgUrl}/images/avatars/female-02.jpg`,
  `${imgUrl}/images/avatars/female-03.jpg`,
  `${imgUrl}/images/avatars/female-04.jpg`,
  `${imgUrl}/images/avatars/female-06.jpg`,
  `${imgUrl}/images/avatars/female-07.jpg`,
  `${imgUrl}/images/avatars/female-08.jpg`,
  `${imgUrl}/images/avatars/female-09.jpg`,
  `${imgUrl}/images/avatars/female-10.jpg`,
  `${imgUrl}/images/avatars/female-11.jpg`,
  `${imgUrl}/images/avatars/female-12.jpg`,
  `${imgUrl}/images/avatars/female-13.jpg`,
  `${imgUrl}/images/avatars/female-14.jpg`,
  `${imgUrl}/images/avatars/male-01.jpg`,
  `${imgUrl}/images/avatars/male-02.jpg`,
  `${imgUrl}/images/avatars/male-04.jpg`,
  `${imgUrl}/images/avatars/male-05.jpg`,
  `${imgUrl}/images/avatars/male-07.jpg`,
  `${imgUrl}/images/avatars/male-17.jpg`,
  `${imgUrl}/images/avatars/male-18.jpg`,
  `${imgUrl}/images/avatars/male-19.jpg`,
  `${imgUrl}/images/avatars/male-20.jpg`,
  `${imgUrl}/images/home/chat-selected.png`,
  `${imgUrl}/images/home/chat.png`,
  `${imgUrl}/images/home/games-selected.png`,
  `${imgUrl}/images/home/games.png`,
  `${imgUrl}/images/home/home.png`,
  `${imgUrl}/images/home/home-selected.png`,
  `${imgUrl}/images/home/sports.png`,
  `${imgUrl}/images/home/sports-selected.png`,
  `${imgUrl}/images/home/wallet.png`,
  `${imgUrl}/images/home/wallet-selected.png`,
  `${imgUrl}/images/banner/1.png`,
  `${imgUrl}/images/banner/2.png`,
  `${imgUrl}/images/banner/3.png`,
  `${imgUrl}/images/banner/4.png`,
  `${imgUrl}/images/index/checkInDi.png`,
  `${imgUrl}/images/index/checkOver.png`,
  `${imgUrl}/images/index/checkOver_1.png`,
  `${imgUrl}/images/index/checkOver1.png`,
  `${imgUrl}/images/index/checkOver1_1.png`,
  `${imgUrl}/images/index/jinBi1.png`,
  `${imgUrl}/images/index/jinBi2.png`,
  `${imgUrl}/images/index/jinBi3.png`,
  `${imgUrl}/images/index/jinBi4.png`,
  `${imgUrl}/images/index/pcQianDao.png`,
  `${imgUrl}/images/index/phoneQianDao.png`,
  `${imgUrl}/images/index/phoneQianDao1.png`,
  `${imgUrl}/images/nav/smallZhuanPan1.png`,
  `${imgUrl}/images/nav/smallZhuanPan2.png`,
  `${imgUrl}/images/nav/spinZi1.png`,
  `${imgUrl}/images/nav/spinZi2.png`,
  `${imgUrl}/images/nav/spinZi3.png`,
  `${imgUrl}/images/nav/zhiZhen.png`,
  `${imgUrl}/images/nav/diPan0.png`,
  `${imgUrl}/images/nav/diPan0_1.png`,
  `${imgUrl}/images/nav/diPan1.png`,
  `${imgUrl}/images/nav/diPan2.png`,
  `${imgUrl}/images/nav/diPan2_1.png`,
  `${imgUrl}/images/nav/diPan2_2.png`,
  `${imgUrl}/images/nav/diPan3.jpg`,
  `${imgUrl}/images/nav/diPan5.png`,
  `${imgUrl}/images/nav/diPan5_1.png`,
  `${imgUrl}/images/nav/diPan5_2.png`,
  `${imgUrl}/images/nav/diPan7.png`,
  `${imgUrl}/images/nav/san1.png`,
  `${imgUrl}/images/nav/san2.png`,
  `${imgUrl}/images/nav/san3.png`,
  `${imgUrl}/images/nav/san4.png`,
  `${imgUrl}/images/nav/jjjDi.png`,
  `${imgUrl}/images/nav/jjjDiTitle.png`,
  `${imgUrl}/images/nav/jjjxc.png`,
  `${imgUrl}/images/nav/faGuan.png`,
  `${imgUrl}/images/index/shareDi.png`,
  `${imgUrl}/images/index/tw.png`,
  `${imgUrl}/images/index/fb.png`,
  `${imgUrl}/images/index/li.png`,
  `${imgUrl}/images/index/q1.png`,
  `${imgUrl}/images/nav/depMoney.png`,
  `${imgUrl}/images/nav/czlb1.png`,
  `${imgUrl}/images/nav/czlb3.png`,
  `${imgUrl}/images/nav/depxc.png`,
  `${imgUrl}/images/nav/depxc2.png`,
  `${imgUrl}/images/nav/sclbDi1.png`,
  `${imgUrl}/images/nav/sclbDi2.png`,
  `${imgUrl}/images/nav/sclbDi3.png`,
  `${imgUrl}/images/nav/sclbDiTitle.png`,
  `${imgUrl}/images/nav/sclbDiTitle2.png`,
  `${imgUrl}/images/nav/sclbDiTitle3.png`,
  `${imgUrl}/images/index/bigXcDi.png`,
  `${imgUrl}/images/index/vipLvDi.png`,
  `${imgUrl}/images/affiliate/byt.png`,
  `${imgUrl}/images/affiliate/di1.png`,
  `${imgUrl}/images/affiliate/labaDi.png`,
  `${imgUrl}/images/affiliate/usd.png`,
  `${imgUrl}/images/affiliate/xcDi.png`,
  `${imgUrl}/images/affiliate/xcDi2.png`,
  `${imgUrl}/images/nav/phoneXc.png`,
  `${imgUrl}/images/index/pay_1.png`,
  `${imgUrl}/images/index/payDi.png`,
  `${imgUrl}/images/index/payDi_1.png`,
  `${imgUrl}/images/index/feiDie.png`,
  `${imgUrl}/images/index/404.png`,
  `${imgUrl}/images/index/pay.png`,


































]
