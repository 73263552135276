import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { motion } from 'framer-motion';
import {memo, useEffect, useMemo, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChat } from './store/chatSlice';
import { selectContacts, selectSelectedContactId } from './store/contactsSlice';
import { openChatPanel } from './store/stateSlice';
import ContactButton from './ContactButton';
import { selectChats } from './store/chatsSlice';
import {selectUserData} from "app/store/user";
import { onLogin } from "app/store/user/userThunk";

const Root = styled(FuseScrollbars)(({ theme }) => ({
  background: theme.palette.background.paper,
}));

function ContactList(props) {
  const dispatch = useDispatch();
  const contacts = useSelector(selectContacts);
  const selectedContactId = useSelector(selectSelectedContactId);
  const chats = useSelector(selectChats);
  const contactListScroll = useRef(null);
  const [ userToken, setUserToken ] = useState('');
  const userData = useSelector(selectUserData);

  useEffect(() => {
    setUserToken(userData.token);
  }, [userData.token]);

  const scrollToTop = () => {
    contactListScroll.current.scrollTop = 0;
  };

  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, scale: 0.6 },
    show: { opacity: 1, scale: 1 },
  };

  const chatListContacts =
      contacts.length > 0 && chats.length > 0
          ? chats.map((_chat) => ({
            ..._chat,
            ...contacts.find((_contact) => _contact.id === _chat.contactId),
          }))
          : [];

  const handleContactClick = (contactId) => {
    console.log(contactId);
    // if (contactId == "beec5287-ed50-4504-858a-5dc3f8ce6935") {
    // } else {
    // }
    // console.log(contactId,6666);
    console.log('userToken', userToken)
    // 如果没登陆，则先跳转登陆
    // if (userToken === '') {
      // dispatch(onLogin({
      //   type: 'login'
      // }))
    //   return
    // }
    dispatch(openChatPanel());
    dispatch(getChat(contactId));
    scrollToTop();
  };

  return (
    <Root
      className="flex shrink-0 flex-col overflow-y-auto  pt-4 overscroll-contain  "
      ref={contactListScroll}
      option={{ suppressScrollX: true, wheelPropagation: false }}
    >
      {useMemo(() => {}, [chats, contacts, dispatch, selectedContactId])}

      {contacts.length > 0 && (
          <>
            <motion.div
                variants={container}
                initial="hidden"
                animate="show"
                className="flex flex-col shrink-0 phoneChatPl "
            >
              {/* {chatListContacts &&
                  chatListContacts.map((contact) => {
                    return (
                      <motion.div variants={item} key={contact.id}>
                        <ContactButton
                          contact={contact}
                          selectedContactId={selectedContactId}
                          onClick={handleContactClick}
                        />
                      </motion.div>
                    );
                  })} */}

              {contacts.map((contact) => {
                const chatContact = chats.find((_chat) => _chat.contactId === contact.id);
                return !chatContact ? (
                    <motion.div variants={item} key={contact.id} className="txtBrightness">
                      <ContactButton
                          contact={contact}
                          selectedContactId={selectedContactId}
                          onClick={handleContactClick}
                      />
                    </motion.div>
                ) : null;
              })}

            </motion.div>
          </>
      )}
    </Root>
  );
}

export default memo(ContactList);
