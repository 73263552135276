import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    userInfo: {},
};

const userInfoSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser: (state, action) => {
            let res = action.payload;
            state.userInfo = res;
            state.isUserInfoLoading = false;
        },
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateUser } = userInfoSlice.actions;

export const selectUserInfo = ({ userInfo }) => userInfo;

export default userInfoSlice.reducer;
