const locale = {
    home_StakeNFT: 'Stake NFT verdienen Sie kostenlos ',
    home_everyday: 'jeden Tag',
    home_Beton: 'Wette auf ',
    home_mining: 'Mining',
    home_shareholder: ' Um Aktionär zu werden',
    home_Stake: 'Stake',
    home_dividend: ' Erhalten Sie Gewinnausschüttungen',
    home_Banker: ' Ein Partner werden',
    home_SIGNUPAND: 'SIGN UP & ',
    home_GET: 'GET',
    home_UPTO: 'UP TO ',
    home_FuniHash: 'Blockchain-Spiel',
    home_Option: 'Option',
    
    home_Reward: ' Reward',
    home_Reward1: 'Reward',
    home_signUpNow: 'Jetzt anmelden',
    home_Casino: 'Kasino',
    home_Sports: 'Sport',
    home_PROMOTION: 'PROMOTION',
    home_Checkin: 'Einchecken',
    home_Bouns: 'Bonus',
    home_CheckinBouns: 'Eincheck-Bonus',
    home_SPIN: 'DREHEN',
    home_Relief: 'Sozialhilfe',
    home_Share: 'Teilen',
    home_Deposit: 'Einzahlung',
    home_Fund: 'Fonds',
    home_VIPClub: 'VIP-Club',
    home_FastAndEasy: 'Schneller und einfacher Weg, um Kryptowährung bis zu USDT-Einzahlungsbonus zu kaufen',
    home_Game: 'Spiel',
    home_User: 'Benutzer',
    home_Time: 'Zeit',
    home_BetAmount: 'Wetteinsatz',
    home_Multiplier: 'Multiplikator',
    home_Payout: 'Auszahlung',
    home_JoinOur: 'Tritt unserer Community von Sportbegeisterten, Kryptobegeisterten und Spielern bei',
    home_JOIN: 'BEITRETEN',
    home_LICENSES: 'LIZENZEN',
    home_OurPlatforms: 'Unsere Plattformen',
    home_Marketplac: 'Marktplatz',
    home_Howtoearn: 'Wie man verdient',
    home_Games: 'Spiele',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'Investor Deck',
    home_News: 'Nachrichten',
    home_Aboutsus: 'Über uns',
    home_Industry: 'Branche',
    home_Roadmap: 'Roadmap',
    home_Partner: 'Partner',
    home_FAQs: 'FAQs',
    home_Affiliate: 'Affiliate',
    home_Stayintouch: 'In Kontakt bleiben',
    home_ChatRoom: 'Chatraum',
    home_Selectacontact: 'Wählen Sie einen Kontakt aus, um eine Konversation zu starten.',
    home_BetSlip: 'Wettschein',
    home_Typeyourmessage: 'Geben Sie Ihre Nachricht ein',
    home_AcceptAnyOdds: 'Akzeptieren Sie alle Quoten',
    home_ClearAll: 'Alle löschen',
    home_Live: 'Live',
    home_Winner: 'Gewinner',
    home_EstPayout: 'Geschätzte Auszahlung',
    home_TotalOdds: 'Gesamtquoten',
    home_RegistertoBet: 'Registrieren Sie sich, um zu wetten',
    home_Single: 'Einzelwette',
    home_Multi: 'Mehrere',
    home_Checkineveryweek: 'Melden Sie sich jede Woche kumulativ an und erhalten Sie entsprechende Belohnungen.',
    home_Day: 'Tag',
    home_Sunday: 'Tag 1',
    home_Monday: 'Tag 2',
    home_Tuesday: 'Tag 3',
    home_Wednesday: 'Tag 4',
    home_Thursday: 'Tag 5',
    home_Friday: 'Tag 6',
    home_Saturday: 'Tag 7',
    home_FreeSpin: 'Freispiel',
    home_Turntheturntable: 'Drehen Sie das Glücksrad jeden Tag, um USGT Belohnungen zu erhalten.',
    home_DepositFift: 'Einzahlungsg',
    home_ANYAMOUNT: 'JEDE HÖHE DER ERSTEN EINZAHLUNG KANN ERHALTEN WERDEN',
    home_DEPOSIT: 'EINZAHLUNG',
    home_First: 'Erste',
    home_1ST: '1.',
    home_2ND: '2.',
    home_3TH: '3.',
    home_ANYAMOUNT: 'JEDER BETRAG!',
    home_EXTRA: 'EXTRA',
    home_ONLY: 'NUR',
    home_PLATFORMSHARING: 'PLATTFORMAUSTAUSCH',
    home_Getrelief: 'Erhalten Sie eine Chance, aus der Notlage herauszukommen.',
    home_YOUCANCLAIM: 'Wenn das Guthaben weniger als ',
    home_YOUCANReceive: ' beträgt, können Sie Erleichterung erhalten und das Guthaben auf ',
    home_threeTimes: ' 3 mal erhöhen.',
    home_Times: 'Mal:',
    home_everydayTimes: '/Tag!',
    home_TotalAssets: 'Gesamtvermögen',
    home_receive: 'empfangen',
    home_TODAY: 'HEUTE',
    home_Share: 'Teilen',
    home_Sharewith: 'Share FuniBet every day and get ',
    home_SHARINGGIFT: 'TEILUNGSGESCHENKPaket',
    home_CODE: 'CODE',
    home_Selectplatform: 'Wählen Sie die Plattform aus, um zu teilen:',
    home_Fund: 'Fonds',
    home_FundBonus: 'Fond-Bonus',
    home_Improveyour: 'Improve your VIP level and get more fund rewards every day.',
    home_BuyFund: 'kaufen',
    home_Receivingconditions: 'Bedingungen',
    home_Receivingstatus: 'Status',
    home_Received: 'Empfangen',
    home_noReceived: 'unmet',
    home_ExclusiveBenefits: 'Exklusive Vorteile',
    home_Asawayof: 'Als Ausdruck unserer Dankbarkeit und Wertschätzung für unsere VIP-Spieler lädt FuniBet Sie ein, unserem VIP-Club beizutreten, wo es eine Fülle von Geschenken, Giveaways, höherem Cashback und einzigartigen Funktionen gibt. Verpassen Sie nicht all den aufregenden und erstaunlichen Spaß!',
    home_Yourcurrent: 'Ihr aktueller Gesamteinsatz',
    home_WhyUpgrade: 'Warum zum VIP aufsteigen?',
    home_VIPhave: 'VIPs erhalten einen exklusiven 24/7-Service durch einen persönlichen Gastgeber. Zusätzlich zu den Grundfunktionen profitieren sie von höherem Rakeback, Boni, schnelleren Zahlungen und können an Top-Spieler-Konferenzen teilnehmen, um neue Funktionen für das FuniBet-Team vorzuschlagen.',
    home_UpgradeYour: 'Aktualisieren Sie Ihr VIP-Level',
    home_ASaFunibet: 'Als FUNIBet VIP-Mitglied erhalten Sie exklusive Belohnungen und personalisierte Geschenke. Durch regelmäßiges Spielen können Sie in den VIP-Rängen aufsteigen und als Elite-VIP-Mitglied unbegrenzte maßgeschneiderte Vorteile genießen.',
    home_YOUCANREACH: 'SIE KÖNNEN ERREICHEN',
    home_LEVELS: 'LEVEL',
    home_eachthehigh: 'Erreichen Sie den hohen Top-Rang, um einen 25% Cashback-Bonus zu erhalten und alle Vorteile der Plattform zu eröffnen. Bleiben Sie bei FUNIBET, um das beste Spielerlebnis und exzellenten Service zu erhalten!',
    home_Cashback: 'Cashback',
    home_Nowater: '1. Kein Wasser-Pumpen im Spiel',
    home_Moregrowth: '2. Mehr Wachstumsfonds',
    home_Increasein: '3. Zunahme der Anzahl von Vorteilen',
    home_Doublethenumber: '4. Verdopplung der Anzahl von Drehungen',
    home_Doublethesign: '5. Verdopplung der Anmeldebelohnung',
    home_Doublesharingrewards: '6. Verdopplung von gemeinsamen Belohnungen',
    home_DoubletheAffiliate: '7. Verdopplung der Affiliate-Belohnung',
    home_Rechargerewardamount: '8. Aufladebelohnungsbetrag X 1,5',
    home_ABOUTVIPCLUB: 'ABOUT VIP CLUB',
    home_HightopRank: 'High top Rank has 25% cashback bonus and the best benefits. Stay with BeDao to receive the best gaming experience and high level of service!',
    home_Beingamember: 'Being a member of the VIP club means that you have access to:',
    home_PersonalVIPmanager: 'Personal VIP manager',
    home_Highercahbackrewards: 'Higher cahback rewards',
    home_Otheradditionalperks: 'Other additional perks',
    home_Insights: 'Einblicke',
    home_WeeklyandMonthlyBonuses: 'Wöchentliche und monatliche Boni',
    home_Showhighgaming: 'Zeigen Sie eine hohe Spielaktivität und erhalten Sie Ihre Einladung zu unserem VIP-Club.',
    home_BindMobile: 'Binden Handy',
    home_Pleasecheckthe: 'Bitte prüfen Sie die USGT, die Sie im Spiel erhalten haben, in Ihrer Brieftasche.',
    home_BINDMOBILENUMBER: 'Mobilnummer binden',
    home_Get1USDT: 'Erhalte 1USDT',
    home_Bind: 'Binden',
    home_GiftCode: 'Gift Code',
    home_EnteryourGiftCode: 'Enter your Gift Code',
    home_EnterCode: 'Code eingeben',
    home_Paste: 'Einfügen',
    home_confirms: 'bestätigt',
    home_BuyCrypto: 'Kaufen',
    home_SignedIn: 'Angemeldet',
    home_Wallet: 'Wallet',
    home_turnTimes: 'Umdrehungen: ',
    home_bonus: ' Bonus',
    home_FirstDepositBonus: 'Ersteinzahlungsbonus',
    home_Hi: 'Hallo ',
    home_welcomeAboard: ', herzlich willkommen an Bord,d',
    
    
    
    home_CommissionRules: 'Provisions de commission',
    home_AffiliateTerms: "Conditions d'affiliation",
    home_AffiliateDashboard: "Tableau de bord d'affiliation",
    home_AFFILIATE: 'AFFILIÉ',
    home_COMMISSONREADYEVERYDAY: 'COMMISSION PRÊTE CHAQUE JOUR',
    home_MYREFERRALCODE: 'MON CODE DE RÉFÉRENCE',
    home_MYREFERRALLink: 'MON LIEN DE RÉFÉRENCE',
    home_AffiliateReward: "Récompense d'affiliation",
    home_EXTRA: 'EXTRA',
    home_USDREWARDS: 'RÉCOMPENSES USD',
    home_USDRewardsRules: 'Règles des récompenses USD',
    home_JUSAREFERRALAWAY: 'À UN PARRAINAGE PRÈS',
    home_NewlyAvailableRewards: 'Nouvelles récompenses disponibles',
    home_ReceivedMao: 'Reçu :',
    home_Locked: 'Verrouillé :',
    home_Yourcurrentfriends: 'Vos amis actuels qui restent actifs vous débloqueront plus de récompenses',
    home_Withdraw: 'Retrait',
    home_MY: 'MON',
    home_COMMISSIONREWARDS: 'RÉCOMPENSES DE COMMISSION',
    home_CommissionRewardRules: 'Règles de récompense de commission',
    home_NewlyAvailableCommissionRewards: 'Nouvelles récompenses de commission disponibles',
    home_CommissionYouhaveReceivedinTotal: 'Commission totale reçue',
    home_REWARDSSENTTODATE: 'Belohnungen bis heute gesendet',
    home_USDrewardssenttodate: 'USD-Belohnungen bis heute gesendet',
    home_Commissionsenttodatet: 'Provision bis heute gesendet',
    home_Areyouablogger: 'Spezielles Angebot für Blogger! Höhere Empfehlungsprämien in unserem Partnerprogramm. Kontaktieren Sie uns für besondere Bedingungen: affiliate@beDao.io.',
    home_onlythoseusers: 'Wichtig: Nur Benutzer, die die Anforderungen des Managers erfüllt haben und nach Genehmigung durch den Manager können am Programm teilnehmen.',
    home_notice: 'Hinweis',
    home_bindPhone: 'Telefon binden',
    home_SignIn: 'Einloggen',
    home_SignUp: 'Registrieren',
    home_SignOut: 'Ausloggen',
    home_CookiesPolicy: 'Cookies-Richtlinien',
    home_isUsingCookies: 'Funibet.com verwendet Cookies für besseren Service. Durch Nutzung stimmen Sie zu. Bitte beachten Sie unsere Datenschutzrichtlinie und Nutzungsbedingungen..',
    home_SETTING: 'EINSTELLUNG',
    home_LiveSupport: 'Live-Unterstützung',
    home_Accept: 'Akzeptieren',
    home_FrequentlyAskedQuestions: 'Häufig gestellte Fragen',
    home_SiteUnnderConstruction: 'Seite im Aufbau',
    home_ComingSoon: 'Kommt bald',
    home_UserId: 'ID: ',
    home_UserName: 'Name: ',
    home_Bonusincreased: "Bonus erhöht +",
    home_SPINBONUSTOTAL: 'GESAMTSPINBONUS',
    home_WIN: 'WIN:',
    home_In: 'in ',
    home_Outoftime: 'Tageslimit erreicht',
    
    game_search: 'Suche',
    game_SortBy: 'Sortieren nach',
    game_Created: 'Erstellt',
    game_Lobby: 'Lobby',
    game_LiveCasino: 'Live Casino',
    game_FeaturedSlots: 'Vorgestellte Slots',
    game_GameShows: 'Game-Shows',
    game_FuniBetOriginals: 'FuniBet Originale',
    game_BigWins: 'Große Gewinne',
    game_LcukyWins: 'Glückliche Gewinne',
    game_Challenges: 'Herausforderungen',
    game_Description: 'Beschreibung',
    game_Slots: 'Slots',
    game_Rank: 'Rang',
    game_User: 'Benutzer',
    game_Date: 'Datum',
    game_BetAmount: 'Einsatzbetrag',
    game_lucky: 'Max. Multiplikator',
    game_Multiplier: 'Multiplikator',
    game_Payout: 'Auszahlung',
    game_Recommend: 'Empfehlen',
    game_Providers: 'Anbieter',
    game_startGame: 'starten',
    game_balance: 'Guthaben',
    game_provider: 'Anbieter',
    game_Selectyourdisplay: 'Wähle dein Anzeigeguthaben',
    game_Balancein: 'Guthaben in',
    game_FunPlay: 'Fun-Spiel',
    game_RealPlay: 'Echtgeld-Spiel',
    game_DispLaying: "Anzeige",
    game_LoadMore: "Mehr laden",
    game_NoData: 'Hoppla! Derzeit keine Daten vorhanden.',
    
    message_SigningIn: "Anmeldung mit JWT",
    message_CourseSaved: "Kurs gespeichert",
    message_PleaseBind: "Bitte binden Sie zuerst Ihr Telefon",
    message_UserData: "Benutzerdaten mit API gespeichert",
    message_Success: "Erfolg",
    message_VIPlevel: "VIP-Level ist niedriger als V3",
    message_ListOrder: "Listenauftrag gespeichert",
    message_CardOrder: "Kartenauftrag gespeichert",
    message_HiHow: "Hallo, wie geht es Ihnen?",

    Funibet_web: 'Funibet-Web3.0,Kryptowährung,Wette,Sport,E-Sport,Spielautomaten,Live-Casino,Poker',

    home_ThereAre: "Es liegen derzeit keine Benachrichtigungen vor.",

    play_no_balance: "Das aktuelle Währungsguthaben ist unzureichend. Bitte wechseln Sie die Währung oder laden Sie es auf.",
    
    home_News_1: 'Nachrichten',
    home_News_2: 'Veranstaltung',
    home_News_3: 'Krypto',
    home_News_4: 'Gaming',
    
    home_SuggRep_1: 'Vorschläge & Berichte',
    home_SuggRep_2: 'Vorschläge & Berichte',
    home_SuggRep_3: 'Teilen Sie Feedback zur Verbesserung!',
    home_SuggRep_4: 'Website melden, die In-Game verkauft.',
    home_SuggRep_5: 'Geben Sie Vorschläge ein oder melden Sie sich.',
    home_SuggRep_6: 'Beispielscreenshot',
    home_SuggRep_7: 'Belohnung für angenommene Vorschläge und Berichte!',
    home_SuggRep_8: 'einreichen',
    
    home_subscription_1: 'Möchten Sie sich für Push-Benachrichtigungen anmelden?',
    home_subscription_2: 'Sie können sich jederzeit abmelden.',
    home_subscription_3: 'Web-Push von WonderPush',
    home_subscription_4: 'Später',
    home_subscription_5: 'Abonnieren',
    
    home_Affiliate_1: 'Kontaktiere uns',
    home_Affiliate_2: 'Jeder Freund, den Sie einladen, bringt Ihnen 10U ein. Je mehr Sie einladen, desto mehr erhalten Sie!',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: 'E-Mail',
    
    home_banner_1: 'Krypto-Gaming',
    home_banner_2: 'Unterstützung von 100+ Kryptowährungen & 50+ NFTs',
    home_banner_3: 'Dezentrales Wetten',
    home_banner_4: 'Smart Contract-Wetten aus dezentraler Wallet',
    home_banner_5:'Bet 2 Earn',
    home_banner_6:'Bet, mine, and invest for steady profits',
    home_banner_7: 'Web3.0 + Casino',
    home_banner_8: 'Spieler ist auch Bankier & Besitzer',

    home_EventDetails: 'Details',

    home_vip_1: "1.Einnahmen durch Anmeldung+",
    home_vip_2: "2.Einnahmen durch Drehen+",
    home_vip_3: "3.Einnahmen durch Teilen+",
    home_vip_4: "4.Einnahmen durch Aufladen+",
    home_vip_5: "5.Chat freigeschaltet, täglich versendbar",
    home_vip_6: "Nachrichten",

    ERROR_628: 'Gutscheincode wurde bereits eingelöst',
    ERROR_629: 'Gutscheincode wurde bereits vollständig eingelöst',
    ERROR_630: 'Gutscheincode ist nicht aktiv',
    ERROR_631: 'Gutscheincode ist abgelaufen',
    ERROR_633: 'Gutscheincode eines anderen Vertreters kann nicht verwendet werden',

    home_Maintaining_1: "Instandhaltung",
    home_Maintaining_2: "Diese Funktion wird gewartet",
    home_Maintaining_3: "ENDE",
    home_Maintaining_4: "START",

    home_Slots: 'Slots',
    home_LiveCasino: 'Live Casino',
    home_CountryFiat: 'Land & Fiat-Währung',
    home_CryptNFTs: 'Kryptowährung & NFTs',

    home_GoTo:'Gehe zu',

    home_gameout_1: "Befinden Sie sich gerade in einem anderen Spiel? Möchten Sie beenden?",
    home_gameout_2: "Abbrechen",

     home_BuyFund1:'Möchten Sie einen Wachstumsfonds kaufen?',

     home_BetFor:'Wetten für',

     tongzhi_1: "Vorschlag",
     tongzhi_2: "Antworten auf den Vorschlag und den Vertreter.",
     tongzhi_3: "Gut gemacht! Das ist großartig.",
     tongzhi_4: "Warten auf Überprüfung.",
     tongzhi_5: "Überprüfung genehmigt.",
     tongzhi_6: "Zahlung in Bearbeitung.",
     tongzhi_7: "Zahlung erfolgreich abgeschlossen.",
     tongzhi_8: "Überprüfung abgelehnt.",
     tongzhi_9: "Zahlung fehlgeschlagen.",
     tongzhi_10: "Abhebung fehlgeschlagen.",
     tongzhi_11: "Bezahlt.",
     tongzhi_12: "Abgeschlossen.",
     tongzhi_13: "Verarbeitung fehlgeschlagen.",
     tongzhi_14: "Kaufen",
     tongzhi_15: "Bis",
     tongzhi_16: "Abheben",
     tongzhi_17: "Kryptowährung",
     tongzhi_18: "Fiat-Währung",
     tongzhi_19: "Herunterladen ",
     tongzhi_20: "Anmeldung kann belohnt werden",
     tongzhi_21: "Herunterladen",
     tongzhi_22: "Derzeit keine iOS-Version verfügbar",
     tongzhi_23: "Senden",
     tongzhi_24: "Tipps senden",

     home_banner_9: "Pferderennen",
     home_banner_10: "Hahnenkampf",
     home_banner_11: "Spannend und aufregend, gewinnen Sie große Preise",
     home_banner_12: "Blutig und voller Kampfgeist",
     
     home_wanfa_1:"Gameplay-Problem",
     home_wanfa_2:"Überweisungsproblem",
     home_wanfa_3:"Anderes Problem",
     play_not_balance: "Sportwetten mit eUSDT sind nicht möglich. Möchten Sie die Währung wechseln? ",




    
    };
    
    export default locale;
    
    
