import PhoneChat from './PhoneChat';


const PhoneChatConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'phoneChat',
            element: <PhoneChat />,
        },
    ],
};

export default PhoneChatConfig;