import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import withReducer from 'app/store/withReducer';
import keycode from 'keycode';
import { memo, useCallback, useEffect, useRef,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Chat from './Chat';
import ContactList from './ContactList';
import reducer from './store';
import { getContacts, selectContacts, selectSelectedContactId } from './store/contactsSlice';
import { closeChatPanel, openChatPanel, selectChatPanelState } from './store/stateSlice';
import { getUserData } from './store/userSlice';
import { getChats } from './store/chatsSlice';
import { useTranslation } from "react-i18next";
import { getChat } from './store/chatSlice';
import { isMobile } from "../../../util/tools/function";
import { selectConfigData } from 'app/store/config';


const standardWith = 1600;
const scrollWith = window.screen.width;
let scale = 1;
if (750 < scrollWith && scrollWith < 1600) {
  scale = (scrollWith / standardWith);
  if (0 < scale && scale <= 1) {
    document.getElementsByTagName('body')[0].style.zoom = scale;
  }
}
const Root = styled('div')(({ theme, opened }) => ({
  position: 'sticky',
  display: 'flex',
  top: 0,
  width: 70,
  maxWidth: 70,
  minWidth: 70,
  height: 100 / scale + 'vh',
  zIndex: 1000,

  [theme.breakpoints.down('lg')]: {
    position: 'fixed',
    height: '100%',
    width: 0,
    maxWidth: 0,
    minWidth: 0,
  },

  ...(opened && {
    overflow: 'visible',
  }),

  ...(!opened && {
    overflow: 'hidden',
    animation: `hide-panel 1ms linear ${theme.transitions.duration.standard}`,
    animationFillMode: 'forwards',
  }),

  '& > .panel': {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 360,
    minWidth: 360,
    height: '100%',
    margin: 0,
    overflow: 'hidden',
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    transform: 'translate3d(0,0,0)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),

    ...(opened && {
      transform: theme.direction === 'rtl' ? 'translate3d(290px,0,0)' : 'translate3d(-290px,0,0)',
    }),

    [theme.breakpoints.down('lg')]: {
      left: 'auto',
      position: 'fixed',
      transform: theme.direction === 'rtl' ? 'translate3d(-360px,0,0)' : 'translate3d(360px,0,0)',
      boxShadow: 'none',
      width: 320,
      minWidth: 320,
      maxWidth: '100%',

      ...(opened && {
        transform: 'translate3d(0,0,0)',
        boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      }),
    },
  },

  '@keyframes hide-panel': {
    '0%': {
      overflow: 'visible',
    },
    '99%': {
      overflow: 'visible',
    },
    '100%': {
      overflow: 'hidden',
    },
  },
}));

function ChatPanel(props) {
  const { t } = useTranslation('mainPage');
  const fuseMain = document.getElementById('fuse-main');
  const dispatch = useDispatch();
  const contacts = useSelector(selectContacts);
  const selectedContactId = useSelector(selectSelectedContactId);
  const state = useSelector(selectChatPanelState);
  const theme = useTheme();
  const contactListScroll = useRef(null);
  const configData = useSelector(selectConfigData);

  const ref = useRef();
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      return state && theme.direction === 'rtl' && dispatch(closeChatPanel());
    },
    onSwipedRight: () => {
      return state && theme.direction === 'ltr' && dispatch(closeChatPanel());
    },
  });
  const scrollToTop = () => {
    contactListScroll.current.scrollTop = 0;
  };
  const selectedContact = contacts.find((_contact) => _contact.id === selectedContactId);

  const handleDocumentKeyDown = useCallback(
    (event) => {
      if (keycode(event) === 'esc') {
        dispatch(closeChatPanel());
          if (fuseMain) {
              fuseMain.style.marginRight = '0px'
          }
      }
    },
    [dispatch]
  );
  const [cardId, setCardID] = useState("cd5fa417-b667-482d-b208-798d9da3213c");
  const languageMap = {
    "en": "cd5fa417-b667-482d-b208-798d9da3213c", 
    "de": "a7806ced-03f1-4197-8b30-00bdd463366b", 
    "fr": "f4ad15d9-5a24-463a-88ea-6189d6bb3a53", 
    "in": "780d0111-5e5c-4694-8d1d-0ea421971fbf", 
    "br": "1eaa3213-ece2-4ba6-8e15-eb36ca388f50", 
    "tr": "efae92cc-3bd1-4c6a-a395-b6760c69bd55", 
    "ph": "bde636a7-c3d2-4bff-939a-aab11df1516b", 
    "jp": "6d80a6f6-2884-4ac4-9c73-06b82c220017", 
    "pl": "35190d23-036e-44ef-b545-cc744c626edd", 
    "vn": "b018c194-68ec-4915-ab56-e9f3bd2d98db", 
    "kr": "b7c355e9-e003-467e-82d2-4f6978c1a696", 
    "id": "cfa07b7c-93d1-42e7-9592-493d9efc78ae", 
    "es": "00feeb63-c83a-4655-a37e-a07da10cfa1c", 
    "ru": "142abf21-e635-4a7d-9330-e57f66adcdbe", 
    "fl": "e4f255a3-b5dd-45a7-975f-c399604a399a", 
    "th": "ab4f712d-d712-41a8-b567-be4c66c349a4", 
    "ae": "ab4f712d-d712-41a8-b567-be4c66c349a5", 
    "my": "ab4f712d-d712-41a8-b567-be4c66c349a6", 
    "bd": "ab4f712d-d712-41a8-b567-be4c66c349a7", 
    "mm": "ab4f712d-d612-41a8-b767-be4z76c349a4",
    "pak": "ab9f719d-d692-49a8-b767-be4z76c349a4",
    "hk": "ab4f712d-d712-41a8-b567-be4c66c349a3", 
    "cn": "ab4f712d-d712-41a8-b567-be4z66c349a4"
  };
  useEffect(()=>{
    setCardID(languageMap[localStorage.getItem("lang")])
    // let cardID=languageMap[localStorage.getItem("lang")]
  },[localStorage.getItem("lang")])
  useEffect(() => {
    dispatch(getUserData());
    dispatch(getContacts());
    dispatch(getChats());
    return () => {
      document.removeEventListener('keydown', handleDocumentKeyDown);
    };
  }, [dispatch, handleDocumentKeyDown]);

  useEffect(() => {
    if (state) {
      document.addEventListener('keydown', handleDocumentKeyDown);
    } else {
      document.removeEventListener('keydown', handleDocumentKeyDown);
    }
  }, [handleDocumentKeyDown, state]);

  /**
   * Click Away Listener
   */
  useEffect(() => {
    function handleDocumentClick(ev) {
      if (ref.current && !ref.current.contains(ev.target)) {
        dispatch(closeChatPanel());
      }
    }

    if (state) {
        if (fuseMain) {
            fuseMain.style.marginRight = '290px'
        }
      document.addEventListener('click', handleDocumentClick, true);
    } else {
        if (fuseMain) {
            fuseMain.style.marginRight = '0px'
        }
      document.removeEventListener('click', handleDocumentClick, true);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick, true);
    };
  }, [state, dispatch]);

  return (
    <Root opened={state ? 1 : 0} {...handlers} ref={contactListScroll}>
      <div className="panel flex flex-col max-w-full " ref={ref}>
        <AppBar position="static" className="shadow-md">
          <Toolbar className="px-4">
            {(!state || !selectedContactId) && (
              <div className="flex flex-1 items-center ">
                <IconButton
                  className="ml-6"
                  color="inherit"
                  onClick={(ev) => {
                    dispatch(openChatPanel())
                    dispatch(getChat(languageMap[localStorage.getItem("lang")]))
                    scrollToTop();
                    fuseMain.style.marginRight = '290px'
                  }
                  }
                  size="large"
                >
                  <FuseSvgIcon size={24}>heroicons-outline:chat-alt-2</FuseSvgIcon>
                </IconButton>
                {!selectedContactId && (
                  <Typography className="text-16 pl-24" color="inherit">
                    {t("home_ChatRoom")}
                  </Typography>
                )}
              </div>
            )}

            {state && selectedContact && (
              <div className="flex flex-1 items-center px-4">
                <Avatar src={selectedContact.avatar} />

                {selectedContactId === "beec5287-ed50-4504-858a-5dc3f8ce6935" ? (
                  <div className='promotion-list1 ml-12 items-center w-200 myBetBtnColor height-40 borderRadius btnPointer txtBrightness txtFootColor'>
                    <img className='ml-8' src='assets/images/icon/307.png' />
                    <Typography className=" text-16" color="inherit">
                      {selectedContact.name}
                    </Typography>
                    <div className='yuanTips circle txtBlackColor btnGreenColor text-align mr-4 font-weight500 '>3</div>
                    <img className='mr-8' src='assets/images/index/jianTou.png'></img>
                  </div>
                ) : (
                  <div className='promotion-list1 ml-12 items-center '>
                    <Typography className="mx-16 text-16" color="inherit">
                      {selectedContact.name}
                    </Typography>
                  </div>
                )}

              </div>
            )}

            <div className="flex px-4 ">
              <IconButton onClick={(ev) => {
                dispatch(closeChatPanel())
              }} color="inherit" size="large">
                <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Paper className="flex flex-1 flex-row min-h-px shadow-0">
          <ContactList className="flex shrink-0" />

          {state && selectedContact ? (
            <Chat className="flex flex-1 z-10" />
          ) : (
            <div className="flex flex-col flex-1 items-center justify-center p-24">
              <FuseSvgIcon size={128} color="disabled">
                heroicons-outline:chat
              </FuseSvgIcon>
              <Typography className="px-16 pb-24 mt-24 text-center" color="text.secondary">
                {t("home_Selectacontact")}
              </Typography>
            </div>
          )}
        </Paper>
      </div>
    </Root>
  );
}

export default withReducer('chatPanel', reducer)(memo(ChatPanel));
