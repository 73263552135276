import "../../../styles/game.css";
import * as React from "react";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import utils from "../../util/tools/utils";
import Common_foot from "../common/Common_foot";
import Common_gameReport from "../common/Common_gameReport";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  getTagCasinoGames,
  getCategoryGames,
  getCasinoGames
} from "../../store/casino/casinoThunk";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectCasinoData } from "../../store/casino";
import { updateSearchCasinoChannel } from "../../store/casino/searchCasinoChannel";
import history from "@history";
import FuseLoading from "@fuse/core/FuseLoading";
import { isMobile } from "../../util/tools/function";
import itemIcon from "../apps/file-manager/ItemIcon";
import {selectTopGroup} from "app/store/casino/topGroup";
import {selectNav} from "app/store/casino/nav";

function Provider(props) {
  const { t } = useTranslation("mainPage");
  const dispatch = useDispatch();
  const params = useParams();
  const tagID = params.tagID;

  const [isLoading, setIsLoading] = useState(false);

  const casinoData = useSelector(selectCasinoData);
  const nav = useSelector(selectNav);
  const group = useSelector(selectTopGroup);
  const [ tags, setTags ] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [channel, setChannel] = useState("all");

  const [groupData, setGroupData] = useState({});
  const [gameList, setGameList] = useState([]);
  const [gameitem, setGameitem] = useState([])
  const [numbers, setNumbers] = useState(null)

  const [loading, setLoading] = useState(false);
  const [inputVal, setInputVal] = useState({
    searchText: "",
  });

  const handleChangeInputVal = (prop) => (event) => {
    setInputVal({ ...inputVal, [prop]: event.target.value });
  };

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const motionItem = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };
  let num = [360, 136, 104, 23, 0, 104, 9, 37, 151]
  const handleTabClick = (tagID, tagGroup) => {
    setLoading(true)
    let count0 = 0
    let count1 = 0
    let count2 = 0
    let count3 = 0
    let count4 = 0
    let count5 = 0
    let count6 = 0
    let count7 = 0
    let count8 = 0

    if (tagGroup == 1) {
      dispatch(getCasinoGames({
        mainTagID: tagID
      })).then((res) => {
        const data = res.payload;
        if (data) {
          data.map((item, index) => {
            if (item.gameChannel === "Facai") {
              count7++;
            } else if (item.gameChannel === "PlayTech") {
              count8++;
            } else if (item.gameChannel === "PGSoft") {
              count2++;
            } else if (item.gameChannel === "PM") {
              count3++;
            } else if (item.gameChannel === "Spribe") {
              count6++;
            } else if (item.gameChannel === "Evolution") {
              count1++;
            } else if (item.gameChannel === "JiLi") {
              count5++;
            } else if (item.gameChannel === "PragmaticPlay") {
              count0++;
            } else if (item.gameChannel === "awc") {
              count4++;
            }

          })
          const counts = [count0, count1, count2, count3, count4, count5, count6, count7, count8];
          setTimeout(() => {
            setNumbers(counts)
            setLoading(false)
          }, 2000)
        }
      });
    } else {
      dispatch(getTagCasinoGames({
        tagID
      })).then((res) => {
        res.payload.map((item, index) => {
          if (item.gameChannel === "Facai") {
            count7++;
          } else if (item.gameChannel === "PlayTech") {
            count8++;
          } else if (item.gameChannel === "PGSoft") {
            count2++;
          } else if (item.gameChannel === "PM") {
            count3++;
          } else if (item.gameChannel === "Spribe") {
            count6++;
          } else if (item.gameChannel === "Evolution") {
            count1++;
          } else if (item.gameChannel === "JiLi") {
            count5++;
          } else if (item.gameChannel === "PragmaticPlay") {
            count0++;
          } else if (item.gameChannel === "awc") {
            count4++;
          }

        })
        const counts = [count0, count1, count2, count3, count4, count5, count6, count7, count8];
        setTimeout(() => {
          setNumbers(counts)
          setLoading(false)
        }, 2000)
      })
    }
    // if (tagID !== casinoData.recommondTag) {
    //   history.push(`/gameOther/${tagID}/${tagName}/0`);
    // }
  };
  const imgOnLoad = (ref, src) => {
    let dom = document.getElementById(ref);
    if (src) {
      dom.src = src;
    }
  };
  useEffect(() => {
    if (JSON.stringify(tags) !== "{}") {
      setSelectedTab(tags[Object.keys(tags)[0]].tagID)
      handleTabClick(tags[Object.keys(tags)[0]].tagID, tags[Object.keys(tags)[0]].tagGroup)
    }
  }, [tags])
  useEffect(() => {
    // dispatch(getTagCasinoGames({
    //     tagID
    // })).then((res) => {
    //     let result = res.payload;
    //
    //     if (result) {
    //         let tmpData = [];
    //         result.map((item) => {
    //             if (tmpData[item.casinoTagID]) {
    //                 tmpData[item.casinoTagID].push(item)
    //             } else {
    //                 tmpData[item.casinoTagID] = [item];
    //             }
    //         });
    //         setGameList(tmpData);
    //     }
    // })
    setIsLoading(true);
    dispatch(getCategoryGames()).then((res) => {
      var pageSize = 14;
      if (isMobile()) {
        pageSize = 15;
      }

      let result = res.payload;
      if (result) {
        let tmpData = [];
        Object.keys(result).map((index) => {
          tmpData[index] = result[index].slice(0, pageSize);
        });
        setGameList(tmpData);
        setIsLoading(false);
      }
    });
  }, [tagID]);

  useEffect(() => {
    setIsLoading(true);
    if (group.length > 0) {
      let tmpGroup = {};
      group.map((item) => {
        tmpGroup[item.tagID] = item;
      });
      setGroupData(tmpGroup);
      setIsLoading(false);
    }
  }, [group]);

  useEffect(() => {
    if (JSON.stringify(nav.allNavTagList) !== "{}") {
      let tmpTags = {}
      tmpTags[0] = {
        tagID: 0,
        gameCount: 0,
        mainMenu: true,
        nullity: false,
        sortID: 0,
        tagDesc: "Video/Classic",
        tagGroup: 1,
        tagGroupDesc: "Video/Classic",
        tagName: "All Games",
      }
      Object.keys(nav.allNavTagList).map((tag) => {
        var item = nav.allNavTagList[tag]
        if (item.tagGroup == 1 || item.tagGroup == 4) {
          tmpTags[item.tagID] = item
        }
      })

      console.log(tmpTags, 'tmpTags')
      setTags(tmpTags)
    }
  }, [nav.allNavTagList]);
  return (
    <div className="flex w-full container">
      <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 p-10 md:p-32 pb-0 md:pb-0">
        <div className="flex flex-col flex-auto align-item">
          <div className="flex grow shrink-0 flex-col items-center container ">
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="activitybanner"
            >
              <motion.div variants={motionItem} className="min-w-full ">
                <div className="zuQiu mt-20">
                  <div className="activitybanner marginJuZhong">
                    <Tabs
                      value={selectedTabIndex}
                      indicatorColor="primary"
                      textColor="inherit"
                      variant="scrollable"
                      scrollButtons={true}
                      className="min-h-40 recommendWidth backGround-foot borderRadius "
                      classes={{
                        indicator:
                          "flex justify-center bg-transparent w-full h-full",
                      }}
                      TabIndicatorProps={{
                        children: (
                          <Box
                            sx={{ bgcolor: "text.disabled" }}
                            className="w-full h-full rounded-full gamesListBtnBackground-Color opacity-100"
                          />
                        ),
                      }}
                    >
                      {JSON.stringify(tags) !== "{}" &&
                        Object.keys(tags).map((item) => {
                          if (tags[item].tagGroup == 1 || tags[item].tagGroup == 4) {
                            return (
                              <Tab
                                key={tags[item].tagID}
                                className="text-14 font-semibold min-h-40 min-w-120 Zindex px-12 flex "
                                disableRipple
                                icon={
                                  <img
                                    className="w-24 h-24"
                                    src={
                                      "assets/images/icon/" +
                                      tags[item].tagName +
                                      ".png"
                                    }
                                  ></img>
                                }
                                iconPosition="start"
                                label={t(
                                  `navigation:${tags[item].tagName
                                    .replace(/\s*/g, "")
                                    .replace(/-/g, "")}`
                                )}
                                onClick={() => {
                                  let index = Object.keys(tags).indexOf(item)
                                  setSelectedTabIndex(index)
                                  setSelectedTab(tags[item].tagID);
                                  console.log(tags[item].tagID, tags[item].tagGroup, 'tags[item].tagID, tags[item].tagGroup')
                                  handleTabClick(tags[item].tagID, tags[item].tagGroup);
                                }}
                              />
                            );
                          }
                        })}
                    </Tabs>
                  </div>
                </div>
                {loading && (
                  <div className="mt-20" style={{ marginTop: "5rem" }}>
                    <FuseLoading />
                  </div>
                )}
                {loading || numbers == null ?
                  ''
                  : <motion.div
                    variants={container}
                    initial="hidden"
                    animate="show"
                    className="activitybanner zuQiu mt-20 gameMt1 promotion-list2 "
                  >
                    {numbers[0] == 0 ? "" : <motion.div variants={motionItem} className="marginXY providerImg">
                      <img
                        className="btnPointer txtBrightness positionAb"
                        style={{
                          top: "0px",
                          left: "0px",
                          right: "0px",
                          width: "153px",
                          height: "60px",
                        }}
                        src="assets/images/work/1.png"
                        onClick={() => {
                          var tmpUrl = ""
                          if (tags[selectedTab].tagGroup === 1) {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/1`
                          } else {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/0`
                          }
                          dispatch(updateSearchCasinoChannel('PragmaticPlay'))
                          history.push(tmpUrl)
                        }}
                      ></img>
                      <div
                        className="item-badge text-white rounded-full notice-num positionAb"
                        style={{ top: "-8px", right: "-8px" }}
                      >
                        {numbers[0]}
                      </div>
                    </motion.div>}
                    {numbers[1] == 0 ? "" : <motion.div variants={motionItem} className="marginXY providerImg">
                      <img
                        className="btnPointer txtBrightness positionAb"
                        style={{
                          top: "0px",
                          left: "0px",
                          right: "0px",
                          width: "153px",
                          height: "60px",
                        }}
                        src="assets/images/work/2.png"
                        onClick={() => {
                          var tmpUrl = ""
                          if (tags[selectedTab].tagGroup === 1) {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/1`
                          } else {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/0`
                          }
                          dispatch(updateSearchCasinoChannel('Evolution'))
                          history.push(tmpUrl)
                        }}
                      ></img>
                      <div
                        className="item-badge text-white rounded-full notice-num positionAb"
                        style={{ top: "-8px", right: "-8px" }}
                      >
                        {numbers[1]}

                      </div>
                    </motion.div>}
                    {numbers[2] == 0 ? "" : <motion.div variants={motionItem} className="marginXY providerImg">
                      <img
                        className="btnPointer txtBrightness positionAb"
                        style={{
                          top: "0px",
                          left: "0px",
                          right: "0px",
                          width: "153px",
                          height: "60px",
                        }}
                        src="assets/images/work/3.png"
                        onClick={() => {
                          var tmpUrl = ""
                          if (tags[3].tagGroup === 1) {
                            tmpUrl = `/gameOther/${tags[3].tagID}/${tags[3].tagName}/1`
                          } else {
                            tmpUrl = `/game/${tags[3].tagName}/0`
                          }
                          dispatch(updateSearchCasinoChannel('PGSoft'))
                          history.push(tmpUrl)
                        }}
                      ></img>
                      <div
                        className="item-badge text-white rounded-full notice-num positionAb"
                        style={{ top: "-8px", right: "-8px" }}
                      >
                        {numbers[2]}
                      </div>
                    </motion.div>}
                    {numbers[3] == 0 ? "" : <motion.div variants={motionItem} className="marginXY providerImg">
                      <img
                        className="btnPointer txtBrightness positionAb"
                        style={{
                          top: "0px",
                          left: "0px",
                          right: "0px",
                          width: "153px",
                          height: "60px",
                        }}
                        src="assets/images/work/4.png"
                        onClick={() => {
                          var tmpUrl = ""
                          if (tags[selectedTab].tagGroup === 1) {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/1`
                          } else {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/0`
                          }
                          dispatch(updateSearchCasinoChannel('PM'))
                          history.push(tmpUrl)
                        }}
                      ></img>
                      <div
                        className="item-badge text-white rounded-full notice-num positionAb"
                        style={{ top: "-8px", right: "-8px" }}
                      >
                        {numbers[3]}
                      </div>
                    </motion.div>}
                    {numbers[4] == 0 ? "" : <motion.div variants={motionItem} className="marginXY providerImg">
                      <img
                        className="btnPointer txtBrightness positionAb"
                        style={{
                          top: "0px",
                          left: "0px",
                          right: "0px",
                          width: "153px",
                          height: "60px",
                        }}
                        src="assets/images/work/5.png"
                        onClick={() => {
                          var tmpUrl = ""
                          if (tags[selectedTab].tagGroup === 1) {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/1`
                          } else {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/0`
                          }
                          dispatch(updateSearchCasinoChannel('awc'))
                          history.push(tmpUrl)
                        }}
                      ></img>
                      <div
                        className="item-badge text-white rounded-full notice-num positionAb"
                        style={{ top: "-8px", right: "-8px" }}
                      >
                        {numbers[4]}
                      </div>
                    </motion.div>
                    }
                    {numbers[5] == 0 ? "" : <motion.div variants={motionItem} className="marginXY providerImg">
                      <img
                        className="btnPointer txtBrightness positionAb"
                        style={{
                          top: "0px",
                          left: "0px",
                          right: "0px",
                          width: "153px",
                          height: "60px",
                        }}
                        src="assets/images/work/6.png"
                        onClick={() => {
                          var tmpUrl = ""
                          if (tags[selectedTab].tagGroup === 1) {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/1`
                          } else {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/0`
                          }
                          dispatch(updateSearchCasinoChannel('JiLi'))
                          history.push(tmpUrl)
                        }}
                      ></img>
                      <div
                        className="item-badge text-white rounded-full notice-num positionAb"
                        style={{ top: "-8px", right: "-8px" }}
                      >
                        {numbers[5]}
                      </div>
                    </motion.div>}
                    {numbers[6] == 0 ? "" : <motion.div variants={motionItem} className="marginXY providerImg">
                      <img
                        className="btnPointer txtBrightness positionAb"
                        style={{
                          top: "0px",
                          left: "0px",
                          right: "0px",
                          width: "153px",
                          height: "60px",
                        }}
                        src="assets/images/work/7.png"
                        onClick={() => {
                          var tmpUrl = ""
                          if (tags[selectedTab].tagGroup === 1) {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/1`
                          } else {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/0`
                          }
                          dispatch(updateSearchCasinoChannel('Spribe'))
                          history.push(tmpUrl)
                        }}
                      ></img>
                      <div
                        className="item-badge text-white rounded-full notice-num positionAb"
                        style={{ top: "-8px", right: "-8px" }}
                      >
                        {numbers[6]}
                      </div>
                    </motion.div>}
                    {numbers[7] == 0 ? "" : <motion.div variants={motionItem} className="marginXY providerImg">
                      <img
                        className="btnPointer txtBrightness positionAb"
                        style={{
                          top: "0px",
                          left: "0px",
                          right: "0px",
                          width: "153px",
                          height: "60px",
                        }}
                        src="assets/images/work/8.png"
                        onClick={() => {
                          var tmpUrl = ""
                          if (tags[selectedTab].tagGroup === 1) {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/1`
                          } else {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/0`
                          }
                          dispatch(updateSearchCasinoChannel('Facai'))
                          history.push(tmpUrl)
                        }}
                      ></img>
                      <div
                        className="item-badge text-white rounded-full notice-num positionAb"
                        style={{ top: "-8px", right: "-8px" }}
                      >
                        {numbers[7]}
                      </div>
                    </motion.div>}
                    {numbers[8] == 0 ? "" : <motion.div variants={motionItem} className="marginXY providerImg">
                      <img
                        className="btnPointer txtBrightness positionAb"
                        style={{
                          top: "0px",
                          left: "0px",
                          right: "0px",
                          width: "153px",
                          height: "60px",
                        }}
                        src="assets/images/work/9.png"
                        onClick={() => {
                          var tmpUrl = ""
                          if (tags[selectedTab].tagGroup === 1) {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/1`
                          } else {
                            tmpUrl = `/gameOther/${tags[selectedTab].tagID}/${tags[selectedTab].tagName}/0`
                          }
                          dispatch(updateSearchCasinoChannel('PlayTech'))
                          history.push(tmpUrl)
                        }}
                      ></img>
                      <div
                        className="item-badge text-white rounded-full notice-num positionAb"
                        style={{ top: "-8px", right: "-8px" }}
                      >
                        {numbers[8]}
                      </div>
                    </motion.div>}
                  </motion.div>
                }
                {/* <div className="width-180 pt-16 marginJuZhong ">
                  <Button
                    className="whitespace-nowrap width-180  borderRadius text-18 py-24 funbetTxt"
                    variant="contained"
                    color="secondary"
                  >
                    {t("game_LoadMore")}
                  </Button>
                </div> */}

                {/*<div className="zuQiu mt-20 displayPhoneSwitch">*/}
                {/*    <div className="promotion-list activitybanner marginJuZhong hidden wrap">*/}
                {/*        <img className="btnPointer maxWidth borderRadius-10 " src="assets/images/game/1.png" onClick={() => { history.push('/play') }}></img>*/}
                {/*        <img className="btnPointer maxWidth borderRadius-10 " src="assets/images/game/2.png" onClick={() => { history.push('/play') }}></img>*/}
                {/*        <img className="btnPointer maxWidth borderRadius-10 " src="assets/images/game/3.png" onClick={() => { history.push('/play') }}></img>*/}
                {/*    </div>*/}
                {/*</div>*/}
              </motion.div>
            </motion.div>
          </div>
          <Common_gameReport />
          <Common_foot />
          <div className="mt-32  promotion-list colorGameListTitle mb-20 displayPhoneSwitch1">
            <div>FuniBet 2022- ALL Rights Reserved</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Provider;
