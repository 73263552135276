import FuseNavigation from '@fuse/core/FuseNavigation';
import clsx from 'clsx';
import { memo, useMemo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation } from 'app/store/fuse/navigationSlice';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import { getCasinoType } from "../../store/casino/casinoThunk";
import { selectCasinoData } from "../../store/casino";
import { updateAllNavTagList } from "../../store/casino/nav";
import { updateTagAndGroup } from "../../store/casino/topGroup";
import { updateTags } from "../../store/casino/tags";
import { useTranslation } from "react-i18next";
import { getNewsList } from "../../store/config/configThunk";
import { divide } from 'lodash';
import { selectUserData } from "app/store/user";
import { changeLanguage } from "../../util/tools/function";
import countryLang from "../../json/country";

function Navigation(props) {
    const navigation = useSelector(selectNavigation);
    const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
    const { t } = useTranslation('navigation');
    const ref = useRef(0);
    const [navList, setNavList] = useState([]);

    const dispatch = useDispatch();
    const casinoData = useSelector(selectCasinoData);
    const userData = useSelector(selectUserData);

    // 获取列表
    const getNavList = () => {
        dispatch(getCasinoType({
            tagGroup: ''
        })).then((res) => {
            let result = res.payload;
            if (result.list) {
                let tmpNav = [];
                let tmpMoreGame = [];
                let tmpGroup = [];
                let tmpTag = [];
                let allTag = {};
                result.list.map((item) => {
                    let tmpUrl = '';
                    if (item.tagGroup === 1) {
                        tmpUrl = `gameOther/${item.tagID}/${t(item.tagName.replace(/\s*/g, '').replace(/-/g, ''))}/1`
                    } else {
                        // tmpUrl = `game/${item.tagID}`
                        tmpUrl = `gameOther/${item.tagID}/${t(item.tagName.replace(/\s*/g, '').replace(/-/g, ''))}/0`
                    }

                    if (item.mainMenu) {
                        if (item.gameCount) {
                            tmpNav.push({
                                id: t(item.tagName.replace(/\s*/g, '').replace(/-/g, '')),
                                title: t(item.tagName.replace(/\s*/g, '').replace(/-/g, '')),
                                type: 'item',
                                icon: `heroicons-outline:${item.tagName}`,
                                url: tmpUrl,
                                translate: t(item.tagName.replace(/\s*/g, '').replace(/-/g, '')),
                                end: true,
                                badge: {
                                    title: item.gameCount,
                                    classes: 'text-white rounded-full',
                                },
                            })
                        } else {
                            tmpNav.push({
                                id: t(item.tagName.replace(/\s*/g, '').replace(/-/g, '')),
                                title: t(item.tagName.replace(/\s*/g, '').replace(/-/g, '')),
                                type: 'item',
                                icon: `heroicons-outline:${item.tagName}`,
                                url: tmpUrl,
                                translate: t(item.tagName.replace(/\s*/g, '').replace(/-/g, '')),
                                end: true
                            })
                        }
                    } else {
                        if (item.tagID !== casinoData.recommondTag && item.nullity === false) {
                            tmpMoreGame.push({
                                id: t(item.tagName.replace(/\s*/g, '').replace(/-/g, '')),
                                title: t(item.tagName.replace(/\s*/g, '').replace(/-/g, '')),
                                type: 'item',
                                icon: `heroicons-outline:${item.tagName}`,
                                url: tmpUrl,
                                translate: t(item.tagName.replace(/\s*/g, '').replace(/-/g, '')),
                                end: true,
                            })
                        }
                    }

                    if (item.tagGroup !== 1) {
                        tmpTag.push(item);
                    } else {
                        tmpGroup.push(item);
                    }

                    allTag[item.tagID] = item;
                });

                tmpNav.push({
                    id: t('MoreGames'),
                    title: t('MoreGames'),
                    type: 'collapse',
                    icon: `heroicons-outline:E-Sports`,
                    translate: t('MoreGames'),
                    children: tmpMoreGame,
                });

                tmpMoreGame.push({
                    id: t('AllGames'),
                    title: t('AllGames'),
                    type: 'item',
                    icon: `heroicons-outline:All Game`,
                    url: 'gameOther/0/All Games/1',
                    end: true,
                });

                setTimeout(() => {
                    dispatch(updateTagAndGroup({
                        topGroup: tmpGroup,
                    }));
                    dispatch(updateTags({
                        tags: tmpTag,
                    }))
                    dispatch(updateAllNavTagList(allTag));
                }, 500)

                navigation[0].children[0].children = tmpNav;
                setNavList(navigation)
            }
        });
    };

    useEffect(() => {
        setTimeout(() => {
            dispatch(getNewsList({
                classID: 1,
                lang: changeLanguage(countryLang, window.localStorage.getItem('lang')),
            })).then((res) => {
                let result = res.payload;
                navigation[1].children[1] = {
                    id: 'News',
                    title: t('News'),
                    type: 'item',
                    icon: 'heroicons-outline:clipboard-list',
                    url: 'documentation/getting-started/introduction/79',
                    translate: t('News'),
                    end: true,
                    badge: {
                        title: result.length,
                        classes: 'text-white rounded-full',
                    },
                }

                setNavList(navigation)
            })
        }, 200)
    }, [window.localStorage.getItem('lang')]);

    useEffect(() => {
        getNavList();
    }, [navigation, userData.token]);

    const handleItemClick = (item) => {
        if (isMobile) {
            dispatch(navbarCloseMobile());
        }
    }

    // return useMemo(() => {
    //     function handleItemClick(item) {
    //         if (isMobile) {
    //             dispatch(navbarCloseMobile());
    //         }
    //     }
    //     return (
    //             <FuseNavigation
    //             className={clsx('navigation', props.className)}
    //             navigation={navigation}
    //             layout={props.layout}
    //             dense={props.dense}
    //             active={props.active}
    //             onItemClick={handleItemClick}
    //         />
    //     );
    // }, [dispatch, isMobile, navigation, props.active, props.className, props.dense, props.layout]);

    return (
        <FuseNavigation
            className={clsx('navigation', props.className)}
            navigation={navList}
            layout={props.layout}
            dense={props.dense}
            active={props.active}
            onItemClick={handleItemClick}
        />
    );
}

Navigation.defaultProps = {
    layout: 'vertical',
};

export default memo(Navigation);
