  const locale = {
    GAME: 'لعبة',
    Casino: "كازينو",
    ProvablyFair: "عادل وموثوق به",
    Option: 'الخيارات الثنائية',
    Crypto: 'العملات الرقمية',
    Forex: 'تداول العملات الأجنبية',
    Stocks: 'الأسهم',
    NFTFuniCard: 'بطاقة شحن NFT',
    StakeNFT: 'الرهان على NFT لكسب USDT',
    NFTStake: 'الرهان على NFT',
    Marketplace: 'السوق',
    NFTMint: 'صياغة NFT',
    BettoEarn: 'الرهان للكسب',
    EarnUSDT: 'كسب USDT',
    DeFi: 'تمويل غير مركزي',
    ToBeVolunteer: 'إلى متطوع',
    SYSTEM: 'النظام',
    Affiliate: 'الوسيط',
    News: 'الأخبار',
    FAQs: 'الأسئلة الشائعة',
    DOWNLOAD: 'تحميل',
    DOWNLOADApp: 'تحميل التطبيق',
    Savetodesktop: 'حفظ على سطح المكتب',
    BetMining: 'تعدين الرهان',
    ToBeBanker: 'أصبح شريكًا',
    ProfitPool: 'بركة الأرباح',
    USGTLiquidityMining: 'تعدين سيولة USDT',
    BGTLiquidityMining: 'تعدين سيولة BGT',
    Slots: 'ألعاب القمار',
    Favorite: 'المفضلة',
    Recent: 'الأخيرة',
    Popular: 'الأكثر شعبية',
    LiveCasino: "كازينو مباشر",
    TableGames: "ألعاب طاولة",
    BlackJack: "البلاك جاك",
    Baccarat: "الباكارات",
    Roulette: "الروليت",
    MoreGames: "المزيد من الألعاب",
    Provablyfair: "نزيهة بإمكان الدلالة",
    Special: "خاص",
    Recommend: "موصى به",
    FeatureBuyin: "شراء الخاصية",
    EnableTrialPlay: "تمكين اللعب التجريبي",
    GameShow: "عرض الألعاب",
    RGSVSB: "RGS - VSB",
    Scratch: "الخدش",
    Bingo: "البنغو",
    Fishing: "صيد الأسماك",
    Turntable: "الدراجة الدوارة",
    Dice: "النرد",
    Poker: "البوكر",
    AllGames: "جميع الألعاب",
    EuropeanView: "رؤية أوروبية",
    AfricanView: "رؤية إفريقية",
    LatinView: "رؤية لاتينية",
    AsianView: "رؤية آسيوية",
    LsportView: "رؤية الدوريات",
    StandardView: "رؤية قياسية",
    EsportView: "الرياضات الإلكترونية",
    LiveBetting: 'الرهان المباشر',
    Provider: 'المزود',
    Sport:'الرياضة',
    
    
    };
    
    export default locale;
    
