import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    allSymbols: {}, // 所有虚拟币
};

const symbolsSlice = createSlice({
    name: 'symbols',
    initialState,
    reducers: {

        updateAllSymbols: (state, action) => {
            let res = action.payload;
            let tmpData = {};
            res.map((item) => {
                tmpData[item.symbol] = item
            });
            state.allSymbols = tmpData;
        },
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateAllSymbols } = symbolsSlice.actions;

export const selectSymbols = ({ symbols }) => symbols;

export default symbolsSlice.reducer;
