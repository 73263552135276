import "../../../styles/index.css";
import "../../../styles/xianStyle.css";
import utils from '../../util/tools/utils'
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { doSaveToWallet } from "app/store/user/userThunk";
import { useState, useEffect, forwardRef, default as React } from 'react';
import user from "app/store/user";
import FuseLoading from "@fuse/core/FuseLoading";

function GameTips(props) {
    const { t } = useTranslation('mainPage');
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };

    const onExit = () => {
        setIsLoading(true)
        dispatch(doSaveToWallet()).then((res) => {
            if (res.payload) {
                setIsLoading(false)
                setOpen(false)
                if (props.successFun) {
                    props.successFun()
                }
            }
        })
    }

    useEffect(() => {
        if (props.open) {
            setOpen(true)
        }
    }, [props]);

    return (
        <>
            {open && (
                <div style={{ position: "fixed", bottom: "40%", margin: "0px auto", left: "0%", right: "0%", zIndex: "999" }} >
                    <motion.div
                        variants={container}
                        initial="hidden"
                        animate="show"
                    >
                        <motion.div variants={item} className="gameTipsSty px-20 py-20 mx-auto" >
                            <div className="cookiesTxt1">
                                <div className="" style={{ fontSize: "20px", marginBottom: "4px", width: "330px", wordWrap: 'break-word' }}>{t("home_gameout_1")}</div>
                            </div>
                            <div className="flex justifyContent mt-16">
                                {isLoading ? (
                                    <div className='flex justify-center items-center' style={{height: '40px'}}>
                                        <FuseLoading />
                                    </div>
                                ) : (<>
                                    <Button className="whitespace-nowrap borderRadius px-24 gameTipsBtn  funbetTxt " variant="contained" color="secondary" style={{ fontSize: "16px" }}
                                            onClick={() => {
                                                onExit()
                                            }}
                                    >
                                        {t("home_confirms")}
                                    </Button>
                                </>)}
                            </div>
                        </motion.div>
                    </motion.div>
                </div>
            )}
        </>
    );
}
export default GameTips;


